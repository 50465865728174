import styled from 'styled-components'
import { Box, H2 } from '../ui/primitives'

export const StyledRoomCard = styled(Box)`
    width: 180px;
    height: 180px;
    background: var(--ELK-Black);
    padding: 10px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h2 {
      height: 92px;
      width: 90%;
      font-style: normal;
      font-weight: 800;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
    }
`

export const StyledMembers = styled.li`
      font-size: 12px;
      font-style: normal;
      font-weight: 800;
      color: ${(props) => props.active ? 'var(--ELK-Sea-Foam-Green)' : 'var(--Elk-White)'};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
`

export const NewRoomCard = styled(Box)`
    min-width: 180px;
    max-height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

export const NewRoomButton = styled.button`
    height: 140px;
    width: 100%;
    display: flex;
    border-radius: 6px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
    background: var(--ELK-Black);
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
`

export const PlusTitle = styled.span`
  font-size: 50px;
  font-weight: 400;
`

export const RoomInput = styled.input`
    background: var(--ELK-White);
    height: 33px;
    border-radius: 6px;
    color: var(--ELK-Black);
    font-size: 12px;
    border: none;
    width: 100%;
    font-weight: 800;
    text-align: center;
    margin-right: 5px;
`

export const EditIconWrapper = styled(Box)`
    position: absolute;
    right: 10px;
    cursor: pointer;
`

export const CardFooter = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

export const RoomTitle = styled(H2)`
  cursor: pointer;
  color: ${(props) => props.disabled ? 'var( --ELK-Dark-Grey)' : 'unset'};
  font-size: ${(props) => `${42 - (props.title.length > 25 ? 20 : props.title.length)}px`};
`
