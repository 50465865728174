import React, { useRef, useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'

import { useStore } from '../../../../store'
import { Box } from '../../../ui/primitives'

import { ELKBlack, ELKStandardEnabled, ELKDarkGrey } from '../../../../variables'
import { BOARD_CONNECTION_STATE_CONNECTED } from '../../../../store/board-connection-states'

const Handle = styled(Box)`
  display: flex;
  align-items: center;
  width: 28px;
  height: 40px;
  border-radius: 6px;
  margin: 0 auto;
${({ stereoMode }) => stereoMode ? 'position: relative; left: 2px;' : ''}
  background-color: ${({ $disabled }) =>
    $disabled ? 'var(--ELK-UI-Disabled)' : 'var(--ELK-White)'};
  }
`

const HandleMarker = styled.div`
    height: 2px;
    background: var(--ELK-Black);
    width: 100%;
    margin: 0px 4px;
    border-radius: 4px;
`

const Container = styled(Box)`
    width: 50px;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin: 0 auto;
`

const FaderGraphic = observer(({ value, disabled, stereoMode = false }) => {
  const canvasRef = useRef(null)

  useEffect(() => {
    const canvas = canvasRef.current
    if (!canvas) return

    let height = 0
    let width = 0

    /* This line is important since we base the canvas height and width on the parent element
     * If we don't have this line the parent element height and width will depend on the canvas
     * and vice versa, leading to potential bugs */
    canvas.style.position = 'absolute'
    canvas.style.left = stereoMode ? '5px' : '0'
    const context = canvas.getContext('2d')
    const drawFrame = (v) => {
      const barWidth = Math.round(width * 0.1)
      const barOffset = Math.round((width - barWidth) / 2)
      const valueHeight = Math.round(height * (1 - (disabled ? 0 : value)))
      // fill background
      context.fillStyle = ELKBlack
      context.fillRect(0, 0, width, height)

      // Bar (below handle)
      context.fillStyle = ELKStandardEnabled
      context.fillRect(barOffset, 0, barWidth, height)
      // parallel lines when in stereo mode
      if(stereoMode) context.fillRect(barWidth + 10, 0, barWidth, height);

      // Bar (above handle)
      context.fillStyle = ELKDarkGrey
      context.fillRect(barOffset, 0, barWidth, valueHeight)
      if(stereoMode) context.fillRect(barWidth + 10 , 0, barWidth, valueHeight)

    }
    const updateWindowSize = () => {
      /* Wrapped in requestAnimationFrame to get correct values for rect */
      requestAnimationFrame(() => {
        const rect = context.canvas.parentElement.getBoundingClientRect()
        context.canvas.width = rect.width
        context.canvas.height = rect.height
        height = context.canvas.height
        width = context.canvas.width
        drawFrame(value)
      })
    }
    updateWindowSize()
    window.addEventListener('resize', updateWindowSize)
    return () => {
      window.removeEventListener('resize', updateWindowSize)
    }
  }, [canvasRef, value, disabled, stereoMode])

  return <canvas ref={canvasRef} />
})

export const Fader = observer(function Fader({
  value,
  onChange,
  disabled = true,
  stereoMode = false
}) {
  const [handleBottomOffset, setHandleBottomOffset] = useState(0)
  const store = useStore()
  const wrapperRef = useRef()
  const handleRef = useRef()
  const boardIsConnected = store.boardConnectionState === BOARD_CONNECTION_STATE_CONNECTED

  const updateValue = useCallback(
    (ev) => {
      const rect = wrapperRef.current.getBoundingClientRect()
      const { pageY, touches } = ev
      const mouseY = touches ? touches[0].pageY : pageY
      onChange(1 - Math.max(0, Math.min(1, (mouseY - rect.top) / rect.height)))
    },
    [onChange],
  )

  const dragMove = useCallback(
    (ev) => {
      ev.preventDefault()
      updateValue(ev)
    },
    [updateValue],
  )

  const dragEnd = useCallback(
    (ev) => {
      ev.preventDefault()
      document.removeEventListener('mouseup', dragEnd)
      document.removeEventListener('mousemove', dragMove)
      document.removeEventListener('touchend', dragEnd)
      document.removeEventListener('touchmove', dragMove)
    },
    [dragMove],
  )

  const dragStart = useCallback(
    (ev) => {
      ev.preventDefault()
      updateValue(ev)
      document.addEventListener('mouseup', dragEnd)
      document.addEventListener('mousemove', dragMove)
      document.addEventListener('touchend', dragEnd)
      document.addEventListener('touchmove', dragMove)
    },
    [updateValue, dragMove, dragEnd],
  )

  useEffect(() => {
    function recalculatePositions(ev) {
      // Get the correct values for height and width
      requestAnimationFrame(() => {
        const wrapperHeight = wrapperRef?.current?.offsetHeight ?? 0
        const handleHeight = handleRef?.current?.offsetHeight ?? 0
        setHandleBottomOffset(
          Math.max(0, value * wrapperHeight - value * handleHeight),
        )
      })
    }

    recalculatePositions()

    window.addEventListener('resize', recalculatePositions)
    return () => {
      window.removeEventListener('resize', recalculatePositions)
    }
  }, [value, boardIsConnected])
  return (
    <Container
      ref={wrapperRef}
      style={{ cursor: disabled ? 'default' : 'pointer' }}
      onClick={updateValue}
      className="noDrag"
    >
      <Box
        absolute
        width="100%"
        zIndex="1"
        style={{ bottom: disabled ? 0 : handleBottomOffset }}
      >
        <Handle
          $disabled={disabled}
          ref={handleRef}
          onTouchStart={dragStart}
          onMouseDown={dragStart}
          stereoMode={stereoMode}
        >
          <HandleMarker />
        </Handle>
      </Box>
      <FaderGraphic value={value} disabled={disabled} stereoMode={stereoMode} />
    </Container>
  )
})
