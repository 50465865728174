import React, { useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { Button } from '../ui/button'
import Checkbox from '../ui/form/checkbox';
import { Dialog, ModalTitle } from './base'
import { openModal } from './overlays'
import { Box, Text } from '../ui/primitives'
import { AudioFeedbackIcon } from '../ui/icons'
import { BOARD_CONNECTION_STATE_UNINITIALIZED } from '../../store/board-connection-states'

const BlackBorderCheckbox = styled(Checkbox)`
  .square {
    &:not(.checked) {
      border: 2px solid black;
    }
  }
`;

const CenteredAudioFeedbackIcon = styled(AudioFeedbackIcon)`
  margin: 22px auto;
`

const FeedbackWarning = observer(({ close, onConfirm, store }) => {

  const [hideInternalDeviceFeedbackWarning, setHideInternalDeviceFeedbackWarning] = useState(false)

  return (
    <Dialog
      width="56rem"
      py="3rem"
      px="3rem"
      bgColor="var(--ELK-White)"
      flex
      flexDirection="column"
    >

      <CenteredAudioFeedbackIcon />

      <ModalTitle textAlign="center" mb="2rem" color="var(--ELK-Black)" fontSize="3rem">
        This might cause feedback!
        <br/> Are you sure?
      </ModalTitle>
      <Text textAlign="left" mb="1.4rem" color="var(--ELK-Black)" fontSize="1.6rem">
        Using the built-in speakers together with the built-in microphone can cause feedback. Please connect headphones or choose a different audio interface.
      </Text>

      <Box textAlign="left" color="var(--ELK-Black)">
        <BlackBorderCheckbox
          style={{ display: 'inline-flex' }}
          my="1rem"
          label="Don’t show again until next login"
          checked={hideInternalDeviceFeedbackWarning}
          onChange={(value) => {
            setHideInternalDeviceFeedbackWarning(value)
          }}
        />
      </Box>

      <Box columnGap="2rem" mt="2rem" flex>
        <Button danger fontSize="1.8rem" width="100%" onClick={() => {
          store.mixer.hideInternalDeviceFeedbackWarning = hideInternalDeviceFeedbackWarning
          onConfirm()
          close()
        }}>
          Yes, apply anyway
        </Button>
        <Button primary fontSize="1.8rem" width="100%" data-hidemenu="false" onClick={() => {
          close()
          if(store.boardConnectionState !== BOARD_CONNECTION_STATE_UNINITIALIZED)
            store.showSettingsView = true
        }}>
          No, back to settings
        </Button>
      </Box>
    </Dialog>
  )
})

export default function showFeedbackWarning({ onConfirm, store }) {
  return openModal(
    ({ close }) => <FeedbackWarning close={close} store={store} onConfirm={onConfirm} />,
    {
      autoDismissable: true,
    }
  )
}
