import React, { useCallback, useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Box, H3 } from '../../../ui/primitives'
import { observer } from 'mobx-react-lite'

const KnobHolder = styled.div`
  width: 55px;
  height: 55px;
  touch-action: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`

const Label = styled.h2`
  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};
  left: ${({ left }) => left};
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  width: 20px;
  display: inline-block;
  position: absolute;
  font-size: 14px;
  user-select: none;
  font-weight: 700;
`

const KnobLabel = styled(H3)`
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  user-select: none;
  transition: 0.3s;
  width: 100%;
  text-align: center;
  color: ${({ $disabled }) =>
    $disabled ? 'var(--ELK-Dark-Green-Disabled)' : 'var(--ELK-White)'};
`

const LineAlongCircle = ({
  color = 'white',
  width = 90,
  radius = 100,
  startTurn = 0,
  endTurn = 0.5,
  largeArc = false,
  clockwise = false,
}) => {
  const start = {
    x: Math.cos(startTurn * Math.PI * 2) * radius,
    y: Math.sin(startTurn * Math.PI * 2) * radius,
  }
  const end = {
    x: Math.cos(endTurn * Math.PI * 2) * radius,
    y: Math.sin(endTurn * Math.PI * 2) * radius,
  }
  return (
    <path
      fill="transparent"
      stroke={color}
      strokeWidth={width}
      d={`M ${start.x} ${-start.y} A ${radius} ${radius} 0 ${
        largeArc ? 1 : 0
      } ${clockwise ? 1 : 0} ${end.x} ${-end.y}`}
    />
  )
}

export const KnobControl = observer(
  ({
    value,
    onChange,
    onChanging,
    titleMin,
    panMode,
    stereoMode,
    titleMax,
    label,
    disabled = true,
    fgColor = 'var(--ELK-Dark-Grey)',
    disabledColor = 'var(--ELK-UI-Disabled)',
    defaultValue = null,
    ...props
  }) => {
    const max = 1.0
    const min = 0.0
    const [dragging, setDragging] = useState(false)
    const dragStartY = useRef(0)

    const dragMove = useCallback(
      (ev) => {
        if (dragging) {
          const { pageY, touches } = ev
          const pY = touches ? touches[0].pageY : pageY

          const interval = max - min
          const dragDelta = dragStartY.current - pY
          dragStartY.current = pY
          const newValue = Math.min(
            max,
            Math.max(min, value + (dragDelta / 100) * interval),
          )
          onChanging(newValue)
        }
      },
      [onChanging, dragging, value],
    )

    const dragEnd = useCallback(() => {
      if (dragging) {
        setDragging(false)
      }
      onChange?.(value)
    }, [onChange, value, dragging])

    const dragStart = useCallback(
      (ev) => {
        ev.preventDefault()
        if (disabled) return

        const { pageY, touches } = ev
        dragStartY.current = touches ? touches[0].pageY : pageY
        setDragging(true)
      },
      [disabled],
    )

    useEffect(() => {
      document.addEventListener('touchmove', dragMove)
      document.addEventListener('mousemove', dragMove)
      document.addEventListener('touchend', dragEnd)
      document.addEventListener('mouseup', dragEnd)
      return () => {
        document.removeEventListener('touchmove', dragMove)
        document.removeEventListener('mousemove', dragMove)
        document.removeEventListener('touchend', dragEnd)
        document.removeEventListener('mouseup', dragEnd)
      }
    }, [dragging, dragMove, dragEnd])

    const resetDefaultValue = () => {
      if (typeof defaultValue === 'number') {
        onChanging?.(defaultValue)
        onChange?.(defaultValue)
      }
    }
    return (
      <Box relative flex justifyContent="center" {...props} style={{ transform: 'scale(0.8)'}} className="noDrag">
        <Box flex column width="100%" alignItems="center">
          <Box relative width="100px" flex justifyContent="center">
            <Label
              color={disabled ? fgColor : 'white'}
              textAlign="right"
              top={panMode ? '20px' : '50px'}
              left={panMode ? '-5px' : '0px'}
            >
              {titleMin}
            </Label>
            <Label
              color={disabled ? fgColor : 'white'}
              textAlign="left"
              top={panMode ? '20px' : '50px'}
              right={panMode ? '-5px' : '0px'}
            >
              {titleMax}
            </Label>
            <KnobHolder
              onTouchStart={dragStart}
              onMouseDown={dragStart}
              onDoubleClick={resetDefaultValue}
              disabled={disabled}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="-150 -150 300 300"
              >
                {panMode ? (
                  <>
                    <LineAlongCircle
                      startTurn={0}
                      endTurn={0.5}
                      color={fgColor}
                    />
                    <LineAlongCircle
                      startTurn={0.25}
                      endTurn={0.25 - (value - 0.5) / 2}
                      clockwise={value > 0.5}
                      color="var(--ELK-Sea-Foam-Green)"
                    />
                    <circle
                      style={{ transition: '1.0s' }}
                      r={100}
                      cx="0"
                      cy="0"
                      fill={disabled ? disabledColor : 'white'}
                    />
                    <g transform={`rotate(${((value - 0.5) / 2) * 360} 0 0)`}>
                      <line
                        x1="0"
                        y1="-40"
                        x2="0"
                        y2="-80"
                        stroke={disabled ? disabledColor : 'black'}
                        strokeWidth="13"
                        strokeLinecap="round"
                      />
                    </g>
                  </>
                ) : (
                  <>
                    <LineAlongCircle
                      startTurn={0.6}
                      endTurn={-0.1}
                      clockwise={true}
                      largeArc={true}
                      color={fgColor}
                    />
                    <LineAlongCircle
                      startTurn={0.6}
                      endTurn={0.6 - value * 0.7}
                      clockwise={true}
                      largeArc={value * 0.7 > 0.5}
                      color="var(--ELK-Sea-Foam-Green)"
                    />
                    <circle
                      style={{ transition: '0.3s' }}
                      r={100}
                      cx="0"
                      cy="0"
                      fill={disabled ? disabledColor : 'white'}
                    />
                    <g transform={`rotate(${(value - 0.5) * 360 * 0.7} 0 0)`}>
                      <line
                        x1="0"
                        y1="-40"
                        x2="0"
                        y2="-80"
                        stroke={disabled ? disabledColor : 'black'}
                        strokeWidth="13"
                        strokeLinecap="round"
                      />
                    </g>
                  </>
                )}
              </svg>
            </KnobHolder>
          </Box>
          <KnobLabel $disabled={disabled}>{label}</KnobLabel>
        </Box>
      </Box>
    )
  },
)
