import React from 'react'
import styled from 'styled-components'
import { Box } from '../../../ui/primitives'
import { observer } from 'mobx-react-lite'
import { AudioIcon, MuteIcon } from '../../../ui/icons'

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 42px;
  height: 42px;
  margin: 0;
  border-radius: 50%;
  transition: 200ms;
  border: 0;
  &.on {
    background-color: var(--ELK-High-Prio-Red-Enabled);
    color: var(--ELK-White);
  }
  &.off {
    background-color: var(--ELK-White);
    color: var(--ELK-Black);
  }
  &.disabled {
    cursor: inherit;
    background-color: var(--ELK-Dark-Grey);
    color: var(--ELK-Black);
  }
`

export const MuteButton = observer(({ muted, onChange, disabled, hideMenu }) => {

  return (
    <Box width="42px" height="42px">
      <Button
        className={disabled ? 'disabled' : (muted ? 'on' : 'off')}
        onClick={() => {
          if (!disabled) {
            onChange(!muted)
          }
        }}
      >
        {muted ? (
          <MuteIcon width="100%" height="100%" alignItems="center" hideMenu={hideMenu} />
        ) : (
          <AudioIcon alignItems="center" hideMenu={hideMenu}/>
        )}
      </Button>
    </Box>
  )
})
