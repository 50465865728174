import { useState, useEffect } from 'react'
import styled from 'styled-components'

import { useStore } from '../../../store'
import { Box, H2, Text } from '../../ui/primitives'
import { Button } from '../../ui/button'
import { VideoSyncIcon, MaximizeIcon, MinimizeIcon } from '../../ui/icons'

const Container = styled(Box)`
  width: ${({ $minimized }) => !$minimized && '100%'};
  display: flex;
  align-items: center;
  background-color: var(--ELK-Raspberry-Pink);
  position: absolute;
  top: 0;
  right: ${({ $minimized }) => $minimized && '0'};
  padding: 0.6rem 0 1rem 0.6rem;
  padding-left: ${({ $minimized }) => $minimized && '1rem'};
  z-index: 10;
`

const Heading = styled(H2)`
  font-weight: bold;
  margin: 0 3rem 0 2rem;
`

const Message = styled(Text)`
  font-size: 1.4rem;
  max-width: 60rem;
`

function VideoSyncBanner() {
  const store = useStore()
  const [minimized, setMinimized] = useState(false)

  useEffect(() => {
    setMinimized(false);
  }, [store.session?.videoSync.active])

  if (!store.session?.videoSync.active) {
    return null
  }

  return (
    <Container $minimized={minimized}>
      <VideoSyncIcon />
      {!minimized && (
        <Box display="flex" alignItems="center">
          <Heading>video sync</Heading>
          <Message>
            This puts audio and video in perfect sync for you to see what going
            on, BUT you won't get any of that Elk ultra-low latency, so be sure
            to turn it off when you start playing together!
          </Message>
        </Box>
      )}
      <Button ml="auto" py="0.7rem" px="0.6rem" onClick={() => setMinimized(!minimized)}>
        {minimized ? <MaximizeIcon /> : <MinimizeIcon />}
      </Button>
    </Container>
  )
}

export default VideoSyncBanner
