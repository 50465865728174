import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore, withStore } from '../../store'
import { ConfirmationDialogTitle, Dialog, ModalButton } from './base'
import { openModal } from './overlays'
import { Box } from '../ui/primitives'
import styled from 'styled-components'
import {
  UPDATE_STATE_AVAILABLE,
  UPDATE_STATE_ANOMALY,
  UPDATE_STATE_IN_PROGRESS,
  UPDATE_STATE_SUCCESS
} from '../../store/update-states'
import { showAskForRebootDialog } from './ask-for-reboot'
import { DESKTOP_HW_TYPES } from '../../store/board'
import { BodyText } from './ConfirmationDialog'
import { showReportIssueDialog } from './report-issue'

const UpdateProgressBarContainer = styled(Box)`
  progress {
    width: 100%;
    height: 30px;
    border: 0;
    appearance: none;
  }
  progress::-webkit-progress-bar {
    height: 30px;
    border-radius: 4px;
    background: var(--ELK-Dark-Grey);
  }
  progress::-webkit-progress-value {
    background: var(--ELK-High-Prio-Green-Enabled);
    border-radius: 4px;
  }
  `

const BodyTextUpdate = styled(BodyText)`
    margin-bottom: 20px;
    text-align: left;
    display: flex;
    align-items: flex-start;
    ul {
      margin-left: 20px;
      list-style: unset;
      line-height: 30px;
    }

    strong {
      font-weight: 700;
    }
  `

const UpdateTitle = styled(ConfirmationDialogTitle)`
    width: auto;
    margin-bottom: 20px;
  `

const TITLE = {
  UPDATE_STATE_SUCCESS: 'Update ready to install!',
  UPDATE_STATE_IN_PROGRESS: 'Update in progress...',
  UPDATE_STATE_ANOMALY: 'Download error!'
}

const UpdatesAvailableTextDesktop = ({ close, version, updateState, store }) => {
  return (
    <>
      <UpdateTitle style={{width: 'unset'}}>{TITLE[updateState] || 'Update needed.'}</UpdateTitle>
      {updateState !== UPDATE_STATE_ANOMALY ? (
        <>
          {updateState === UPDATE_STATE_SUCCESS ? (
            <BodyTextUpdate fontSize="14px">
              Please close app and locate and run the downloaded Elk installer.
            </BodyTextUpdate>
          ) : (
            <>
              <BodyTextUpdate fontSize="14px">
                We need to update the app to latest version. It’ll only take a minute.
              </BodyTextUpdate>
              {/* <BodyTextUpdate>
                <ul>
                  <li><strong>Elk WIRE VST3/AU plugin</strong></li>
                  <li><strong>UI Scale on resize</strong></li>
                  <li><strong>General stability fixes</strong></li>
                </ul>
              </BodyTextUpdate> */}
            </>
          )}
        </>
      ) : (
        <BodyTextUpdate fontSize="14px">
          There was a problem downloading the update. Try one more time. Make sure you don’t do anything during the download.
          <br />
          If it still doesn’t work, <strong style={{ cursor: 'pointer'}}onClick={() => {
            close()
            showReportIssueDialog({ store })
          }}>contact support.</strong>
        </BodyTextUpdate>
      )}
    </>
  )}

const UpdatesAvailableButtons = ({
  store,
  device,
  updateState,
  startUpdate,
  close,
}) => {

  if(DESKTOP_HW_TYPES.includes(device.hwType)){

    if (updateState === UPDATE_STATE_SUCCESS) {
      return null
    }

    if(updateState === UPDATE_STATE_IN_PROGRESS) return null

    return (
      <ModalButton success onClick={async () => {
        await store.downloadDesktopUpdate()
      }}>
       Download now!
      </ModalButton>
    )
  }

  if (updateState === UPDATE_STATE_AVAILABLE) {
    return (
      <ModalButton
        primary
        onClick={startUpdate}
      >
        Download now!
      </ModalButton>
    )
  }

  if (updateState === UPDATE_STATE_ANOMALY) {
    return (
      <>
      <ModalButton success onClick={async () => {
        await store.downloadDesktopUpdate()
      }}>
        Retry
      </ModalButton>
      </>
    )
  }

  return (
    <ModalButton primary onClick={() => {
      close()
      showAskForRebootDialog({ store, device })
    }}>
      Close
    </ModalButton>
  )
}

const UpdatesAvailableDialog = observer(({ close, device }) => {

  const store = useStore()
  const desktopVersion = store.serverStartupData?.requiredDesktopVersion
  const updateState = store.swUpdate.state
  const updateProgress = store.swUpdate.update_percent

  return (
    <Dialog
      width="35rem"
      py="4rem"
      textAlign="center"
      px="2.5rem"
    >
      <>
      <UpdatesAvailableTextDesktop version={desktopVersion} updateState={updateState} store={store} close={close} />
        {updateState === UPDATE_STATE_IN_PROGRESS && (
          <UpdateProgressBarContainer>
            <progress max={100} value={updateProgress} />
          </UpdateProgressBarContainer>
        )}
      </>

      <Box flex columnGap="1rem" justifyContent="center" alignItems="center">
        <UpdatesAvailableButtons
          store={store}
          device={device}
          updateState={updateState}
          close={close}
          startUpdate={() => store.startSoftwareUpdate()}
        />
      </Box>
    </Dialog>
  )
})

export function showUpdatesAvailableDialog({ store, device }) {
  return openModal(
    ({ close }) =>
      withStore(
        store,
        <UpdatesAvailableDialog device={device} close={() => {
          store.disconnectFromBoard()
          close()
        }} />,
      ),
    {
      autoDismissable: false,
      alwaysOnTop: true
    },
  )
}
