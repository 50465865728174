import React, { createContext, useContext } from 'react'
import PropTypes from 'prop-types'

const Context = createContext()

export const StoreProvider = Context.Provider

/**
 * @returns {Store}
 */
export function useStore() {
  return useContext(Context)
}

export function withStore(store, children) {
  return <Context.Provider value={store}>{children}</Context.Provider>
}

StoreProvider.propTypes = {
  children: PropTypes.element,
}
