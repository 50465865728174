import styled from 'styled-components'
import { CloseIcon } from '../ui/icons'
import { Box, H2, Text } from '../ui/primitives'
import Button from '../ui/button'

export const Dialog = styled(Box)`
  position: relative;
  background: ${props => props.bgColor ?? 'var(--ELK-Dark-Background-Grey)'};
  border-radius: 6px;
  padding: ${props => props.py ?? '1.5rem'} ${props => props.px ?? '1.5rem'};
  p { margin-bottom: 1em; }
`

export const CloseButton = styled(({ onClick, className }) => (
  <button type="button" className={className} onClick={onClick}>
    <CloseIcon size={14} />
  </button>
))`
  position: absolute;
  right: ${props => props.inset ?? '2rem'}; top: ${props => props.inset ?? '2rem'};
  padding: .5rem;
  color: ${props => props.color && props.color};
`

export const UnderlineButton = styled.button.attrs({type: 'button'})`
  position: relative;
  text-transform: uppercase;
  border-bottom: 1px solid currentColor;
  &:focus { outline: 1px dotted var(--color-light-grey); }
  &, &:after {
    transition: .2s ease;
  }
  &:hover:after {
    position: absolute;
    bottom: -2px; left: 0; right: 0;
    border-bottom: 1px solid white;
    content: '';
  }
`

export const TextArea = styled.textarea`
  color: var(--ELK-Black);
  resize: none;
  font-size: 1.2rem;
  width: 100%;
  height: 90px;
  margin: 1rem 0;
  border-radius: 6px;
  padding: 1rem;
`

export const Label = styled(Text)`
  color: var(--Elk-Black);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
`

export const DialogTitle = styled(H2)`
  color: var(--Elk-White);
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin-top: 4px;
`

export const ConfirmationDialogTitle = styled(DialogTitle)`
  width: 165px;
  margin: 0 auto;
  word-break: break-word;
`


export const ModalTitle = styled(Text).attrs({as: 'h1'})`
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.075em;
  letter-spacing: .2;
`

export const ModalButton = styled(Button)`
    padding: 15px 20px;
    color: var(--ELK-White);
    width: 100%;
    font-size: 14px;
    border: ${props => props.secondary ? '1px solid #8C8C8C' : 'unset'};
`