import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { Box, H1, H2, Text } from '../../ui/primitives'
import { InformationIcon, MultipleBridgesIcon } from '../../ui/icons'
import { CloseButton, Dialog } from '../base'
import { useStore, withStore } from '../../../store'
import styled from 'styled-components'
import { logger } from '../../../utils/logging'
import { RPCClient } from '../../../api/rpc-client'
import { BOARD_CONNECTION_STATE_CONNECTED, BOARD_CONNECTION_STATE_DISCONNECTED, BOARD_CONNECTION_STATE_CONNECTING, BOARD_CONNECTION_STATE_DISCONNECTING } from '../../../store/board-connection-states'
import { showDeviceInfoDialog } from '../device-info'
import { APIClient, DEFAULT_BOARD_BACKEND_PORT, DEFAULT_BOARD_ESSENTIAL_SERVICES_PORT, board_ip_to_url } from '../../../api/client'
import DeviceRowIcon from './DeviceRowIcon'
import IdentifyDeviceButton from './IdentifyDeviceButton'
import { List } from '../../ui/list'
import { Row } from '../../ui/row'
import Button from '../../ui/button'
import { openModal } from '../overlays'
import { BridgeStatusText } from '../../ui/bridge-status-icon'
import { DESKTOP_HW_TYPES, DESKTOP_IP } from '../../../store/board'
import { isDesktop } from '../../../utils/utils'
import { ELKHighPrioRedEnabled } from '../../../variables'

const InformationIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--ELK-Standard-Enabled);
`

const Capitalize = styled.div`
  text-transform: capitalize;
`

const CloudDebugModeIndicator = styled.div`
  background: var(--ELK-High-Contrast-Red);
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px;
  pointer-events: none;
`

const DialogHeaderContainer = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const StatusTextDescription = styled(Text)`
  font-size: 1.5em;
  color: var(--ELK-Black);
`

const DeviceSelectionDialog = observer(({ close }) => {

  const [identificationInProgressIp, setIdentificationInProgressIp] = useState(null)
  const store = useStore()

  const devices = store.devices

  const identify = async (device) => {
    const boardClient = RPCClient(board_ip_to_url(device.ipAddress), DEFAULT_BOARD_BACKEND_PORT, true)
    const essentialServicesClient = RPCClient(board_ip_to_url(device.ipAddress), DEFAULT_BOARD_ESSENTIAL_SERVICES_PORT, device.ipAddress !== DESKTOP_IP)
    setIdentificationInProgressIp('0.0.0.0')
    try {
      /* We make sure that the board is reachable before we indicate that an identification is in progress */
      await essentialServicesClient.invoke('is_board_alive')
      /* Here we start the indication in the web app that an identification is in progress */
      setIdentificationInProgressIp(device.ipAddress)
      await boardClient.invoke('identify_bridge')
      device.unableToIdentify = false
    } catch (error) {
      logger.error(error)
      if (error.code === 32610) {
        store.showSnackBar({
          heading: 'Legacy device detected!',
          content: 'You are using a device that does not support this method of identification. Please use the IP address instead.',
          level: 'error',
        })
      } else {
        device.unableToIdentify = true
      }
    }
    setIdentificationInProgressIp(null)
  }

  const tryToConnect = async (device) => {
    const ip = device.ipAddress
    close()
    try {
      await store.disconnectFromBoard()
      // connecting to desktop, initialize desktop audio
      if(ip === DESKTOP_IP) {
        await store.initDesktopAudio(false)
      } else { //otherwise we connect to hardware board
        showDeviceInfoDialog({store: store, device: device})
        await store.connectToBoard(ip)
      }

    } catch (err) {
      // ignore errors
    }
  }

  return (
    <Dialog
      flex
      flexDirection="column"
      px="5.7rem"
      py="4.3rem"
      bgColor="var(--ELK-White)"
      width="90rem"
      minHeight="40rem"
    >
      <CloseButton onClick={() => close()} color="var(--ELK-Black)" />

      <DialogHeaderContainer>
        <H1 mb="2rem" color="var(--ELK-Black)">My Bridge</H1>
        {APIClient._cloud_debug_mode && (
          <CloudDebugModeIndicator>Cloud Debug Mode</CloudDebugModeIndicator>
        )}

        <MultipleBridgesIcon size="95"/>

        <StatusTextDescription fontWeight="700" mt="2rem">
          Status: <BridgeStatusText connectionState={store.boardConnectionState} devices={store.devices} />
        </StatusTextDescription>
        <StatusTextDescription fontWeight="300" mt="1.5rem" mb="2rem">
          Select a Bridge to Connect
        </StatusTextDescription>
      </DialogHeaderContainer>
      <List>
        {devices.slice().sort((a, b) => {
          const aName = a.displayName
          const bName = b.displayName
          if (aName < bName) return -1
          if (aName > bName) return 1
          return 0
        }).map((device) => {

          if (!isDesktop() && DESKTOP_HW_TYPES.includes(device.hwType) && !store.isDeveloperMode) return null;

          const isCurrentBoard = device.ipAddress === store.board?.ipAddress
          const connectionState = isCurrentBoard ? store.boardConnectionState : BOARD_CONNECTION_STATE_DISCONNECTED

          return (
            <Row key={device.uuid}>
              <Box flex justifyContent="space-between" width="100%" height="6.5rem">
                <Box flex row>
                  <Box flex alignItems="center" pl="1rem">
                    <DeviceRowIcon device={device} identificationInProgressIp={identificationInProgressIp} connectionState={connectionState} />
                  </Box>
                  <Box color="var(--ELK-Black)" flex column px="1rem" justifyContent="center">
                    <H2>
                      <Capitalize>{device.displayName}</Capitalize>
                    </H2>
                  </Box>
                </Box>
                <Box flex columnGap="2rem">
                  {device.ipAddress !== DESKTOP_IP && (
                    <InformationIconWrapper onClick={() => {
                      close()
                      showDeviceInfoDialog({ store, device, onClose: () => {
                          showDeviceSelectionDialog({ store })
                        }})
                    }}>
                      <InformationIcon color={device.unableToIdentify ? ELKHighPrioRedEnabled : undefined} />
                    </InformationIconWrapper>
                  )
                  }
                  <Box flex row columnGap="0.4rem">
                    <IdentifyDeviceButton device={device} width="16rem" height="100%" primary disabled={identificationInProgressIp} onClick={() => identify(device)}>Identify</IdentifyDeviceButton>
                    {connectionState === BOARD_CONNECTION_STATE_CONNECTED ? (
                      <Button width="16rem" height="100%" disabled={true} primary>Connected</Button>
                    ) : (
                      <Button width="16rem" height="100%" disabled={store.boardConnectionState === BOARD_CONNECTION_STATE_CONNECTING || store.boardConnectionState === BOARD_CONNECTION_STATE_DISCONNECTING} primary onClick={() => tryToConnect(device)}>Connect</Button>
                    )}
                  </Box>
                </Box>
              </Box>
            </Row>
          )
        })}
      </List>
    </Dialog>
  )
})

export function showDeviceSelectionDialog({ store }) {
  store.userHasOpenedDeviceSelection = true
  return openModal(
    ({ close }) => withStore(store, <DeviceSelectionDialog close={close} />),
    {
      autoDismissable: true,
    },
  )
}
