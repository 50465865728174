import React from 'react'
import { observer } from 'mobx-react-lite'
import {
  Container,
  Header,
  NavContainer,
  Footer,
  FooterDivider,
  BetaBgContainer,
  SocialFooter,
  JoinButton,
  BetaBodyContaienr
} from './style'
import { ElkLogo } from '../../../../ui/icons'
import { getElkLiveWebsiteUrl } from '../../../../../utils/utils'

export const BetaLayout = observer(({ children, setPage }) => {
  const year = new Date().getFullYear()
  const elkLiveWebsite = getElkLiveWebsiteUrl()
  return (
    <Container>
      <Header>
        <NavContainer>
          <a href="https://elk.live"><ElkLogo size={57}/></a>
          <nav>
            <a href={elkLiveWebsite + 'all-you-need-to-know'} target="_blank" rel="noreferrer">
              <h2>All you need to know</h2>
            </a>
            <a href={elkLiveWebsite + 'stories'} target="_blank" rel="noreferrer">
              <h2>Testimonials</h2>
            </a>

            <a href={elkLiveWebsite + 'pricing'} target="_blank" rel="noreferrer">
              <h2>Pricing</h2>
            </a>
            <JoinButton onClick={() => setPage('join')}>
              Sign up
            </JoinButton>
          </nav>
        </NavContainer>
      </Header>
      <BetaBodyContaienr>
        <BetaBgContainer>
          {children}
        </BetaBgContainer>
      </BetaBodyContaienr>
      <Footer>
        <a href="https://elk.audio/" target="_blank" rel="noreferrer">© {year} Elk</a>
        <FooterDivider />
        <a href="https://jobs.elk.audio/" target="_blank" rel="noreferrer">About</a>
        <FooterDivider />
        <a href="https://os.elk.audio/" target="_blank" rel="noreferrer">Elk Audio OS</a>
        <FooterDivider />
        <a href="https://jobs.elk.audio/" target="_blank" rel="noreferrer">Jobs</a>
        <FooterDivider />
        <a href="https://elk.audio/elk-audio-terms-conditions" target="_blank" rel="noreferrer">T&C</a>
        <FooterDivider />
        <a  href="https://elk.audio/elk-audio-privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>
        <FooterDivider />
        <a href="https://elk.audio/elk-audio-terms-conditions" target="_blank" rel="noreferrer">Cookie Policy</a>
        <FooterDivider />
        <SocialFooter>
          <a href="https://www.instagram.com/elk_audio/" target="_blank" rel="noreferrer">
            <img src="https://uploads-ssl.webflow.com/62b3665d0fe941d6750b546b/62b3665d0fe9419ce90b54eb_Vector-1.svg" loading="lazy" alt="instagram" />
          </a>

          <a href="https://www.youtube.com/c/ElkLIVEmusic" target="_blank" rel="noreferrer">
            <img src="https://uploads-ssl.webflow.com/62b3665d0fe941d6750b546b/6311b054d3c67a919a651ef2_youtube19x19.svg" loading="lazy" alt="youtube" />
          </a>

          <a href="https://www.facebook.com/elklivemusic" target="_blank" rel="noreferrer">
            <img src="https://uploads-ssl.webflow.com/62b3665d0fe941d6750b546b/62b3665d0fe9416fc10b54ec_Vector.svg" loading="lazy" alt="facebook" />
          </a>
        </SocialFooter>
      </Footer>
    </Container>
  )
})


