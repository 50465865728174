import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import ReactMarkdown from 'react-markdown'
import { CloseButton, Dialog } from '../base'
import { openModal } from '../overlays'
import { H1 } from '../../ui/primitives'
import changelog from '../../../CHANGELOG.md'
import { ChangelogContainer } from './styles';
import { getCurrentVersion } from '../../../utils/utils'

const formatVersion = (desktopVersionInfo) => {
  if(desktopVersionInfo){
    return `${desktopVersionInfo.buildInfo.version} `+
      `build-${desktopVersionInfo.buildInfo.buildNumber}`
  }

  const { version } = getCurrentVersion()
  return version
}

export const VersionInfoDialog = observer(({ close, desktopVersionInfo }) => {

  const [changelogText, setChangelogText] = useState('')

  useEffect(() => {
    if(!desktopVersionInfo){
      fetch(changelog).then((response) => response.text()).then((text) => {
        setChangelogText(text)
      })
    } else {
      setChangelogText(desktopVersionInfo.changelog)
    }
  }, [desktopVersionInfo])

  const version = formatVersion(desktopVersionInfo)

  return (
    <Dialog
      width="60rem"
      py="4rem"
      px="2.5rem"
      bgColor="var(--ELK-White)"
    >
      <CloseButton onClick={() => close()} color="var(--ELK-Black)"/>

      <H1 color="var(--ELK-Black)" textAlign="center" mb="2.5rem">{version}</H1>
      <ChangelogContainer>
        <ReactMarkdown>
          {changelogText}
        </ReactMarkdown>
      </ChangelogContainer>
    </Dialog>
  )
})

export function showVersionInfoDialog(desktopVersionInfo) {
  return openModal(
    ({ close }) => <VersionInfoDialog close={close} desktopVersionInfo={desktopVersionInfo}/>, {
      autoDismissable: true,
    }
  )
}
