import React from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { Box, H2 } from '../../../ui/primitives'
import InputDropdown from './InputDropdown'

const Label = styled(H2)`
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  user-select: none;
`

const InputSourceSelector = observer(
  ({ channelIndex, title, hideDropdown }) => {
    return (
      <Box flex column alignItems="center" justifyContent="center" width="100%">
        <Label mb="10px" width="100%" textAlign="center">
          {title}
        </Label>
        <Box width="90px">
          {!hideDropdown && (
            <InputDropdown channelIndex={channelIndex} />
          )}
        </Box>
      </Box>
    )
  },
)

export default InputSourceSelector
