import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { Box } from '../../../ui/primitives'
import { useStore } from '../../../../store'
import { INPUT_OPTION_MIC } from '../../../../store/input-options'

const PhantomControlsWrapper = styled.div`
  border: 2px solid var(--ELK-White);
  transition: 0.3s border-color;
  padding: 2px;
  &.disabled {
    border-color: var(--ELK-Dark-Green-Disabled);
  }
`

const PhantomControls = styled.div`
  padding: 4px 0;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  width: 95px;
  transition: 0.3s color, 0.3s border-color, 0.3s background;
  user-select: none;
  &.disabled {
    color: var(--ELK-Dark-Green-Disabled);
    background: none !important;
    cursor: default;
  }
  &.on {
    background: var(--ELK-Candy-Tangerine);
  }
`

const PhantomSettings = observer(
  ({ channelIndex, isInSession }) => {
    const store = useStore()
    const phantomPower = store.mixer.phantomPower[channelIndex]
    const disabled =
      store.mixer.inputOptions[channelIndex] !== INPUT_OPTION_MIC || isInSession
    return (
      <Box>
        <PhantomControlsWrapper
          className={`${disabled ? 'disabled' : 'enabled'}`}
        >
          <PhantomControls
            className={`${phantomPower ? 'on' : 'off'} ${
              disabled ? 'disabled' : 'enabled'
            }`}
            onClick={() => {
              if (!disabled) {
                store.mixer.phantomPower[channelIndex] = !phantomPower
              }
            }}
          >
            {store.mixer.inputOptions[channelIndex] !== INPUT_OPTION_MIC
              ? 'phantom'
              : phantomPower
              ? 'phantom on'
              : 'phantom off'}
          </PhantomControls>
        </PhantomControlsWrapper>
      </Box>
    )
  },
)

export default PhantomSettings
