/* eslint-disable no-useless-computed-key */

import React, { useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../store'
import {
  SectionContainer,
  RoomsList,
  HeaderContainer,
  ScrollableRooms,
  SelectionContainer,
  VideoMixerContainer,
} from '../styles'
import { ErrorBoundary } from '../../../../utils/error-boundaries'
import { VideoOutput } from '../../video/video-output'
import { VideoPreview } from '../../../video-chat/components/video-preview'
import { Mixer } from '../../mixer'
import { Box, H1 } from '../../../ui/primitives'
import { VideoSourceSettings } from '../../../features/Settings'
import MinimalDesktopAudioSelection from '../../../features/Settings/AudioSection/minimal-desktop'
import { RoomCard } from '../../../cards/room-card'
import { NewRoomButton, NewRoomCard, PlusTitle, RoomInput } from '../../../cards/styles'
import { showCreateRoomDialog } from '../../../dialogs/Room/room-form'
import { FooterTooltip, RoundedButton } from '../../../layout/styles'
import { GoArrowIcon, InformationIcon } from '../../../ui/icons'
import { OWNER, WHITELISTED } from '../../../dialogs/Room/member-status'
import { SimpeH2 } from '../../../features/Settings/styles'
import { TooltipBoundary, TooltipDescription } from '../../../ui/tooltip'
import { NIKKEI_DISABLED } from '../../../../store/system-states'

const MainView = observer(() => {
  const store = useStore()
  const scrollableContainer = useRef()
  const [roomCode, setRoomCode] = useState('')
  const [tooltipOpen, setTooltipOpen] = useState({ pluginMode: false, notProcessing: false})

  const isRoomOrSessionActive = store.isRoomOrSessionActive()

  return (
    <>
      {!isRoomOrSessionActive && (
        <SectionContainer>
          <HeaderContainer>
            <H1>ROOMS</H1>
          </HeaderContainer>
          <RoomsList>
            <NewRoomCard>
              <NewRoomButton onClick={() => {
                showCreateRoomDialog({ store })
                store.analytics.track('Room_Clicked Create')
              }}>
                <PlusTitle>+</PlusTitle>
                Create New Room
              </NewRoomButton>
              <Box flex>
                <RoomInput
                  type="text"
                  placeholder="... or enter Room ID here"
                  value={roomCode}
                  onChange={(e) => setRoomCode(e.target.value)}
                />
                <RoundedButton
                  className={roomCode ? 'on' : 'dark-disabled'}
                    onClick={async () => {
                      if(!store.mixer.isAudioEngineReady())
                      {
                        store.roomManager.showGenericErrorDialog(
                          store.systemState === NIKKEI_DISABLED
                          ? 'You need to enable Elk WIRE plugin before entering the room'
                          : 'You need to select audio device before entering a room'
                        )
                        return
                      }
                      store.analytics.track('Room_Clicked Enter via Room ID', {['Room Identifier'] : roomCode })
                      await store.roomManager.joinRoom(roomCode)
                    }}
                >
                   <GoArrowIcon size={19} />
                </RoundedButton>
              </Box>
            </NewRoomCard>
            <ScrollableRooms ref={scrollableContainer}>
              {store.rooms.length > 0 && store.rooms.map(room => {

                  const ownUserHasPermanentAccess = room.users
                    .find(user => user.user_id === store.currentUserId && [OWNER, WHITELISTED].includes(user.status))

                  return ownUserHasPermanentAccess && room.status !== 'DELETED' && (
                    <RoomCard
                      key={room.room_id}
                      room={room}
                    />
                  )
              }
              )}
           </ScrollableRooms>
          </RoomsList>
        </SectionContainer>
      )}
        <SectionContainer>
        {!isRoomOrSessionActive && (
          <HeaderContainer>
            <H1>VIDEO + AUDIO</H1>
          </HeaderContainer>
        )}
          {store.currentUser
            ? (
            <VideoMixerContainer
              sessionMode={isRoomOrSessionActive}
              onMouseEnter={() => {
                if(store.isRoomOrSessionActive())
                  store.mixerVisible = true
              }}
              onMouseLeave={() => {
                if(store.isRoomOrSessionActive())
                  store.mixerVisible = false
              }}
            >
              {store.sessionMode ? (
                <ErrorBoundary
                  error_message={'Something went wrong with the video client.'}
                >
                  <VideoOutput className="video-output" />
                </ErrorBoundary>
              ) : (
                <VideoPreview
                  sessionMode={isRoomOrSessionActive}
                  enabled={store.previewVideoEnabled}
                  user={store.users.get(store.currentUserId)}
                />
              )}

              {!store.sessionMode && (
                <div>
                  <Mixer expanded={isRoomOrSessionActive} />
                </div>
              )}
            </VideoMixerContainer>
          )
            : <Box>Loading</Box>}
      </SectionContainer>
      {!isRoomOrSessionActive && (
        <SelectionContainer pluginMode={store.mixer?.isPluginMode()}>
          <Box flex justifyContent="space-between" alignItems="center" width="auto">
            <VideoSourceSettings dropUp width="222px"/>
          </Box>
          <Box flex justifyContent="space-around" alignItems="center" width="auto">
            {store.mixer && (
              <>
                {store.mixer.isPluginMode() ?
                  <>
                    <Box flex alignItems="center">
                      <SimpeH2 mx="10px" color={store.isNikkeiProcessing ? 'var(--Elk-White)' : "#FA213B"} my="0">
                        {store.isNikkeiProcessing ? 'Elk Wire' : 'Elk Wire Connection Lost!'}
                      </SimpeH2>
                      <TooltipBoundary
                        onClickOutside={() => {
                          if(!tooltipOpen.pluginMode) return
                          setTooltipOpen({
                            ...tooltipOpen,
                            pluginMode: false
                          })
                        }}
                        tooltip={
                          <FooterTooltip
                            bgColor='var(--ELK-White)'
                            color='var(--ELK-Dark-Grey)'
                            isOpen={tooltipOpen.pluginMode}
                          >
                            <h2><strong>{store.isNikkeiProcessing ? 'Connected to Elk Wire' : 'Elk Wire Connection Lost'}</strong>
                            </h2>
                            <TooltipDescription>
                              <p>{store.isNikkeiProcessing ? 'Your audio is routed through the Elk Wire plugin. To break the connection, please deactivate or close the plugin.' : 'Please verify that your input channel is armed for recording.'}</p>
                            </TooltipDescription>
                          </FooterTooltip>
                        }
                      >
                        <Box cursor="pointer" onClick={() => {
                          setTooltipOpen({
                            ...tooltipOpen,
                            pluginMode: !tooltipOpen.pluginMode
                          })
                        }}>
                          <InformationIcon />
                        </Box>
                      </TooltipBoundary>
                    </Box>
                  </>
                  : <MinimalDesktopAudioSelection  />
                }
              </>
            )}
          </Box>
        </SelectionContainer>
      )}
    </>

  );
})

export default MainView
