import * as rudderanalytics from "rudder-sdk-js";
import { getRudderstackKey, getRudderstackUrl } from '../../../utils/utils'

const loadRudderstackSDK = () => {
  try {
    rudderanalytics.load(getRudderstackKey(), getRudderstackUrl());
  }catch (error){
    console.log('Error trying to setup rudderstack SDK', error)
  }
}

loadRudderstackSDK()

export class Rudderstack {

  identified = false

  identify(user, analyticsId) {
    if(this.identified) return
    if(user.email) {
      rudderanalytics.identify(String(user.email), {
        email: user.email,
        name: user.display_name || user.name,
        first_name: user.display_name || user.name,
      })
    } else {
      rudderanalytics.identify(String(analyticsId), {
        email: user.email,
        name: user.display_name || user.name,
        first_name: user.display_name || user.name
      })
    }
    this.identified = true
  }

  alias(primaryKey, alias) {
    rudderanalytics.alias(primaryKey, alias)
  }

  track(name, eventData = {}) {
    rudderanalytics.track(name, eventData)
  }

  getAnonymousId(){
    return rudderanalytics.getAnonymousId()
  }
}