import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../store'
import PhantomSettings from '../PhantomSettings'
import { Box } from '../../../ui/primitives'

const StereoLinkButton = styled.button`
  border-radius: 4px;
  border: 1px solid #FFF;
  font-size: 12px;
  background: var(--ELK-Black);
  padding: 2px 10px;
  ${(props) => props?.sessionMode ? 'margin-bottom: 10px' : 'unset'};
  user-select: none;
  & svg {
    pointer-events: none;
  }
  font-weight: 600;
  &.disabled {
    color: var(--ELK-Dark-Green-Disabled);
    cursor: default;
  }
`

const BridgeAudioSettings = observer(() => {
  const store = useStore()
  const board = store.board
  const ownTrack = store.mixer.tracks.own

  if (!board) return <Box />

  return (
    <Box flex mb="5px" mt="5px">
      <Box justifyContent="center" flex>
        <PhantomSettings board={board} channelIndex={0} />
      </Box>
      <Box  justifyContent="center" flex>
        <StereoLinkButton
          sessionMode={store.isRoomOrSessionActive()}
          className={`${!store.mixer.isStereoLinkAvailable() ? 'disabled' : ''}`}
          isOn={ownTrack.stereoLinked}
          onClick={() => {
            if (!store.mixer.isStereoLinkAvailable()) {
              return
            }
            ownTrack.stereoLinked = !ownTrack.stereoLinked
          }}
        >
          {ownTrack.stereoLinked ? (
            <>
              <Box>unlink</Box>
            </>
          ) : (
            <>
              <Box>Stereo Link</Box>
            </>
          )}
        </StereoLinkButton>
      </Box>
      <Box justifyContent="center" flex>
        <PhantomSettings board={board} channelIndex={1} />
      </Box>
    </Box>
  )
})

export default BridgeAudioSettings
