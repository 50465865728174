import styled from 'styled-components'

export const Link = styled.a`
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: 1px dotted var(--color-light-grey);
  }
`
