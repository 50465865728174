import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../store'

import BridgeAudioSection from './default'
import DesktopAudioSection from './desktop'
import { StyledH1 } from '../styles'
import { BOARD_CONNECTION_STATE_CONNECTED } from '../../../../store/board-connection-states'

function AudioSection(props) {
  const store = useStore();
  let audioSection = <DesktopAudioSection {...props} />
  if (store.boardConnectionState === BOARD_CONNECTION_STATE_CONNECTED && !store.board?.isDesktopDevice) {
    audioSection = <BridgeAudioSection {...props} />
  }

  return (
    <>
      {!props.noTitle && <StyledH1>Audio</StyledH1>}
      {audioSection}
    </>
  )
}

export default observer(AudioSection)
