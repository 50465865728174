import { useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../../store'
import { Box, Text } from '../../../../ui/primitives'
import {
  SubscriptionH2,
} from '../styles'

const TransactionList = styled.ul`
  list-style-type: none;
  margin-top: 12px;
  padding: 0;
`;

const TransactionListItem = styled.li`
  margin: 6px auto;
  .date {
    display: inline-block;
    width: 110px;
  }
`

const ShowMoreButton = styled.div`
  cursor: pointer;
  span {
    text-decoration: underline;
  }
`

const PAGE_SIZE = 5;

const Transactions = observer(() => {
  const store = useStore()
  const [transactionStartIndex, setTransactionStartIndex] = useState(0);

  const subsciptionData = store.currentUser.fastspringData?.subscription
  const charges = store.currentUser.fastspringData?.account.charges || []

  const futurePayment = {
    date: subsciptionData?.nextChargeDateDisplayISO8601 || '',
    amount: subsciptionData?.nextChargeTotalDisplay || '',
  }

  const transactionHistory = charges.map(({timestampDisplayISO8601, total, currency }) => ({
    date: timestampDisplayISO8601,
    total,
    currency
  }))

  const transactionHistoryToShow = transactionHistory.slice(0, transactionStartIndex + PAGE_SIZE)
  const showMoreButton = transactionHistory.length >= PAGE_SIZE && transactionHistory.length !== transactionHistoryToShow.length

  const formatCurrency = (total, currency) => {
    const options = { style: 'currency', currency };
    return  new Intl.NumberFormat('en-US', options).format(total);
  }

  return (
    <Box mt="4rem">
      {futurePayment.amount && (
        <Box flex column mt="2rem" mb="2rem">
          <SubscriptionH2>Next payment</SubscriptionH2>
          <Text>Your next payment will be:</Text>
          <TransactionList>
            <TransactionListItem>
              <span className="date">{futurePayment.date}</span>
              <span>{futurePayment.amount}</span>
            </TransactionListItem>
          </TransactionList>
        </Box>
      )}

      {charges.length > 0 && (
        <Box flex column mt="2rem">
          <SubscriptionH2>Transaction history</SubscriptionH2>
          <Text>Here are your latest subscription transactions:</Text>
          <TransactionList>
            {transactionHistoryToShow.map(({date, total, currency}) => (
              <TransactionListItem key={date}>
                <span className="date">{date}</span>
                <span>{formatCurrency(total, currency)}</span>
              </TransactionListItem>
            ))}
            {showMoreButton &&  (
              <ShowMoreButton onClick={() => {
                setTransactionStartIndex(transactionStartIndex + PAGE_SIZE)
              }}>
                <span>show more</span>
              </ShowMoreButton>
            )}
          </TransactionList>
        </Box>
      )}
    </Box>
  )
})

export default Transactions