import mixpanel from 'mixpanel-browser'
import { getMixpanelTokenId } from '../../../utils/utils'

mixpanel.init(getMixpanelTokenId())

export class Mixpanel {
  identify(user) {
    if(user.id) {
      mixpanel.identify(user.id)
    } else {
      mixpanel.identify()
    }
    mixpanel.people.set({ $email: user.email, $first_name: user.display_name || user.name });
  }

  track(name, eventData = {}) {
    mixpanel.track(name, eventData)
  }

  getAnonymousId(){
    return mixpanel.get_distinct_id()
  }
}
