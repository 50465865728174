import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../../store'

import BridgeInputDropdown from "./default";
import DesktopInputDropdown from "./desktop";

function InputDropdown(props) {
  const store = useStore();
  if (store.board?.isDesktopDevice) {
    return <DesktopInputDropdown {...props} />
  }

  return <BridgeInputDropdown {...props}/>
}

export default observer(InputDropdown);
