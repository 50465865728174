import React, { forwardRef } from 'react'
import styled from 'styled-components'

const Input = styled.input`
  width: 100%;
  padding: ${props => props.py ?? '1rem'} 0 ${props => props.py ?? '1rem'} 1rem;
  display: block;
  background: var(--color-grey-2);
  border: 0;
  transition: .2s ease;
  &::placeholder {
    color: var(--color-light-grey);
  }
  &[type=number] {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  &:disabled {
    cursor: default;
    background: #555;
  }
  &:focus {
    box-shadow: 0 0 0 1px var(--color-light-grey);
  }
`

/* eslint-disable-next-line */
export const TextField = forwardRef((props, ref) => <Input {...props} ref={ref} />)
