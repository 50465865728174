const colors = [
  'amaranth',
  'amber',
  'amethyst',
  'apricot',
  'aqua',
  'aquamarine',
  'azure',
  'beige',
  'black',
  'blue',
  'bronze',
  'brown',
  'buff',
  'burgundy',
  'camouflage',
  'carmine',
  'cerise',
  'cerulean',
  'champagne',
  'charcoal',
  'chartreuse',
  'chocolate',
  'cinnabar',
  'cinnamon',
  'cobalt',
  'copper',
  'coral',
  'corn',
  'cornflower',
  'cream',
  'crimson',
  'cyan',
  'dandelion',
  'denim',
  'ecru',
  'emerald',
  'eggplant',
  'falu-red',
  'firebrick',
  'flax',
  'forest',
  'fuchsia',
  'gamboge',
  'gold',
  'goldenrod',
  'green',
  'grey',
  'harlequin',
  'heliotrope',
  'indigo',
  'ivory',
  'jade',
  'khaki',
  'lavender',
  'lemon',
  'lilac',
  'lime',
  'linen',
  'magenta',
  'magnolia',
  'malachite',
  'maroon',
  'mauve',
  'midnight',
  'mint',
  'misty',
  'moss',
  'mustard',
  'myrtle',
  'navajo',
  'navy',
  'ochre',
  'green',
  'olive',
  'olivine',
  'orange',
  'orchid',
  'peach',
  'pear',
  'persimmon',
  'pine',
  'pink',
  'platinum',
  'plum',
  'powder',
  'pumpkin',
  'purple',
  'red',
  'rose',
  'royal',
  'royal',
  'ruby',
  'russet',
  'rust',
  'orange',
  'saffron',
  'salmon',
  'sandy-brown',
  'sangria',
  'sapphire',
  'scarlet',
  'yellow',
  'sea-green',
  'seashell',
  'sepia',
  'shamrock',
  'shocking',
  'silver',
  'sky',
  'grey',
  'smalt',
  'spring',
  'spring',
  'steel',
  'tan',
  'tangerine',
  'taupe',
  'teal',
  'clay',
  'thistle',
  'titanium',
  'tomato',
  'turquoise',
  'purple',
  'ultramarine',
  'vermilion',
  'violet',
  'viridian',
  'wheat',
  'white',
  'yellow',
]

const animals = [
  'albatross',
  'alligator',
  'alpaca',
  'ant',
  'anteater',
  'antelope',
  'fox',
  'wolf',
  'armadillo',
  'badger',
  'eagle',
  'eagle',
  'bandicoot',
  'barnacle',
  'barracuda',
  'basilisk',
  'bass',
  'bat',
  'whale',
  'bear',
  'beaver',
  'bug',
  'bee',
  'beetle',
  'bird',
  'bison',
  'boar',
  'bobcat',
  'bug',
  'butterfly',
  'camel',
  'caribou',
  'carp',
  'cat',
  'catshark',
  'chameleon',
  'cheetah',
  'chicken',
  'chipmunk',
  'cobra',
  'cod',
  'cougar',
  'cow',
  'coyote',
  'crab',
  'crawdad',
  'crayfish',
  'crocodile',
  'crow',
  'deer',
  'dinosaur',
  'dog',
  'dolphin',
  'donkey',
  'dragon',
  'dragonfly',
  'duck',
  'eagle',
  'eel',
  'elk',
  'falcon',
  'firefly',
  'flamingo',
  'fish',
  'frog',
  'gazelle',
  'giraffe',
  'goat',
  'goldfish',
  'goose',
  'grasshopper',
  'bear',
  'pig',
  'shark',
  'hamster',
  'hawk',
  'hedgehog',
  'hermit crab',
  'herring',
  'hoverfly',
  'hummingbird',
  'hyena',
  'iguana',
  'impala',
  'jaguar',
  'jellyfish',
  'kangaroo',
  'kingfisher',
  'kite',
  'kiwi',
  'koala',
  'koi',
  'dragon',
  'krill',
  'ladybug',
  'lark',
  'lemming',
  'lemur',
  'leopard',
  'lion',
  'lizard',
  'llama',
  'lobster',
  'mammal',
  'manatee',
  'manta ray',
  'meerkat',
  'mink',
  'mockingbird',
  'mole',
  'mongoose',
  'lizard',
  'monkey',
  'moose',
  'mosquito',
  'goat',
  'mouse',
  'muskox',
  'narwhal',
  'nightingale',
  'ocelot',
  'octopus',
  'orca',
  'ostrich',
  'otter',
  'owl',
  'ox',
  'panda',
  'panther',
  'parakeet',
  'parrot',
  'partridge',
  'peacock',
  'pelican',
  'penguin',
  'perch',
  'pheasant',
  'pig',
  'pigeon',
  'pike',
  'pilot whale',
  'pinniped',
  'piranha',
  'planarian',
  'platypus',
  'polar bear',
  'pony',
  'porcupine',
  'porpoise',
  'prairie dog',
  'prawn',
  'puma',
  'python',
  'quail',
  'rabbit',
  'raccoon',
  'trout',
  'rat',
  'rattlesnake',
  'raven',
  'reindeer',
  'reptile',
  'rhinoceros',
  'roadrunner',
  'rodent',
  'rook',
  'rooster',
  'sailfish',
  'salamander',
  'salmon',
  'sawfish',
  'scorpion',
  'sea lion',
  'sea snail',
  'seahorse',
  'shark',
  'sheep',
  'sheep',
  'shrew',
  'shrimp',
  'sloth',
  'snail',
  'snake',
  'leopard',
  'sparrow',
  'spider',
  'squid',
  'squirrel',
  'starfish',
  'stingray',
  'stork',
  'sturgeon',
  'swallow',
  'swan',
  'swift',
  'swordfish',
  'swordtail',
  'tapir',
  'tarantula',
  'tiger shark',
  'tiger',
  'toad',
  'tortoise',
  'spider',
  'tree frog',
  'trout',
  'tuna',
  'turkey',
  'turtle',
  'tyrannosaurus',
  'viper',
  'wallaby',
  'walrus',
  'warbler',
  'wasp',
  'weasel',
  'whitefish',
  'crane',
  'wildcat',
  'wolf',
  'wolverine',
  'wombat',
  'yak',
  'finch',
  'zebra',
]

const uuidToMnemonicName = (uuid) => {
  if (uuid === 'manual') return 'Manual'
  const parts = uuid.split('-')
  const lastPart = parts[parts.length - 1]
  const value = parseInt(`0x${lastPart}`)
  return colors[value % colors.length] + ' ' + animals[(value >>> 8) % animals.length]
}

export default uuidToMnemonicName
