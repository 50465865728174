import { render, unmountComponentAtNode } from "react-dom"

const MODAL_ROOT_ID = "__modals"
const ANCHOR_ID = "root"

function getModalRoot() {
  let root = document.getElementById(MODAL_ROOT_ID)
  if (!root) {
    root = document.createElement("div")
    root.id = MODAL_ROOT_ID
    document.getElementById(ANCHOR_ID).insertAdjacentElement('afterend', root)
  }
  return root
}

function getTransitionDuration(node, recursive = false) {
  const durations = getComputedStyle(node)
    .transitionDuration.split(",")
    .map(s => parseFloat(s))
  let duration = Math.max(...durations)

  if (duration === 0 && recursive) {
    duration = Math.max(
      0,
      ...[...node.children].map(element => getTransitionDuration(element))
    )
  }
  return duration
}

export function startCSSTransition(node, state, callback) {
  const onTransitionEnd = function() {
    node.removeEventListener("transitionend", onTransitionEnd)
    if (callback) callback()
  }
  node.dataset.transitionState = state + "ing"
  node.scrollTop // eslint-disable-line no-unused-expressions
  node.dataset.transitionState = state

  const duration = getTransitionDuration(node, true)
  if (duration > 0) {
    node.addEventListener("transitionend", onTransitionEnd)
  } else {
    onTransitionEnd()
  }
}

export function openModal(componentFn, { autoDismissable = false, onClose, alwaysOnTop = false } = {}) {
  const root = getModalRoot()
  let isClosed = false
  const backdrop = document.createElement("div")
  backdrop.className = "backdrop"
  backdrop.style.zIndex = alwaysOnTop ? '1000' : '999'
  root.appendChild(backdrop)


  function close() {
    if (isClosed) {
      return
    }
    startCSSTransition(backdrop, "exit", () => {
      unmountComponentAtNode(backdrop)
      backdrop.remove()
    })
    if (onClose) {
      onClose(...arguments)
    }
    isClosed = true
  }

  function escClose(e) {
    if (e.keyCode === 27) {
      close()
    }
  }

  render(componentFn({ close }), backdrop, () => {
    startCSSTransition(backdrop, "enter")
    if (autoDismissable) {
      backdrop.addEventListener("click", (event) => {
        if (event.currentTarget !== event.target) {
          return
        }
        close()
      })
      document.addEventListener("keydown", (e) => escClose(e));
    }
  })

  return { close }
}
