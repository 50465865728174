import { RequestManager, HTTPTransport, Client } from '@open-rpc/client-js'
import { logger } from '../utils/logging'
import { Auth } from 'aws-amplify'

const sensitiveMethods = ['login', 'register']
const redundantMethods = [
  'get_settings',
  'set_settings',
  'get_logs',
  'get_logs_discrete',
  'report_an_issue',
]

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const debugLogMethods = [
  'query_user',
  'query_users',
  'get_friends',
  'verify_id_token',
  'get_active_users'
]

export const RPCClient = (address, port, secure = true, socketio_client) => {
  const protocol = secure ? 'https' : 'http'
  const portToUse = port ? `:${port}` : ''
  const url = `${protocol}://${address}${portToUse}`

  let transport = new HTTPTransport(url)
  let client = new Client(new RequestManager([transport]))

  return {
    _url: url,

    async invoke(method, params) {
      try {
        const result = await client.request({
          method,
          params,
        })
        /* Logging */
        if (method !== 'periodic_client_poll' && method !== 'check_access_token') {

          const logLevel = debugLogMethods.includes(method) ? 'debug' : 'info'

          const name = `[${
            url.includes('ip-') ? 'BOARD' : 'CLOUD'
          } RPC] ${method}`
          if (
            sensitiveMethods.includes(method) ||
            redundantMethods.includes(method)
          ) {
            logger[logLevel](name)
          } else {
            logger[logLevel](name, { params: params || {}, result })
          }
        }
        return result
      } catch (error) {
        if (error.data?.type === 'ExpiredSignatureError') {
          logger.info(
            'Got ExpiredSignatureError, trying to refresh access token',
          )
          try {
            // If error, obtain new access token ...
            const success = await this.refresh_access_token()
            if (success) {
              if (socketio_client !== null) {
                logger.info('access token refreshed, wait 100 ms')
                timeout(100)
                logger.info(
                  'access token refreshed, reset socketio access token',
                )
                socketio_client.set_session_access_token(this._access_token)
              }
              // ... and retry invoke
              logger.info('access token refreshed, wait 100 ms')
              timeout(100)
              logger.info('access token refreshed, retrying invoke')
              await this.invoke(method, params)
            }
          } catch (e) {
            // throw new error e
            logger.error(
              '[RPC Error] Failed to refresh access token: ' +
              method +
              ': ' +
              JSON.stringify({
                ...(e.data || {}),
                code: e.code,
                message: e.message,
              }),
            )
            throw e
          }
        } else {
          logger.info(
            '[RPC Error] Got error when invoking ' + method + ': ',
            {
              ...(error.data || {}),
              code: error.code,
              message: error.message,
            },
          )
          // rethrow
          throw error
        }
      }
    },

    set_session_access_token(access_token) {
      // for use with the server backend
      transport = new HTTPTransport(url, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      client = new Client(new RequestManager([transport]))
      this._access_token = access_token
    },

    set_session_refresh_token(refresh_token) {
      this._refresh_token = refresh_token
    },

    async refresh_access_token() {
      // Rather than use the set_session_access_token to update transport with a refresh token
      // use own temporary refresh client.
      // This is to avoid a race condition bug where cloud client
      // calls are made with a refresh token instead of access_token
      // this.set_session_access_token(this._refresh_token)
      const session = await Auth.currentSession()

      this._access_token = session.accessToken.jwtToken
      this.set_session_access_token(session.accessToken.jwtToken)

      return true
    },

    set_board_connection_token(connection_token) {
      // for use with the board backend
      transport = new HTTPTransport(url, {
        headers: {
          AlohaConnectionToken: `${connection_token}`,
        },
      })
      client = new Client(new RequestManager([transport]))
    },

    get_ip(){
      return address.replace('ip-','').replace('.local.elkpowered.com', '')
    }
  }
}
