/* eslint-disable no-useless-computed-key */

import { APIClient } from '../client'
import { closeAllModals, getCurrentVersion } from '../../utils/utils'
import { sleep } from '../../store/board'
import { logger } from '../../utils/logging'
import { showDialog } from '../../components/dialogs/ConfirmationDialog'
import { showAsyncLoading } from '../../components/dialogs/AsyncLoading'

const initPaddle = (store) => {
    if(!window.Paddle) return
    const Paddle = window.Paddle

    Paddle.Setup({
        seller: parseInt(process.env.REACT_APP_PADDLE_SELLER_ID),
        eventCallback: async (event) => {
            if(event.name === 'checkout.completed'){
                await sleep(1000)
                await APIClient.get_subscription_data(store.currentUserId)
                store.userHasActiveSubscription = true
                localStorage.removeItem('freemiumWaitingDisabled')
            }
        },
        checkout: {
            settings: {
                theme: "dark",
            }
        }
    })

    Paddle.Environment.set(
        'sandbox'
        )

    return Paddle
}


const setProductDetails = (productResponse, store) => {
    store.subscriptionPlanPrices = productResponse.product_details.data.pop()
}

const setSelectedPlan = (subscriptionResponse, store) => {
    store.selectedSubscriptionPlan = subscriptionResponse
}

const launchPaddleCheckout = (store, priceId) => {
    store.paddle.Checkout.open({
    displayMode: 'overlay',
    items: [{ priceId, quantity: 1}],
    discountId: process.env.REACT_APP_PADDLE_DEFAULT_DISCOUNT || undefined,
    customData: { user_id: store.currentUserId, env: getCurrentVersion().env },
    customer: {
        email: store.currentUserEmail
    }
    })
}
const launchCheckout = async (store, priceId) => {
  if(!store.selectedSubscriptionPlan){
      launchPaddleCheckout(store, priceId); return;
  }

  const activeSubscriptions = store.selectedSubscriptionPlan.subscriptions.data.filter(item => item.status === 'active')

  if(activeSubscriptions.length === 0) {
      launchPaddleCheckout(store, priceId); return;
  }

  showDialog(
    {
      store,
      title: `Are you sure?`,
      body: 'You’re about to change the billing frequency of your plan.',
      okText: 'Yes, change',
      cancelText: 'No, keep my plan',
      reverse: true,
      okAction: async () => {
        store.analytics.track('Subs_clicked_upgrade_cta', {['Price ID']: priceId})
        await requestSubscriptionBillingFrequencyChange(store, priceId)
      }
    })
}

const requestSubscriptionBillingFrequencyChange = async (store, priceId) => {
  try {

   const activeSubscriptions = store.selectedSubscriptionPlan.subscriptions.data.filter(item => item.status === 'active')

    if(!activeSubscriptions){
      logger.error('There is not subscription ID for this user')
      return
    }

    const subscriptionId = activeSubscriptions[0].id

    showAsyncLoading({ store, text: 'Processing request to change plan...' })
    store.loadingStatus = 'LOADING'

    await APIClient.request_a_subscription_billing_frequency_change(
      subscriptionId,
      priceId
    )
  } catch (e){
      store.loadingStatus = 'FAILURE'
      setTimeout(() => {
        closeAllModals()
      }, 1500)
    logger.error('Error on updating billing frequency', { error: e })
  }
}

const getCurrentPriceId = (store) => {
    if(!store.selectedSubscriptionPlan) return null

    const subscription = store.selectedSubscriptionPlan
        .subscriptions.data
      .filter((item) => item.status === 'active')

    if(!subscription[0]) return null

    return {
        priceId: subscription[0].items[0].price.id,
        productId: subscription[0].items[0].price.product_id
    }
}

const getManagementUrls = (store) => {
    if(!store.selectedSubscriptionPlan) return null

    return store.selectedSubscriptionPlan
          .subscriptions.data
          .filter((item) => item.status === 'active')[0].management_urls
}

const getSubscriptionByPriceId = (store, priceId) => {
    return store.subscriptionPlanPrices
            .prices.find((item) => item.id === priceId)
}

const getScheduleCancel = (store) => {
    if(!store.selectedSubscriptionPlan) return false

      const currentActiveSubscription = store.selectedSubscriptionPlan.subscriptions.data.find(({ status }) => status === 'active')

      if(currentActiveSubscription && currentActiveSubscription.scheduled_change && currentActiveSubscription.scheduled_change.action === 'cancel') {
          return currentActiveSubscription.scheduled_change.effective_at
      }

      return false
}


export {  initPaddle,
          setProductDetails,
          launchCheckout,
          setSelectedPlan,
          requestSubscriptionBillingFrequencyChange,
          getCurrentPriceId,
          getSubscriptionByPriceId,
          getManagementUrls,
          getScheduleCancel
      }
