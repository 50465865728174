
export const OWNER = 'OWNER'
export const ADMITTED = 'ADMITTED'
export const WHITELISTED = 'WHITELISTED'

export const MemberStatusText = {
  [WHITELISTED]: "permanent access",
  [OWNER]: 'owner',
  [ADMITTED]: 'temporary access'
}

