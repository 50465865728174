import styled from 'styled-components'
import { Box } from '../primitives'
import { CheckboxIcon } from '../icons'

const CheckboxSquare = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease;
  cursor: pointer;
  border: 2px solid white;
  &.checked {
    background: var(--ELK-Sea-Foam-Green);
    border-color: var(--ELK-Sea-Foam-Green);
  }
  &.disabled {
    border-color: var(--ELK-Dark-Grey) !important;
    color: var(--ELK-Black) !important;
    cursor: default;
  }
  &.disabled.checked {
    background-color: var(--ELK-Dark-Grey) !important;
  }
  & svg { pointer-events: none; }
`

const CheckboxLabel = styled.div`
  font-size: 1.6rem;
  user-select: none;
  font-weight: 400;
  cursor: pointer;
  &.disabled {
    cursor: default;
    color: var(--ELK-Text-Inactive) !important;
  }
`

const Checkbox = ({ label, checked = false, disabled = false, onChange = () => {}, ...rest }) => {
  return (
    <Box flex columnGap="1.1rem" alignItems="center" onClick={() => {
        if (!disabled) {
          onChange(!checked)
        }
      }} {...rest}>
      <Box width="30px" height="30px">
        <CheckboxSquare className={`square ${checked ? 'checked' : ''} ${disabled ? 'disabled' : ''}`}>
          {checked && <CheckboxIcon />}
        </CheckboxSquare>
      </Box>
      <CheckboxLabel className={`${checked ? 'checked' : ''} ${disabled ? 'disabled' : ''}`}>
        {label}
      </CheckboxLabel>
    </Box>
  )
}

export default Checkbox
