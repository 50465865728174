import styled from 'styled-components'
import { Box } from '../../ui/primitives'
import { RoundedButton } from '../../layout/styles'

export const FormContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const AvatarContainer = styled(Box)`
  display: flex;
  justify-content: center;
  width: 180px;
  height: 180px;
  margin-top: 10px;
`

export const ProfileEditButtons = styled(RoundedButton)`
  background: #353535;
  width: 30px;
`

export const FloatingButtonContainer = styled(Box)`  
  display:flex;
  width: 165px;
  justify-content: space-between;
  position: absolute;
  top: 244px;
`

export const HiddenFileInput = styled.input`
  visibility: hidden;
  position: absolute;
`