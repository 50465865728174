import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../store'

import BridgePhantomSettings from './default'

function PhantomSettings(props) {
  const store = useStore()
  if (store.board?.isDesktopDevice) {
    return null;
  }

  return <BridgePhantomSettings {...props} />
}

export default observer(PhantomSettings)
