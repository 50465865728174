import styled from 'styled-components'
import { Box, H2, H3 } from '../../../../ui/primitives'
import { TextField } from '../../../../ui/text-field'
import BetaRegisterHero from '../../../../../assets/beta-register-hero.jpg'
import { LegacyButton } from '../../../../legacy/legacy-button'
import { mediaQueries } from '../../../../../variables'

const Header = styled.div`
  width: 100%;
  height: 78px;
  background: var(--ELK-Black);
  position: fixed;
  z-index:1;
`
const Container = styled.div`
  width: 100%;
  background-color: var(--ELK-Black);
`

const BetaBodyContaienr = styled.div`
  position: relative;
  top: 100px;
  z-index:0;
`

const BetaBgContainer = styled.div`
  width: 100%;
  color: var(--ELK-White);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Subtitle = styled(H3)`
  font-size: 50px;
  font-weight: 700;
  color: var(--ELK-High-Contrast-Yellow);
  ${mediaQueries.lessThan('medium')`
    font-size: 24px;
  `}
`

const Title = styled(H2)`
  font-size: 50px;
  font-weight: 700;
  margin-top: 20px;
  text-align: center;
  
  ${mediaQueries.lessThan('medium')`
    font-size: 24px;
  `}
`

const Hero = styled.div`
  width: 100%;
  height: 35vw;
  max-height: 790px;
  background-image: url(${BetaRegisterHero});
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

const HeroText = styled.h1`
  background: var(--ELK-High-Contrast-Yellow);
  color: var(--ELK-Black);
  padding: 10px;
  font-size: 50px;
  font-weight: 700;
  ${mediaQueries.lessThan('medium')`
    font-size: 24px;
  `}
`

const StyledButton = styled(LegacyButton)`
  font-weight: 700;
  background: var(--ELK-High-Contrast-Yellow);
  color: var(--ELK-Black);
  border-radius: 6px;
  height: 4.3rem;
  width: 100%;
  min-width: 0;
  font-size: 1.4rem;
  align-items: center;
  font-weight: 700;
  justify-content: center;
  display: flex;
  :disabled {
    opacity: 0.25;
  }
`

const SocialButton = styled(StyledButton)`
  font-weight: 700;
  background: var(--ELK-White);
  color: var(--ELK-Black);
  height: 4.3rem;
  width: 100%;
  min-width: 0;
  font-size: 1.4rem;
  align-items: center;
  font-weight: 700;
  justify-content: center;
  margin: 1.5rem 0;
  display: flex;
  :disabled {
    opacity: 0.25;
  }
  svg {
    margin-right: 7px;
  }
`

const FacebookButton = styled(SocialButton)`
  background: var(--Facebook-Blue);
  color: var(--ELK-White);
`

const BetaTextField = styled(TextField)`
  padding: 1.3rem;
  color: var(--ELK-Black);
  text-align: center;
  font-weight: 700;
`

const NavContainer = styled.div`
  width: 90%;
  ${mediaQueries.greaterThan('large')`
    width: 1080px;
    padding: 17px 0px;
  `}
  height: 100%;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
    nav {
      
      display: flex;
      align-items: center;
      h2 {
        cursor: pointer;  
        margin: 12px 21px;
        font-size: 14px;
        font-weight: 800;
        &:hover {
         color: var(--ELK-High-Contrast-Yellow); 
        }
      }
      ${mediaQueries.lessThan('medium')`
        display:none;
      `
      }
    }
`

const StyledH4 = styled.h4`
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 5rem;
    color: var(--ELK-White);
`

const Paragraph = styled.p`
  line-height: 2.4rem;
  strong {
    font-weight: 700;
  }
`

const Footer = styled.footer`
  display: flex;
  height: 66px;
  margin-top: 163px;
  justify-content: center;
  align-items: center;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  a {
    color: #fff;
    font-size: 16px;
    line-height: 13px;
    font-weight: 500;
    text-decoration: none;
     ${mediaQueries.lessThan('medium')`
      font-size: 14px;
     `}
  } 
  img {
    height: 13px;
  }
    ${mediaQueries.lessThan('medium')`
      display: grid;
      height: auto;
      padding-right: 33px;
      padding-bottom: 40px;
      padding-left: 33px;
      grid-auto-columns: 1fr;
      grid-column-gap: 24px;
      grid-row-gap: 24px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
    `
    }
`

const FooterDivider = styled.div`
    width: 1px;
    height: 20px;
    background-color: #fff;
    ${mediaQueries.lessThan('medium')`
      display:none;
    `}
`
const SocialFooter = styled.div`
  display: flex;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  a {
    max-width: 100%;
    display: inline-bloc
  }
  img {
    max-width: 20px;
    min-width: 20px;
  }
`

const AppDownloadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: end;
  width: 254px;
  height: auto;
  border-radius: 6px;
  padding: 22px 40px;
  ${mediaQueries.lessThan('medium')`
      margin: 20px;
  `}

  margin: 0px 20px;
  cursor: pointer;
  background: ${props => props?.disabled ? 'var(--ELK-Dark-Grey)' : 'var(--ELK-High-Contrast-Yellow)'};
  span {
    color: ${props => props?.disabled ? 'var(--ELK-Disabled-Grey)' : 'var(--ELK-Black)'};
    font-size: 1.5rem;
    font-weight: 700;
  }
`

const DownloadBoxLink = styled.a`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: space-between;
`

const JoinButton = styled.button`
  background: var(--ELK-High-Contrast-Yellow);
  color: var(--ELK-Black);
  border-radius: 4px;
  font-size: 16px;
  font-weight: 700;
  width: 90px;
  padding: 5px 0px;
`

const StyledLink = styled.a`
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 800;
  text-decoration: underline;
  margin-bottom: 1.8rem;
`

const InlineStyledLink = styled(StyledLink)`
  margin-bottom: 0;
`

const DownloadIconsContainer = styled(Box)`
  display: flex;
  margin-top:4.5rem;
  ${mediaQueries.lessThan('medium')`
      flex-direction: column;
  `}
`

export {
  Container,
  Header,
  BetaBgContainer,
  JoinButton,
  DownloadIconsContainer,
  Subtitle,
  Title,
  Hero,
  HeroText,
  StyledButton,
  SocialButton,
  FacebookButton,
  BetaTextField,
  NavContainer,
  StyledH4,
  Paragraph,
  Footer,
  FooterDivider,
  AppDownloadContainer,
  SocialFooter,
  StyledLink,
  BetaBodyContaienr,
  DownloadBoxLink,
  InlineStyledLink
}