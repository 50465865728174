import { observer } from 'mobx-react-lite'

import { openModal } from '../overlays'
import { Box, H1, Text } from '../../ui/primitives'
import { Dialog } from '../base'
import { useStore, withStore } from '../../../store'
import { useEffect } from 'react'

const OfflineError = observer(({ close }) => {

  const store = useStore()

  useEffect(() => {
    if(store.isOnline){
      close()
    }
  // eslint-disable-next-line
  }, [store.isOnline])

  return (
    <Dialog
      flex
      flexDirection="column"
      px="5.7rem"
      py="4.3rem"
      color="var(--ELK-White)"
      width="47rem"
      alignItems="center"
      textAlign="center"
      fontSize="1.6rem"
    >
      <H1 mb="2rem" color="var(--ELK-White)">You are offline!</H1>
      <Box fontWeight="500" mt="2rem">
        <Text>
          You lost your internet connection, make sure you are connected via ethernet or WiFi.
        </Text>
      </Box>
    </Dialog>
  )
})

export function showOfflineError({ store }) {
  return openModal(
    ({ close }) => withStore(store, <OfflineError close={close}  />),
    {
      autoDismissable: false
    },
  )
}
