import styled from 'styled-components'
import { Box, property } from './primitives'

export const Button = styled(Box).attrs((props) => ({
  as: props.as || 'button',
  type: props.type || 'button',
}))`
  ${(props) =>
    (props.primary || props.secondary || props.danger || props.success) &&
    `
    width: ${props.width ?? '22.5rem'};
    height: ${props.height ?? '3.5rem'};
    border-radius: 6px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 1rem 2rem;
    text-align: center;
    font-size: ${props.fontSize ?? '2.4rem'};
    font-weight: ${props.fontWeight ?? '700'};
    background: ${
      props.secondary
        ? 'transparent'
        : props.success
        ? 'var(--ELK-High-Prio-Green-Enabled)'
        : props.danger
        ? 'var(--ELK-High-Prio-Red-Enabled)'
        : 'var(--ELK-Sea-Foam-Green)'
    };
    transition: background .2s ease, color .2s ease;
    ${property(
      props,
      'px',
      (px) => `padding-left: ${px}; padding-right: ${px};`,
    )}
    ${property(
      props,
      'py',
      (py) => `padding-top: ${py}; padding-bottom: ${py};`,
    )}
    ${property(props, 'width', 'width')}

    &:hover {
      background: ${
        props.secondary
          ? 'transparent'
          : props.success
          ? 'var(--ELK-High-Prio-Green-Hover)'
          : props.danger
          ? 'var(--ELK-High-Prio-Red-Hover)'
          : 'var(--ELK-Standard-Hover)'
      };
    }

    &:focus, &.focus {
      background: ${
        props.secondary
          ? 'transparent'
          : props.success
          ? 'var(--ELK-High-Prio-Green-Pressed)'
          : props.danger
          ? 'var(--ELK-High-Prio-Red-Pressed)'
          : 'var(--ELK-Standard-Pressed)'
      };
    }

    &:disabled {
      cursor: default;
      background: var(--ELK-Standard-Inactive);
      color: var(--ELK-Text-Standard-Inactive);
    }

    &.disabledStyle {
      cursor: default;
      background: var(--ELK-Standard-Inactive);
      color: var(--ELK-Text-Standard-Inactive);
      pointer-events: all;
    }
  `}
`

export default Button
