import styled from 'styled-components'
import { Box, H1, H2, P, Text } from '../../../ui/primitives';

export const Container = styled(Box)`
  font-size: 1.4rem;
  max-width: 90rem;
`
export const Header = styled(Box)``

export const SubscriptionH1 = styled(H1)`
  font-size: 4.6rem;
  margin-bottom: 1rem;
`
export const SubscriptionH2 = styled(H2)`
  font-weight: bold;
  margin-bottom: 1rem;
`
export const SubscriptionP = styled(P)`
  font-size: 1.4rem;
`
export const SubscriptionText = styled(Text)`
  font-size: 1.4rem;
`
