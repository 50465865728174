import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../store'
import { USB_OUTPUT_FULL_MIX, USB_OUTPUT_LOCAL_ONLY, USB_OUTPUT_REMOTE_ONLY, USB_OUTPUT_DISABLED } from '../../../../store/usb-output'
import { QuestionMarkIcon } from '../../../ui/icons'
import { StyledH2 } from '../styles'
import Dropdown from '../../../ui/form/dropdown'

function BridgeAudio() {
  const store = useStore();
  const usbOutputSettings = [
    { title: 'Full Mix - All Channels', value: USB_OUTPUT_FULL_MIX },
    { title: 'Local Audio Only', value: USB_OUTPUT_LOCAL_ONLY },
    { title: 'Remote Audio Only', value: USB_OUTPUT_REMOTE_ONLY },
  ]
  const selectedIndex = usbOutputSettings.findIndex((setting) => {
    return setting.value === store.mixer.usbOutput
  })
  return (
    <>
      <StyledH2>
        USB Audio Output{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://intercom.help/elk-audio/en/articles/5922679-usb-audio"
        >
          <QuestionMarkIcon />
        </a>
      </StyledH2>
      {store.mixer.usbOutput === USB_OUTPUT_DISABLED ? (
        <>
          <Dropdown width="100%" title={'Not Available'} enabled={false} />
        </>
      ) : (
        <Dropdown
          width="100%"
          title={usbOutputSettings[selectedIndex].title}
          menuItems={usbOutputSettings}
          enabled={!store.sessionMode}
          selectedIndex={selectedIndex}
          onChange={(primarySelection) => {
            store.mixer.usbOutput = usbOutputSettings[primarySelection].value
          }}
        />
      )}
    </>
  )
}

export default observer(BridgeAudio)
