import React from 'react'
import { observer } from 'mobx-react-lite'
import InputSourceSelector from './InputSourceSelector'
import { Box } from '../../ui/primitives'
import { useStore } from '../../../store'

const BridgeSettings = observer((props) => {

  const store = useStore()

  return (
      <Box
        flex
        alignItems="start"
        width="100%"
        justifyContent="center"
        mt="10px"
      >
        {props.stereoLinked || store.mixer?.isPluginMode() ? (
          <InputSourceSelector title={store.mixer?.isPluginMode() ? 'DAW' : 'IN 1+2'} hideDropdown channelIndex={0} />
        ) : (
          <>
            <InputSourceSelector title="IN 1" hideDropdown channelIndex={0} />
            <InputSourceSelector title="IN 2" hideDropdown channelIndex={1} />
          </>
        )}
      </Box>
  )
})

export default BridgeSettings
