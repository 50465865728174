import React, { useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { LogLevel } from 'amazon-chime-sdk-js'
import {
  darkTheme,
  MeetingProvider,
  useMeetingManager,
} from 'amazon-chime-sdk-component-library-react'
import { useStore } from '../../../store'
import { logger } from '../../../utils/logging'

export const getLoggerWrapper = () => {
  let logLevel = LogLevel.INFO
  return {
    getLogLevel() {
      return logLevel
    },
    setLogLevel(value) {
      logLevel = value
    },
    notice() {
      logger.notice('[CHIME]', { ...arguments })
    },
    info() {
      logger.info('[CHIME]', { ...arguments })
    },
    debug() {
      // ignore debug messages from Chime
    },
    warn() {
      logger.warn('[CHIME]', { ...arguments })
    },
    error() {
      logger.error('[CHIME]', { ...arguments })
    },
    exception() {
      logger.exception('[CHIME]', { ...arguments })
    },
  }
}

const AttendeeNameProvider = ({ children }) => {
  const store = useStore()
  const meetingManager = useMeetingManager()

  useEffect(() => {
    meetingManager.getAttendee = (chimeAttendeeId, externalUserId) => {
      let name = 'unknown'
      try {
        name = store.users.get(parseInt(externalUserId))?.name || name
      } catch (e) {
        logger.error('Failed to retreive participant name', e)
      }
      return { name }
    }
  }, [meetingManager, store])

  return children
}

const VideoChatProvider = ({ children }) => {
  return (
    <ThemeProvider theme={darkTheme}>
      {/* <MeetingProvider logger={getLoggerWrapper()}> */}
      <MeetingProvider id={"meetingProvider"}>
        <AttendeeNameProvider>{children}</AttendeeNameProvider>
      </MeetingProvider>
    </ThemeProvider>
  )
}

export default VideoChatProvider
