import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import React from 'react'
import Input from '../../../ui/form/input'
import { H2, Box } from '../../../ui/primitives'
import { useStore } from '../../../../store'
import {
  DEFAULT_JITTER_BUFFER_MULTIPLIER
} from '../../../../store/settings'

const StyledH2 = styled(H2)`
  font-size: 2rem;
`

export const JitterDelayControls = observer(({ userId }) => {
  const store = useStore()

  return (
    <>
      <StyledH2 pb='2rem'>Buffer</StyledH2>
      <Box flex columnGap='10px' alignItems='center' justifyContent='right'>
        <Box width='140px'>
          <Input
            value={store.session?.jitterBufferMultiplier.get(userId)}
            type='number'
            disabled={store.session?.videoSync.active}
            onChange={(e) => {
              let value = parseInt(e.target.value)
              if (isNaN(value)) {
                store.session.setPartnerJitterBufferMultiplier(userId, DEFAULT_JITTER_BUFFER_MULTIPLIER)
                return
              }
              if (value > 1000) {
                value = 1000
              }
              if (value < 0) {
                value = 0
              }
              store.session.setPartnerJitterBufferMultiplier(userId, value)
            }}
          />
        </Box>
      </Box>
    </>
  )
})
