import styled from 'styled-components'

export const BottomContainer = styled.div`
  position: absolute;
  bottom: ${(props) => props?.minimized ? 'unset' : '0'};
  height: 30px;
  width: 100%;
  display: flex;
  background: transparent;
  background: ${(props) => props?.minimized ? 'var(--ELK-Black)' : 'transparent'};
  justify-content: flex-start;
  align-items: flex-end;
  padding: 4px 10px;
  align-items: center;
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 37px;
  border-radius: 100%;
  &.off {
    color: var(--ELK-White);
    background-color: var(--ELK-High-Prio-Red-Enabled);
  }
  &.on {
    color: var(--ELK-Black);
    background-color: var(--ELK-White);
  }
  &.open {
    color: var(--ELK-White);
    background-color: var(--ELK-Standard-Pressed);
  }
`
