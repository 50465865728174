import styled from 'styled-components'
import { List } from '../../ui/list'
import { Button } from '../../ui/button'
import { LegacyButton } from '../../legacy/legacy-button'

const Input = styled.input`
  background: #eee;
  height: 50px;
  border: 0;
  padding: 12px;
  font-size: 15px;
  font-weight: 600;
  margin-left: 11px;
`

const SettingsButton = styled.button`
  color: var(--ELK-Black);
  display: flex;
  cursor: pointer;
  align-items: center;
`

const SettingsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  margin-left: 15px;
`

const PrimaryColumn = styled.div`
  flex-grow: 1;
`

const ContentWrapper = styled.div`
  display: flex;
  padding: 2.5rem 0 0;
  color: var(--ELK-Black);
`

const StyledList = styled(List)`
  flex: 1;
  padding-right: 6px;
  height: 350px;
  margin-bottom: 25px;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 172px);
  grid-template-rows: repeat(2, 172px);
  gap: 6px;
  margin-bottom: 25px;
`

const SelectedPartnerBox = styled.div`
  position: relative;
  cursor: grab;
`

const SelectedPartnerName = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--ELK-Black);
  color: var(--ELK-White);
  padding: 0 8px;
`

const RemoveIconWrapper = styled.div`
  padding: 2px;
  cursor: pointer;
`

const ActiveIconWrapper = styled.div`
  margin-left: 1rem;
`

const StyledButton = styled(Button)`
  width: 100%;
  color: var(--ELK-Black);
`

const LoadMoreButton = styled(LegacyButton)`
  font-weight: 700;
  background: var(--ELK-Standard-Enabled);
  color: var(--ELK-White);
  height: 4rem;
  width: 400px;
  margin-bottom: 10px;
  min-width: 0;
  font-size: 1.6rem;
  align-items: center;
  justify-content: center;
  display: flex;
  :disabled {
    opacity: 0.25;
  }
`

export {
  Input,
  SettingsButton,
  SettingsWrapper,
  PrimaryColumn,
  ContentWrapper,
  StyledList,
  Grid,
  SelectedPartnerBox,
  SelectedPartnerName,
  RemoveIconWrapper,
  ActiveIconWrapper,
  StyledButton,
  LoadMoreButton
}
