import { observer } from 'mobx-react-lite'
import { CloseButton, Dialog } from './base'
import { useStore, withStore } from '../../store'
import { useState } from 'react'
import styled from 'styled-components'
import { Spinner } from '../ui/spinner'
import { openModal } from './overlays'
import { Box, H1, H2 } from '../ui/primitives'
import { Button } from '../ui/button'

const StyledH2 = styled(H2)`
  font-weight: 400;
  margin-bottom: 5rem;
  margin-top: 1.5rem;
`

const AskForRebootDialog = observer(({ close, device }) => {

  const [waiting, setWaiting] = useState(false)
  const store = useStore()

  return (
    <Dialog
      flex
      flexDirection="column"
      px="5.7rem"
      py="4.3rem"
      width="72rem"
      alignItems="center"
      bgColor="var(--ELK-White)"
      textAlign="center"
    >
      <H1 color="var(--ELK-Black)">Reboot</H1>
      <Box maxWidth="44rem">
        <CloseButton onClick={() => close()} color="var(--ELK-Black)" />
        <StyledH2 color="var(--ELK-Black)">
          Please power off your Bridge then turn it back on again.
          <br />
          <br />
          Then wait for the LED lights underneath the Bridge to go from solid white to solid blue.
        </StyledH2>
      </Box>
      <Box>
        <Button primary disabled={waiting} width="60rem" onClick={async () => {
          if (waiting) {
            return
          }
          setWaiting(true)
          try {
            await store.connectToBoard(device.ipAddress)
            close()
          } catch (err) {
            // ignore errors
          }
          setWaiting(false)
        }} ml="0.8rem">{waiting ? <Spinner size={40} /> : <>I Have Rebooted and LEDs are blue</>}</Button>
      </Box>
    </Dialog>
  )
})

export function showAskForRebootDialog({ store, device }) {
  return openModal(
    ({ close }) => withStore(store, <AskForRebootDialog close={close} device={device} />),
    {
      autoDismissable: false,
    },
  )
}
