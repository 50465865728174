import { useState, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { observer } from 'mobx-react-lite'
import {
  Container,
  Header,
  SubscriptionH1,
  SubscriptionH2,
  SubscriptionP,
  SubscriptionText,
} from '../styles'
import { useStore } from '../../../../../store'
import Button from '../../../../ui/button'
import { Box } from '../../../../ui/primitives'
import { Link } from '../../../../ui/link'
import { ExternalLinkIcon } from '../../../../ui/icons'
import Checkbox from '../../../../ui/form/checkbox'

const SubscriptionForm = styled(Box)`
  max-width: 75rem;
`

const InactiveStatusTitle = styled.span`
  color: var(--ELK-High-Prio-Red-Enabled);
`

const SubscriptionButton = styled(Button)`
  color: var(--ELK-Black);
  background-color: var(--ELK-Sea-Foam-Green);
  font-size: inherit;
  margin-top: 4rem;
  height: initial;
  width: 100%;

  &:hover:not(:disabled) {
    background-color: var(--ELK-High-Prio-Green-Hover);
  }

  &:focus:not(:disabled) {
    background-color: var(--ELK-High-Prio-Green-Pressed);
  }
`

const StyledCheckbox = styled(Checkbox)`
  & .square:not(.checked) {
    border-color: var(--ELK-Black);
  }
  margin-bottom: 1.6rem;
`

const BoldLink = styled(Link)`
  font-weight: bold;
  text-decoration: underline;
`

const BoldText = styled(SubscriptionText)`
  font-weight: bold;
  display: inline;
`

const PlanPrice = styled.span`
  font-size: 46px;
  font-weight: bold;
  line-height: 1;
`
const PlanPriceUnit = styled.span`
  margin-left: 1rem;
`

const PlanSelectButton = styled(Button)`
  height: 15rem;
  border: 2px solid var(--ELK-Sea-Foam-Green);
  padding: 1.6rem;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  flex-grow: 1;
  flex-basis: 50%;
  ${({ $active }) =>
    $active &&
    css`
      background-color: var(--ELK-Sea-Foam-Green);
    `}
`

const SUBSCRIPTION_PLANS = {
  MONTHLY: 'elk-live-monthly-subscription'
}

function Inactive() {
  // eslint-disable-next-line
  const [promoCode] = useState('')
  // eslint-disable-next-line
  const [taxId] = useState('')
  const [acceptedAgreements, setAcceptedAgreements] = useState(false)
  const [selectedPricePlan, setSelectedPricePlan] = useState(
    SUBSCRIPTION_PLANS.MONTHLY,
  )
  const store = useStore()
  const subscriptionPlanPrices = store.fastSpringSubscriptionData

  const launchFastSpringCheckout = useCallback(() => {
    const session = {
      reset: true,
      paymentContact: {
        email: store.currentUserEmail,
        // firstName: 'Hardcoded',
        // lastName: 'Hardcodesson',
      },
      products: [
        {
          path: selectedPricePlan,
          quantity: 1,
        },
      ],
    }

    if (taxId) {
      window.fastspring.builder.taxId(taxId)
    }

    if (promoCode) {
      window.fastspring.builder.promo(promoCode)
    }

    window.fastspring.builder.push(session)
    window.fastspring.builder.checkout()
  }, [selectedPricePlan, store.currentUserEmail, promoCode, taxId])

  return (
    <Container>
      <Header>
        <SubscriptionH1>
          Subscription <InactiveStatusTitle>(not active)</InactiveStatusTitle>
        </SubscriptionH1>
        <SubscriptionH2>
          To get access to the elk.live experience, you need a subscription.
        </SubscriptionH2>
        <SubscriptionP>
          As soon as your subscription is activated you can add friends and
          start a session!
          <br />
        </SubscriptionP>
      </Header>
      <SubscriptionForm>
        <Box mt="3rem" mb="3rem" flex columnGap="2rem">
          <PlanSelectButton
            $active={selectedPricePlan === SUBSCRIPTION_PLANS.MONTHLY}
            onClick={() => setSelectedPricePlan(SUBSCRIPTION_PLANS.MONTHLY)}
          >
            <PlanPrice>{subscriptionPlanPrices?.monthly}</PlanPrice>
            <PlanPriceUnit>/month</PlanPriceUnit>
            <Box flex column rowGap="1rem">
              <span>the monthly plan</span>
              <span>cancel anytime</span>
            </Box>
          </PlanSelectButton>
        </Box>
        {/* NOTE: Removed until further investigation
        <InputContainer>
          <label>
            Add your discount code here:
            <br />
            <Input
              placeholder="discount code"
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value.toUpperCase())}
            />
          </label>
        </InputContainer>
        <InputContainer>
          <label>
            Got a corporate VAT/TAX ID? Otherwise, leave blank.
            <br />
            <Input
              placeholder="corporate vat/tax id"
              value={taxId}
              onChange={(e) => setTaxId(e.target.value)}
            />
          </label>
        </InputContainer>
        <br />
        */}
        <Box mt="2rem" mb="2rem">
          <SubscriptionH2>30 days money back guarantee!</SubscriptionH2>
          <SubscriptionText>
            We are super confident in the Elk experience, therefore we give you
            a 30 day return policy and money back if it doesn’t live up to your
            expectations.
          </SubscriptionText>
        </Box>
        <Box mb="2rem">
          <SubscriptionText><BoldText>Got a promotional code?</BoldText> Stay cool, that's added on the next page</SubscriptionText>
        </Box>
        <Box>
          <StyledCheckbox
            label={
              <>
                I accept&nbsp;
                <BoldLink
                  href="https://fastspring.com/terms-sale-us/?utm_source=store&amp;utm_medium=terms_of_sale_eu&amp;utm_content=pinhole&amp;utm_campaign=store_traffic"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms &amp; Conditions
                </BoldLink>
              </>
            }
            checked={acceptedAgreements}
            onChange={(value) => setAcceptedAgreements(value)}
          />
        </Box>
        <SubscriptionButton
          primary
          disabled={!acceptedAgreements}
          onClick={launchFastSpringCheckout}
        >
          Proceed to checkout
          <Box ml="1rem">
            <ExternalLinkIcon size="13" />
          </Box>
        </SubscriptionButton>
      </SubscriptionForm>
    </Container>
  )
}

export default observer(Inactive)
