import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import {
  ConsoleLogger,
  DefaultDeviceController
} from 'amazon-chime-sdk-js'
import VideoPlaceholder from './video-placeholder'
import { useStore } from '../../../store'
import { logger } from '../../../utils/logging'

const LocalVideo = () => {

  const store = useStore()
  const ref = useRef()

  useEffect(() => {
    if (!ref.current) return
    const domNode = ref.current
    const deviceLogger = new ConsoleLogger('VideoChatDeviceController')
    const deviceController = new DefaultDeviceController(deviceLogger)
    async function init() {
      try {
        const deviceList = await deviceController.listVideoInputDevices()
        if (deviceList.length === 0) return
        let selectedIndex = deviceList.findIndex((device) => {
          return device.deviceId === store.settings.videoChat.selectedVideoInputDeviceId
        })
        if (selectedIndex === -1) {
          selectedIndex = 0
        }
        await deviceController.startVideoInput(deviceList[selectedIndex].deviceId)
        deviceController.startVideoPreviewForVideoInput(domNode)
      } catch (e) {
        logger.error(e.message)
      }
    }
    init()
    return async () => {
      try {
        await deviceController.stopVideoInput()
        deviceController.stopVideoPreviewForVideoInput(domNode)
        deviceController.destroy()
      } catch (e) {
        logger.error(e.message)
      }
    }
  }, [ref, store.settings.videoChat.selectedVideoInputDeviceId])

  return (
    <video ref={ref} style={{ height: '100%', width: '100%', objectFit: 'cover' }}/>
  )
}

const VideoPreviewContainer = styled.div`
  position: relative;
  width: ${(props) => props?.fullSize ? '100vw': '675px'};
  height:  ${(props) => props?.fullSize ? 'calc(100vh - 120px)': '100%'};
  background: var(--Elk-Background-Grey);
`

export const VideoPreview = ({ enabled, user, sessionMode = false }) => {
  return (
    <VideoPreviewContainer fullSize={sessionMode}>
      {enabled ? (
        <LocalVideo />
      ) : (
        <VideoPlaceholder user={user} sessionMode={sessionMode} />
      )}
    </VideoPreviewContainer>
  )
}
