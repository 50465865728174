import React from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { Box, H3 } from '../../../ui/primitives'
import { useStore } from '../../../../store'
import { Auth } from 'aws-amplify'
import styled from 'styled-components'
import { TextField } from '../../../ui/text-field'
import { ShowPasswordIcon, PasswordTick } from '../../../ui/icons'
import { Spinner } from '../../../ui/spinner'
import {
  StyledH1,
  StyledButton,
  ErrorMessage
} from './styles'
import { LoginLayout } from './layout'

const RegisterForm = styled.form`
  width: 40.8rem;
  height: 70.7rem;
  background-color: var(--ELK-High-Contrast-Yellow);
  padding: 2.6rem 3.4rem;
`

const StyledH3 = styled(H3)`
  font-size: 1.4rem;
  font-weight: normal;
`

const StyledLink = styled.a`
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 800;
  text-decoration: underline;
  margin-bottom: 1.8rem;
`

const HalfWidthTextField = styled.input`
  width: 47%;
  padding: 1rem;
  margin: ${props => props.m ? props.m : 0};
  background: var(--ELK-White);
  color: var(--Elk-Black);
  border: 0;
  font-size: 1.4rem;
  transition: .2s ease;
  &::placeholder {
    color: var(--ELK-Light-Grey);
  }
  &:disabled {
    cursor: default;
    background: var(--ELK-Dark-Grey);
  }
  display:inline-flex;
  align-items: center;
  justify-content: flex-end;
`

const BoldPassword = styled.span`
  font-size: 1.4rem;
  font-weight: 800;
`

const ShowPasswordButton = styled.button`
  position: relative;
  z-index: 99;
  float: right;
  top: -4.3rem;
  right: 1rem;
  margin: ${props => props.m ? props.m : 0};
`

const FloatTick = styled.span`
  float:left;
  padding-right: 0.5rem;
`

const FloatExclaim = styled.span`
  float:left;
  font-weight: 800;
  border-radius: 1rem;
  width: 2rem;
  height: 2rem;
  align-content: center;
  background-color: var(--ELK-Black);
  color: var(--ELK-High-Prio-Yellow-Enabled);
  padding: 0.2rem 0.8rem;
  margin-right: 0.5rem;
  margin-bottom: 2rem;
  vertical-align: center;
`

export const AwsRegister = observer(({ setPage }) => {
  const store = useStore()
  const state = useLocalObservable(() => ({
    email: '',
    given_name: '',
    family_name: '',
    displayname: '',
    password: '',
    confirm_password: '',
    verificationCode: '',
    busy: false,
    hasRegistered: false,
    requiredMissing: true,
    passwordsMatch: true,
    passwordAtLeastEight: false,
    passwordShown: false,
    passwordError: ''
  }))

  const checkPasswordRequirements = () => {
    state.passwordAtLeastEight = state.password.length >= 8
    state.isPasswordValid = state.passwordAtLeastEight
  }

  const togglePasswordShown = () => {
    state.passwordShown = !state.passwordShown
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    state.passwordsMatch = state.password === state.confirm_password
    state.passwordError = state.passwordsMatch ? '' : 'var(--ELK-Candy-Tangerine)'

    state.busy = true
    if (!state.hasRegistered && state.passwordsMatch && state.isPasswordValid) {
      try {
        await Auth.signUp({
          username: state.email,
          password: state.password,
          attributes: {
            given_name: state.given_name,
            family_name: state.family_name,
            'custom:displayname': state.displayname
          }
        })
        state.hasRegistered = true
        store.showSnackBar({
          heading: 'Signup successful',
          level: 'success'
        })
      } catch (error) {
        console.error(error)
        store.showSnackBar({
          heading: 'Signup failed',
          content: error.message,
          level: 'error',
          duration: 5000
        })
      }
    }
    state.busy = false
  }

  const field = (name) => ({
    value: state[name],
    onChange: (e) => {
      (state[name] = e.target.value)
      if (name === 'password') {
        checkPasswordRequirements()
      }
      state.passwordError = ''
      state.passwordsMatch = true
    }
  })

  return (
    <LoginLayout>
        <RegisterForm onSubmit={handleSubmit}>
          {!state.hasRegistered
            ? (
              <>
                <StyledH1>register.</StyledH1>
                <Box
                  width='100%'
                  flexDirection='column'
                >
                  <HalfWidthTextField
                    placeholder='first name'
                    type='text'
                    {...field('given_name')}
                    required
                  />
                  <HalfWidthTextField
                    placeholder='last name'
                    type='text'
                    {...field('family_name')}
                    m='1.5rem 0 0 2rem'
                    required
                  />
                </Box>
                <TextField
                  placeholder='display name'
                  type='text'
                  {...field('displayname')}
                  m='1.5rem 0'
                  required
                />
                <TextField
                  placeholder='email'
                  type='email'
                  {...field('email')}
                  m='1.5rem 0'
                  required
                />
                <TextField
                  placeholder='password'
                  type={state.passwordShown ? 'text' : 'password'}
                  {...field('password')}
                  required
                  m='1.5rem 0'
                  bg={state.passwordError}
                />
                <ShowPasswordButton
                  type='button' onClick={togglePasswordShown}
                  m='0 0 -1.5rem 0'
                >
                  <ShowPasswordIcon size='18' />
                </ShowPasswordButton>
                <TextField
                  placeholder='re-type password'
                  type={state.passwordShown ? 'text' : 'password'}
                  {...field('confirm_password')}
                  required
                  m='1.5rem 0'
                  bg={state.passwordError}
                />
                <ShowPasswordButton type='button' onClick={togglePasswordShown}>
                  <ShowPasswordIcon size='18' />
                </ShowPasswordButton>
                <StyledH3>
                  <strong>Password requirements:</strong><br /><br />
                  {state.passwordAtLeastEight
                    ? <BoldPassword><FloatTick><PasswordTick size='16' stroke='#1C1C1C' /></FloatTick> At least 8 characters</BoldPassword>
                    : <><FloatTick><PasswordTick size='16' /></FloatTick> At least 8 characters</>}<br /><br />
                </StyledH3>
                <ErrorMessage>
                  {!state.passwordsMatch && <>
                    <FloatExclaim>!</FloatExclaim> passwords do not match
                                            </>}
                </ErrorMessage>

                <StyledButton
                  type='submit'
                  data-testid='submit'
                  disabled={state.busy || !state.isPasswordValid}
                >
                  {state.busy ? <Spinner size='24' /> : <>Register</>}
                </StyledButton>
              </>
              )
            : (
              <>
                <StyledH1>register.</StyledH1>
                <StyledH3>
                  Check your email and use the verification link we sent to
                  your email address.
                  <br /><br />
                </StyledH3>
              </>
              )}

          <Box fontSize='1.4rem'> Already a member? &nbsp;
            <StyledLink onClick={() => setPage('login')}>
              login here
            </StyledLink>
          </Box>
        </RegisterForm>
    </LoginLayout>
  )
})
