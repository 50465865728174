import {
  MeetingStatus,
  useAudioVideo,
  useMeetingStatus,
} from 'amazon-chime-sdk-component-library-react'
import { useEffect, useState } from 'react'
import { logger } from '../../../utils/logging'

const useRemoteAudioTrack = () => {
  const audioVideo = useAudioVideo()
  const meetingStatus = useMeetingStatus()

  // TODO: implement proper change tracking
  const [audioTrackCaptured, setAudioTrackCaptured] = useState(false)
  const [audioTrack, setAudioTrack] = useState(null)

  useEffect(() => {
    if (meetingStatus !== MeetingStatus.Succeeded) return
    if (audioTrackCaptured) return

    try {
      // alternatively, we could find the <audio/> element that LocalAudioOutputProvider renders
      // and take its srcObject value
      const chimeRpc = audioVideo.audioVideoController.rtcPeerConnection
      const audioStream = chimeRpc
        .getRemoteStreams()
        .find((s) => s.id === 'audio')
      const [audioTrack] = audioStream.getAudioTracks()
      setAudioTrack(audioTrack)
    } catch (e) {
      logger.error('Failed to retrieve remote audio track.', e)
    }

    // for now, just set it to true even if it failed to avoid possible uncontrolled retries
    setAudioTrackCaptured(true)
  }, [audioTrackCaptured, meetingStatus, audioVideo])

  return audioTrack
}

export default useRemoteAudioTrack
