import {
  INPUT_OPTION_NONE,
  INPUT_OPTION_GUITAR,
  INPUT_OPTION_LINE,
  INPUT_OPTION_MIC,
  INPUT_OPTION_USB,
} from '../../../../../store/input-options'

const baseOptions = [
  { title: 'line', option: INPUT_OPTION_LINE },
  { title: 'guitar', option: INPUT_OPTION_GUITAR },
  { title: 'mic', option: INPUT_OPTION_MIC },
  { title: 'USB', option: INPUT_OPTION_USB },
  { title: 'none', option: INPUT_OPTION_NONE },
]

const channel0Options = [...baseOptions]
const channel1Options = [...baseOptions]

const channelOptions = {
  0: channel0Options,
  1: channel1Options,
}

export default channelOptions
