/* eslint-disable no-useless-computed-key */

import { APIClient } from '../api/client'
import { logger } from '../utils/logging'
import { showDialog } from '../components/dialogs/ConfirmationDialog'
import { closeAllModals, copyContent, getArrayDifference } from '../utils/utils'
import { ADMITTED, OWNER, WHITELISTED } from '../components/dialogs/Room/member-status'
import { showSubscriptionDialog } from '../components/dialogs/Subscription'

export const MAX_DURATION_MINUTES = process.env.REACT_APP_MAX_DURATION_MINUTES || 2
export const MAX_DURATION_SESSIONS = MAX_DURATION_MINUTES * 60
export const MAX_BLOCKED_MINUTES = process.env.REACT_APP_MAX_BLOCKED_TIME || 2
export const MAX_BLOCKED_TIME = MAX_BLOCKED_MINUTES * 60
export const TIMERS_ENABLED =  Boolean(process.env.REACT_APP_TIMERS_ENABLED) || false

export class RoomManager {

  currentActiveRoom = null

  currentActiveTime = null

  waitingState = false

  constructor({ store }) {
    this.store = store
  }

  async getUserRooms(){
    try {
      this.store.rooms = []
      this.store.rooms = await APIClient.get_user_rooms()
    } catch (e) {
      logger.error('Error trying to get user rooms')
    }
  }

  async createRoom({ name, whitelistedUsers, roomCode, turnServerIp, turnServerTitle }){
    try {
      const response = await APIClient.create_room(
        name,
        whitelistedUsers,
        turnServerIp,
        turnServerTitle,
        roomCode
      )
      await this.getUserRooms()
      this.store.analytics.track('Room_Created', { ['Room ID']: response.room_id })

      whitelistedUsers.forEach((userId) => {
        this.store.analytics.track('Room_Member Added', { ['Room ID']: response.room_id, ['User ID'] : userId})
      })

      return response
    } catch (e) {
      this.showGenericErrorDialog(this.store.isDeveloperMode ? e.data.message : 'There was a problem creating the room, please wait and try again')
      logger.error(`Error trying to create the room`, e)
    }
  }

  async updateRoom({ id, name, whitelistedUsers, turnServerIp, turnServerTitle }) {
    try {
      const response = await APIClient.set_room_fields(
        id,
        name,
        whitelistedUsers,
        turnServerIp,
        turnServerTitle
      )

      const index = this.store.rooms.findIndex((room) => room.room_id === response.room_id)
      if(index !== -1) {
        this.store.rooms[index] = response
      }
      await this.getUserRooms()
      return response
    } catch (e) {
      this.showGenericErrorDialog(this.store.isDeveloperMode ? e.data.message : 'There was a problem updating the room, if it persist, try creating a new one')
      logger.error(`Error trying to update the room`, e)
    }
  }

  async deleteRoom(id) {
    try {
      await APIClient.delete_room(id)
      this.store.analytics.track('Room_Deleted', {['Room ID'] : id })
    } catch (e) {
      this.showGenericErrorDialog(this.store.isDeveloperMode ? e.data.message : 'There was a problem deleting the room, please wait and try again')
      logger.error(`Error trying to create the room`, e)
    }
  }

  async joinRoom(identifier) {
    try {

      if(this.areRoomsBlocked()){ this.showBlockedRoomDialog(); return }

      if(this.closingRoom){
        showDialog({
          store: this.store,
          title: 'The room is being closed',
          body: 'You have to wait a few seconds to re-join the room',
          okText: 'Ok',
        })
        return
      }
      const roomToEnter = this.store.rooms.find
      (room => room.room_id === identifier ||
        room.room_code === identifier
      )
      this.currentActiveRoom = roomToEnter || { room_code: identifier }

      await APIClient.join_room(identifier, this.store.systemState.includes('P'))

      const eventPayload = roomToEnter ?  {['Room ID'] : roomToEnter.room_id } :  {['Room Identifier'] : identifier }

      this.store.analytics.track('Room_Joined', eventPayload)
      this.store.resetVideoPreview()

    } catch (e) {
      if(e.data.message.includes('Not on whitelist')){
        const activeUsers = Number(e.data.message.split(',')[3].split(')')[0].trim())
        if(activeUsers === 0){
          showDialog({
            store: this.store,
            title: 'The room is empty',
            body: 'No one\'s in this room yet, please try again later.',
            cancelText: 'Cancel',
          })
          this.currentActiveRoom = null
          return
        }
        this.showRoomWaitingDialog()
        return
      }
      if(e.data.message.includes('Unable to identify')) {
        this.currentActiveRoom = null
        this.showGenericErrorDialog('The room you are trying to enter could not be identified.')
        return
      }

      this.currentActiveRoom = null
      this.showGenericErrorDialog(this.store.isDeveloperMode ? e.data.message : 'There was a problem with the server, please wait and try again')
      logger.error(`Error trying to join the room`, e)
    }
  }

  async leaveRoom(id){
    try {
      await APIClient.remove_room(id, this.store.currentUserId)
      await this.getUserRooms()
      await this.cleanRoomStatus()
    } catch (e) {
      this.showGenericErrorDialog(this.store.isDeveloperMode ? e.data.message : 'There was a problem leaving the room, please wait and try again')
      logger.error(`Error trying to leave the room`, e)
    }
  }

  async cleanRoomStatus() {
    try {
      if(this.store.rooms.length === 0) return

      const activeRooms = this.store.rooms.map(
        (room) => (room?.users.map(roomUser => ({...roomUser, id: room.room_id})))).flat()
        .filter(roomUser => roomUser.user_id === this.store.currentUserId && roomUser.is_active)

      await Promise.all(activeRooms.map(activeRoom => APIClient.update_room_user_active_status(activeRoom.id, false)))
    } catch (e) {
      logger.error(`Error trying to leave the room`, e)
    }
  }

  async setCurrentRoomActive() {
    try {
      await APIClient.update_room_user_active_status(this.currentActiveRoom.room_id, true)
    } catch (e) {
      logger.error(`Error trying to set user status in the active room`, e)
    }
  }

  async generateRandomRoomCode() {
    try {
      const response = await APIClient.generate_random_room_code()
      return response.room_code
    } catch (e) {
      logger.error(`Error trying to update the room`, e)
    }
  }

  isRoomActive(){
    return this.currentActiveRoom !== null
  }

  async closeRoom() {
    this.closingRoom = true
    this.currentActiveRoom = null
    await this.cleanRoomStatus()
    this.store.resetVideoPreview()
    setTimeout(() => {
      this.closingRoom = false
    }, 3000)
  }

  async replyRoomAccessRequest(accessUserId, roomId, answer)  {
    try {
      await APIClient.reply_to_room_access_request(
        roomId,
        accessUserId,
        this.store.currentUserId,
        answer
      )

      if(answer){
        this.store.analytics.track('Room_Admitted person', { ['Room ID']: roomId, ['Admitted User ID'] : accessUserId})
        return
      }
      this.store.analytics.track('Room_Declined access', { ['Room ID']: roomId, ['Declined User ID'] : accessUserId})
    } catch (e) {
      this.showGenericErrorDialog(this.store.isDeveloperMode ? e.data.message : 'There was a problem replying to the room request, please wait and try again')
      logger.error(`Error trying to reply room access request`, e)
    }
  }

  showGenericErrorDialog(message){
    showDialog({
      store: this.store,
      okText: 'Ok',
      body: message
    })
  }

  //Event handling

  async handleRoomUpdateNotification(updatedRoom) {
    const index = this.store.rooms.findIndex((room) => room.room_id === updatedRoom.room_id)
    if(index !== -1){
      logger.info(`Updating room with name ${updatedRoom.title}`)
      this.store.rooms[index] = updatedRoom
    } else {
      logger.info(`New room with name ${updatedRoom.title}, checking user's permissions`)

      const ownUserRole = updatedRoom.users
        .find(user => user.user_id === this.store.currentUserId)?.status

      logger.info(`User with role ${ownUserRole}`)

      if([WHITELISTED, OWNER].includes(ownUserRole)){
        logger.info('User has the right access, adding it to the local store')
        this.store.rooms.push(updatedRoom)
      }
    }

    const isCurrentUserActive = updatedRoom.users.find(item => item.user_id === this.store.currentUserId).is_active

    if(updatedRoom.room_id === this.currentActiveRoom?.room_id
      || updatedRoom.room_code === this.currentActiveRoom?.room_code){

      if(this.currentActiveRoom?.session_id && !updatedRoom.session_id){
        logger.info(`A previous session on the active room has been closed, checking user permissions..`)
        await this.handleTemporaryAccessUsers(updatedRoom)
      }

      if(isCurrentUserActive && this.shouldAdmittedUserBeKickedOut(updatedRoom) === false){
        logger.info(`Updated room is the same as active room: ${updatedRoom.title} syncing..`)
        this.currentActiveRoom = updatedRoom
      }
    }


    if(updatedRoom.session_id && this.currentActiveRoom){
      logger.info(`Room ${updatedRoom.title} with session active`)

      //ignore room update with session if the room is not the current active one
      if(updatedRoom.room_id !== this.currentActiveRoom.room_id) return
      //ignore room update if user is not active in that room
      if(!isCurrentUserActive) return

      if(this.waitingState){
        closeAllModals()
        this.waitingState = false
      }

      const activeUsersIds = updatedRoom.users
        .filter((user) => user.is_active && user.user_id !== this.store.currentUserId)
        .map(user => user.user_id)

      activeUsersIds.forEach((id) => {
        this.store.requestFetchUserInfo(id, true)
      })

      if(TIMERS_ENABLED){
        this.checkAndStartFreemiumTimer(updatedRoom, activeUsersIds)
      }

      if(!this.store.sessionMode){
        logger.info(`Starting a own session for the room`)
        await this.store.startExistingSession(updatedRoom, activeUsersIds)
      } else {
        //Getting the user id to add to the session
        const mapAndFilterActiveUsers = (users) => users.filter(user => user.is_active).map(user => user.user_id)
        const currentActiveUsers = [...Object.values(this.store.session.partnerIds), this.store.currentUserId]
        const newActiveUsers = mapAndFilterActiveUsers(updatedRoom.users)

        if(newActiveUsers.length > currentActiveUsers.length) {
          const usersToAdd = getArrayDifference(newActiveUsers, currentActiveUsers)

          if(usersToAdd[0]){
            logger.info(`Session active, new user added, adding them to the current session`)
            closeAllModals()
            this.store.session.addPartnerToSession(usersToAdd[0])
          }
        }

        if(newActiveUsers.length < currentActiveUsers.length) {
          logger.info('User leave the room, checking temporary access users states')
          const usersToRemove = getArrayDifference(currentActiveUsers, newActiveUsers)
          await this.handleTemporaryAccessUsers(updatedRoom)
          this.store.session.removePartnerFromSession(usersToRemove[0])
        }
      }

      await this.store.triggerBoardSessionAccepted(activeUsersIds, updatedRoom.session_id)
      await this.store.acceptSession(updatedRoom.session_id)
    }

    //maintaining the user active in a room if there is no session.
    if(this.currentActiveRoom && updatedRoom.session_id === null && this.getCurrentActiveRole() !== ADMITTED) {
      const isCurrentUserActive = updatedRoom.users.find(item => item.user_id === this.store.currentUserId).is_active

      logger.info(`Checking if user was set false by cloud backend ${isCurrentUserActive}`)

      if(!isCurrentUserActive){
        await this.setCurrentRoomActive()
      }
    }
  }

  //TODO: Move this logic to aloha-server to stop relying on potentially out of sync data
  async handleTemporaryAccessUsers(room){
    if(this.shouldAdmittedUserBeKickedOut(room)){
        logger.info('Only admitted users are in the room, closing the session')
        if(this.store.sessionMode){
          await this.store.leaveSession()
        }
        setTimeout(async() => {
          await this.closeRoom()
        }, 1000)
        this.store.mixerVisible = true
    }
  }

  shouldAdmittedUserBeKickedOut(room){
    if(this.isCurrentUserAdmitted()) {
      const currentActiveUsers = room.users
        .filter(user => user.user_id !== this.store.currentUserId && user.is_active)
        .map(user => user?.status)

      logger.info('Current active users', { currentActiveUsers })

      if(currentActiveUsers.includes(OWNER)) return false
      if(currentActiveUsers.includes(WHITELISTED)) return false

        logger.info('Only admitted users are in the room, closing the session')
        return true
    }

    return false
  }

  async handleRequestRoomAccess(eventData){

    if(this.getCurrentActiveRole() === ADMITTED) return

    const accessUserId = eventData.user_id
    const roomId = eventData.room_id

    await this.store.requestFetchUserInfo(accessUserId)

    const accessUser = this.store.users.get(accessUserId)

    showDialog({
      store: this.store,
      title: 'Admit person?',
      body: `${accessUser?.display_name} is waiting to be admitted to the room.`,
      okText: "Admit",
      cancelText: "Decline",
      okAction: async () => {
        await this.replyRoomAccessRequest(accessUserId, roomId, true)
      },
      cancelAction: async () => {
        await this.replyRoomAccessRequest(accessUserId, roomId, false)
      },
      reverse: true
    })
  }

  handleRoomAccessDeclined(){
    closeAllModals()
    this.currentActiveRoom = null
    showDialog({
      store: this.store,
      title: 'Access declined',
      body: 'Your request to enter this room was declined.',
      okText: 'Ok'
    })
  }

  async handleFreemiumSessionExpired(event) {
    logger.info('Freemium session expired event received')

    if(!TIMERS_ENABLED){
      logger.info('Timers are disabled, skipping logic')
      return
    }
    if(event.session_id === this.store.session.id){
      logger.info('Session will be closed automatically')
      setTimeout(async () => {
        await this.store.leaveSession()
        await this.closeRoom()
        this.currentActiveTime = null
        localStorage.setItem('freemiumWaitingDisabled', Date.now().toString())
      }, 1000)
      this.store.mixerVisible = true
      showDialog({
        store: this.store,
        title: 'Time limited session ended!',
        body: 'For unlimited sessions, have a look at our subscription plans',
        okText: 'Ok',
        hideClose: true,
        okAction: async () => {
          await this.store.leaveSession()
          await this.closeRoom()
          this.store.analytics.track('Subs_clicked_upgrade_link', {
            ['Location']: 'Time limited session ended dialog'
          })
          showSubscriptionDialog({ store: this.store })
          this.currentActiveTime = null
        }
      })
    }
  }

  checkAndStartFreemiumTimer(room){

    const activeUsersIds = room.users
        .filter((user) => user.is_active)
        .map(user => user.user_id)

    logger.info('Checking subscription status of active room members..')
    const usersSubscriptionStatus = activeUsersIds.map(userId =>
      this.store.users.get(userId) ? this.store.users.get(userId).active_subscription : false
    )

    if(usersSubscriptionStatus.includes(true)) {
      logger.info('One or more users are subscribed, the session can continue without timer limitations')
      this.currentActiveTime = null
      return
    }

    logger.info('All users in the session are freemium, starting timer..')

    if(this.currentActiveTime === null){
      this.currentActiveTime = room.session_start ? Math.floor((new Date().getTime() - new Date(`${room.session_start}.000Z`).getTime()) / 1000) : 0
    }
}

  getCurrentActiveRole() {
    return this.currentActiveRoom ? this.currentActiveRoom.users
      .find(user => user.user_id === this.store.currentUserId)?.status : null
  }

  isCurrentUserAdmitted(){

    if(!this.currentActiveRoom) return false

    return this.currentActiveRoom.room_id ?
    this.getCurrentActiveRole() === ADMITTED :
    (this.currentActiveRoom.room_code && !this.currentActiveRoom.room_id)
  }

  showRoomWaitingDialog(){
    showDialog({
      store: this.store,
      title: '',
      body: 'Please wait to be admitted',
      showSpinner: true,
      hideClose: true
    })
    this.waitingState = true
  }

  async copyRoomCode(code, id){
    await copyContent(code)
    this.store.analytics.track('Room_Link Copied', { ['Room Identifier'] : id || code})
  }

  areRoomsBlocked() {
    const freemiumTimestamp = localStorage.getItem('freemiumWaitingDisabled')

    if(freemiumTimestamp){
      const remainingSeconds = Math.floor((Date.now() - Number(freemiumTimestamp)) / 1000)
      return remainingSeconds < MAX_BLOCKED_TIME
    }

    return false

}

    showBlockedRoomDialog(){
      showDialog(
        {
          store: this.store,
          body: 'You\'ll be able to enter another room shortly! For unlimited sessions please have a look at our subscription plans.',
          okText: 'Ok',
          reverse: true,
          okAction: async () => {
            this.store.analytics.track('Subs_clicked_upgrade_link',
            {['Location']: 'Blocked room dialog'}
            )
            showSubscriptionDialog({ store: this.store })
          }
        })
    }
  }
