import { useStore } from '../../../../store'
import React from 'react'
import { observer } from 'mobx-react-lite'
import { Box } from '../../../ui/primitives'
import { Fader } from './fader'
import { JitterDelayControls } from './jitter-delay-controls'
import { INPUT_OPTION_NONE } from '../../../../store/input-options.js'
import BridgeSettings from '../../../features/BridgeSettings'
import BridgeAudioSettings from '../../../features/BridgeSettings/SendControls/settings'
import {
  FaderContainer,
  MixerControlsContainer,
  StereoLinkButtonWrapper, SubTrackContainer
} from './track-control-styles'
import { KnobControl } from './knob-control'
import { KnobContainer, PluginModePeakMeterContainer } from '../styles'
import { SignalMeter } from './signal-meter'
import { LOCAL_VIDEO_MODE } from '../../../../store/session'
import Nameplate from '../../../video-chat/components/nameplate'
import { PeakMeter } from './peak-meter'

const SubTrack = observer(
  ({ trackId, stereo, channelIndex, disabled = true }) => {
    const store = useStore()

    const track = store.mixer.tracks[trackId]
    const ownPluginMode = store.mixer?.isPluginMode() && trackId === store.mixer.OWN && !store.isDeveloperMode
    const signalIndicatorUpdatedAt = store.mixer.signalIndicatorUpdatedAt

    if(!track) return null

    return (
      <FaderContainer>
        <Box height="100%" width="72px" minHeight="140px" maxHeight="200px">
          {!ownPluginMode ? (
            <Fader
              stereoMode={stereo}
              onChange={(value) => {
                if (stereo) {
                  track.volume[0] = value
                  track.volume[1] = value
                } else {
                  track.volume[channelIndex] = value
                }
              }}
              disabled={disabled}
              value={stereo ? track.volume[0] : track.volume[channelIndex]}
            />
          ) : (
            <PluginModePeakMeterContainer>
              {[0, 1].map((idx) => (
                <Box key={idx} width='4px' height="280px">
                  <PeakMeter
                    getValue={() => store.mixer?.signalMeters.get(`${trackId}/level_${idx}`)  ?? 0}
                    markerHeight={3}
                    markerSpacing={0}
                    getUpdatedAt={() =>
                      store.mixer?.signalMeterUpdatedAt.get(`${trackId}/level_${idx}`) ?? 0}
                    errorLimit={Infinity}
                    disabled={store.mixer?.signalMeters.get(`${trackId}/level_0`) === undefined}
                  />
                </Box>
              ))}
            </PluginModePeakMeterContainer>
          )}
        </Box>
        <Box
          flex
          row
          columnGap="6px"
          mx="auto"
          height="20px"
          mt="10px"
        >
          {!ownPluginMode && <Box width="12px" height="12px" mx="auto">
            <SignalMeter
              signalIndicatorUpdatedAt={
                stereo
                  ? () =>
                    Math.max(
                      signalIndicatorUpdatedAt.get(`${trackId}/level_0`),
                      signalIndicatorUpdatedAt.get(`${trackId}/level_1`),
                    )
                  : () =>
                    signalIndicatorUpdatedAt.get(
                      `${trackId}/level_${channelIndex}`,
                    )
              }
              inputClipIndicatorUpdatedAt={
                stereo
                  ? () =>
                    Math.max(
                      signalIndicatorUpdatedAt.get(`${trackId}/clip_0`),
                      signalIndicatorUpdatedAt.get(`${trackId}/clip_1`),
                    )
                  : () =>
                    signalIndicatorUpdatedAt.get(
                      `${trackId}/clip_${channelIndex}`,
                    )
              }
              height={14}
              width={14}
              disabled={disabled}
            />
          </Box>}
        </Box>
        {!ownPluginMode && (
          <KnobContainer>
            <KnobControl
              defaultValue={0.5}
              panMode={true}
              stereoMode={stereo}
              titleMin="L"
              titleMax="R"
              onChanging={(value) => {
                if (stereo) {
                  if (value <= 0.5) {
                    track.pan[0] = 0
                    track.pan[1] = 2 * value
                  } else {
                    track.pan[0] = 2 * (value - 0.5)
                    track.pan[1] = 1
                  }
                } else {
                  track.pan[channelIndex] = value
                }
              }}
              value={
                stereo
                  ? (track.pan[0] + track.pan[1]) / 2
                  : track.pan[channelIndex]
              }
              disabled={disabled}
            />
          </KnobContainer>
        )}
      </FaderContainer>
    )
  },
)

export const TrackControl = observer(
  ({ label, trackId, userId, disabled = true }) => {
    const store = useStore()
    const stereoLinked = store.mixer.tracks[trackId]?.stereoLinked
    const inputOptions = userId === store.currentUserId ? store.mixer.inputOptions : store.mixer.tracks[trackId]?.inputs
    const minimized = store.session.localVideoMode === LOCAL_VIDEO_MODE.MINIMIZED
    const user = store.users.get(userId)
    const ownPluginMode = store.mixer?.isPluginMode() && trackId === store.mixer.OWN

    return (
      <MixerControlsContainer stereo={stereoLinked || ownPluginMode } minimized={minimized}>
        <BridgeSettings stereoLinked={stereoLinked} />
        {stereoLinked || ownPluginMode ? (
          <SubTrackContainer expanded stereo>
            <SubTrack
              trackId={trackId}
              label={label}
              stereo={true}
              disabled={disabled}
            />
          </SubTrackContainer>
        ) : (
          <SubTrackContainer expanded>
            {inputOptions?.[0] === INPUT_OPTION_NONE ? null : (
              <SubTrack
                trackId={trackId}
                label={label}
                stereo={false}
                channelIndex={0}
                disabled={disabled}
              />
            )}
            {inputOptions?.[1] === INPUT_OPTION_NONE ? null : (
              <SubTrack
                trackId={trackId}
                label={label}
                stereo={false}
                channelIndex={1}
                disabled={disabled}
              />
            )}
          </SubTrackContainer>
        )}
        {(trackId === 'own' && !ownPluginMode) && (
          <StereoLinkButtonWrapper>
            <BridgeAudioSettings />
          </StereoLinkButtonWrapper>
        )}
        {!minimized && <Nameplate text={user?.display_name} />}
        {store?.settings?.ui?.showJitterDelaySettings &&
        userId !== store.currentUserId ? (
          <Box mb="2rem">
            <JitterDelayControls userId={userId} />
          </Box>
        ) : null}
      </MixerControlsContainer>
    )
  },
)
