import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { CloseButton, Dialog } from './base'
import { useStore, withStore } from '../../store'
import { openModal } from './overlays'
import { Button } from '../ui/button'
import { Box, H1, Text } from '../ui/primitives'
import {
  BOARD_CONNECTION_STATE_CONNECTING,
  BOARD_CONNECTION_STATE_ALREADY_IN_USE,
  BOARD_CONNECTION_STATE_FAILED,
  BOARD_CONNECTION_STATE_CONNECTED,
  BOARD_CONNECTION_STATE_DISCONNECTING,
  BOARD_CONNECTION_STATE_DISCONNECTED,
  BOARD_CONNECTION_STATE_RECONNECTING
} from '../../store/board-connection-states'
import { BridgeStatusText } from '../ui/bridge-status-icon'
import { logger } from '../../utils/logging'
import DeviceRowIcon from './DeviceSelection/DeviceRowIcon'
import usePrevious from '../../hooks/use-previous'

const Link = styled.a`
  text-decoration: underline;
`

const DeviceInformationContainer = styled.div`
    text-align: left;
    display: flex;
    justify-content: space-between;
`

const timeSince = (date) => {
  const seconds = Math.floor((new Date() - date) / 1000)
  let interval = seconds / 31536000
  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}

const DeviceInfoDialogButtons = ({ close, connectionState, connectionInProgress, device }) => {

  const store = useStore()
  const prevConnectionState = usePrevious(connectionState)

  useEffect(() => {
    if(prevConnectionState === BOARD_CONNECTION_STATE_RECONNECTING && connectionState === BOARD_CONNECTION_STATE_CONNECTED){
      close()
    }
// eslint-disable-next-line
  }, [connectionState])

  const tryToConnect = async (ip) => {
    try {
      await store.disconnectFromBoard()
      await store.connectToBoard(ip)
    } catch (err) {
      // ignore errors
    }
  }

  if (connectionState === BOARD_CONNECTION_STATE_ALREADY_IN_USE) {
    return (
        <Box flex columnGap="2rem">
          <Button danger onClick={() => store.performTakeover(device.ipAddress)}>Take Over</Button>
          <Button primary onClick={() => close()}>Close</Button>
        </Box>
    )
  }

  if (connectionState === BOARD_CONNECTION_STATE_CONNECTED) {
    return (
      <Button primary onClick={() => close()}>Close</Button>
    )
  }
  return (
    <Button disabled={connectionInProgress} primary onClick={() => {
      logger.info(`User invoked tryToConnect(${device.ipAddress})`)
      tryToConnect(device.ipAddress)
    }}>Connect</Button>
  )
}

const DeviceInfoDialog = observer(({ close, device }) => {

  const store = useStore()

  const connectionInProgress = store.boardConnectionState === BOARD_CONNECTION_STATE_CONNECTING || store.boardConnectionState === BOARD_CONNECTION_STATE_DISCONNECTING

  /**
   * Always use BOARD_CONNECTION_STATE_DISCONNECTED if the last board that we
   * tried to connect to is not this board
   */
  const isCurrentBoard = device.ipAddress === store.board?.ipAddress
  const connectionState = isCurrentBoard ? store.boardConnectionState : BOARD_CONNECTION_STATE_DISCONNECTED

  let deviceLastSeen;
  if(device?.lastSeen) { // Currently not set in board mock mode?
    // Change date - timestamp delimiter, " " -> "T" to get date parsing working in Safari
    deviceLastSeen = device.lastSeen.replace(/([0-9]{4}-[0-9]{2}-[0-9]{2})( )([0-9]{2}.*)/, '$1T$3');
  }
  const diffInMs = new Date().getTime() - new Date(deviceLastSeen).getTime()
  const diffInMinutes = diffInMs / (1000 * 60)
  const showDNSError = connectionState === BOARD_CONNECTION_STATE_FAILED && diffInMinutes < 10
  return (
    <Dialog
      flex
      flexDirection="column"
      px="5.7rem"
      py="4.3rem"
      bgColor="var(--ELK-White)"
      color="var(--ELK-Black)"
      width="70rem"
      alignItems="center"
      textAlign="center"
      fontSize="1.6rem"
    >
      <CloseButton onClick={() => close()} color="var(--ELK-Black)" />
      <H1 mb="3rem" color="var(--ELK-Black)">My Bridge</H1>
      <DeviceInformationContainer>
        <DeviceRowIcon connectionState={connectionState} device={device} size={70} />
        <Box ml="1.8em">
          <Box>
            Firmware: {device.softwareVersion}
          </Box>
          <Box>
            IP Address: {device.ipAddress}
          </Box>
          {deviceLastSeen && (
            <Box>
              Last seen: {timeSince(new Date(deviceLastSeen))} ago
            </Box>
          )}
        </Box>
      </DeviceInformationContainer>
      <Box fontWeight="700" mt="2rem">
        <Text>
          Status: <BridgeStatusText connectionState={connectionState} shorten={false} />
        </Text>
      </Box>
      {showDNSError && (
        <Box mt="1.5rem" width="50rem">
          Looks like your having a DNS resolution failure... No worries, it’s an easy fix! Just follow <Link href="https://intercom.help/elk-audio/en/collections/3294509-how-to-change-your-dns-settings">these instructions</Link>.
        </Box>
      )}
      {connectionState === BOARD_CONNECTION_STATE_FAILED && (
        <>
          <Box mt="2rem" width="50rem">
            Make sure your Bridge is plugged in and turned on, and connected to the network. Go <a style={{textDecoration: 'underline'}} target="_blank" rel="noreferrer" href="https://intercom.help/elk-audio/en/articles/5796614-how-to-connect-your-bridge">here</a> to troubleshoot.
          </Box>
          {!showDNSError && (
            <Box mt="1.5rem" width="40rem">
              Try again or contact support.
            </Box>
          ) }
        </>
      )}

      {connectionState === BOARD_CONNECTION_STATE_RECONNECTING && (
        <>
          <Box mt="2rem" width="50rem">
            Attempting to reconnect. Make sure your Bridge is plugged in, turned on, and connected to the network. Go <a style={{textDecoration: 'underline'}} target="_blank" rel="noreferrer" href="https://intercom.help/elk-audio/en/articles/5796614-how-to-connect-your-bridge">here</a> to troubleshoot.
          </Box>
        </>
      )}
      <Box mt="4.2rem">
        <DeviceInfoDialogButtons close={close} connectionState={connectionState} connectionInProgress={connectionInProgress} device={device} />
      </Box>
    </Dialog>
  )
})

export function showDeviceInfoDialog({ store, device, onClose }) {
  return openModal(
    ({ close }) => withStore(store, <DeviceInfoDialog close={close} device={device} />),
    {
      autoDismissable: true,
      onClose
    },
  )
}
