import styled from 'styled-components'
import { Label, ModalButton } from '../base'
import { Box } from '../../ui/primitives'

export const SecondaryButton = styled(ModalButton)`
  max-width: 85px;
  min-width: 85px;
  margin-left: 10px;
`

export const AccessList = styled.ul`
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    list-style: none;
    margin: 10px 0;
    li {
      margin: 10px 0;
      svg {
        cursor: pointer;
      }
    }
`

export const MemberStatus = styled.span`
  font-size: 12px;
  color: var(--ELK-High-Contrast-Yellow);
`

export const FormLabel = styled(Label)`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-left: 10px;
    cursor: pointer;
  }
`

export const CopyClipboardButton = styled(Box)`
  position: absolute;
  bottom: 10px;
  right: 12px;
  cursor: pointer;
  display: flex;
`