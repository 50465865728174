import styled from 'styled-components'
import { Box } from '../../../ui/primitives'

export const FaderContainer = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  align-items: center;
`

export const MixerControlsContainer = styled.div`
  background-color: var(--ELK-Black);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${(props) => props.stereo && !props.minimized ? '115px' : '204px'};
  position: relative;
`

export const StereoLinkButtonWrapper = styled(Box)`
  position: relative;
`

export const SubTrackContainer = styled(Box)`
  display: flex;
  justify-content: ${(props) => props.stereo ? 'center' : 'space-between'};
  width: 80%;
`