import {
  MeetingStatus,
  useLocalVideo,
  useMeetingManager, useMeetingStatus,
  VideoGrid,
} from 'amazon-chime-sdk-component-library-react'
import {
  MeetingSessionConfiguration,
  VideoPriorityBasedPolicy,
  VideoPriorityBasedPolicyConfig,
  ConsoleLogger,
} from 'amazon-chime-sdk-js';
import React, { useEffect } from 'react'
import { APIClient } from '../../../api/client'
import useRemoteAudioTrack from '../hooks/use-remote-audio-track'
import { useStore } from '../../../store'
import { LOCAL_VIDEO_MODE } from '../../../store/session'
import LocalParticipant from './local-participant'
import RemoteParticipants from './remote-participants'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { logger } from '../../../utils/logging'

const StyledVideoGrid = styled(VideoGrid)`
  gap: 3px 1px;

  ${({ $size }) => ($size === 1) && `
    grid-template: repeat(1, 1fr) / repeat(1, 1fr);
  `}

  ${({ $size }) => ($size === 2) && `
    grid-template: repeat(1, 1fr) / repeat(2, 1fr);
  `}

  ${({ $size }) => ($size === 3) && `
    grid-template: repeat(2, 1fr) / repeat(2, 1fr);

    > div:nth-child(1) {
      grid-row-start: span 2;
    }
  `}

  ${({ $size }) => ($size === 4) && `
    grid-template: repeat(2, 1fr) / repeat(2, 1fr);
  `}

  ${({ $size }) => ($size === 5) && `
    grid-template: repeat(2, 1fr) / repeat(12, 1fr);

    > div:nth-child(1),
    > div:nth-child(2) {
      grid-column: span 6;
    }

    > div:nth-child(3),
    > div:nth-child(4),
    > div:nth-child(5) {
      grid-column: span 4;
    }
  `}
`

/**
 * This function returns a Promise that will get resolved when the joinData
 * object is available on the session object. This happens when the server
 * sends a `video_chat_data` event.
 */
const awaitJoinData = (session) => {
  return new Promise((resolve, reject) => {
    let tries = 0
    const id = setInterval(() => {
      tries++
      if (session.joinData) {
        clearInterval(id)
        resolve()
      } else if (tries > 8) {
        clearInterval(id)
        reject()
      }
    }, 250)
  })
}

/**
 * Request meeting details from the backend.
 *
 * This request will make the backend send a new event `video_chat_data`. That
 * event will contains a token and a unique identifier needed to connect to the
 * meeting.
 */
const requestMeeting = async (session) => {
  try {
    const nearestMediaRegion = await fetch(
      'https://nearest-media-region.l.chime.aws',
    ).then((response) => response.json())
    APIClient.request_join_video_chat(nearestMediaRegion.region, session.id)
  } catch (e) {
    logger.error('Failed to join meeting', e)
  }
}

const VideoChatClient = observer((props) => {
  const meetingManager = useMeetingManager()
  const audioTrack = useRemoteAudioTrack()
  const { toggleVideo } = useLocalVideo()
  const store = useStore()
  const session = store.session
  const settings = store.settings
  const meetingStatus = useMeetingStatus();


  let sessionMembers = session.partnerIds.length;
  if(session.localVideoMode === LOCAL_VIDEO_MODE.DEFAULT) {
    sessionMembers += 1;
  }

  /**
   * Dependencies of useEffect are omitted since this hook should only run
   * once.
   */
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    async function run() {
      try {
        await requestMeeting(session)
        await awaitJoinData(session)
        const { meeting, attendee } = session.joinData
        const meetingSessionConfiguration = new MeetingSessionConfiguration(meeting, attendee)

        // Video quality policys
        if (store.settings.videoChat.enableBandwidthOptimizer) {
          const policyLogger = new ConsoleLogger('VideoPriorityBasedPolicy')
          meetingSessionConfiguration.videoDownlinkBandwidthPolicy = new VideoPriorityBasedPolicy(
            policyLogger,
            VideoPriorityBasedPolicyConfig.DefaultPreset
          )
          meetingSessionConfiguration.enableSimulcastForUnifiedPlanChromiumBasedBrowsers = true
        }

        await meetingManager.join(meetingSessionConfiguration)
        await meetingManager.start()
        const {
          width,
          height,
          uplinkFramerate,
          uplinkMaxBandwidth,
        } = settings.videoChat
        meetingManager.audioVideo.realtimeMuteLocalAudio()
        meetingManager.audioVideo.chooseVideoInputQuality(
          width,
          height,
          uplinkFramerate,
        )
        meetingManager.audioVideo.setVideoMaxBandwidthKbps(uplinkMaxBandwidth);
      } catch (err) {
        logger.error(err)
      }
    }
    run()
    return () => {
      meetingManager.leave()
    }
  }, [])

  // Handle video input source change
  async function runVideoChange() {
    try {
      if(!store.previewVideoEnabled) return
      if (!meetingManager.audioVideo) return

      const videoInputDevices = await meetingManager.audioVideo.listVideoInputDevices()
      if (videoInputDevices.length < 1) {
        return
      }

      let selectedVideoDevice = videoInputDevices.find((device) =>
        device.deviceId === store.settings.videoChat.selectedVideoInputDeviceId)

      if (!selectedVideoDevice) {
        selectedVideoDevice = videoInputDevices[0]
      }
      await meetingManager.audioVideo.startVideoInput(selectedVideoDevice.deviceId)
    } catch (err) {
      logger.error(err)
    }
  }

  useEffect(() => {
    async function toggle() {
      if (meetingStatus === MeetingStatus.Succeeded && store.previewVideoEnabled) {
        await toggleVideo();
        const device = store.settings.videoChat.selectedVideoInputDeviceId
        store.settings.videoChat.selectedVideoInputDeviceId = null
        store.settings.videoChat.selectedVideoInputDeviceId = device
      }
    }
    toggle();
  }, [meetingStatus]);


  useEffect(() => {
    runVideoChange()
  }, [settings.videoChat.selectedVideoInputDeviceId, meetingManager.audioVideo, store.previewVideoEnabled])


  // Handle audio input source change
  useEffect(() => {
    async function run() {
      try {
        if (!meetingManager.audioVideo) return

        const audioInputDevices = await meetingManager.audioVideo.listAudioInputDevices()
        const audioOutputDevices = await meetingManager.audioVideo.listAudioOutputDevices()

        if (audioInputDevices.length < 1) {
          return
        }

        let selectedAudioDevice = audioInputDevices.find((device) =>
          device.deviceId === settings.videoChat.selectedAudioInputDeviceId)

        if (!selectedAudioDevice) {
          selectedAudioDevice = audioInputDevices[0]
        }

        let selectedElkOutputAudioDevice = audioOutputDevices.find((device) =>
        device.label.includes(store.mixer.getCurrentOutputDevice()?.name))

        if(selectedElkOutputAudioDevice){
          await meetingManager.audioVideo.chooseAudioOutput(selectedElkOutputAudioDevice.deviceId)
        }

        await meetingManager.audioVideo.startAudioInput(selectedAudioDevice.deviceId)
      } catch (err) {
        logger.error(err)
      }
    }
    run()
  }, [settings.videoChat.selectedAudioInputDeviceId, meetingManager.audioVideo])

  useEffect(() => {
    if (!audioTrack || !session) return
    session.talkback.setAudioTrack(audioTrack)
  }, [audioTrack, session])

  return (
    <StyledVideoGrid {...props} $size={sessionMembers}>
      <RemoteParticipants partnerIds={session.partnerIds}/>
      <LocalParticipant />
    </StyledVideoGrid>
  )
})

export default VideoChatClient
