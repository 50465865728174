import styled from 'styled-components'


const breakpoints = {
  'sm': '640px',
  'md': '768px',
  'lg': '1024px',
  'xl': '1280px',
  '2xl': '1536px',
}

function responsiveProps(props, params) {
  let rv = ''
  for (const param of params) {
    rv += property(props, param[0], param[1], param[2])
  }
  return rv
}

function getPropertyValues(property, compound) {
  if (typeof property === 'object')
    return property

  if (typeof property !== 'string')
    property = property.toString()

  if (compound) {
    return {all: property}
  }

  const rv = {}
  for (const part of property.split(/\s/)) {
    const match = part.match(/(sm|md|lg|xl|2xl|hover):/)
    const prefix = match ? match[1] : false
    const value = prefix ? part.substr(prefix.length + 1) : part
    rv[prefix || 'all'] = value
  }
  return rv
}

export function property(props, prop, attr, {compound = false} = {}) {
  if (!props[prop])
    return ''

  let rv = ''
  for (const [prefix, value] of Object.entries(getPropertyValues(props[prop], compound))) {
    const line =  typeof attr === 'function' ? attr(value) : `${attr}: ${value};`
    if (prefix === 'all') {
      rv += line
    } else if (prefix === 'hover') {
      rv += `&:hover { ${line} }`
    } else if (Object.keys(breakpoints).includes(prefix)) {
      rv += `@media (min-width: ${breakpoints[prefix]}) { ${line} }`
    }
  }
  return rv
}

export const Box = styled.div`
  ${props => responsiveProps(props, [
    ['display', 'display'],
    ['direction', 'flex-direction'],
    ['alignItems', 'align-items'],
    ['justifyContent', 'justify-content'],
    ['flexDirection', 'flex-direction'],
    ['flexBasis', 'flex-basis'],
    ['grow', 'flex-grow'],
    ['rowGap', 'row-gap'],
    ['columnGap', 'column-gap'],
    ['width', 'width'],
    ['height', 'height'],
    ['size', value => `width: ${value}; height: ${value};`],
    ['minWidth', 'min-width'],
    ['minHeight', 'min-height'],
    ['maxWidth', 'max-width'],
    ['maxHeight', 'max-height'],

    ['p', 'padding'],
    ['px', value => `padding-left: ${value}; padding-right: ${value};`],
    ['py', value => `padding-top: ${value}; padding-bottom: ${value};`],
    ['pl', 'padding-left'],
    ['pt', 'padding-top'],
    ['pr', 'padding-right'],
    ['pb', 'padding-bottom'],

    ['m', 'margin'],
    ['mx', value => `margin-left: ${value}; margin-right: ${value};`],
    ['my', value => `margin-top: ${value}; margin-bottom: ${value};`],
    ['ml', 'margin-left'],
    ['mt', 'margin-top'],
    ['mr', 'margin-right'],
    ['mb', 'margin-bottom'],

    ['bgColor', 'background-color'],
    ['bgImage', 'background-image'],
    ['bgPosition', 'background-position'],
    ['bgSize', 'background-size'],
    ['bgRepeat', 'background-repeat'],
    ['color', 'color'],

    ['textAlign', 'text-align'],
    ['fontSize', 'font-size'],
    ['fontWeight', 'font-weight'],
    ['lineHeight', 'line-height'],
    ['letterSpacing', 'letter-spacing'],
    ['textDecoration', 'text-decoration'],
    ['textTransform', 'text-transform'],
    ['whiteSpace', 'white-space'],
    ['cursor', 'cursor'],

    ['opacity', 'opacity'],
    ['overflow', 'overflow'],
    ['userSelect', 'user-select'],
    ['zIndex', 'z-index'],
    ['position', 'position'],
    ['inset', value => `left: ${value}; right: ${value}; top: ${value}; bottom: ${value};`],

    ['border', 'border', {compound: true}],
    ['borderLeft', 'border-left', {compound: true}],
    ['borderRight', 'border-right', {compound: true}],
    ['borderTop', 'border-top', {compound: true}],
    ['borderBottom', 'border-bottom', {compound: true}],

    ['transition', 'transition', {compound: true}],
  ])}
  ${props => props.outlined && 'outline: 1px solid red;'}
  ${props => props.relative && 'position: relative;'}
  ${props => props.absolute && 'position: absolute;'}
  ${props => props.inline && 'display: inline-block;'}
  ${props => props.flex && 'display: flex;'}
  ${props => props.column && 'flex-direction: column;'}
  ${props => props.expanded && 'flex: 1;'}
  ${props => props.circled && 'border-radius: 9999px;'}
`

export const Span = styled(Box).attrs({as: 'span'})`
  display: inline-block;
`

export const Expanded = styled(Box)`
  flex: 1;
`

export const Center = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Text = styled(Box)``
export const Content = styled(Box)``
export const Header = styled(Box).attrs({as: 'header'})``
export const Footer = styled(Box).attrs({as: 'footer'})``

export const H1 = styled(Text).attrs({as: 'h1'})`
  font-size: 2.4rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 89.023%; /* 53.414px */
  letter-spacing: -1.8px;
  color: #8C8C8C;
`

export const H2 = styled(Text).attrs({as: 'h2'})`
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.075em;
`

export const H3 = styled(Text).attrs({as: 'h3'})`
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.075em;
`


export const P = styled(Text).attrs({as: 'p'})`
  margin-bottom: 1rem;
`

export const I = styled(Text).attrs({as: 'i'})`
`

export const Bold = styled.strong`
  font-weight: 700;
  cursor: ${(props) => props.clickable ? 'pointer' : 'unset'};
`

export const Link = styled(Text).attrs({as: 'a'})`
  &:hover { text-decoration: underline; }
  &:focus { outline: 1px dotted var(--color-light-grey); }
`

function getHeight({ axis, size }) {
  return axis === 'horizontal' ? 1 : size;
}
function getWidth({ axis, size }) {
  return axis === 'vertical' ? 1 : size;
}

export const Spacer = styled.span`
  display: block;
  width: ${getWidth}px;
  min-width: ${getWidth}px;
  height: ${getHeight}px;
  min-height: ${getHeight}px;
`;

export const Divider = styled.div`
  width: 2px;
  background: #4E4E4E;
  height: 80%;
  margin: auto 1rem;
`

export const RangeSlider = styled.input.attrs({
  type: 'range'
})`
    -webkit-appearance: none;
    height: 10px;
    background-color: transparent;
    border-radius: 0px;
    width: ${props => props.width || '280px'};
    margin: 15px 0px;
    position:relative;
    z-index: 1;

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 40px;
    width: 20px;
    border-radius: 6px;
    border:none;
    background: var(--ELK-White);
    cursor: pointer;
    box-shadow: 0 0 2px 0 #555;
    transition: background .3s ease-in-out;
  }

  ::-webkit-slider-runnable-track  {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background-color: transparent;
  }
`
