import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../store'
import { BOARD_CONNECTION_STATE_CONNECTED } from '../../../store/board-connection-states'
import { TrackControl } from './components/track-control'
import { SESSION_PARTNER_STATE_JOINED } from '../../../store/session-partner-states'
import { Container, MixerWrapper, SuspendedStateContainer, SuspendedStateContent, SuspendedStateHeader, TrackWrapper } from './styles'
import { LOCAL_VIDEO_MODE } from '../../../store/session'
import { TooltipBoundary, TooltipDescription } from '../../ui/tooltip'
import { FooterTooltip } from '../../layout/styles'

export const SuspendedState = (props) => {

  const [tooltipOpen, setTooltipOpen] = useState(false)

  return (
      <SuspendedStateContainer cursor="pointer" onClick={() => {
        setTooltipOpen(!tooltipOpen)
      }}>
        <TooltipBoundary
          onClickOutside={() => {
          if(!tooltipOpen) return
            setTooltipOpen(false)
        }}
          tooltip={
            <FooterTooltip
              bgColor='var(--ELK-White)'
              color='var(--ELK-Dark-Grey)'
              isOpen={tooltipOpen}
              >
              <h2><strong>Audio OFF!</strong></h2>
              <TooltipDescription>
                <p>The connection to your DAW is lost. Check the channel and make sure it’s armed and ready.</p>
              </TooltipDescription>
            </FooterTooltip>
          }
          >
          <SuspendedStateContent>
            <SuspendedStateHeader> <h2>Audio OFF </h2>
              {/*<InformationIcon />*/}
            </SuspendedStateHeader>
            <p>Arm your channel</p>
          </SuspendedStateContent>
        </TooltipBoundary>
      </SuspendedStateContainer>
  )
}

export const Mixer = observer(({ expanded = false }) => {
  const store = useStore()
  const session = store.session

  if (!session) return null

  return (
    <Container>
      <MixerWrapper className={store.showBridgeSettingsView && store.boardConnectionState === BOARD_CONNECTION_STATE_CONNECTED ? 'expanded' : ''}>
        <LocalTrack expanded={expanded} />
      </MixerWrapper>
    </Container>
  )
})

export const LocalTrack = observer(({ expanded = false }) => {
  const store = useStore()
  const minimizedEnable = store.session.localVideoMode === LOCAL_VIDEO_MODE.MINIMIZED
  return (
    <TrackWrapper
      key={store.mixer.OWN}
      sessionMode={store.sessionMode || expanded}
      visible={store.mixerVisible}
      minimized={minimizedEnable}
    >
      <TrackControl
        trackId={store.mixer.OWN}
        userId={store.currentUserId}
        disabled={store.boardConnectionState !== BOARD_CONNECTION_STATE_CONNECTED}
      />
    </TrackWrapper>
  )
})

export const PartnerTrack = observer(({ userId }) => {
  const store = useStore()
  const disabled = store.session?.partnerState.get(userId) !== SESSION_PARTNER_STATE_JOINED
  return (
    <TrackWrapper key={userId}
                  sessionMode={store.sessionMode}
                  videoMode={store.session?.localVideoMode}
                  visible={store.mixerVisible}>
      <TrackControl
        trackId={userId}
        userId={userId}
        disabled={disabled}
      />
    </TrackWrapper>
  )
})

export default Mixer;
