import React from 'react'
import styled from 'styled-components'//'styled-components' -> /macro for debug purposes
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../store'
import { VideoChatClient } from '../../video-chat'

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const VideoOutput = observer(() => {
  const store = useStore()

  return (
    <Container hasMeetingData={store.session.hasMeetingData}>
      <VideoChatClient />
    </Container>
  )
})
