import styled from 'styled-components'

export const Checkbox = styled(({ className, checked, onChange, readOnly = false, disabled = false }) => (
  <span className={className}>
    <input type="checkbox" checked={checked} onChange={onChange} readOnly={readOnly} disabled={disabled}/>
    <svg viewBox="0 0 15 15" width={15} height={15}>
      <rect x="1.5" y="1.5" width="12" height="12" stroke="white" strokeWidth="1" fill="none"/>
      <path d="M 3 7.421 L 6.579 11 L 12.586 3" stroke="transparent" strokeWidth="2" fill="none"></path>
    </svg>
  </span>
))`
  position: relative;
  width: 15px; height: 15px;
  display: inline-block;
  cursor: pointer;
  input { position: absolute; opacity: 0; display: block; width: 15px; height: 15px; cursor: pointer; }
  path { stroke-dasharray: 15; stroke-dashoffset: 15; transition: stroke-dashoffset .2s ease-in-out;  }
  input:checked + svg path { stroke: var(--ELK-White); stroke-dashoffset: 0; }
  input:checked + svg rect { stroke-opacity: 0; }
  input:disabled + svg rect { stroke-opacity: .4; cursor: pointer; }

  outline: 1px solid var(--color-light-grey);
  margin-left: 1px;
`
