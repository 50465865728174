import { React, useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { DropDownIndicator, MenuExpandIndicator } from '../icons'
import { Box } from '../primitives'

const Scrollable = styled.div`
  position: absolute;
  bottom: ${(props) => props.dropUp ? '35px' : 'auto'};
  display: ${(props) => props.menuVisible ? 'block' : 'none'};
  flex-direction: column;
  width: 100%;
  border-radius: 6px;
  z-index: 100;
  height: ${(props) => props.height};
  overflow-y: ${(props) => props.overflow};
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-
  thumb {
    background-color: var(--ELK-Standard-Enabled);
    outline: none;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--ELK-Standard-Inactive);
    background-clip: content-box;
  }
`

const MenuButton = styled.button`
  display: flex;
  width:  ${(props) => props.width || '100%'};
  height: 35px;
  border-radius: 6px;
  position: relative;
  font-size: 14px;
  user-select: none;
  font-weight: 600;
  border: 1px solid #5A5A5A;
  background: ${(props) =>
    !props.enabled
      ? 'var(--ELK-Dark-Grey)'
      : props.menuVisible
      ? 'var(--ELK-Green-Fog)'
      : 'transparent'};
  color: ${(props) =>
    props.enabled ? 'var(--ELK-Text-Standard-Enabled)' : '#bbb'};
  padding: 0 15px;
  align-items: center;
  justify-content: space-between;
`

const Title = styled.span`
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MenuItem = styled.button`
  align-items: center;
  background: ${(props) => props.darkMode ? 'var(--ELK-Black)' : 'var(--ELK-White)'};
  color: ${(props) => props.darkMode ? 'var(--ELK-White)' : 'var(--ELK-Black)'};
  display: inline-flex;
  font-size: 14px;
  user-select: none;
  font-weight: 600;
  height: 35px;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  user-select: none;
  width: 100%;
  &:hover {
    background: var(--ELK-Green-Fog);
    color: var(--ELK-White);
  }
  &:focus {
    background: var(--ELK-Standard-Enabled);
    color: var(--ELK-White);
  }
  &:disabled {
    color: #888;
  }
`

const Dropdown = observer(
  ({
    menuItems,
    enabled = true,
    onChange,
    expandSubmenu = 'right',
    dropUp = false,
    darkMode = false,
    selectedIndex,
    height = 'auto',
    overflow = 'auto',
    title,
    submenuOffset,
    width = "100%"
  }) => {
    const [menuVisible, setMenuVisible] = useState(false)
    const [expandedIndex, setExpandedIndex] = useState(1)

    const holderRef = useRef(null)

    const hideMenuIfClickOutside = function (e) {
      if (holderRef.current?.contains(e.target)) return
      setMenuVisible(false)
    }

    useEffect(() => {
      document.addEventListener('click', hideMenuIfClickOutside)
      return () => {
        document.removeEventListener('click', hideMenuIfClickOutside)
      }
    }, [])

    const showMenu = () => {
      if (enabled && !menuVisible) {
        setExpandedIndex(-1)
        setMenuVisible(true)
      } else if (menuVisible) {
        setMenuVisible(false)
      }
    }

    return (
      <Box flex column relative ref={holderRef}>
        <MenuButton
          onClick={showMenu}
          enabled={enabled}
          menuVisible={menuVisible}
          width={width}
        >
          <Title>{title}</Title>
          <DropDownIndicator />
        </MenuButton>
        <Box>
          {enabled && (
            <Scrollable dropUp={dropUp} height={height} overflow={overflow} menuVisible={menuVisible}>
              {menuItems.map((item, index) => (
                <Box key={index}>
                  {expandedIndex === index && (
                    <Box
                      display={
                        enabled &&
                        menuVisible &&
                        expandedIndex > -1 &&
                        menuItems[expandedIndex]?.submenu
                          ? 'flex'
                          : 'none'
                      }
                      absolute
                      column
                      style={{
                        left: expandSubmenu === 'right' ? '100%' : 'auto',
                        right: expandSubmenu === 'left' ? '100%' : 'auto',
                      }}
                      zIndex="100"
                    >
                      {menuItems[expandedIndex]?.submenu?.map((innerItem, innerIndex) => (
                        <MenuItem
                          key={innerIndex}
                          darkMode={darkMode}
                          onClick={(e) => {
                            e.preventDefault()
                            setMenuVisible(false)
                            onChange(expandedIndex, innerIndex)
                          }}
                          cursor="pointer"
                        >
                          <Title>{innerItem.title}</Title>
                        </MenuItem>
                      ))}
                    </Box>
                  )}
                  <MenuItem
                    key={index}
                    darkMode={darkMode}
                    disabled={item.disabled}
                    onClick={(e) => {
                      e.preventDefault()
                      if (item.submenu) {
                        setExpandedIndex(index)
                      } else {
                        setMenuVisible(false)
                        onChange(index, -1)
                      }
                    }}
                  >
                    <Title>{item.title}</Title>
                    {item.submenu ? <MenuExpandIndicator /> : null}
                  </MenuItem>
                </Box>
              ))}
            </Scrollable>
          )}
        </Box>
      </Box>
    )
  },
)

export default Dropdown
