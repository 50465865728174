import styled from "styled-components";
import { H2 } from "./primitives";

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  ${(props) => props.draggable && 'cursor: grab'};

  :nth-child(even) {
    background-color: var(--ELK-List-Grey);
  }

  :nth-child(odd) {
    background-color: var(--ELK-List-White);
  }
`

export const RowText = styled(H2)`
  font-weight: bold;
  margin-left: 1rem;
  margin-right: 2.4rem;
  line-height: 1.5;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
