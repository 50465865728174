import React from 'react'
import { observer } from 'mobx-react-lite'
import { withStore } from '../../../store'
import { openModal } from '../overlays'
import { Dialog } from '../base'
import TalkbackSection from '../../features/Settings/TalkbackSection'

const TalkbackDialog = observer(({ close }) => {
  return (
    <Dialog width="60rem" py="2.4rem" px="3.4rem">
       <TalkbackSection close={close} />
    </Dialog>
  )
})


export function showTalkbackDialog({ store, autoSetup }) {
  return openModal(
    ({ close }) => withStore(store,
      <TalkbackDialog close={close} />
    ),
    {
      autoDismissable: true,
    }
  )
}
