import { makeAutoObservable } from 'mobx'
import { APIClient } from '../api/client'
import { Talkback } from './talkback'
import {
  SESSION_PARTNER_STATE_JOINED,
  SESSION_PARTNER_STATE_WAITING,
} from './session-partner-states'

import {
  DEFAULT_JITTER_BUFFER_MULTIPLIER
} from './settings'

export const LOCAL_VIDEO_MODE = {
  DEFAULT: 'default',
  MINIMIZED: 'minimized',
}

export const SESSION_EVENT_TRIGGER_TYPE_MANUAL = 'Manual'
export const SESSION_EVENT_TRIGGER_TYPE_AUTOMATIC = 'Automatic'

const VISUAL_MODE_DEFAULT_DELAY = 200;
export class Session {
  /**
   * Session id (number, or null if this is a private session)
   */
  id = null

  /**
   * Boolean indicating whether we have left this session.
   */
  hasLeft = false

  /**
   * Timestamp for when this session was created.
   */
  startTime = null

  /**
   * A reference to the dialog shown in the UI if the user was invited
   * to this session.
   */
  dialog = null

  /**
   * Array containing the partner user ids (number) that
   * was invited to this session. Should not be modified.
   */
  partnerIds = []

  /**
   * Map from user ids (number) to a session partner state (see
   * session-partner-states.js)
   */
  partnerState = new Map()

  /**
   * Map from user ids (number) to a session partner ICE status
   */
  partnerICEStatus = new Map()

  /**
   * Map from user ids (number) to jitter delay.
   */
  jitterBufferMultiplier = new Map()

  /**
   * Object containing information needed to connect to the video chat.
   */
  joinData = null

  localVideoMode = LOCAL_VIDEO_MODE.DEFAULT

  hasMeetingData = false

  /**
   * Video sync
   * Active - has video sync been activated by user or someone else
   * Delay - delay to pass to board when activated
   */
  videoSync = {
    active: false,
    delay: VISUAL_MODE_DEFAULT_DELAY,
  }

  constructor({
    store,
    id = null,
    partnerIds = [],
    initiatorId = null,
    payload = {},
    dialog = null,
  }) {
    this.dialog = dialog
    this.startTime = Date.now()
    this.store = store
    this.id = id
    this.initiatorId = initiatorId
    this.partnerIds = APIClient._sort_partners_id(partnerIds)
    this.payload = payload
    partnerIds.forEach((partnerId) => {
      this.initPartnerSession(partnerId)
    })
    this.talkback = new Talkback()
    makeAutoObservable(this)
  }

  getDurationInSeconds() {
    return Math.floor((Date.now() - this.startTime) / 1000)
  }

  userIdToTrackId(userId) {
    if (userId === this.store.currentUserId) {
      return 'own'
    }
    return userId
  }

  initPartnerSession(partnerId) {
    const jitterBufferSettingsEnabled = this.store.settings.ui.showJitterDelaySettings
    this.setPartnerJitterBufferMultiplier(partnerId, jitterBufferSettingsEnabled ? DEFAULT_JITTER_BUFFER_MULTIPLIER : 0)
    this.partnerState.set(
      partnerId,
      partnerId === this.initiatorId,
      SESSION_PARTNER_STATE_JOINED
    )
    this.store.mixer.initPartnerTrack(partnerId)
  }

  addPartnerToSession(partnerId) {
    this.partnerIds = APIClient._sort_partners_id([...this.partnerIds, partnerId])
    this.initPartnerSession(partnerId)
  }

  removePartnerFromSession(partnerId) {
    setTimeout(() => {
      if(this.partnerIds){
        if(!this.partnerIds.includes(partnerId)) return

        this.partnerIds.splice(this.partnerIds.indexOf(partnerId), 1)
        this.store.mixer.removePartnerTrack(partnerId)
      }
    }, 3000)
  }

  setPartnerJitterBufferMultiplier(partnerId, value) {
    this.jitterBufferMultiplier.set(partnerId, value)
    APIClient.jitter_buffer_multiplier(partnerId, value)
  }

  handleOscMessage(path, value) {
    if (path.startsWith('/webrtc/')) {
      this.talkback.onBoardMessage(path.replace('/webrtc/', ''), value)
    }
  }

  handleICEStatusMessage(message) {
    this.partnerICEStatus.set(parseInt(message.to_id), message.value)
    if(message.value === 'connected') {
      this.store.mixer.resetPanToDefault(message.to_id)
    }
  }

  setJoinData(data) {
    this.joinData = data
    this.hasMeetingData = true
  }

  setVideoSyncMode(active, delay) {
    APIClient.video_sync(active, delay);
    APIClient.post_message_to_users(this.store.session.partnerIds, {
      type: 'video-sync-update',
      data: { active, delay }
    })
  }

  userWillBeAloneForever() {
    for (const id of this.partnerIds) {
      const state = this.partnerState.get(id)
      if (state === SESSION_PARTNER_STATE_JOINED || state === SESSION_PARTNER_STATE_WAITING) {
        return false
      }
    }
    return true
  }

}

