/*
 * Using socket.io v3
 * */

import io from 'socket.io-client'
import { logger } from '../utils/logging'

export class SocketIOClient {
  constructor(address, port, secure, on_event_callback) {
    this._on_event_callback = on_event_callback
    this._address = address
    this._port = port
    this._access_token = null
    this._socket = null
    this._secure = secure

    this.connectSocketIO()
  }

  invoke(method, params = {}) {
    // add socketio key to all calls made via socketio,
    // so the server can route responses if necessary
    params['socketio'] = true
    logger.info(`[SocketIO] ${method}`, params || {})

    const payload = {
      method: method,
      params: params,
      jsonrpc: '2.0',
      id: 2,
    }

    this._socket.emit('jsonrpc', payload)
  }

  set_session_access_token(access_token) {
    logger.info('[SocketIO] set session_access_token')
    this._access_token = access_token
    if (this._socket !== null) {
      this._socket.disconnect()
    }
    // reconnect now access token is available
    this.connectSocketIO()
  }

  connectSocketIO() {
    const namespace = '/app'
    const portToUse = this._port ? `:${this._port}` : ''
    const protocol = this._secure ? 'wss://' : 'ws://'
    let url = `${protocol}${this._address}${portToUse}` + namespace

    if (this._access_token !== null) {
      url = `${url}?jwt=${this._access_token}`
    }

    this._socket = io.connect(url, {
      namespace: '/app',
      transports: ['websocket'],
    })

    this._socket.on('connect', function () {
      logger.info('[SocketIO] connected via socketio')
    })

    // for debugging
    this._socket.onAny((event, ...args) => {
      logger.debug(`[SocketIO] got ${event} :`, { args })
    })

    this._socket.on('message', (event) => {
      logger.info('[SocketIO] socketio received: ', { event })
      // relay the event to the store event handling
      // <store>_handleCloudEvent
      this._on_event_callback?.call(this, event)
    })

    this._socket.on('disconnect', function () {
      logger.info('[SocketIO] socketio disconnected')
    })

    this._socket.on('connect_error', (error) => {
      logger.info('[SocketIO] error: ', { error })
    })
  }
}
