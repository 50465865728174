import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../store'
import { UserProfile } from './user-profile-view'
import SubscriptionPage from './subscriptions'

import { ACTIVE_VIEW_STATE_LOBBY } from '../../../store/active-view-states'
import { Banners } from './banners'

import {
  Container,
  MainContent,
  Scrollable,
  PrimaryContentContainer,
} from './styles'
import MainView from './mixer-video'

export const PAGE_VIEWS = {
  MAIN: 'main',
  PROFILE_SETTINGS: 'profile-settings',
  SUBSCRIPTION: 'subscription',
}

const {
  MAIN,
  PROFILE_SETTINGS,
  SUBSCRIPTION,
} = PAGE_VIEWS

const PrimaryContent = ({ pageView }) => {
  let selectedView = null;
  switch (pageView) {
    case PROFILE_SETTINGS:
      selectedView = <UserProfile />
      break;
    case SUBSCRIPTION:
      selectedView = <SubscriptionPage />
      break;
    case MAIN:
    default:
      selectedView = <MainView />
  }

  return (
    <PrimaryContentContainer>
      {selectedView}
    </PrimaryContentContainer>
  );
};

export const LobbyView = observer(() => {
  const store = useStore()
  const [pageView, setPageView] = useState(MAIN);

  useEffect(() => {
    if (store.activeViewState.includes(ACTIVE_VIEW_STATE_LOBBY)) {
      if(store.activeViewState.includes('/')){
        const redirectPageView = store.activeViewState.split('/')[1]
        setPageView(redirectPageView)
        return
      }
      setPageView(MAIN)
    }
  }, [store.activeViewState])

  return (
    <Container>
      <Banners setPageView={setPageView}/>
      <MainContent>
        <Scrollable ref={store.scrollableRef}>
          <PrimaryContent pageView={pageView} />
        </Scrollable>
      </MainContent>
    </Container>
  )
})
