import { observer } from 'mobx-react-lite'
import { Dialog } from '../base'
import { openModal } from '../overlays'
import { Text, H1 } from '../../ui/primitives'

const MaintenanceModeDialog = observer(({ close, store }) => {
  return (
    <Dialog
      width="80rem"
      py="4rem"
      px="2.5rem"
      bgColor="var(--ELK-White)"
    >
      <H1 color="var(--ELK-Black)" textAlign="center" mb="2.5rem">Hey!</H1>
        <Text textAlign="left" mb="5rem" color="black" fontSize="2rem">
          Sorry, we’re running maintenance on the system right now.
          <br />
          We should be done at around 5 am Eastern Time. Please come back then!
        </Text>
    </Dialog>
  )
})

function showMaintenanceModeDialog({ store }) {
  return openModal(
    ({ close }) => <MaintenanceModeDialog close={close} store={store} />, {
      autoDismissable: false,
      alwaysOnTop: true
    }
  )
}

export default showMaintenanceModeDialog