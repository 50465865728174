import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

import { useStore } from '../../../store'
import { BridgeStatusText } from '../../ui/bridge-status-icon'
import { showDeviceSelectionDialog } from '../../dialogs/DeviceSelection'
import { showDeviceInfoDialog } from '../../dialogs/device-info'
import { showDeviceNotFoundDialog } from '../../dialogs/device-not-found'
import { StatusText } from './styles'
import { Box } from '../../ui/primitives'

const BridgeStatusWrapper = styled.button`
  display: flex;
  column-gap: 1rem;
  min-width: 135px;
`

function BridgeStatus() {
  const store = useStore()
  return (
    <BridgeStatusWrapper
      onClick={() => {
        if (store.devices.length === 0) {
          showDeviceNotFoundDialog({ store })
        } else if (store.devices.length === 1) {
          showDeviceInfoDialog({ store, device: store.devices[0] })
        } else {
          showDeviceSelectionDialog({ store })
        }
      }}
    >
      <Box flex column alignItems="flex-start" justifyContent="center" mx={"10px"}>
       <Box> {store.isConnectedToDesktop() ? 'Status:': 'Bridge status:'}</Box>
        <StatusText lineHeight="22px">
          <BridgeStatusText connectionState={store.boardConnectionState} devices={store.devices} />
        </StatusText>
      </Box>
    </BridgeStatusWrapper>
  )
}

export default observer(BridgeStatus)
