import { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store'

import { NetworkWarning } from '../ui/icons'

const ToastList = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 6rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 1rem;
  max-width: 50rem;
  z-index: 9999;
  overflow: hidden; // In case of multiple toasts are present at the same time -> hide for now pending designs
`

const slideIn = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
`

const ToastContainer = styled.div`
  background: var(--ELK-White);
  color: var(--ELK-Black);
  height: 100%;
  display: flex;
  flex-direction: row;
  margin: 0.7rem 0.7rem;
  animation: ${slideIn} 200ms forwards;
`

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 1.5rem;
`

const IconContainer = styled.div`
  margin: 0 1rem 0 1.5rem;
  display: flex;
  align-items: center;
`
const Heading = styled.span`
  font-weight: bold;
  font-size: 1.6rem;
`
const Content = styled.span`
  font-size: 1.4rem;
`

const IconSelector = ({ type }) => {
  let icon
  switch (type) {
    case 'POOR_NETWORK':
      icon = <NetworkWarning />
      break
    default:
      icon = null
  }
  return icon
}

const Toast = ({ heading, content, type, close, duration }) => {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      close()
    }, duration)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [duration, close])


  return (
    <ToastContainer onClick={() => {
      close()
    }}>
      <IconContainer>
        <IconSelector type={type} />
      </IconContainer>
      <MessageContainer>
        <Heading>{heading}</Heading>
        <Content>{content}</Content>
      </MessageContainer>
    </ToastContainer>
  )
}

const Toasts = observer(() => {
  const store = useStore()

  return (
    <ToastList>
      {store.toasts.map((t) => <Toast key={t.key} {...t} />)}
    </ToastList>
  )
})

export default Toasts
