import React from 'react'
import styled from 'styled-components'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { Button } from '../../ui/button'
import { CloseButton, Dialog } from '../base'
import { openModal } from '../overlays'
import { Box, Text, H1 } from '../../ui/primitives'
import { showThanksDialog } from '../thanks'
import { logger } from '../../../utils/logging'

const TextArea = styled.textarea`
  color: var(--ELK-Black);
  resize: none;
  width: 100%;
  height: 26rem;
  padding: 1rem 1.2rem;
  border: 1px solid var(--ELK-Border);
  font-style: italic;
  font-size: 2.4rem;
`

const ModalFooter = styled(Box)`
  display: flex;
  justify-content: center;
  gap: 2rem;
`;


const CancelSubscription = observer(({ close, store }) => {
  const state = useLocalObservable(() => ({
    subject: '[CANCEL SUBSCRIPTION]',
    description: '',
    isSubmitting: false,
  }))

  const submit = async (e) => {
    e.preventDefault()

    state.isSubmitting = true
    try {
      const result = await store.reportAnIssue({
        subject: state.subject,
        description: state.description,
        includeLogs: false,
      })
      if (result) {
        showThanksDialog({ text: ' ' })
        close()
      }
    }
    catch (err) {
      logger.error(err)
    }
    finally {
      store.settings.subscription.canceledAt = (new Date()).toISOString()
      store.settings.save()
      state.isSubmitting = false
    }
  }

  return (
    <Dialog
      width="80rem"
      py="4rem"
      px="2.5rem"
      bgColor="var(--ELK-White)"
    >
      <CloseButton onClick={() => close()} color="var(--ELK-Black)"/>

      <H1 color="var(--ELK-Black)" textAlign="center" mb="2.5rem">Cancel subscription?</H1>
      <Text textAlign="center" mb="5rem" color="black" fontSize="2rem">We’re sorry to hear you want to cancel your subscription. Please describe why you want to cancel, if it’s about technical issues, maybe we can help you out.</Text>

      <Box mb="2rem">
        <TextArea name="description" placeholder="Describe reason to cancel..." value={state.description} onChange={e => state.description = e.target.value} />
      </Box>

      <ModalFooter color="var(--ELK-Black)">
        <Button
          danger
          fontSize="2rem"
          width="100%"
          disabled={state.isSubmitting}
          onClick={close}>
            No, I changed my mind
        </Button>
        <Button
          primary
          fontSize="2rem"
          width="100%"
          disabled={!state.description || state.isSubmitting}
          onClick={submit}>
            {state.isSubmitting ? 'Sending...' : 'Yes, send request to cancel'}
        </Button>
      </ModalFooter>
    </Dialog>
  )
})

function showCancelSubscriptionDialog({ store }) {
  return openModal(
    ({ close }) => <CancelSubscription close={close} store={store} />, {
      autoDismissable: true,
    }
  )
}

export default showCancelSubscriptionDialog