import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../../store'
import { Box } from '../../../../ui/primitives'
import { Button } from '../../../../ui/button'
import OpenReactivateSubscriptionDialog from '../../../../dialogs/ReactivateSubscription'
import Transactions from '../transactions'
import {
  Container,
  Header,
  SubscriptionH1,
  SubscriptionH2,
  SubscriptionP,
} from '../styles'

const CanceledStatusTitle = styled.span`
  color: var(--ELK-High-Prio-Red-Enabled);
`

const CanceledScreen = observer(() => {
  const store = useStore()
  const currentSubscription = store.currentUser.fastspringData?.subscription || {}
  const { deactivationDateDisplayISO8601 } = currentSubscription
  return (
    <Container>
      <Header>
        <SubscriptionH1>Subscription <CanceledStatusTitle>(canceled)</CanceledStatusTitle></SubscriptionH1>
        <SubscriptionH2>You have cancelled you subscription and it will be deactivated {deactivationDateDisplayISO8601}.</SubscriptionH2>
      </Header>
      <SubscriptionP>Feel free to use elk.live until the current subscription expires {deactivationDateDisplayISO8601}. To continue to use elk.live after that, you need to start a new subscription. The new subscription will start after the end of the previous one.
To do that, just click the button below.</SubscriptionP>

      <Box flex column mt="2rem">
        <Button primary my="0.5rem" fontSize="14px" height="auto" onClick={() => OpenReactivateSubscriptionDialog({ store })}>Reactivate Subscription</Button>
      </Box>
      <Transactions />
    </Container>
  )
})

export default CanceledScreen
