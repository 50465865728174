import React from 'react'
import styled from 'styled-components'
import { H2 } from '../../ui/primitives'

const StyledText = styled(H2)`
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
   font-size: 14px;
   font-weight: 600;
   margin: 7px 0 10px 0;
`

const Nameplate = ({ text, minimized = false }) => {
  return <StyledText minimized={minimized}>{text}</StyledText>
}

export default Nameplate
