import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useStore } from '../../store'
import { Box } from '../ui/primitives'
import { Footer } from '../layout/footer'
import { Header } from '../layout/header'
import { LobbyView } from './main-view'
import { Chat } from '../features/chat'
import VideoSyncBanner from './video/video-sync-banner'
import { useLocalVideo } from 'amazon-chime-sdk-component-library-react'

import { Page, MainContent, Container, Section, ChatSection } from './styles'
import { showAdvancedConfigurationSettingsDialog } from '../legacy/dialogs/advanced-settings'
import { closeAllModals } from '../../utils/utils'
import { showDeviceSelectionDialog } from '../dialogs/DeviceSelection'
import { INPUT_OPTION_LINE } from '../../store/input-options'

export const PageContainer = observer(() => {
  const { isVideoEnabled, toggleVideo } = useLocalVideo()

  const store = useStore()

  useEffect(() => {
    store.startEventLoop()
    return () => {
      store.endEventLoop()
    }
  }, [store])

  const keyHandler = async (e) => {
    if (e.ctrlKey && e.altKey && e.which === 89) {
      store.isDeveloperMode = !store.isDeveloperMode
      if(store.isDeveloperMode){
        showAdvancedConfigurationSettingsDialog({ store })
        return
      }
      closeAllModals()
    }
    if (e.ctrlKey && e.altKey && e.which === 76) {
      store.AWSLogout()
    }
    if (e.ctrlKey && e.altKey && e.which === 88) {
      await store.queryDevicesOnNetwork(true)
      store.isDeveloperMode = true
      store.mixer.inputOptions = [INPUT_OPTION_LINE, INPUT_OPTION_LINE]
      showDeviceSelectionDialog({ store })
    }

    if (e.key === 'Enter') {
      const modals = document.getElementsByClassName('backdrop')
      if(modals.length > 0){
        e.preventDefault()
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', keyHandler)
    return () => {
      window.removeEventListener('keydown', keyHandler)
    }
  })

  return (
    <Page>
      <Header />
      <MainContent>
        <Container
          grow
          $videoSyncActive={store.session?.videoSync.active}
        >
          <Box>
            <VideoSyncBanner />
            <Section expanded $noScroll>
              <LobbyView />
            </Section>
          </Box>
        </Container>
        <Container grow={false}>
          <ChatSection expanded={store.showChatView}>
            <Chat messages={store.sessionChatMessages} sessionPrivate={true} />
          </ChatSection>
        </Container>
      </MainContent>
      <Footer
        videoEnabled={store.sessionMode ? isVideoEnabled : store.previewVideoEnabled}
        onToggleVideo={
          store.sessionMode
            ? async () => {
                await toggleVideo()
                const device = store.settings.videoChat.selectedVideoInputDeviceId
                store.settings.videoChat.selectedVideoInputDeviceId = null
                store.settings.videoChat.selectedVideoInputDeviceId = device
            }
            : () => {
                store.previewVideoEnabled = !store.previewVideoEnabled
              }
        }
      />
    </Page>
  )
})
