import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'

import { ELKBlack, ELKHighPrioYellowEnabled, ELKHighPrioGreenEnabled, ELKDarkGrey } from '../../../../variables'

const easeIn = (k) => {
  return Math.pow(k, 1.675) * 0.000005
}

const RotatedCanvas = styled.canvas`
  transform: rotate(180deg);
  position: absolute;
  bottom: 18px;
  right: 0;
  z-index: 0;
  width: 280px;
`

export const TalkbackPeakMeter = ({ getValue, getUpdatedAt, warningLimit = 0.7, barWidth = 10, barGap = 4, disabled }) => {
  const canvasRef = useRef(null)
  useEffect(() => {
    let requestId
    const canvas = canvasRef.current
    if (!canvas) return
    /* This line is important since we base the canvas height and width on the parent element
     * If we don't have this line the parent element height and width will depend on the canvas
     * and vice versa, leading to potential bugs */
    canvas.style.position = 'absolute'

    const context = canvas.getContext('2d')
    let height = 0
    let width = 0
    const updateWindowSize = () => {
      context.canvas.width = context.canvas.parentElement.clientWidth
      context.canvas.height = context.canvas.parentElement.clientHeight
      height = context.canvas.height
      width = context.canvas.width
    }
    updateWindowSize()
    window.addEventListener('resize', updateWindowSize)
    const drawFrame = () => {
      const value = getValue()
      const updatedAt = getUpdatedAt()
      const currentTime = Date.now()
      const leftTimeDiff = currentTime - updatedAt
      const leftValue = Math.max(value - easeIn(leftTimeDiff), 0)
      /* Fill whole canvas */
      context.fillStyle = ELKBlack
      context.fillRect(0, 0, width, height)
      if (disabled) {
        context.fillStyle = ELKDarkGrey
        context.fillRect(0, 0, barWidth, height)
        context.fillRect(barWidth + barGap, 0, barWidth, height)
      } else {
        /* Draw green parts */
        context.fillStyle = ELKHighPrioGreenEnabled
        context.fillRect(0, 0, width, barWidth)
        /* Draw yellow parts */
        context.fillStyle = ELKHighPrioYellowEnabled
        context.fillRect(0, 0, width * (1.0 - warningLimit), barWidth)
        /* Draw grey parts */
        context.fillStyle = ELKDarkGrey
        context.fillRect(0, 0, (1 - leftValue) * width, barWidth)
      }
    }
    const render = () => {
      drawFrame()
      requestId = requestAnimationFrame(render)
    }
    render()
    return () => {
      window.removeEventListener('resize', updateWindowSize)
      cancelAnimationFrame(requestId)
    }
  }, [getUpdatedAt, getValue, warningLimit, barGap, barWidth, canvasRef, disabled])

  return (
    <RotatedCanvas width={`${barWidth * 2 + barGap}px`} ref={canvasRef} />
  )
}
