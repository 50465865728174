import {
  RemoteVideo, useAudioVideo,
  useRemoteVideoTileState,
} from 'amazon-chime-sdk-component-library-react'
import { observer } from 'mobx-react-lite'
import React, { useState, useRef, useEffect } from 'react'
import { Box } from '../../ui/primitives'
import { JitterDelayControls } from '../../pages/mixer/components/jitter-delay-controls'
import { useStore } from '../../../store'
import { BottomContainer } from './video-overlays'
import VideoPlaceholder from './video-placeholder'
import { SettingsGear } from '../../ui/icons'
import { SessionStatus } from '../../pages/mixer/components/session-status'
import { IconWrapper } from './video-overlays'
import styled from 'styled-components'
import { PartnerTrack, SuspendedState } from '../../pages/mixer'

const ButtonWrapper = styled.div`
  cursor: pointer;
`

const Settings = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 260px;
  background-color: var(--ELK-Black);
  padding: 1.7rem 3.2rem;
  &.hidden {
    display: none;
  }
`

const SessionStatusOverlay = styled.div`
  bottom: 0px;
  left: 0;
  position: absolute;
  width: 100%;
  height: 30px;
`

const RemoteParticipant = observer(({ attendee, user, isSuspended = false, localAttendee,  ...rest }) => {
  const store = useStore()
  const audioVideo = useAudioVideo()
  const { attendeeIdToTileId } = useRemoteVideoTileState()

  const [settingsVisible, setSettingsVisible] = useState(false)
  const tileId = attendee?.chimeAttendeeId ? attendeeIdToTileId[attendee.chimeAttendeeId] : null
  let timeout;

  const settingsRef = useRef(null)
  const buttonRef = useRef(null)

  useEffect(() => {
    if(audioVideo && attendee?.chimeAttendeeId){
      audioVideo.realtimeSubscribeToVolumeIndicator(attendee.chimeAttendeeId,
        (id,volume) => {
          store.session.talkback.volumeIndicators.set(id, volume)
        })
      audioVideo.realtimeSubscribeToVolumeIndicator(localAttendee.chimeAttendeeId,
        (id,volume) => {
          store.session.talkback.localVolumeIndicator = volume
        })
    }
  // eslint-disable-next-line
  },[audioVideo, attendee])

  useEffect(() => {
    if (!settingsRef.current || !buttonRef.current) return
    const hideMenu = (e) => {
      if (settingsRef.current?.contains(e.target)) return
      if (buttonRef.current?.contains(e.target)) return
      setSettingsVisible(false)
    }
    document.addEventListener('click', hideMenu)
    return () => {
      document.removeEventListener('click', hideMenu)
    }
  }, [settingsRef, buttonRef])

  useEffect(() => {
    return () => clearTimeout(timeout)
  }, [timeout])

  return (
    <Box relative>
      {tileId ? (
        <RemoteVideo {...rest} tileId={tileId} />
      ) : (
        <VideoPlaceholder {...rest} user={user} sessionMode={store.isRoomOrSessionActive()} />
      )}
      {isSuspended && <SuspendedState />}
      <SessionStatusOverlay>
        <SessionStatus userId={user?.id} tileId={tileId} />
      </SessionStatusOverlay>
      <Settings className={settingsVisible ? "" : "hidden"} ref={settingsRef}>
        <JitterDelayControls userId={user?.id} />
      </Settings>
      <BottomContainer>
        <Box>
          {store?.settings?.ui?.showJitterDelaySettings && (
            <ButtonWrapper>
              <IconWrapper className={settingsVisible ? "open" : "on"} onClick={() => setSettingsVisible(!settingsVisible)} ref={buttonRef}>
                <SettingsGear size={25} />
              </IconWrapper>
            </ButtonWrapper>
          )}
        </Box>
      </BottomContainer>
      <PartnerTrack userId={user.id} />
    </Box>
  )
})

export default RemoteParticipant
