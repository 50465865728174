import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../../store'
import { INPUT_OPTION_USB } from '../../../../../store/input-options'
import Dropdown from '../../../../ui/form/dropdown'
import InputOptions from '../InputOptions'
import showUsbInputConfirmationDialog from '../../../../dialogs/usb-input-confirmation'

function BridgeInputDropdown({ channelIndex }) {
  const store = useStore()
  const board = store.board
  const hideUsbConfirmation = store.mixer.hideUsbInputConfirmation;

  const menuItems = InputOptions[channelIndex]
  const selectedItem =
    menuItems.find(
      (item) => item.option === store.mixer.inputOptions[channelIndex],
    ) ?? menuItems[menuItems.length - 1]

  return (
    <Dropdown
      enabled={!!board}
      title={selectedItem.title}
      textAlign="center"
      menuItems={menuItems}
      selectedItem={selectedItem}
      onChange={(index) => {
        if (
          menuItems[index].option === INPUT_OPTION_USB &&
          !hideUsbConfirmation
        ) {
          showUsbInputConfirmationDialog({
            onConfirm: () =>
              (store.mixer.inputOptions[channelIndex] =
                menuItems[index].option),
            store,
          })
        } else {
          store.mixer.inputOptions[channelIndex] = menuItems[index].option
        }
      }}
    />
  )
}

export default observer(BridgeInputDropdown)
