import { useStore } from '../../../../store'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'

const WarningBanner = styled.div`
  width: 100%;
  color: var(--ELK-Black);
  text-align: center;
  padding: 1rem 0;
  background: #FFBCBC;
  font-size: 1.4rem;
`;

const MaintenaceScheduleBanner = () => {
    return (
      <WarningBanner>
        <>
          Heads up! On Thursday between 3-5 am Eastern Time, we’ll be running some maintenance to the system and during that time you won’t be able to log in and jam. Sorry for any inconvenience!
        </>
      </WarningBanner>
    )
}

export const Banners = observer(({ setPageView }) => {
  const store = useStore()
  return (
    <>
      {store.userHasActiveSubscription && store.isMaintenanceScheduled() && <MaintenaceScheduleBanner />}
    </>
  )
})