import styled, { css, keyframes } from 'styled-components'
import { Box, H3, Text } from '../ui/primitives'
import Tooltip from '../ui/tooltip'

const FooterElement = styled.div`
  flex-shrink: 0;
  flex-basis: 6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--ELK-Black);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  column-gap: 1rem;
`

const LeftSection = styled(Box)`
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
  align-items: center;
  flex-basis: 20%;
  column-gap: 1rem;
`

const CenterSection = styled.div`
  display: flex;
  column-gap: 1rem;
`

const RightSection = styled(Box)`
  display: flex;
  justify-content: flex-end;
  column-gap: 1rem;
  flex-grow: 1;
  flex-basis: 20%;
`

const QuestionMarkButton = styled.button`
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

const RoundedButton = styled.button`
  display: inline-flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 700;
  justify-content: center;
  cursor: pointer;
  width: ${props => props.width ?? '3.5rem'};
  border-radius: 6px;
  height: 3.5rem;
  flex-shrink: 0;
  &.off {
    background-color: var(--ELK-High-Prio-Red-Enabled);
    &:hover {
      background-color: var(--ELK-High-Prio-Red-Hover);
    }
  }
  &.on {
    background-color: var(--ELK-Sea-Foam-Green);
    color: var(--ELK-Black);
    &:hover {
      background-color: var(--ELK-High-Prio-Green-Hover);
    }
  }
  &.stop {
    background-color: var(--ELK-High-Prio-Red-Enabled);
    &:hover {
      background-color: var(--ELK-High-Prio-Red-Hover);
    }
  }
  &.disabled {
    background: var(--ELK-Dark-Grey) !important;
    color: var(--ELK-Black) !important;
    cursor: default;
  }
  &.dark-disabled {
    background: var(--ELK-Footer-Disabled) !important;
  }
`

const FooterButton = styled.button`
  display: inline-flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 700;
  justify-content: center;
  cursor: pointer;
  width: ${props => props.width ?? '3.5rem'};
  height: 3.5rem;
  border-radius: 6px;
  background: var(--ELK-Black);
  border: 1px solid var(--ELK-White);
  &:hover {
    border-color: var(--ELK-High-Prio-Green-Enabled);
    color: var(--ELK-High-Prio-Green-Enabled);
    path {
      stroke : var(--ELK-High-Prio-Green-Enabled);
    }
  }
  &.on {
    border-color: var(--ELK-High-Prio-Green-Enabled);
    background: var(--ELK-High-Prio-Green-Enabled);
    color: var(--ELK-Black);
    &:hover {
      background-color: var(--ELK-High-Prio-Green-Hover);
      border-color: var(--ELK-High-Prio-Green-Hover);
    }
  }
  &.disabled,:disabled {
    border-color: var(--ELK-Dark-Grey) !important;
    color: var(--ELK-Dark-Grey) !important;
    cursor: default;
    background: none !important;
    path {
      stroke :  var(--ELK-Dark-Grey);
    }
  }
`

const DurationInfo = styled.div`
  line-height: 1;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  border-radius: 6px;
  justify-content: center;
  height: 3.5rem;
  width: 96px;
  cursor: pointer;
  background: var(--ELK-Dark-Grey);
  color: var(--ELK-Black);
  font-weight: 700;
`

const ProgressBarContainer = styled(Box)`
  width: 100%;
  height: 0px;
  position: relative;
  display: flex;
  justify-content: center;
  bottom: -7px;

  progress {
    width: 100%;
    height: 4px;
    border: 0;
    appearance: none;
  }
  progress::-webkit-progress-bar {
    height: 4px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    background: var(--ELK-Dark-Grey);
  }
  progress::-webkit-progress-value {
    background: var(--ELK-High-Contrast-Yellow);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`

const Timer = styled.div`
  font-size: 1.4rem;
  color: ${(props) => props?.sessionMode ? 'var(--ELK-White)' : 'unset'}};
  font-variant-numeric: tabular-nums;
`

const FooterTooltip = styled(Tooltip)`
  position: absolute;
  bottom: 15px;
  width: 300px;
  left: -80px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.3);
  padding: 1rem 1.2rem;
  ${(props) => props.headerToolTip && `
    top: 30px;
    z-index: 100;
    left: -262px;
    bottom: unset;
  `}
`

const flashAnimation = keyframes`
 0% { opacity: 1; }
 100% { opacity: 0.3; }
`

const MuteAllButton = styled(FooterButton)`
  width: 8rem;
  height: 3.5rem;
  background: var(--ELK-Black);
  border: 1px solid var(--ELK-White);
  font-weight: 700;
  &.off:hover {
    border-color: var(--ELK-High-Prio-Red-Enabled);
    color: var(--ELK-High-Prio-Red-Enabled);
  }
  &.on {
    border: none;
    background-color: var(--ELK-High-Prio-Red-Enabled);
    color: var(--ELK-White);
    &:hover {
      background-color: var(--ELK-High-Prio-Red-Hover);
    }
  }
  &.flashing {
    animation-name: ${flashAnimation};
    animation-duration: 0.3s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  span:before {
    content: "mute all";
  }
  &.on span:before {
    content: "all muted";
  }
  &.on:hover span:before {
    content: "unmute";
  }
  &.disabled {
    border-color: var(--ELK-Footer-Disabled) !important;
    color: var(--ELK-Footer-Disabled) !important;
    cursor: default;
    background: none !important;
  }
`

const PeakMeterWrapper = styled(Box)`
  width: 160px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  position: absolute;
  top: 94px;
  left: 77px;
  > div {
    margin-bottom: 2px;
  }
`

const VideoSyncButton = styled(FooterButton)`
  &:hover {
    border-color: var(--ELK-Raspberry-Pink);
    color: ${({ $videoSyncActive }) =>
  $videoSyncActive ? 'var(--ELK-White)' : 'var(--ELK-Raspberry-Pink)'};
  }

  ${({ $videoSyncActive }) =>
  $videoSyncActive &&
  css`
      background-color: var(--ELK-Raspberry-Pink);
      border-color: var(--ELK-Raspberry-Pink);
      color: var(--ELK-White);
    `};
`

const HelpContainer = styled.div`
  display: flex;
  align-items: center;
`

const BorderlessFooterButton = styled(FooterButton)`
  display: flex;
  color: var(--ELK-Black);
  width: auto;
  border: none;
  padding: 12px;
  &:hover {
    cursor: pointer;
  }
`

const HeaderContainer = styled(Box)`
  flex-shrink: 0;
`;

const ChatMenuWrapper = styled.div`
  width: ${props => props.active ? '30rem' : '5rem'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: width 0.225s;
  overflow: hidden;
  margin-right: 1rem;
  cursor: pointer;
  user-select: none;
`

const MenuOption = styled.button`
  background: ${props => props.active ? 'var(--ELK-High-Prio-Yellow-Enabled)' : 'var(--ELK-Black)'};
  color: ${props => props.active ? 'var(--ELK-Black)' : 'var(--ELK-White)'};
  padding: 0 2rem;
  display: flex;
  transition: 0.225s;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
  user-select: none;
`

const StyledH3 = styled(H3)`
  white-space: nowrap;
  font-size: 1.4rem;
`
const pulse = keyframes`
  from {
    opacity: 0.2;
  }
  to {
    opacity: 0.9;
  }
`

const PulseContainer = styled.div`
  &.pulsing {
    animation: ${pulse} 0.5s linear alternate infinite;
  }
`

const StyledHeader = styled.div`
  height: 60px;
  padding: 0 1.5rem;
  width: 100%;
  background: var(--ELK-Black);
  color: var(--ELK-White);
  display: flex;
  justify-content: space-between;
`


const SettingsWrapper = styled.div`
  width: 390px;
  height: calc(100vh - 120px);
  position: absolute;
  right: 0;
  background-color: var(--ELK-Black);
  padding: 0 0 2rem 0;
  top: calc(6.6rem - 3px);
  overflow: auto;
  z-index: 1000;
`

const NetworkWarning = styled(Box)`
   width: 28px;
   height: 28px;
   display:flex;
   align-items: center;
   justify-content: center;
   margin-right: 20px;
   cursor: pointer;
   color: #BCBCBC;
   font-weight: 700;
   font-size: 1.6rem;
   position: relative;
`

const LowInternetWarning = styled(Box)`
    margin-right: 25px;
    cursor: pointer;
    position: relative;
    bottom: 2px;
`

const WarningIcon = styled.div`
    position: absolute;
    top: -8px;
    right: -17px;
`

const AvatarContainer = styled.div`
  width: 44px;
  height: 44px;
`

const DraggableHeader = styled(Box)`
  width: 100%;
  height: 100%;
  -webkit-app-region: drag;
`

const RoomName = styled(Text)`
    font-size: 14px;
    font-weight: 800;
    margin-right: 8px;
`

export {
  CenterSection,
  RightSection,
  FooterElement,
  LeftSection,
  QuestionMarkButton,
  RoundedButton,
  FooterButton,
  DurationInfo,
  Timer,
  FooterTooltip,
  flashAnimation,
  MuteAllButton,
  PeakMeterWrapper,
  VideoSyncButton,
  HelpContainer,
  BorderlessFooterButton,
  HeaderContainer,
  ChatMenuWrapper,
  MenuOption,
  StyledH3,
  pulse,
  PulseContainer,
  StyledHeader,
  SettingsWrapper,
  NetworkWarning,
  LowInternetWarning,
  WarningIcon,
  AvatarContainer,
  DraggableHeader,
  RoomName,
  ProgressBarContainer
}
