import styled from 'styled-components'

const ChangelogContainer = styled.div`
  height: 75vh;
  overflow-y: auto;
  color: var(--ELK-Black);
  h2 {
    margin-top: 10px;
    line-height: 25px;
  }
  h3 {
    line-height: 22px;
  }
`

export { ChangelogContainer }