import { makeAutoObservable } from 'mobx'
import { APIClient } from '../api/client'
import { logger } from '../utils/logging'

export class Talkback {
  audioTrack = null
  audioElement = null
  volumeIndicators = new Map()
  localVolumeIndicator = null
  offer = null
  rpc = null
  deviceList = []

  constructor() {
    makeAutoObservable(this)
  }

  close() {
    logger.info('[Talkback] Closing talkback connection.')
    this.rpc?.close()
    this.rpc = null
    this.audioTrack = null
    this.audioElement = null
    this.offer = null
  }

  onBoardMessage(path, value) {
    if (path === 'offer_ready') {
      this._readOffer()
    } else {
      logger.warn(
        '[Talkback] Unhandled talkback message received from board.',
        path,
        value,
      )
    }
  }

  getAverageVolumeIndicator(){
    const average = arr => arr.reduce( ( p, c ) => p + c, 0 ) / arr.length;
    const volumes = Array.from(this.volumeIndicators.values())
    return volumes.length > 0 ? average(volumes) : 0
  }

  async setAudioTrack(audioTrack) {
    if (this.audioTrack) {
      logger.warn('[Talkback] audio track already set.')
      return
    }

    this.audioTrack = audioTrack
    await this._tryConnect()
  }

  setAudioElement(audioElement) {
    if (this.audioElement) {
      logger.warn('[Talkback] audio element already set.')
      return
    }

    logger.debug('[Talkback] setting audio element')
    this.audioElement = audioElement
    this.audioElement.volume = 0.5
  }

  async _readOffer() {
    if (this.offer !== null) {
      logger.warn('[Talkback] remote offer already set.')
      return
    }

    try {
      this.offer = await APIClient.get_sdp_offer()
      logger.info('[Talkback] Offer received from board.')
    } catch (e) {
      logger.error('[Talkback] Failed to read offer from board.')
      return
    }
    await this._tryConnect()
  }

  async _tryConnect() {
    if (!this.offer || !this.audioTrack) {
      return
    }

    if (this.rpc) {
      logger.warn('[Talkback] RTC peer connection already created.')
      return
    }

    const rpc = new RTCPeerConnection()
    try {
      logger.info('[Talkback] Establishing RTC connection with board.')
      rpc.addTrack(this.audioTrack)
      // ICE gathering starts after the local answer is set.
      const iceGatheringCompletePromise = this._waitForIceGatheringComplete(rpc)
      await this._acceptOffer(rpc, this.offer)
      await iceGatheringCompletePromise
      await this._sendAnswerToBoard(rpc)
      this.rpc = rpc
    } catch (e) {
      logger.error(
        '[Talkback] Failed to establish RTC connection with board.',
        e,
      )
      rpc.close()
    }
  }

  async _acceptOffer(rpc, offer) {
    await rpc.setRemoteDescription(offer)
    await rpc.setLocalDescription(await rpc.createAnswer())
  }

  _waitForIceGatheringComplete(rpc) {
    const TIMEOUT_MS = 5000
    return new Promise((resolve, reject) => {
      let timeout
      const onIceGatheringStateChange = async () => {
        if (rpc.iceGatheringState === 'complete') {
          logger.info('[Talkback] ICE Gathering complete')
          clearTimeout(timeout)
          timeout = null
          rpc.removeEventListener(
            'icegatheringstatechange',
            onIceGatheringStateChange,
          )
          resolve()
        }
      }

      timeout = setTimeout(() => {
        timeout && reject(new Error('ICE gathering timed out.'))
      }, TIMEOUT_MS)

      rpc.addEventListener('icegatheringstatechange', onIceGatheringStateChange)
    })
  }

  async _sendAnswerToBoard(rpc) {
    const localSdp = rpc.localDescription
    await APIClient.set_sdp_answer(localSdp)

    await APIClient.read_answer()
  }
}
