import BridgeStatus from './default'
import { isDesktop } from '../../../utils/utils'
import { useStore } from '../../../store'
import { observer } from 'mobx-react-lite'

function DeviceStatus(props) {

  const store = useStore()

  const hardwareBridges = store.devices.filter((device) => device.hwType !== 'desktop-mac')
  if (isDesktop() && hardwareBridges.length === 0) {
    return null
  }

  return (<>
    <BridgeStatus {...props} />
  </>)
}

export default observer(DeviceStatus)
