import { observer } from 'mobx-react-lite'

import DesktopTalkbackSection from './desktop'
import { StyledH1 } from '../styles'

function TalkbackSection(props) {
  return (
    <>
      <StyledH1>Talkback</StyledH1>
      <DesktopTalkbackSection {...props} />
    </>
  )
}

export default observer(TalkbackSection)
