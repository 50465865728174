import React from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'

import { TextField } from '../../../ui/text-field'
import { useStore } from '../../../../store'
import { H3 } from '../../../ui/primitives'
import { Auth } from 'aws-amplify'
import { ShowPasswordIcon, PasswordTick } from '../../../ui/icons'
import { Spinner } from '../../../ui/spinner'
import styled from 'styled-components'
import {
  StyledH1,
  StyledButton,
  ErrorMessage
} from './styles'
import { logger } from '../../../../utils/logging'
import { LoginLayout } from './layout'

const ResetPasswordForm = styled.form`
  width: 40.8rem;
  height: 55.7rem;
  padding: 2.6rem 3.4rem;
`

const StyledH3 = styled(H3)`
  font-size: 1.4rem;
  font-weight: normal;
  color: var(--ELK-White);
`

const BoldPassword = styled.span`
  font-size: 1.4rem;
  font-weight: 800;
`

const ShowPasswordButton = styled.button`
  position: relative;
  z-index: 99;
  float: right;
  top: -4.3rem;
  right: 1rem;
  margin: ${props => props.m ? props.m : 0};
`

const FloatTick = styled.span`
  float:left;
  padding-right: 0.5rem;
`

const FloatExclaim = styled.span`
  float:left;
  font-weight: 800;
  border-radius: 1rem;
  width: 2rem;
  height: 2rem;
  align-content: center;
  background-color: var(--ELK-Black);
  color: var(--ELK-High-Prio-Yellow-Enabled);
  padding: 0.2rem 0.8rem;
  margin-right: 0.5rem;
  margin-bottom: 2rem;
  vertical-align: center;
`

export const ResetPasswordToken = observer(({ setPage }) => {
  const store = useStore()
  const url = new URL(window.location.href)
  const resetPasswordCode = url.searchParams.get('reset-password-code')
  const resetPasswordEmail = url.searchParams.get('email')

  const state = useLocalObservable(() => {
    return {
      email: resetPasswordEmail,
      code: resetPasswordCode,
      password: '',
      confirm_password: '',
      busy: false,
      hasResetPassword: false,
      requiredMissing: true,
      passwordsMatch: true,
      passwordAtLeastEight: false,
      passwordMixedCase: false,
      passwordAtLeastOneNumber: false,
      passwordAtLeastOneSymbol: false,
      passwordShown: false,
      passwordError: ''
    }
  })

  const checkPasswordRequirements = () => {
    const specialCharRegex = new RegExp('[$*.^\\[\\]{}()?"!@#%&/\\\\,><\':;|_~`=+-]')

    state.passwordAtLeastEight = state.password.length >= 8
    state.passwordAtLeastOneNumber = state.password.match(/\d+/g)
    state.passwordAtLeastOneSymbol = specialCharRegex.test(state.password)
    state.passwordMixedCase = (/[a-z]/.test(state.password)) && (/[A-Z]/.test(state.password))
    state.isPasswordValid = state.passwordAtLeastEight &&
        state.passwordMixedCase &&
        state.passwordAtLeastOneNumber &&
        state.passwordAtLeastEight &&
        state.passwordAtLeastOneSymbol
  }

  const togglePasswordShown = () => {
    state.passwordShown = !state.passwordShown
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    state.passwordsMatch = state.password === state.confirm_password
    state.passwordError = state.passwordsMatch ? '' : 'var(--ELK-Candy-Tangerine)'

    state.busy = true
    if (!state.hasResetPassword && state.passwordsMatch && state.isPasswordValid) {
      try {
        await Auth.forgotPasswordSubmit(
          state.email,
          state.code,
          state.password)
        state.hasResetPassword = true
        window.history.replaceState({}, document.title, '/')
        setPage('login')
        store.showSnackBar({
          heading: 'Your password has been changed',
          content: '',
          level: 'success',
          duration: 5000
        })
      } catch (error) {
        logger.error('Failed changing password', error)
        state.dirty = true
      }
    }
    state.busy = false
  }

  const field = (name) => ({
    value: state[name],
    onChange: (e) => {
      (state[name] = e.target.value)
      state.dirty = false
      if (name === 'password') {
        checkPasswordRequirements()
      }
      state.passwordError = ''
      state.passwordsMatch = true
    }
  })

  return (
    <LoginLayout>
        <ResetPasswordForm onSubmit={handleSubmit}>
          <StyledH1>set new password</StyledH1>
          <TextField
            placeholder='email'
            type='hidden'
            {...field('email')}
            required
            value={state.email}
          />
          <TextField
            placeholder='password'
            type={state.passwordShown ? 'text' : 'password'}
            {...field('password')}
            required
            m='1.5rem 0'
            bg={state.passwordError}
          />
          <ShowPasswordButton
            onClick={togglePasswordShown}
            type='button'
            m='0 0 -1.5rem 0'
          >
            <ShowPasswordIcon size='18' />
          </ShowPasswordButton>
          <TextField
            placeholder='re-type password'
            type={state.passwordShown ? 'text' : 'password'}
            {...field('confirm_password')}
            required
            m='1.5rem 0'
            bg={state.passwordError}
          />
          <ShowPasswordButton
            onClick={togglePasswordShown}
            type='button'
          >
            <ShowPasswordIcon size='18' />
          </ShowPasswordButton>
          <StyledH3>
            <strong>Password requirements:</strong><br /><br />
            {state.passwordAtLeastEight
              ? <BoldPassword><FloatTick><PasswordTick size='16' stroke='#fff' /></FloatTick> At least 8 characters</BoldPassword>
              : <><FloatTick><PasswordTick size='16' /></FloatTick> At least 8 characters</>}<br /><br />
            {state.passwordMixedCase
              ? <BoldPassword><FloatTick><PasswordTick size='16' stroke='#fff' /></FloatTick> mixture of uppercase and lowercase letters</BoldPassword>
              : <><FloatTick><PasswordTick size='16' /></FloatTick> mixture of uppercase and lowercase letters</>}<br /><br />
            {state.passwordAtLeastOneNumber
              ? <BoldPassword><FloatTick><PasswordTick size='16' stroke='#fff' /></FloatTick> mixture of letters and numbers</BoldPassword>
              : <><FloatTick><PasswordTick size='16' /></FloatTick> mixture of letters and numbers</>}<br /><br />
            {state.passwordAtLeastOneSymbol
              ? <BoldPassword><FloatTick><PasswordTick size='16' stroke='#fff' /></FloatTick> at least one special character, e.g., ! @ # ? ]</BoldPassword>
              : <><FloatTick><PasswordTick size='16' /></FloatTick> at least one special character, e.g., ! @ # ? ]</>}<br /><br />
          </StyledH3>
          <ErrorMessage>
            {state.dirty && <>
              <FloatExclaim>!</FloatExclaim> problem setting password, old link?
            </>}
            {!state.passwordsMatch && <>
              <FloatExclaim>!</FloatExclaim> passwords do not match
            </>}
          </ErrorMessage>
          <StyledButton
            type='submit'
            data-testid='submit'
            disabled={state.busy || !state.isPasswordValid}
          >
            {state.busy ? <Spinner size='24' /> : <>save and continue to login</>}
          </StyledButton>
        </ResetPasswordForm>
    </LoginLayout>
  )
})
