import React, { useState } from 'react'
import { AwsLogin } from './pages/aws-login'
import { AwsRegister } from './pages/aws-register'
import { ResetPassword } from './pages/reset-password'
import { ResetPasswordToken } from './pages/reset-password-token'
import { Register } from './pages/betaJoin/register'
import { Download } from './pages/betaJoin/download'
import { isBetaRegistrationRoutes } from '../../../utils/utils'

export const UserConfiguration = () => {
  const url = new URL(window.location.href)
  const currentBetaRoute = isBetaRegistrationRoutes()
  const initialPage = isBetaRegistrationRoutes() ? currentBetaRoute.replace('/','') : url.searchParams.get('page')
  const [page, setPage] = useState(initialPage ?? 'login')
  return {
    'login': <AwsLogin setPage={setPage}/>,
    'register': <AwsRegister setPage={setPage}/>,
    'reset-password': <ResetPassword setPage={setPage}/>,
    'reset-password-token': <ResetPasswordToken setPage={setPage}/>,
    'join': <Register setPage={setPage} />,
    'download' : <Download setPage={setPage} />
  }[page]
}
