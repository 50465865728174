import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore, withStore } from '../../../store'
import { openModal } from '../../dialogs/overlays'
import { Dialog } from '../../dialogs/base'
import DesktopAudioSection from '../../features/Settings/AudioSection/desktop'

const AudioSelectionDialog = observer(({ close, autoSetup }) => {
  const store = useStore()
  return (
    <Dialog width="auto"
            py={store.isDesktopAudioInitializing ? '0' : '2.4rem'}
            px={store.isDesktopAudioInitializing ? '0' : '3.4rem'}>
       <DesktopAudioSection close={close} autoSetup={autoSetup} />
    </Dialog>
  )
})


export function showAudioSelectionDialog({ store, autoSetup }) {
  return openModal(
    ({ close }) => withStore(store,
      <AudioSelectionDialog close={close} autoSetup={autoSetup} />
    )
  )
}
