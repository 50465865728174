/* eslint-disable no-useless-computed-key */

import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Dialog, CloseButton, DialogTitle, ModalButton } from '../base'
import { openModal } from '../overlays'
import { Bold, Box, Text } from '../../ui/primitives'
import Dropdown from '../../ui/form/dropdown'
import { CopyPasteInput, TextField } from '../../ui/text-field'
import { withStore } from '../../../store'
import { AUTOMATIC_LABEL } from '../AddPartnerToSession/settings'
import { compareArrays, getArrayDifference, validateEmail } from '../../../utils/utils'
import { CopyClipboardIcon, InformationIcon, RemoveMemberIcon } from '../../ui/icons'
import { ADMITTED, MemberStatusText, OWNER } from './member-status'
import { AccessList, CopyClipboardButton, FormLabel, MemberStatus, SecondaryButton } from './styles'
import { showDialog } from '../ConfirmationDialog'
import { FooterTooltip } from '../../layout/styles'
import { TooltipBoundary, TooltipDescription } from '../../ui/tooltip'

const CreateRoomDialog = observer(({ close, store, room }) => {
  const existingRooms = store.rooms.filter((room) => room?.status !== 'DELETED')
  const [roomName, setRoomName] = useState('ROOM ' + (existingRooms.length + 1).toString().padStart(3,'0'))
  const [isSearchUsersLoading, setIsSearchUsersLoading] = useState(false)
  const [whitelistedUsers, setWhitelistedUsers] = useState([])
  const [currentEmailSearch, setCurrentEmailSearch] = useState('')
  const [currentRoom, setCurrentRoom] = useState(null)
  const [generatedRoomCode, setGeneratedRoomCode] = useState(null)
  const [isCopied, setIsCopied] = useState(false)
  const [tooltipOpen, setTooltipOpen ] = useState({
    code: false,
    access: false,
    connection: false,
  })
  const creationMode = !currentRoom
  const role = whitelistedUsers.find(user => user.id === store.currentUserId)?.status
  const ownerEditing = currentRoom && role === OWNER
  const ownerEditingOrCreationMode = ownerEditing || creationMode
  const filteredUserStatus = [OWNER, ADMITTED]
  const [turnServerIndex, setTurnServerIndex] = useState(0)

  const turnServerList = [
    { title: AUTOMATIC_LABEL, ip: '' },
  ].concat(store.turnServers.map((server) => {
    return {
      title: server.city,
      ip: server.ip
    }
  }))

  useEffect(() => {
    async function getRandomCode(){
      const code = await store.roomManager.generateRandomRoomCode()
      setGeneratedRoomCode(code)
    }
    if(room) {
      loadCurrentRoom(room)
      return
    }
    getRandomCode()
    //eslint-disable-next-line
  }, [])

  const loadCurrentRoom = (room) => {
    setCurrentRoom(room)
    setRoomName(room.title)

    const users = room.users.map(({ user_id, status }) => {
      const currentUser = store.users.get(user_id)

      return currentUser && {
        id: user_id,
        name: currentUser.display_name || currentUser.name,
        status: status
      }
    }).filter(user => user?.id && user?.status !== ADMITTED)
    setWhitelistedUsers(users)

    const index = turnServerList.findIndex((item) => item.title === room.turn_server_title)

    if(index !== -1){
      setTurnServerIndex(index)
    }
  }

  const searchAndAddUsers = async () => {
    try {

      // checking limit of room (4 users + the owner)
      const usersWithoutOwner = whitelistedUsers.filter(user => !filteredUserStatus.includes(user.status))

      if(usersWithoutOwner.length === 4) return
      if(currentEmailSearch === store.currentUserEmail) return

      //checking user email
      const foundedUser = await store.queryUserByEmail(currentEmailSearch)

      const userAlreadyOnTheList = usersWithoutOwner.find(user => user.id === foundedUser.id)

      if(userAlreadyOnTheList) return

      setWhitelistedUsers([...whitelistedUsers, foundedUser])
      setCurrentEmailSearch('')
    } catch(error){
      setWhitelistedUsers([...whitelistedUsers, { email: currentEmailSearch }])
    }
  }

  const saveRoom = async () => {
    if(invalidForm()) return

    const usersToWhitelist = whitelistedUsers.length > 0 ?
      whitelistedUsers.map(user => Number(user.id)).filter(item => item) :
      whitelistedUsers

    if(currentRoom){
      const roomId =  Number(currentRoom.room_id)

      //excluding owner of the room
      const owner = currentRoom.users.find(user => user.status === OWNER)
      const usersWithoutOwner = usersToWhitelist.filter(id => id!== owner.user_id)

      await store.roomManager.updateRoom({
        id: roomId,
        name: roomName,
        whitelistedUsers: usersWithoutOwner,
        turnServerIp: turnServerList[turnServerIndex].ip,
        turnServerTitle: turnServerList[turnServerIndex].title
      })
      trackChanges()
      close()
      return
    }

    await store.roomManager.createRoom({
      name: roomName,
      whitelistedUsers: whitelistedUsers.length > 0
        ? whitelistedUsers.map(user => Number(user.id)).filter(item => item)
        : whitelistedUsers,
      roomCode: generatedRoomCode,
      turnServerIp: turnServerList[turnServerIndex].ip,
      turnServerTitle: turnServerList[turnServerIndex].title
    })
    close()
  }

  const removeMember = (index) => {
    setWhitelistedUsers(
      [...whitelistedUsers.slice(0, index), ...whitelistedUsers.slice(index + 1)]
    )
  }

  const getRoomChanges = () => {
    const usersWithoutOwner = whitelistedUsers.filter(user => !filteredUserStatus.includes(user.status))
    const selectedUsers = usersWithoutOwner.map((user) => user?.id)
    const savedUsersWithoutOwner = currentRoom.users.filter(user => !filteredUserStatus.includes(user.status))
    const savedUsers = savedUsersWithoutOwner.map((user) => user?.user_id)
    const usersToAdd = getArrayDifference(selectedUsers, savedUsers)
    const usersToRemove = getArrayDifference(savedUsers, selectedUsers)

    const userListHasChanged = !compareArrays(selectedUsers, savedUsers)
    const turnServerChanged = currentRoom.turn_server_url !== turnServerList[turnServerIndex].ip
    const roomNameChanged = roomName !== currentRoom?.title
    return {
      userListHasChanged, turnServerChanged, roomNameChanged, usersToAdd, usersToRemove
    }
  }

  const roomHasChanged = () => {
    if(creationMode) return true

    const { userListHasChanged, turnServerChanged, roomNameChanged } = getRoomChanges()

    return roomNameChanged || userListHasChanged || turnServerChanged
  }

  const trackChanges = () => {
    const { userListHasChanged, turnServerChanged, roomNameChanged, usersToAdd, usersToRemove } = getRoomChanges()

    if(userListHasChanged){
      usersToAdd.forEach((userId) => {
        store.analytics.track('Room_Member Added', { ['Room ID']: currentRoom.room_id, ['User ID'] : userId})
      })
      usersToRemove.forEach((userId) => {
        store.analytics.track('Room_Member Removed', { ['Room ID']: currentRoom.room_id, ['User ID'] : userId})
      })
    }

    if(roomNameChanged){
      store.analytics.track('Room_Name  changed', { ['Room ID']: currentRoom.room_id})
    }
    if(turnServerChanged){
      store.analytics.track('Room_Connection Settings changed', { ['Room ID']: currentRoom.room_id, ['Setting value']: store.selectedTurnServerTitle})
    }
  }


  const invalidForm = () => !roomName || !roomHasChanged()
  const roomCode = currentRoom ? currentRoom.room_code : generatedRoomCode

  const InformationTooltipIcon = ({ name }) => {
    return (
      <Box onClick={() => {
        setTooltipOpen({
        ...tooltipOpen,
        [name]: !tooltipOpen[name]
      })
     }}>
      <InformationIcon />
    </Box>)
  }

  const RemoveMember = ({ memberIndex }) => (
    <Box onClick={() => { removeMember(memberIndex)}}>
      <RemoveMemberIcon
        size={24}
      />
    </Box>
  )

  const copyRoomCode = async () => {
      await store.roomManager.copyRoomCode(roomCode)
      setIsCopied(true)
  }

  return (
    <Dialog
      width="40rem"
      py="2rem"
      px="2rem"
      bgColor="var(--ELK-Dark-Grey)"
    >
      <CloseButton onClick={() => {
        if (roomHasChanged()) {
          showDialog({
            store,
            title: 'Exit without\n' +
              'saving changes?',
            body: 'Closing without saving deletes the adjustments you’ve done to this room.',
            okText: currentRoom ? 'Continue editing' : 'Save and exit',
            cancelText: `Discard Changes`,
            okAction: async () => {
              if(currentRoom) return

              if (!invalidForm()) {
                await saveRoom()
              }
            },
            cancelAction: () => {
              close()
            }
          })
        } else { close ()}
      }}
       color="var(--ELK-White)" />
      <DialogTitle>{currentRoom ?
        (ownerEditing ? 'Room settings' : currentRoom.title) :
        'Create room'}</DialogTitle>
      <Box>
          {ownerEditingOrCreationMode && (
            <>
              <FormLabel>
                Room Name
              </FormLabel>
              <TextField
              placeholder="Name"
              type="text"
              m="1rem 0"
              maxLength={30}
              value={roomName}
              onChange={(e) =>{
              setRoomName(e.target.value)
              }}
              />
            </>
          )}
        <FormLabel>
          Room ID
          {ownerEditingOrCreationMode && (

            <TooltipBoundary
            onClickOutside={() => {
              if(!tooltipOpen.code) return
              setTooltipOpen({
                ...tooltipOpen,
                code: false
              })
            }}
            tooltip={
              <FooterTooltip
                bgColor='var(--ELK-Dark-Grey)'
                color='var(--ELK-White)'
                isOpen={tooltipOpen.code}
              >
                <h2>Room ID</h2>
                <TooltipDescription>
                  <p>The Room ID is a unique link to a specific room.</p>
                  <p>Share the ID with people you want to invite to your room for a one-off session.</p>
                </TooltipDescription>
              </FooterTooltip>
            }
          >
            <InformationTooltipIcon name="code" />
          </TooltipBoundary>
            )}
        </FormLabel>
        <Box position="relative" height="auto">
          <CopyPasteInput
            type="text"
            m="1rem 0"
            readOnly
            onClick={async () => {
              await copyRoomCode()
            }}
            value={roomCode}
          />
          <CopyClipboardButton
            onClick={async () => {
              await copyRoomCode()
            }}
          >
            {isCopied && <Text mr="10px">copied!</Text>}
            <CopyClipboardIcon />
          </CopyClipboardButton>
        </Box>
        <FormLabel>
          Room Access
          {ownerEditingOrCreationMode && (
              <TooltipBoundary
                onClickOutside={() => {
                  if(!tooltipOpen.access) return
                  setTooltipOpen({
                    ...tooltipOpen,
                    access: false
                  })
                }}
                tooltip={
                  <FooterTooltip
                    bgColor='var(--ELK-Dark-Grey)'
                    color='var(--ELK-White)'
                    isOpen={tooltipOpen.access}
                  >
                    <h2>Room Access</h2>
                    <TooltipDescription>
                      <p>You can share your room with up to 4 people and give them unlimited access to it without the need for you to admit them.</p>
                      <p>Enter their registered email address to grant them permanent access and the room will show up in their account for easy access.</p>
                    </TooltipDescription>
                  </FooterTooltip>
                }
              >
                <InformationTooltipIcon name="access" />
              </TooltipBoundary>
          )
          }
        </FormLabel>
        {whitelistedUsers.length > 0 && (
          <AccessList>
            {whitelistedUsers.map((user, index) => {
              if(user.status === ADMITTED) return null
              return (
                <li>
                  {user.id ? (
                    <Box key={user.id} flex justifyContent="space-between">
                      <Box flex flexDirection="column">
                      <span>{user.name} {user.email && (<Bold>({user.email})</Bold>)}
                        <Bold>
                        {user.status === OWNER ? `(${MemberStatusText[user.status]})` : ''}
                        </Bold>
                      </span>
                        <MemberStatus></MemberStatus>
                      </Box>
                      {user.status !== OWNER && (
                        <>
                          {creationMode  ? (
                            <RemoveMember memberIndex={index} />
                          ) : role !== OWNER ? null : (
                            <RemoveMember memberIndex={index} />
                            )
                          }
                        </>
                      )}
                    </Box>
                  ) : (
                    <Box key={user.email} flex justifyContent="space-between">
                      <Box flex flexDirection="column">
                        <span>{user.email}</span>
                        <MemberStatus>not registered yet</MemberStatus>
                      </Box>

                      <Box flex alignItems="center">
                        <Box onClick={() => { removeMember(index)}}>
                          <RemoveMemberIcon
                            size={24}
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}
                </li>
              )
              }
            )}
          </AccessList>
        )}
          {(creationMode || role === OWNER) && (
            <Box flex alignItems="center">
              <TextField
                placeholder="Email"
                type="email"
                m="1rem 0"
                value={currentEmailSearch}
                onChange={(e) => {
                  setCurrentEmailSearch(e.target.value)
                }}
              />
              <SecondaryButton secondary
                               onClick={async () => {
                                 setIsSearchUsersLoading(true)
                                 await searchAndAddUsers()
                                 setIsSearchUsersLoading(false)
                               }}
                               disabled={!validateEmail(currentEmailSearch) || isSearchUsersLoading}>
                Add
              </SecondaryButton>
            </Box>
          )}
        {ownerEditingOrCreationMode &&
          (
            <>
              <FormLabel>
                Connection Settings
                <TooltipBoundary
                  onClickOutside={() => {
                    if(!tooltipOpen.connection) return
                    setTooltipOpen({
                      ...tooltipOpen,
                      connection: false
                    })
                  }}
                  tooltip={
                    <FooterTooltip
                      bgColor='var(--ELK-Dark-Grey)'
                      color='var(--ELK-White)'
                      isOpen={tooltipOpen.connection}
                    >
                      <h2>Connection Settings</h2>
                      <TooltipDescription>
                        <p>As default, Elk is set to automatically find the optimal way to connect you and your fellow musicians to ensure the best experience possible.</p>
                        <p>But in some cases, the automatic process is not possible and you'll need to set up a manual connection.</p>
                        <p>To set up a manual connection, please select the location that is closest to where you are.</p>

                      </TooltipDescription>
                    </FooterTooltip>
                  }
                >
                  <InformationTooltipIcon name="connection" />
                </TooltipBoundary>
              </FormLabel>
              <Box my="1rem">
                <Dropdown
                  title={turnServerList[turnServerIndex || 0]?.title}
                  textAlign='center'
                  dropUp
                  menuItems={turnServerList}
                  onChange={(idx) => {
                    setTurnServerIndex(idx)
                  }}
                />
              </Box>
            </>
          )}
      </Box>

      <Box flex flexDirection="column" mt="40px">
        {ownerEditing && (
          <ModalButton
            secondary
            onClick={() => showDialog({
              store,
              title: 'Delete room?',
              body: 'Are you sure you want to delete this room? Deleting a room removes it permanently  for all users.',
              okText: 'No, keep this room',
              cancelText: `Yes, delete this room`,
              cancelAction: async () => {
                await store.roomManager.deleteRoom(currentRoom.room_id)
                close()
              },
            })}
          >
            Delete room
          </ModalButton>

        )}
        <ModalButton
          primary={ownerEditingOrCreationMode}
          danger={!ownerEditingOrCreationMode}
          mt={ownerEditing ? '30px' : '0'}
          disabled={ownerEditingOrCreationMode ? invalidForm() : false}
          onClick={async () => {
            if(ownerEditingOrCreationMode){
              await saveRoom()
              return
            }

            showDialog(
              {
                store,
                title: `Are you sure?`,
                body: 'Removing your permanent room access results in that you won’t see this room in your backstage area.\n' +
                  '\n   The Room ID is still valid, and the room will exist until the owner decides to delete it.',
                okText: 'No, keep my access',
                cancelText: 'Yes, remove my access',
                reverse: false,
                cancelAction: async () => {
                  await store.roomManager.leaveRoom(currentRoom.room_id)
                  close()
                }
              })
          }}
        >
          {ownerEditingOrCreationMode ? 'Save room' : 'Remove my room access'}
        </ModalButton>
      </Box>
    </Dialog>
  )
})

export function showCreateRoomDialog({ store, room }) {
  return openModal(
    ({ close }) =>
      withStore(
        store,
        <CreateRoomDialog store={store} close={close} room={room} />
      ),
    {
      autoDismissable: false
    }
  )
}
