import { winstonLogger, getArrayLogs } from './winston'
import { DESKTOP_IP } from '../store/board'

// wrapper for winston logger
class ElkLogger {

  initLogger(store){
    this.store = store

    window.addEventListener('error', (event) => {
      this.error(event.message, { error: event.error })
    })
  }

  notice() {
    winstonLogger.info(arguments)
  }

  info() {
    winstonLogger.info(arguments[0],
      {...arguments[1], ...this.commonFields()},
      () => {}
    )
  }

  debug() {
    winstonLogger.debug(arguments[0], {...arguments[1], ...this.commonFields()})
  }

  warn() {
    winstonLogger.warn(arguments[0], { ...this.commonFields() })
  }

  error() {
    winstonLogger.error(arguments[0], {error: { ...arguments[1]}, ...this.commonFields()})
  }

  exception() {
    winstonLogger.error(arguments[0], {...arguments[1], ...this.commonFields()})
  }

  commonFields(){
    return {
      user_id: this.store?.currentUserId,
      session_id: this.store?.session?.id,
      is_bridge: this.store?.board ? this.store.board.ipAddress !== DESKTOP_IP  : undefined
    }
  }

  /*
   * Returns all logs as array
   */
  static getLogs() {
    return getArrayLogs()
  }
}

const logger = new ElkLogger()

export { ElkLogger, logger }
