import { Box } from '../../../ui/primitives'
import Button from '../../../ui/button'

function IdentifyDeviceButton(props) {
  const { device, children } = props
  if (device.isDesktopDevice) {
    return <Box width={props.width} />
  }

  return (
    <Button
      {...props}
    >
      { children }
    </Button>
  )
}

export default IdentifyDeviceButton
