import styled from "styled-components";

export const List = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 41px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--ELK-Standard-Enabled);
    outline: none;
    background-clip: content-box;
    border-left: 26px solid transparent;
  }

  ::-webkit-scrollbar-track {
    background-color: var(--ELK-Standard-Inactive);
    background-clip: content-box;
    border-left: 26px solid transparent;
  }
`
