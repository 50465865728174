import React, { useState } from 'react'
import styled from 'styled-components'

export const Tabs = styled(({ children, className }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const tabs = React.Children.map(children, (child, index) => {
    if (React.isValidElement(child))
      return (
        <Tab key={index} active={index === activeIndex} onClick={()=> setActiveIndex(index)}>
          {child.props.title}
        </Tab>
      )
    return null
  })

  const panes = React.Children.map(children, (child, index) => {
    if (React.isValidElement(child))
      return (
        React.cloneElement(child, {
          ...child.props,
          key: index,
          active: activeIndex === index,
          title: null,
        })
      )
    return null
  })

  return (
    <div className={className}>
      <div>{tabs}</div>
      <div>{panes}</div>
    </div>
  )
})`
  & > :first-child { display: flex; }
  & > :last-child {
    display: flex;
    align-items: stretch;
    min-height: ${props => props.minHeight || 'auto'};
  }
`

export const TabPane = styled.div`
  display: ${props => props.active ? 'block' : 'none'};
  width: 100%;
  background-color: var(--color-grey-2);
  padding: 2rem;
  max-height: 56vh;
  overflow: auto;
`

const Tab = styled.div`
  font-size: 1rem;
  padding: .75rem 2rem;
  margin-right: 1px;
  background-color: ${props => props.active ? 'var(--color-grey-2)' : 'var(--color-grey-1)'};
  text-transform: uppercase;
  user-select: none;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.active ? 'var(--color-grey-2)' : '#222222'};
  }
`