import styled from 'styled-components'
import { Box } from '../../ui/primitives'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--Elk-Background-Grey);
  color: var(--ELK-White);
`

const RoomsList = styled.div`
  width: 100%;
  display: flex;
  justify-content:f lex-start;
  align-self:center;
`

const ScrollableRooms = styled.div`
  display: flex;
  overflow-y: auto;
  column-gap: 1rem;
  row-gap: 1rem;
  height: 188px;
  margin-left: 40px;
  ::-webkit-scrollbar {
      width: 41px;
      height: 2px
    }

  ::-webkit-scrollbar-thumb {
    background-color: var(--ELK-White);
    outline: none;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-track {
    background-color: var(--ELK-Dark-Grey);
    background-clip: content-box;
  }
`

const MainContent = styled.div`
  display: flex;
  min-height: 0;
`

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.6rem 0 1.6rem 1.6rem;
`;

const SidebarItem = styled.div`
  &:not(:first-child) {
    margin-top: 2rem;
  }
`;

const Scrollable = styled(Box)`
  width: 100%;
  overflow: hidden;
`;

const PrimaryContentContainer = styled.div`
  width: 100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
`;

const SidebarMenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.active ? 'var(--ELK-High-Prio-Yellow-Enabled)' : 'transparent'};
  color:  ${(props) => props.active ? 'var(--ELK-Black)' : 'var(--ELK-White)'};
  cursor: pointer;
  padding: 6px;
  padding-left: ${(props) => props.submenu ? '22px' : '6px'};
  font-size: 12px;
  user-select: none;
  font-weight: bold;
  svg {
    pointer-events: all;
    cursor: pointer;
  }
`;

const AddFriendsItem = styled(SidebarMenuItem)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: var(--ELK-Intense-Pink);
  color: #fff;
  svg {
    margin-right: 10px;
  }
`

const SelectionContainer =styled(Box)`
    display: flex;
    justify-content: center;
    margin-top: 20px;
    align-items: center;
    width: 100%;
    align-self: center;
    width: ${(props) => props?.pluginMode ? '790px' : '100%'};
    justify-content: ${(props) => props?.pluginMode ? 'space-between' : 'center'};
`

const HeaderContainer = styled(Box)`
    width: 100%;
    margin: 15px 0;
    display: flex;
    border-bottom: 2px solid #606060;
    padding-bottom: 5px;
`

const SectionContainer = styled(Box)`
    width: 1190px;
    display: flex; 
    height: 100%; 
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    align-self: center;
`
const VideoMixerContainer = styled(Box)`
  display: flex;
  justify-content: center;
  height: ${(props) => props?.sessionMode ? 'calc(100vh - 120px)' : '380px'};
  width: ${(props) => props?.sessionMode ? '100vw' : 'unset'};
`

const RefreshIconContainer = styled.div`
  cursor: pointer;
  margin-right: 10px;
`


export {
  Container,
  RoomsList,
  MainContent,
  Sidebar,
  SidebarItem,
  RefreshIconContainer,
  Scrollable,
  PrimaryContentContainer,
  SidebarMenuItem,
  AddFriendsItem,
  ScrollableRooms,
  SelectionContainer,
  HeaderContainer,
  SectionContainer,
  VideoMixerContainer
}