import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store'
import { ChatSendIcon } from '../ui/icons'
import { Box, H3 } from '../ui/primitives'

const Container = styled.div`
  height: 100%;
  z-index: 999;
  display: grid;
  grid-template-rows: 1fr auto;
  background-color: var(--ELK-List-Grey);
  color: var(--ELK-Black);
`

const ChatBox = styled.div`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  padding: 1.6rem;
`

const Chatrow = styled.div`
  width: fit-content;
  max-width: 80%;
  ${(props) => props.right && `margin-left: auto`};
`

const Username = styled(H3)`
  font-size: 1.4rem;
  display: inline-flex;
  line-height: 1.3;
`

const Time = styled.span`
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.3;
`

const Message = styled(H3)`
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.3;
  width: fit-content;
  word-break: break-word;
  padding: 1rem;
  margin-top: 0.6rem;
  ${(props) => props.right && `margin-left: auto`};
  background-color: var(--ELK-High-Contrast-Yellow);
`

const Form = styled.form`
  display: flex;
  align-items: center;
  background-color: var(--ELK-Black);
  &.disabled {
    background-color: var(--ELK-Text-Inactive);
  }
`

const SendButton = styled.button`
  background: none;
  padding: 1rem 1.4rem;
  align-self: flex-end;
`

const StyledTextarea = styled.textarea`
  display: flex;
  flex: 1;
  align-items: center;
  resize: none;
  padding: 1rem 1.2rem;
  font-weight: 500;
  font-size: 1.4rem;
  background: none;
  color: var(--ELK-White);
  border: none;
  height: 4.2rem;
  max-height: 40vh;
`

export const Chat = observer(
  ({ messages: chatMessages, sessionPrivate = false, ...props }) => {
    const inputRef = useRef()
    const chatBoxRef = useRef()
    const store = useStore()
    const [messageText, setMessageText] = useState('')

    useEffect(() => {
      requestAnimationFrame(() => {
        if (chatBoxRef.current !== null) {
          chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight
        }
      })
    }, [chatMessages])

    const onInputChange = (e) => {
      const value = e.target.value
      setMessageText(value)

      inputRef.current.style.height = 'auto'
      inputRef.current.style.height = inputRef.current.scrollHeight / 10 + 'rem'
    }

    const onKeyDown = (e) => {
      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault()
        onSubmit()
      }
    }

    const onSubmit = () => {

      if (messageText && messageText.trim() !== '') {
        store.postChatMessage(messageText, sessionPrivate)
        setMessageText('')
        inputRef.current.style.height = '4.2rem'
      }
    }

    const showDate = (chatMessage, index) => {
      const previousMessage = chatMessages[index - 1]
      const timeDuration = chatMessage.time - previousMessage.time
      if (
        chatMessage.userId === previousMessage.userId &&
        timeDuration < 900000
      ) {
        return false
      } else {
        return true
      }
    }

    const getUserName = (userId) => {
      if (userId === store.currentUserId) {
        return "You"
      }
      return store.users.get(userId)?.name
    }

    return (
      <Container {...props}>
        <ChatBox variant="outlined" square={true} ref={chatBoxRef}>
        {store.sessionMode ? chatMessages.map((chatMessage, index) => (
            <Chatrow key={index} right={chatMessage.userId === store.currentUserId}>
              {(index === 0 || showDate(chatMessage, index)) && (
                <>
                  <Username mt={index !== 0 && '1.5rem'}>
                    {getUserName(chatMessage.userId)}
                  </Username>{' '}
                  <Time fontWeight="400">
                    {chatMessage.time.toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </Time>
                </>
              )}
              <Message right={chatMessage.userId === store.currentUserId}>
                {chatMessage.message}
              </Message>
            </Chatrow>
        )) : (
          <Box flex height="100%" justifyContent="center" alignItems="center">
            <Box flex column alignItems="flex-end">
              <svg width="156" height="131" viewBox="0 0 156 131" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.47638 5.45489C1.98382 8.94795 0.0214844 13.6852 0.0214844 18.625L0 125.755C0 126.676 0.27366 127.576 0.785723 128.342C1.29828 129.108 2.0259 129.705 2.87768 130.057C3.72897 130.408 4.66569 130.5 5.56912 130.319C6.47256 130.139 7.30199 129.694 7.95263 129.042L34.5213 102.473C36.2676 100.726 38.6357 99.745 41.1051 99.7445H136.875C141.815 99.7445 146.552 97.7821 150.045 94.2891C153.538 90.7965 155.5 86.0593 155.5 81.1195L155.521 18.625C155.521 13.6852 153.559 8.94795 150.066 5.45489C146.574 1.96233 141.836 0 136.896 0H18.6465C13.7066 0 8.96944 1.96233 5.47638 5.45489Z" fill="white"/>
                <text x="70" y="25" fill="black">
                  <tspan x="25" dy="1.2em">When in a session,</tspan>
                  <tspan x="25" dy="1.2em">you can chat with</tspan>
                  <tspan x="25" dy="1.2em">your friends here!</tspan>
                </text>
              </svg>
              <svg width="74" height="70" viewBox="0 0 74 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M70.5555 2.6812C72.2722 4.39811 73.2367 6.72655 73.2367 9.15459V67.712C73.2367 68.1648 73.1022 68.6074 72.8505 68.9838C72.5986 69.3603 72.2409 69.6535 71.8223 69.8266C71.4038 69.9994 70.9434 70.0443 70.4994 69.9557C70.0553 69.8668 69.6476 69.6483 69.3278 69.3276L56.2687 56.2687C55.4104 55.4102 54.2464 54.9278 53.0326 54.9275H9.15459C6.72655 54.9275 4.39811 53.963 2.68144 52.2461C0.964531 50.5294 0 48.201 0 45.7729V9.15459C0 6.72655 0.964531 4.39811 2.68144 2.6812C4.39811 0.964527 6.72655 0 9.15459 0H64.0821C66.5102 0 68.8386 0.964527 70.5555 2.6812Z" fill="white"/>
                <text x="70" y="18" fill="black">
                  <tspan x="22" dy="1.2em">nice!</tspan>
                </text>
              </svg>
            </Box>
          </Box>
        )}
        </ChatBox>

        <Form onSubmit={(e) => {e.preventDefault(); onSubmit()}} autoComplete="off" className={`${!store.sessionMode ? 'disabled' : ''}`}>
          <StyledTextarea
            disabled={!store.sessionMode}
            id="chat-input"
            rows="1"
            placeholder=""
            value={messageText}
            ref={inputRef}
            onKeyDown={(e) => onKeyDown(e)}
            onChange={(value) => onInputChange(value)}
          />
          <SendButton type="submit">
            <ChatSendIcon size={22.5} />
          </SendButton>
        </Form>
      </Container>
    )
  },
)
