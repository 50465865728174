import { logger } from '../utils/logging'

export class BoardWebsocketClient {
  constructor(
    host,
    port,
    on_ToBeForwarded_message_callback,
    on_board_notification_callback,
    on_mixer_control,
    on_stats,
    { cloud_debug_mode = false },
    secure = true
  ) {
    this._forward = on_ToBeForwarded_message_callback
    this._notify_app = on_board_notification_callback
    this._mixer_update = on_mixer_control
    this._cloud_debug_mode = cloud_debug_mode
    this._handle_stats = on_stats
    if (cloud_debug_mode) {
      return
    }
    const protocol = secure ? 'wss' : 'ws'
    const url = `${protocol}://${host}:${port}`
    logger.info('[BOARD WS] Opening board websocket')
    this.ws = new WebSocket(url)
    this.ws.addEventListener('open', () => {
      logger.info('[BOARD WS] Board websocket opened')
      this._isReady = true
    })
    this.ws.addEventListener('close', () => {
      logger.info('[BOARD WS] Board websocket opened')
      this._isReady = true
    })
    this.ws.addEventListener('message', this.route_message)
  }

  route_message = (message) => {
    const data = JSON.parse(message.data)
    const method = data.method
    if (method === 'mixer') {
      this._mixer_update(data.params)
    } else if (method === 'notify_app') {
      this.handle_notification_from_board(data.params)
    } else if (method === 'statistics') {
      this._handle_stats(data.params)
    } else {
      this.forward_message_to_user(data)
    }
  }

  send_OSC_message(path, value) {
    if (this._cloud_debug_mode) return
    if (this._isReady) {
      const msg = {
        method: 'osc',
        params: {
          address: path,
          params: [value],
        },
      }
      logger.info('[BOARD WS (OSC)]', { address: msg.params.address, params: msg.params.params[0] })
      this.ws.send(JSON.stringify(msg))
    }
  }

  send_message(method, params) {
    if (this._cloud_debug_mode) return
    const msg = {
      method: method,
      params: params,
    }
    logger.info('[BOARD WS]', { method: msg.method, params: msg.params })
    this.ws.send(JSON.stringify(msg))
  }

  handle_notification_from_board(msg) {
    this._notify_app(msg)
  }

  forward_message_to_board(msg) {
    logger.info('[BOARD WS]', 'MSG TO BOARD', { msg })
    if (this._cloud_debug_mode) return
    if (this._isReady) {
      this.ws.send(JSON.stringify(msg))
    }
  }

  forward_message_to_user(data) {
    const dest_ids = []
    dest_ids.push(data.params.to_id)
    this._forward(dest_ids, data)
    logger.info('[BOARD WS] Forwarding to user', { to_id: data.params.to_id, data })
  }

  close() {
    if (this._cloud_debug_mode) return
    logger.info('[BOARD WS] Closing board websocket')
    this.ws.close()
  }

}
