/**
 * $ grep Rule src/store/mixer.js
 */

import { APIClient } from '../api/client'
import { makeAutoObservable, reaction } from 'mobx'
import { DEFAULT_LOCAL_COMPENSATION_DELAY } from './settings'
import clone from 'lodash/cloneDeep'
import { logger } from '../utils/logging'

import { INPUT_OPTION_NONE, INPUT_OPTION_MIC, INPUT_OPTION_LINE, INPUT_OPTION_GUITAR, INPUT_OPTION_USB } from './input-options'
import {
  USB_OUTPUT_FULL_MIX,
} from './usb-output'
import { showAudioSelectionDialog } from '../components/dialogs/AudioSelection'
import { BOARD_CONNECTION_STATE_CONNECTED, BOARD_CONNECTION_STATE_SUSHI_ERROR } from './board-connection-states'
import showFeedbackWarning from '../components/dialogs/feedback-warning'
import { promiseWithTimeout } from '../utils/utils'
import { showSampleRateError } from '../components/dialogs/sample-rate'
import { sleep } from './board'
import { NIKKEI_DISABLED, NIKKEI_ENABLED, STANDALONE_AUDIO } from './system-states'
import { showDialog } from '../components/dialogs/ConfirmationDialog'
import { relaunchApp } from './electronAPI'

const trackDefaults = {
  pan: [0.5, 0.5],
  volume: [0.8333, 0.8333],
  muted: [false, false],
  stereoLinked: false,
}

export class Mixer {

  /**
   * True if panic mode is enabled
   */
  panicMode = false
  panicModeShouldFlash = false

  /**
   * True if settings should be persisted to cloud
   */
  pendingPersist = false

  /**
   * USB Output option
   */
  usbOutput = USB_OUTPUT_FULL_MIX

  /**
   * Data for the master fader.
   */
  master = {
    volume: 0,
    muted: false,
  }

  /**
   * Data for the headphones fader.
   */
  headphones = {
    volume: 0,
    muted: false,
  }

  send = [true, true]

  inputOptions = [INPUT_OPTION_LINE, INPUT_OPTION_LINE]

  inputGains = [0, 0]

  phantomPower = [false, false]

  compensationDelay = DEFAULT_LOCAL_COMPENSATION_DELAY

  /**
   * String with user track id
   */
  OWN = 'own'

  /**
   * The tracks as defined by the bridge.
   *
   * The first track represents the users own track in the mixer view and
   * partner tracks are initialized dynamically.
   */
  tracks = {
    own: clone(trackDefaults),
  }

  /**
   * The peak meter data sent by the bridge.
   */
  peakMeters = new Map()
  peakMeterUpdatedAt = new Map()

  /**
   * The preamp meter data sent by the bridge.
   */
  preampMeters = new Map()
  preampMeterUpdatedAt = new Map()

  /**
   * The peak meter data sent by the bridge.
   */
  signalMeters = new Map()
  signalMeterUpdatedAt = new Map()

  signalIndicatorUpdatedAt = new Map()

  /**
   * Boolean indicating whether the user has decided to hide the USB input warning
   */
   hideUsbInputConfirmation = false

  desktop = {
    availableDevices: [],
    isSushiReady: false,
    selectedInput: null,
    selectedOutput: null,
  }

  /**
   * Boolean indicating whether the user has decided to hide the internal device feedback warning
   */
  hideInternalDeviceFeedbackWarning = false

  getAudioDevicesRetries = 0

  pendingPartnerTracks = {}

  constructor(store) {

    const newInputGains = store.settings.hw_settings.input_gains.slice()
    this.store = store

    /**
     * Do not observe high frequency changes since we do not want to
     * re-render or perform DOM-diffing every time they change
     */
    makeAutoObservable(this, {
      peakMeters: false,
      peakMeterUpdatedAt: false,
      preampMeters: false,
      preampMeterUpdatedAt: false,
      onMixerMessage: false,
    })

    /* Default values for peak meters and preamp meters */
    this.initPeakMeters(this.OWN)

    /*
     * Rule: When changing input type or input mode the setting should be saved in the cloud and loaded on next login
     */
    for (const channelIndex of [0, 1]) {
      reaction(
        () => this.inputOptions[channelIndex],
        (value) => {
          store.settings.hw_settings.input_options = this.inputOptions
          this.pendingPersist = true
        },
      )
    }

    /*
     * Rule: Whenever input mode is changed, phantom power should be switched off
     */
    for (const channelIndex of [0, 1]) {
      reaction(
        () => this.inputOptions[channelIndex],
        (value) => {
          this.phantomPower[channelIndex] = false
        },
      )
    }

    /*
     * Rule: When changing input gain the setting should be saved in the cloud and loaded on next login
     */
    for (const channelIndex of [0, 1]) {
      reaction(
        () => this.inputGains[channelIndex],
        (value) => {
          store.settings.hw_settings.input_gains = this.inputGains
          this.pendingPersist = true
        },
      )
    }

    /**
     * Rule: When changing stereo link the setting should be saved in the cloud and loaded on next login
     */
    reaction(
      () => this.tracks[this.OWN].stereoLinked,
      (value) => {
        store.settings.hw_settings.stereo_link = value
        this.pendingPersist = true
      },
    )

    /**
     * Rule: Mic should have default gain of 0.5, every other input mode should have a default gain of 0
     */
    for (const channelIndex of [0, 1]) {
      reaction(
        () => this.inputOptions[channelIndex],
        (value) => {
          this.inputGains[channelIndex] = this.getDefaultGain(value)
        },
      )
    }

    /**
     * Rule: Whenever one of the inputs becomes USB the other one should also become USB
     * Rule: When changing input type to USB stereo linking should be enabled automatically
     */
    for (const channelIndex of [0, 1]) {
      const otherChannelIndex = 1 - channelIndex
      reaction(
        () => this.inputOptions[channelIndex],
        (value) => {
          if (this.inputOptions[channelIndex] === INPUT_OPTION_USB) {
            this.inputOptions[otherChannelIndex] = INPUT_OPTION_USB
            this.tracks.own.stereoLinked = true
          }
        },
      )
    }

    /**
     * Rule: Whenever one of the inputs goes from USB to something else the other input should go to None
     */
    for (const channelIndex of [0, 1]) {
      const otherChannelIndex = 1 - channelIndex
      reaction(
        () => this.inputOptions[channelIndex],
        (value) => {
          if (this.inputOptions[channelIndex] !== INPUT_OPTION_USB) {
            if (this.inputOptions[otherChannelIndex] === INPUT_OPTION_USB) {
              this.inputOptions[otherChannelIndex] = INPUT_OPTION_NONE
            }
          }
        },
      )
    }

    /**
     * Rule: Stereo link is only available when either both inputs are set to Line or both inputs are set to USB
     */
    for (const channelIndex of [0, 1]) {
      reaction(
        () => this.inputOptions[channelIndex],
        (value) => {
          if (!this.isStereoLinkAvailable()) {
            this.tracks[this.OWN].stereoLinked = false
          }
        },
      )
    }

    /**
     * Rule: [Desktop only] If the selected input also has an output, or if there is an available device with the same name with an output,
     * it is automatically selected as current output device
     */
    reaction(() => this.desktop.selectedInput, (item) => {
      if(this.desktop.selectedInput === null) return

      const currentInputDevice = this.getCurrentInputDevice()

      const inputOutputDevice =  this.desktop.availableDevices.find(({ uid, outputs }) =>
        currentInputDevice.uid === uid && outputs > 0
      )

      this.desktop.selectedOutput = inputOutputDevice ? inputOutputDevice.uid : null
    })

    /**
     * Rule: When enabling stereo link the panning should be set to (Left, Right), when disabling stereo link the panning should be set to (Center, Center)
     */
    this.initStereoLink(this.OWN)

    /**
     * Rule: When stereo linking, the gain should bet set to the lowest gain of the two channels
     */
    reaction(
      () => this.tracks[this.OWN].stereoLinked,
      (value) => {
        if (value) {
          // Set both gains to previous lowest gain on link.
          const min = Math.min(this.inputGains[0], this.inputGains[1])
          this.inputGains[0] = min
          this.inputGains[1] = min
        }
      },
    )

    /**
     * Rule: When stereo linking, the broadcast state should be set to "live!" if either of the channels had "live!" otherwise private
     */
    reaction(
      () => this.tracks[this.OWN].stereoLinked,
      (value) => {
        if (value) {
          // Set send to true if either channels previously had send.
          const send = this.send[0] || this.send[1]
          this.send[0] = send
          this.send[1] = send
        }
      }
    )

    /* -------------------------------------------------- */

    reaction(
      () => this.tracks[this.OWN].stereoLinked,
      (value) => {
        APIClient.stereo_link(value)
      },
    )

    reaction(
      () => this.master.volume,
      (value) => {
        if (!this.master.muted) {
          APIClient.master_volume(value)
        }
      },
    )

    reaction(
      () => this.master.muted,
      (value) => {
        if (value) {
          APIClient.master_volume(0)
        } else {
          APIClient.master_volume(this.master.volume)
        }
      },
    )

    reaction(
      () => this.compensationDelay,
      (value) => {
        APIClient.compensation_delay(value)
      },
    )

    for (const channelIndex of [0, 1]) {
      reaction(
        () => this.send[channelIndex],
        (value) => {
          try {
            APIClient.send(channelIndex, value)
          } catch (err) {
            this.showSnackBar({
              heading: 'Board connection error',
              content: 'Failed to set broadcast state',
              level: 'error',
              duration: 12000,
            })
          }
        },
      )
      reaction(
        () => this.inputGains[channelIndex],
        (value) => {
          APIClient.preamp_gain(channelIndex, value)
        },
      )
    }

    for (const channelIndex of [0, 1]) {
      reaction(
        () => this.phantomPower[channelIndex],
        (value) => {
          APIClient.phantom_power(channelIndex, value)
        },
      )
    }

    for (const channelIndex of [0, 1]) {
      reaction(
        () => this.inputOptions[channelIndex],
        (value) => {
          APIClient.input_config(channelIndex, value)
        },
      )
    }

    this.initPanTracking('own')

    /* ---------------------- */

    setInterval(() => {
      if (this.pendingPersist) {
        APIClient.save_user_settings_key(
          store.currentUserId,
          'hw_settings',
          store.settings.hw_settings,
          store.sessionMode,
        )
        this.pendingPersist = false
      }
    }, 1500)


    this.inputOptions = store.settings.hw_settings.input_options
    this.inputGains = newInputGains
    this.tracks[this.OWN].stereoLinked = store.settings.hw_settings.stereo_link
  }

  setDefaultDesktopInputOptions(){
     logger.info('Setting default input options for desktop...')
      if(this.inputOptions.filter(input => input === 'INPUT_OPTION_NONE').length === 2){
        this.inputOptions = [INPUT_OPTION_LINE, INPUT_OPTION_LINE]
      }
   }

  initPartnerTrack(userId) {
    this.tracks[userId] = clone(trackDefaults)

    this.initPeakMeters(userId)
    this.initStereoLink(userId)
    this.initPanTracking(userId)

    this.applyPendingPartnerConfig(userId)
  }

  async applyPendingPartnerConfig(userId) {
    await sleep(1000)
    if(this.pendingPartnerTracks[userId]) {
      logger.info('Applying pending config')
      this.tracks[userId] = clone({ ...trackDefaults, ...this.pendingPartnerTracks[userId]})
      APIClient.partner_stereo_link(userId, this.pendingPartnerTracks[userId].stereoLinked)
    }
  }

  resetPanToDefault(userId) {
    if(this.tracks[userId].stereoLinked){
      APIClient.track_pan(userId, 1, 0)
      APIClient.track_pan(userId, 2, 1)
    } else {
      for (const channelIndex of [0, 1]) {
        APIClient.track_pan(userId, channelIndex + 1, 0.5)
      }
    }
  }

  removePartnerTrack(userId) {
    delete this.tracks[userId]
  }

  initPeakMeters(trackId) {
    this.peakMeters.set(`${trackId}/level_0`, 0)
    this.peakMeters.set(`${trackId}/level_1`, 0)
    this.peakMeterUpdatedAt.set(`${trackId}/level_0`, 0)
    this.peakMeterUpdatedAt.set(`${trackId}/level_1`, 0)
    this.preampMeters.set(`${trackId}/level_0`, 0)
    this.preampMeters.set(`${trackId}/level_1`, 0)
    this.preampMeterUpdatedAt.set(`${trackId}/level_0`, 0)
    this.preampMeterUpdatedAt.set(`${trackId}/level_1`, 0)
    this.signalMeters.set(`${trackId}/level_0`, 0)
    this.signalMeters.set(`${trackId}/level_1`, 0)
  }

  initStereoLink(trackId) {
    reaction(
      () => this.tracks[trackId].stereoLinked,
      (value) => {
        if (value) {
          this.tracks[trackId].pan = [0, 1]
        } else {
          this.tracks[trackId].pan = [0.5, 0.5]
        }
      },
    )
  }

  initPanTracking(trackId) {
    for (const channelIndex of [0, 1]) {
      reaction(
        () => this.tracks[trackId].pan[channelIndex],
        (value) => {
          APIClient.track_pan(trackId, channelIndex + 1, value)
        },
      )
      reaction(
        () => this.tracks[trackId].volume[channelIndex],
        (value) => {
          if (!this.tracks[trackId].muted[channelIndex]) {
            APIClient.track_volume(trackId, channelIndex + 1, value)
          }
        },
      )
      reaction(
        () => this.tracks[trackId].muted[channelIndex],
        (value) => {
          if (value) {
            APIClient.track_volume(trackId, channelIndex + 1, 0)
          } else {
            APIClient.track_volume(
              trackId,
              channelIndex + 1,
              this.tracks[trackId].volume[channelIndex],
            )
          }
        },
      )
    }

  }

  onMixerMessage(params) {
    if (params.type === 'peakmeter') {
      if (/^\d+.*$/.test(params.track)) {
        this.peakMeters.set(params.track, params.value)
        this.peakMeterUpdatedAt.set(params.track, Date.now())
      } else {
        this.peakMeters.set(params.track, params.value)
        this.peakMeterUpdatedAt.set(params.track, Date.now())
      }
    } else if (params.type === 'preamp_meter') {
      this.preampMeters.set(params.track, params.value)
      this.preampMeterUpdatedAt.set(params.track, Date.now())
    } else if (params.type === 'input_clip') {
      // ignored since there's nowhere to display this in UI
    } else if (params.type === 'output_clip') {
      // ignored since there's nowhere to display this in UI
    } else if (params.type === 'signal') {
      this.signalMeters.set(params.track, params.value)
      this.signalMeterUpdatedAt.set(params.track, Date.now())
      this.signalIndicatorUpdatedAt.set(params.track, Date.now())
    }
  }

  hasGainControls(inputOption) {
    return inputOption === INPUT_OPTION_MIC || inputOption === INPUT_OPTION_GUITAR || inputOption === INPUT_OPTION_LINE;
  }

  getDefaultGain(inputOption) {
    if (inputOption === INPUT_OPTION_MIC) {
      return 0.5
    }
    return 0
  }

  isStereoLinkAvailable() {
    return (this.inputOptions[0] === INPUT_OPTION_LINE &&
           this.inputOptions[1] === INPUT_OPTION_LINE) ||
           (this.inputOptions[0] === INPUT_OPTION_USB &&
           this.inputOptions[1] === INPUT_OPTION_USB)
  }

  /**
   * Unsupported devices: Built-In Microphone, Zoom AudioDevice
   **/

  filterUnsupportedDevices(devices)  {
    return devices.filter(({ uid }) => {
      return !['zoom.us','BuiltInMicrophone', 'AppleHDAEngineInput'].some((unsupported) => uid?.includes(unsupported))
    })
  }


  /**
   * Get list of audio devices
   **/
  async getAudioDevices() {
    const { promiseOrTimeout: getAudioDevices, timeoutId } = promiseWithTimeout(
      APIClient.get_audio_devices_info(),
      12000)

     try {
       const devicesInfo = await getAudioDevices;
       this.desktop.availableDevices = this.filterUnsupportedDevices(devicesInfo.devices)
       this.desktop.defaultDevices = {input: devicesInfo.default_input_device, output: devicesInfo.default_output_device}
       this.getAudioDevicesRetries = 0
     } catch (error) {

      if(this.getAudioDevicesRetries <= 3){
        this.getAudioDevicesRetries++
        logger.error('Error trying to get audio devices, retrying in 700ms', error)
        await sleep(700)
        return await this.getAudioDevices()
      }

       logger.error(`Error trying to get audio devices after ${this.getAudioDevicesRetries} retries`, error)
       this.getAudioDevicesRetries = 0
       logger.error('Error trying to get audio devices', error)
       this.showDesktopErrors()
     } finally {
       clearTimeout(timeoutId)
     }
  }

  setMuteOwnTracks(muted) {
    for (const channelIndex of [0, 1]) {
      this.tracks[this.OWN].muted[channelIndex] = muted
    }
  }

  isUsingAppleDevices(input,output) {
    const defaultAppleInputs = ['BuiltInMicrophone', 'AppleHDAEngine']
    const defaultAppleOutputs = ['BuiltInSpeaker', 'AppleHDAEngine']

    const usingAppleInput = defaultAppleInputs.some((builtInUid) => input?.includes(builtInUid))
    const usingAppleOutput = defaultAppleOutputs.some((builtInUid) => output?.includes(builtInUid))

    return { usingAppleOutput, usingAppleInput }
  }

  checkAppleBuiltInDevices(input,output) {

    const { usingAppleInput, usingAppleOutput } = this.isUsingAppleDevices(input,output)

    if(usingAppleInput && usingAppleOutput && !this.hideInternalDeviceFeedbackWarning){
      this.store.showSettingsView = false

      showFeedbackWarning({ store: this.store, onConfirm: () => {
        this.desktop.selectedInput = input
        this.desktop.selectedOutput = output
        this.setAudioDevices()
        }})
      this.master.volume = 0
      return true
    }

    return false
  }

  /**
   * Check previously stored devices and update the indexes accordingly
   * Meant to be called on a devicechange event
   **/
  updateSelectedDevices() {
    const audioSettings = this.getStoredDesktopOptions()
    const input = this.desktop.availableDevices.find(({ uid }) => uid === audioSettings.input.uid)
    const output = this.desktop.availableDevices.find(({ uid }) => uid === audioSettings.output.uid)

    this.desktop.selectedInput = input?.uid || null
    this.desktop.selectedOutput = output?.uid || null
  }


  /**
   * Sets audio input and output and restart master volume to 0
   **/
  async setAudioDevices() {

    logger.info(`Audio devices selected, input: ${this.getCurrentInputDevice()?.name}, output: ${this.getCurrentOutputDevice()?.name}`)

    const { promiseOrTimeout: setAudioDevices, timeoutId } = promiseWithTimeout(
      APIClient.set_audio_device(
        this.desktop.selectedInput,
        this.desktop.selectedOutput,
        !this.desktop.isSushiReady
      ),
      20000)

     try {
       this.store.showDesktopLoading()

       const response = await setAudioDevices

       if(response){
         this.master.volume = 0.83
         this.desktop.isSushiReady = true
         this.store.isDesktopAudioInitializing = false
         this.store.systemState = STANDALONE_AUDIO

         this.setDefaultDesktopInputOptions()
         this.storeDesktopAudioOptions()
       } else {
         this.store.isDesktopAudioInitializing = false
         this.showDesktopGenericError({ reload: true })
       }

     } catch (error) {
       logger.error('Error trying to set audio devices (initializing sushi)', error)
       this.store.isDesktopAudioInitializing = false
       this.showDesktopErrors()
     } finally {
       clearTimeout(timeoutId)
     }
  }

  async stopSushi() {
     try {
       await APIClient.stop_sushi()
       this.desktop.isSushiReady = false
     } catch (error) {
       logger.error('Error trying to shut down sushi', error)
     }
  }

  showDesktopErrors(){
    const sampleRateError = localStorage.getItem('sampleRateError')
    this.resetSelectedDevices()
    if(sampleRateError === 'true'){
      showSampleRateError({ store: this.store })
    } else {
      this.showDesktopGenericError({ reload: false })
    }
  }

  /**
   * Store selected audio devices in localStorage
   **/
  storeDesktopAudioOptions() {
     const { isSushiReady, ...audioIODevices } = this.desktop

    const input = this.getCurrentInputDevice()
    const output = this.getCurrentOutputDevice()

     const audioSettings = {
       input: { name: input.name, uid: audioIODevices.selectedInput },
       output: { name: output.name, uid: audioIODevices.selectedOutput }
     }

     localStorage.setItem('desktopOptions', JSON.stringify(audioSettings))
     localStorage.removeItem('sampleRateError')
  }

  /**
   * Returns parsed object of localStorage desktop stores devices
   **/
  getStoredDesktopOptions() {
     const localDesktopOptions = localStorage.getItem('desktopOptions')

     // cleaning possible previous versions values
     if(localDesktopOptions && !localDesktopOptions.includes('uid')){
       localStorage.removeItem('desktopOptions')
       return
     }

     if(localDesktopOptions){
       return JSON.parse(localDesktopOptions)
     }
  }

  /**
   * Removes selected input/output devices and clear localStorage
   **/
  resetSelectedDevices(){
    this.desktop.selectedInput = null
    this.desktop.selectedOutput = null
    localStorage.removeItem('desktopOptions')
  }

  /**
   * Current devices getters
   **/
  getCurrentInputDevice() { return this.desktop.availableDevices.find(({ uid }) => uid === this.desktop.selectedInput)}
  getCurrentOutputDevice() { return this.desktop.availableDevices.find(({ uid }) => uid === this.desktop.selectedOutput)}

  /**
   * Check if selected devices are available or not and update devices list accordingly
   **/
  async updateDevicesList() {
    logger.info('An audio device has been connected/disconnected, updating devices list...')
    await this.getAudioDevices()
    if (this.desktop.selectedInput && this.desktop.selectedOutput && !this.store.isOnPluginMode()) {
      logger.info('Checking if selected devices are available...')
      this.updateSelectedDevices()

      if((!this.desktop.selectedInput || !this.desktop.selectedOutput) && this.desktop.isSushiReady){
        logger.info('The selected device is not longer available, prompting audio selection dialog..')
        if(this.store.sessionMode){
          await this.store.disconnectFromBoard()
          this.desktop.isSushiReady = false
        }
        showAudioSelectionDialog({ store: this.store })
      } else {
        logger.info('The selected device is still available..')
      }
    }
  }

  async handleSushiCrash(errorMessage) {
    this.store.showSettingsView = false
    this.store.boardConnectionState = BOARD_CONNECTION_STATE_SUSHI_ERROR
    this.resetSelectedDevices()
    this.desktop.isSushiReady = false
    if(errorMessage.error_code === 55){
      localStorage.setItem('sampleRateError', 'true')
      showSampleRateError({ store: this.store })
      return
    }
    this.showDesktopGenericError({ reload: false })
  }

  isAudioEngineReady(){
    return this.store.boardConnectionState === BOARD_CONNECTION_STATE_CONNECTED
      && (this.desktop.isSushiReady || (this.store.systemState === NIKKEI_ENABLED && this.store.isNikkeiProcessing) || this.store.isDeveloperMode)

  }

  isPluginMode(){
    return [NIKKEI_ENABLED, NIKKEI_DISABLED].includes(this.store.systemState)
  }

  showDesktopGenericError({ reload = false, sampleRate = false }){
      showDialog({
        store: this.store,
        okText: reload ? 'Reboot' : 'Reload',
        okAction: () => {
          if(reload) {
            window.location.reload()
          } else {
            relaunchApp()
          }
        },
        title: sampleRate ? 'Sample rate conflict': 'Something went wrong',
        body: 'Currently, Elk only runs at 48 kHz. If you need to use another audio application at the same time, please make sure it also runs at 48 kHz',
      })
  }

}
