/* eslint-disable no-useless-computed-key */

import React from 'react'
import { observer } from 'mobx-react-lite'
import { Dialog, CloseButton, ModalButton, DialogTitle } from '../base'
import { openModal } from '../overlays'
import { Box, Text } from '../../ui/primitives'
import { withStore } from '../../../store'
import { Paragraph } from '../../pages/user-configuration/pages/betaJoin/style'
import styled from 'styled-components'
import { getCurrentPriceId, getManagementUrls, getScheduleCancel, launchCheckout } from '../../../api/payment/paddle'

const PlanBox = styled(Box)`
  width: 260px;
  padding: 15px;
  border: 2px solid ${(props) => props.selected ? 'var(--ELK-Sea-Foam-Green)' : '#8C8C8C'};
  border-radius: 6px;
  text-align: center;
  h2 {
    font-size: 18px;
    strong { font-weight: 700; }
  }
  p {
    margin-top: 10px;
    font-size: 11px;
    strong { font-weight: 700; }
  }
`

const SusbcriptionDialog = observer(({ close, store }) => {

  const currentUser = store.users.get(store.currentUserId)
  const countryCode = currentUser.location.split(':')[0]

  const launchPaddleCheckout = async (id) => {
    const { id: priceId } = store.subscriptionPlanPrices.prices.find((plan) => plan.id === id)
    close()
    await launchCheckout(store, priceId)
  }

  const getPriceToShow = (plan) => {
    const specificCountryPrice = plan.unit_price_overrides.find(plan => plan.country_codes.includes(countryCode))
    return specificCountryPrice ? specificCountryPrice.unit_price : plan.unit_price
  }

  const currentPriceId = getCurrentPriceId(store)?.priceId
  const scheduledCancel = getScheduleCancel(store)

  return (
    <Dialog
      width="86rem"
      py="2rem"
      px="2rem"
      bgColor="var(--ELK-Dark-Grey)"
    >
      <CloseButton onClick={() => close()} color="var(--ELK-White)" />
      <DialogTitle>Whatever your needs. We have a plan for you.</DialogTitle>

      <Box textAlign="center" mt="25px">
        {!currentPriceId ? (
          <Paragraph>
            Right now, we have a special <span style={{
              color: 'var(--ELK-High-Prio-Green-Enabled)',
              fontWeight: 700
            }}>EARLYBIRD</span> offer that gives you a 50% reduction on your subscription! <br />
            Subscribe anytime between now and June 30th, and that price remains locked in forever, as long as you keep your subscription active!
          </Paragraph>
        ) : (
          <Paragraph>
            You have an active unlimited subscription.<br />
            Great choice! This gives you unlimited session time for you AND the people you are playing with!
          </Paragraph>
        )}
      </Box>

      <Box my="25px" flex justifyContent="space-between">

        <PlanBox selected={!currentPriceId}>
          <h2><strong>free</strong></h2>
          <p>Get your first taste of Elk without even
            having to put in your credit card details.<br /><br />
            20 min/session time limit<br /><br />
            <strong>Price: absolutely nothing.</strong>
          </p>
          {!currentPriceId ? (
            <>
            <Text fontWeight="700" mt="40px" fontSize="14px">
              Current plan
            </Text>
            </>
            ) :
          <>
            {scheduledCancel === false && (
              <ModalButton
                mt="22px"
                disabled={scheduledCancel !== false}
                onClick={() => {
                window.open(getManagementUrls(store).cancel)
                  store.analytics.track('Subs_clicked_downgrade_free', {['Current Price ID']: currentPriceId})
              }}
                primary>Change to free
              </ModalButton>
            )}
          </>}
        </PlanBox>

        {store.subscriptionPlanPrices.prices.reverse().map((item) => (
          <PlanBox key={item.id} selected={currentPriceId === item.id}>
            <h2><strong>unlimited.</strong> {item.billing_cycle.interval}ly</h2>
            <p>{item.description}.<br /><br />
              Unlimited sessions (also with free users)<br /><br />
              <strong style={{ textDecoration: 'line-through'}}>Price: {getPriceToShow(item).currency_code} {getPriceToShow(item).amount.slice(0,-2)}</strong><br />
              <strong style={{ fontSize: '16px' }}
                >Price: {getPriceToShow(item).currency_code} {
              Number(getPriceToShow(item).amount.slice(0,-2)) * 0.5
              }</strong>
            </p>

            {currentPriceId !== item.id ? (
              <ModalButton
                disabled={scheduledCancel !== false}
                onClick={async () => { await launchPaddleCheckout(item.id) }}
                primary>Change to {item.billing_cycle.interval}ly
              </ModalButton>
            ) : (
              <>
              {scheduledCancel !== false ? (
                <Text mt="10px" color="var(--ELK-High-Prio-Green-Enabled)" textAlign="center">
                  Scheduled to cancel on <strong>{new Date(scheduledCancel).toDateString()}</strong>
                </Text>
                ) : (
                  <Text fontWeight="700" fontSize="14px">
                    Current plan
                  </Text>
                )}
              </>
            )}

          </PlanBox>
        ))}

      </Box>

    </Dialog>
  )
})

export function showSubscriptionDialog({ store }) {
  return openModal(
    ({ close }) =>
      withStore(
        store,
        <SusbcriptionDialog store={store} close={close} />
      ),
    {
      autoDismissable: false
    }
  )
}
