import React, { forwardRef } from 'react'
import styled from 'styled-components'

const Input = styled.input`
  width: 100%;
  padding: ${props => props.p ? props.p : '1rem'};
  margin: ${props => props.m ? props.m : 0};
  display: block;
  border-radius: 6px;
  background: ${props => props.bg ? props.bg : 'var(--ELK-White)'};
  color: var(--ELK-Black);
  border: 0;
  font-size: 1.2rem;
  transition: .2s ease;
  &::placeholder {
    color: var(--ELK-Light-Grey);
  }
  &:disabled {
    cursor: default;
    background: var(--ELK-Dark-Grey);
  }
`

export const CopyPasteInput = styled(Input)`
  background: var(--ELK-Green-Fog);
  color: var(--ELK-White);
  font-weight: 800;
  cursor: pointer;
`

/* eslint-disable-next-line */
export const TextField = forwardRef((props, ref) => <Input {...props} ref={ref} />)
