import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { Button } from '../ui/button'
import Checkbox from '../ui/form/checkbox';
import { CloseButton, Dialog } from './base'
import { openModal } from './overlays'
import { Box, Text, H1, Link } from '../ui/primitives'

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

const BlackBorderCheckbox = styled(Checkbox)`
  .square {
    &:not(.checked) {
      border: 2px solid black;
    }
  }
`;

const UsbInputConfirmationDialog = observer(({ close, onConfirm, store }) => {
  return (
    <Dialog
      width="56rem"
      py="3rem"
      px="3rem"
      bgColor="var(--ELK-White)"
      flex
      flexDirection="column"
    >
      <CloseButton color="var(--ELK-Black)" onClick={close}/>

      <H1 textAlign="center" mb="1.6rem" color="var(--ELK-Black)">Heads up!</H1>
      <Text textAlign="center" mb="1.4rem" color="var(--ELK-Black)" fontSize="1.8rem">
        By running USB IN & OUT at the same time you run the risk of creating some pretty nasty feedback loops. Proceed with caution! <StyledLink href="https://help.elk.live/en/articles/6114811-usb-audio-in-out-heads-up" target="_blank" rel="noreferrer">Click here for more info</StyledLink>
      </Text>

      <Box textAlign="center" color="var(--ELK-Black)">
        <BlackBorderCheckbox
          style={{ display: 'inline-flex' }}
          my="1rem"
          label="Don't show until next login"
          checked={store.mixer.hideUsbInputConfirmation}
          onChange={(value) => {
            store.mixer.hideUsbInputConfirmation = value
          }}
        />
      </Box>

      <Box columnGap="2rem" mt="2rem" flex>
        <Button primary fontSize="1.8rem" width="100%" onClick={() => {
          onConfirm()
          close()
          }}>
          Ok! Turn on USB input!
        </Button>
        <Button danger fontSize="1.8rem" width="100%" onClick={close}>
          Cancel
        </Button>
      </Box>
    </Dialog>
  )
})

export default function showUsbInputConfirmationDialog({ onConfirm, store }) {
  return openModal(
    ({ close }) => <UsbInputConfirmationDialog close={close} store={store} onConfirm={onConfirm} />,
    {
      autoDismissable: true,
    }
  )
}
