import styled from "styled-components";
import {H1, H3} from "../../../ui/primitives";
import {LegacyButton} from "../../../legacy/legacy-button";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  height: 6rem;
  width: 100%;
  background: var(--ELK-Black);
  color: var(--ELK-White);
  display: flex;
`

const IconWrapper = styled.span`
  height: 100%;
  display: inline-flex;
  align-items: center;
  padding-right: 0.5rem;
`
const BgContainer = styled.div`
  width: 100%;
  flex: 1;
  background-color: var(--ELK-Dark-Background-Grey);
  color: var(--ELK-Black);
  display: flex;
  align-items: center;
  justify-content: center;
`

const BgOverlay = styled.div`
    background: rgba(28,28,28,0.5);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const StyledH1 = styled(H1)`
  font-size: 5rem;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 2.2rem;
  font-weight: 800;
  text-align: center;
`

const StyledButton = styled(LegacyButton)`
  font-weight: 700;
  border-radius: 6px;
  background: var(--ELK-High-Prio-Yellow-Enabled);
  color: var(--ELK-Black);
  height: 4rem;
  width: 100%;
  margin-bottom: 10px;
  min-width: 0;
  font-size: 1.6rem;
  align-items: center;
  justify-content: center;
  display: flex;
  :disabled {
    opacity: 0.5;
  }
`

const ErrorMessage = styled(H3)`
  font-size: 1.4rem;
  font-weight: normal;
  color: var(--ELK-White);
  height: auto;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
`

export { Header,
    IconWrapper,
    BgContainer,
    Container,
    StyledH1,
    StyledButton,
    ErrorMessage,
    BgOverlay
}