import { observer } from 'mobx-react-lite'
import { useStore } from '../../../store'
import { SettingsWrapper } from './styles'
import { Box } from '../../ui/primitives'
import { QuestionMarkIcon } from '../../ui/icons'
import Dropdown from '../../ui/form/dropdown'

export const AUTOMATIC_LABEL = 'Automatic (Default)'

export const Settings = observer(() => {
  const store = useStore()

  const menuItems = [
    { title: AUTOMATIC_LABEL, ip: '' },
  ].concat(store.turnServers.map((server) => {
    return {
      title: server.city,
      ip: server.ip
    }
  }))

  const index = menuItems.findIndex((item) => {
    return item.title === store.selectedTurnServerTitle
  })

  return (
    <SettingsWrapper>
      <Box mr='12px'>
        connection
      </Box>
      <a target='_blank' rel='noreferrer' href='https://help.elk.live/en/articles/5904260-manual-connection'><QuestionMarkIcon /></a>
      <Box flex columnGap='2rem'>
        <Box width='22.5rem' ml='15px'>
          <Dropdown
            title={menuItems[index].title}
            textAlign='center'
            dropUp
            darkMode
            menuItems={menuItems}
            selectedItem={null}
            onChange={(idx) => {
              const item = menuItems[idx]
              store.selectedTurnServerTitle = item.title
              store.selectedTurnServerIp = item.ip
            }}
          />
        </Box>
      </Box>
    </SettingsWrapper>
  )
})
