import { logger } from '../utils/logging'

/**
 * Handlers for board events by type and notification
 */

const TypesHandlers = {
  heartbeat: (store, msg) => { store.boardLastSeen = Date.now() },
  kill_switch: (store,msg) => { store.mixer.panicMode = msg.value },
  bad_network: (store,msg ) => {
    logger.warn('Poor network detected')
  },
  sushi_crash: async (store, msg) => {
    await store.mixer.handleSushiCrash(msg)
  },
  speedtest: async (store, msg) => {
    store.setSpeedTestData(msg.results)
  },
  audio_engine_state_change: async (store,msg) => {
    await store.audioEngineStateChange(msg)
  },
  is_processing: (store, msg) => {
    store.setNikkeiProcessing(msg.value)
  },
  aloha_pause_state_change: (store, msg) => {
    store.setAlohaSuspendedState(msg.user_id, msg.new_value)
  }
}

const NotificationsHandler = {
  connection_status: (store, msg) => {  store.session.handleICEStatusMessage(msg) },
  bridge_takeover: (store, msg ) => { store.disconnectFromBoard() }
}

export { TypesHandlers, NotificationsHandler }
