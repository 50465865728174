export const loadScript = (id, url, callback = () => {}, attributes = {}) => {
  const scriptExists = document.getElementById(id);
  if (!scriptExists) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.id = id;
    script.onload = callback;
    Object.keys(attributes).forEach((key) => {
      script.setAttribute(key, attributes[key])
    })
    document.body.appendChild(script);
  }

  if (scriptExists) {
    callback();
  }
};

export default loadScript;
