import { observer } from 'mobx-react-lite'
import { useStore } from '../../store'
import { Box, H2 } from '../ui/primitives'
import styled from 'styled-components'
import { APIClient } from '../../api/client'

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 1rem;
  width: 700px;
`

export const DebugOverlay = observer(() => {
  const store = useStore()
  return (
    <Box fontSize="1rem" absolute bgColor="black" style={{bottom: '20px', left: '20px', opacity: 0.9, pointerEvents: 'none'}} p="20px">
      <Box flex columnGap="30px">
        <Box>
          <H2>store</H2>
          <Grid>
            <Box>store.showDebugOverlay</Box>
            <Box>{JSON.stringify(store.showDebugOverlay)}</Box>
            <Box>store.activeViewState</Box>
            <Box>{store.activeViewState}</Box>
            <Box>store.currentUserId</Box>
            <Box>{store.currentUserId}</Box>
            <Box>store.autoconnectionState</Box>
            <Box>{store.autoconnectionState}</Box>
            <Box>store.boardConnectionState</Box>
            <Box>{store.boardConnectionState}</Box>
            <Box>store.swUpdate</Box>
            <Box style={{whiteSpace: 'pre'}}>
              {JSON.stringify(store.swUpdate, null, 2)}
            </Box>
            <Box>store.showChatView</Box>
            <Box style={{whiteSpace: 'pre'}}>
              {JSON.stringify(store.showChatView)}
            </Box>
            <Box>store.userHasOpenedDeviceSelection</Box>
            <Box>{JSON.stringify(store.userHasOpenedDeviceSelection)}</Box>
            <Box>store.showSettingsView</Box>
            <Box style={{whiteSpace: 'pre'}}>
              {JSON.stringify(store.showSettingsView, null, 2)}
            </Box>
            <Box>store.board</Box>
            <Box style={{whiteSpace: 'pre'}}>
              {JSON.stringify({...store.board, store: {}}, null, 2)}
            </Box>
            <Box>store.serverStartupData</Box>
            <Box style={{whiteSpace: 'pre'}}>
              {JSON.stringify({...store.serverStartupData, elkImageDownloadLink: "...", elkSWUDownloadLink: "..."}, null, 2)}
            </Box>
            <Box>store.devices</Box>
            <Box style={{whiteSpace: 'pre'}}>
              {JSON.stringify(store.devices.map(device => {
                return {...device, store: {}}
              }), null, 2)}
            </Box>
            <Box>store.fetchedUsers</Box>
            <Box style={{whiteSpace: 'pre'}}>
              {JSON.stringify(store.fetchedUsers, null, 2)}
            </Box>
          </Grid>
        </Box>
        <Box>
          <H2>APIClient</H2>
          <Grid>
            <Box>APIClient._board_rpc_client</Box>
            <Box>{JSON.stringify(APIClient._board_rpc_client)}</Box>
            <Box>APIClient._cloud_socket_client</Box>
            <Box>{JSON.stringify({...APIClient._cloud_socket_client, _socket: null})}</Box>
            <Box>store.activeViewState</Box>
            <Box>{store.activeViewState}</Box>
            <Box>store.currentUserId</Box>
            <Box>{store.currentUserId}</Box>
            <Box>store.autoconnectionState</Box>
            <Box>{store.autoconnectionState}</Box>
            <Box>store.boardConnectionState</Box>
            <Box>{store.boardConnectionState}</Box>
            <Box>store.swUpdate</Box>
            <Box style={{whiteSpace: 'pre'}}>
              {JSON.stringify(store.swUpdate, null, 2)}
            </Box>
            <Box>store.showChatView</Box>
            <Box style={{whiteSpace: 'pre'}}>
              {JSON.stringify(store.showChatView)}
            </Box>
            <Box>store.userHasOpenedDeviceSelection</Box>
            <Box>{JSON.stringify(store.userHasOpenedDeviceSelection)}</Box>
            <Box>store.showSettingsView</Box>
            <Box style={{whiteSpace: 'pre'}}>
              {JSON.stringify(store.showSettingsView, null, 2)}
            </Box>
            <Box>store.board</Box>
            <Box style={{whiteSpace: 'pre'}}>
              {JSON.stringify({...store.board, store: {}}, null, 2)}
            </Box>
            <Box>store.serverStartupData</Box>
            <Box style={{whiteSpace: 'pre'}}>
              {JSON.stringify({...store.serverStartupData, elkImageDownloadLink: "...", elkSWUDownloadLink: "..."}, null, 2)}
            </Box>
            <Box>store.devices</Box>
            <Box style={{whiteSpace: 'pre'}}>
              {JSON.stringify(store.devices.map(device => {
                return {...device, store: {}}
              }), null, 2)}
            </Box>
            <Box>store.fetchedUsers</Box>
            <Box style={{whiteSpace: 'pre'}}>
              {JSON.stringify(store.fetchedUsers, null, 2)}
            </Box>
          </Grid>
        </Box>
        <Box>
          <Box>
            <H2>store.settings.knownDevices</H2>
            <Box style={{whiteSpace: 'pre'}}>
              {JSON.stringify(store.settings.knownDevices, null, 2)}
            </Box>
          </Box>
          <Box>
            <H2>store.settings.partnerData</H2>
            <Box style={{whiteSpace: 'pre'}}>
              {JSON.stringify(store.settings.partnerData, null, 2)}
            </Box>
          </Box>
          <Box>
            <H2>store.settings.videoChat</H2>
            <Box style={{whiteSpace: 'pre'}}>
              {JSON.stringify(store.settings.videoChat, null, 2)}
            </Box>
          </Box>
        </Box>
        <Box>
          <Box>
            <H2>store.settings.auth</H2>
            <Box style={{whiteSpace: 'pre'}}>
              {JSON.stringify({...store.settings.auth, refreshToken: "..."}, null, 2)}
            </Box>
          </Box>
          <Box>
            <H2>store.settings.ui</H2>
            <Box style={{whiteSpace: 'pre'}}>
              {JSON.stringify(store.settings.ui, null, 2)}
            </Box>
          </Box>
          <Box>
            <H2>store.settings.connection</H2>
            <Box style={{whiteSpace: 'pre'}}>
              {JSON.stringify(store.settings.connection, null, 2)}
            </Box>
          </Box>
        </Box>
        <Box>
          <Box>
            <H2>store.settings.hw_settings</H2>
            <Box style={{whiteSpace: 'pre'}}>
              {JSON.stringify(store.settings.hw_settings, null, 2)}
            </Box>
          </Box>
        </Box>
        <Box>
          <Box>
            <H2>store.settings.debug</H2>
            <Box style={{whiteSpace: 'pre'}}>
              {JSON.stringify(store.settings.debug, null, 2)}
            </Box>
          </Box>
          <Box>
            <H2>store.settings.sync</H2>
            <Box style={{whiteSpace: 'pre'}}>
              {JSON.stringify(store.settings.sync, null, 2)}
            </Box>
          </Box>
          <Box>
            <H2>store.settings.ecc</H2>
            <Box style={{whiteSpace: 'pre'}}>
              {JSON.stringify(store.settings.ecc, null, 2)}
            </Box>
          </Box>
        </Box>
        <Box>
          <Box>
            <H2>store.settings.sessionData</H2>
            <Box style={{whiteSpace: 'pre'}}>
              <textarea style={{height: '500px', width: '200px'}}>
              {JSON.stringify(store.settings.sessionData, null, 2)}
              </textarea>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
})
