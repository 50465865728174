import { useCallback, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import {
  LocalVideo,
  useLocalVideo,
} from 'amazon-chime-sdk-component-library-react'
import React from 'react'
import { useStore } from '../../../store'
import { LOCAL_VIDEO_MODE } from '../../../store/session'
import { Box } from '../../ui/primitives'
import { Button } from '../../ui/button'
import { MinimizeIcon, MaximizeIcon } from '../../ui/icons'
import Nameplate from './nameplate'
import { BottomContainer } from './video-overlays'
import VideoPlaceholder from './video-placeholder'
import { LocalTrack, SuspendedState } from '../../pages/mixer'
import { observer } from 'mobx-react-lite'
import Draggable from 'react-draggable'
import { NIKKEI_DISABLED } from '../../../store/system-states'

const fluidStyles = `
  height: 100%;
  margin: 0 auto;
  opacity: ${(props) => props?.visible ? '1' : '0'};
`

const minimizedVideo = css`
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 180px;
  height: 120px;
  cursor: move;
`

const VIDEO_MODES = Object.values(LOCAL_VIDEO_MODE)
const LocalVideoBox = styled(Box)`
  ${({ $mode }) =>
    $mode === LOCAL_VIDEO_MODE.MINIMIZED ?
    `${minimizedVideo}`
    :
    `transform: unset !important;`
  }
    opacity: ${(props) => props.$mode === LOCAL_VIDEO_MODE.MINIMIZED ? props?.visible ? '1' : '0' : '1'};
    transition: opacity 0.3s ease;
`

const VideoModeButton = styled(Button)`
  margin-right: 1.5rem;
`

const LocalParticipant = observer(() => {
  const { isVideoEnabled } = useLocalVideo()
  const store = useStore()
  const [mode, setMode] = useState(0)
  const currentUser = store.users.get(store.currentUserId)

  const currentVideoMode = store.session.localVideoMode

  useEffect(() => {
    if (currentVideoMode !== VIDEO_MODES[mode]) {
      setMode(VIDEO_MODES.findIndex((mode) => mode === currentVideoMode))
    }
  }, [currentVideoMode, mode])

  const toggleVideoMode = useCallback(() => {
    let newMode = mode + 1
    if (newMode >= VIDEO_MODES.length) {
      newMode = 0
    }
    setMode(newMode)
    store.session.localVideoMode = VIDEO_MODES[newMode]
  }, [mode, store.session])

  const minimizedEnable = store.session.localVideoMode === LOCAL_VIDEO_MODE.MINIMIZED

  return (
    <Draggable disabled={!minimizedEnable} cancel=".noDrag" bounds="parent">
      <LocalVideoBox $mode={currentVideoMode} visible={store.mixerVisible}>
        <LocalTrack  />
        <Box relative height="100%">
          {isVideoEnabled ? (
            <LocalVideo css={fluidStyles}  />
          ) : (
            <VideoPlaceholder user={currentUser} sessionMode={store.isRoomOrSessionActive()} />
          )}
          {store.systemState === NIKKEI_DISABLED && <SuspendedState />}
          <BottomContainer minimized={minimizedEnable}>
            <Box flexBasis="10%">
              <VideoModeButton p="0.5rem" onClick={toggleVideoMode}>
                {currentVideoMode !== LOCAL_VIDEO_MODE.MINIMIZED ? (
                  <MaximizeIcon width={15} />
                ) : (
                  <MinimizeIcon width={15} />
                )}
              </VideoModeButton>
            </Box>
            {minimizedEnable && <Nameplate text={`${currentUser?.display_name}`} />}
            <Box flexBasis="10%" />
          </BottomContainer>
        </Box>
      </LocalVideoBox>
    </Draggable>
  )
})

export default LocalParticipant
