import styled from 'styled-components'

import { H1, H2, H3 } from '../../ui/primitives'

export const StyledH1 = styled(H1)`
  font-size: 2.4rem;
  font-weight: 600;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  color: var(--ELK-High-Contrast-Yellow);
`

export const StyledH2 = styled(H2)`
  display: flex;
  font-weight: 700;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  line-height: 24px;
  svg {
    margin-left: 10px;
  }
`

export const StyledH3 = styled(H3)`
  display: flex;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 24px;
  svg {
    margin-left: 10px;
  }
`

export const SimpeH2 = styled(H2)`
  display: flex;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 24px;
  svg {
    margin-left: 10px;
  }
`
