import React, { useRef } from 'react'
import styled from 'styled-components'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { Button } from '../ui/button'
import { CloseButton, Dialog } from './base'
import { openModal } from './overlays'
import { Box, Text } from '../ui/primitives'
import { useStore, withStore } from '../../store'
import data from '../../../package.json'
import { logger } from '../../utils/logging'
import { AttachmentIcon, RemoveIcon } from '../ui/icons';
import { showVersionInfoDialog } from './VersionInfoDialog';
import { showDialog } from './ConfirmationDialog'

const TextArea = styled.textarea`
  color: var(--ELK-Black);
  resize: none;
  width: 100%;
  height: 18rem;
  border-radius: 6px;
  border: none;
  padding: 1rem 1.2rem;
  font-size: 1.4rem;`


/**
 * Temporary component used to display version info
 * to testers and developers.
 */
const VersionInfo = styled.div`
  position: absolute;
  color: #eee;
  margin-bottom: 10px;
`

const Input = styled.input`
  color: var(--ELK-Black);
  width: 100%;
  padding: 1rem 1.2rem;
  border-radius: 6px;
  font-size: 1.4rem;
  border: none;
`

const ModalFooter = styled(Box)`
  text-align: center;
  align-items: center;
  grid-template-columns: repeat(3, minmax(0, 1fr));
`;

const AttachmentLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  max-width: 100%;

  ${Text} {
    display: block;
    align-items: center;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    padding-top: 1rem;
    padding-bottom: 1rem;
  };
`


const ReportIssueDialog = observer(({ close }) => {
  const store = useStore()
  const state = useLocalObservable(() => ({
    subject: '',
    description: '',
    isSubmitting: false,
    images: [],
    imageName: '', // Only used when uploading single image
  }))
  const fileInputRef = useRef()

  const submit = async (e) => {
    e.preventDefault()

    state.isSubmitting = true
    try {
      const result = await store.reportAnIssue({
        subject: state.subject,
        description: state.description,
        images: state.images,
      })
      if (result) {
        showDialog({
          store,
          title:'Thank you!',
          body: 'Your feedback is very valuable to us!',
          okText: 'Move along'
        })
        close()
      }
    }
    catch (err) {
      logger.error(err)
    }
    finally {
      state.isSubmitting = false
    }
  }

  const onFileInputChange = (e) => {
    // Reset current state when new files arrive. Files don't compound
    state.images = []
    const files = e.currentTarget.files;
    if (files.length === 1) {
      state.imageName = files[0].name
    }
    Object.keys(files).forEach(i => {
      const file = files[i];
      const reader = new FileReader();
      reader.onload = (fileRead) => {
        const readImage = fileRead.target.result
        state.images.push(readImage)
      }
      reader.readAsDataURL(file);
    })
  }

  function resetFileUpload(e) {
    e.preventDefault()
    fileInputRef.current.value = null
    state.images = []
  }

  return (
    <Dialog
      width="55rem"
      py="4rem"
      px="2.5rem"
    >
      <CloseButton onClick={() => close()} color="var(--ELK-White)"/>

      {store.isDeveloperMode && (
        <VersionInfo onClick={() => {
          showVersionInfoDialog()
        }}>
          {data.version}<br />
          {process.env.REACT_APP_GIT_COMMIT || 'dev'}<br />
          {(process.env.REACT_APP_SERVER_URL || data.aloha_server_url).split('.')[0]}
        </VersionInfo>
      )}

      <Text fontSize="2rem" color="var(--ELK-White)" textAlign="center" mb="2.5rem">Report an issue</Text>
      <Text textAlign="center" mb="3rem" color="var(--ELK-White)" fontSize="1.6rem">Please describe the issue you encountered.</Text>

      <Box mb="1.4rem">
        <Input autoFocus name="subject" placeholder="Subject" value={state.subject} onChange={e => state.subject = e.target.value} />
      </Box>

      <Box mb="1.4rem">
        <TextArea name="description" placeholder="Description of the issue..." value={state.description} onChange={e => state.description = e.target.value} />
      </Box>

      <ModalFooter color="var(--ELK-White)">
        <Box pr="1rem" flex alignItems="stretch" maxWidth="100%" justifyContent="flex-end">
          <input ref={fileInputRef} type="file" id="file-upload" hidden accept="image/png, image/jpeg" onChange={onFileInputChange} />
          <AttachmentLabel htmlFor="file-upload">
            <AttachmentIcon size={25} style={{ flexShrink: 0, cursor: 'pointer' }} />
            <Text ml="1rem" fontSize="1.4rem">
              {state.images.length === 0 && 'add attachment'}
              {state.images.length === 1 && `${state.imageName}`}
              {state.images.length > 1 && `${state.images.length} files attached`}
            </Text>
            {state.images.length > 0 && (
              <RemoveIcon
                onClick={resetFileUpload}
                style={{ flexShrink: 0, marginLeft: '1rem', cursor: 'pointer' }}
              />
            )}
          </AttachmentLabel>
        </Box>
        <Box>
          <Button
            primary
            fontSize="1.6rem"
            disabled={!state.description || state.isSubmitting} onClick={submit}>
              {state.isSubmitting ? 'Submitting...' : 'Send Feedback'}
          </Button>
        </Box>
      </ModalFooter>
    </Dialog>
  )
})

export function showReportIssueDialog({ store }) {
  return openModal(
    ({ close }) => withStore(store, <ReportIssueDialog close={close}/>), {
      autoDismissable: true,
    }
  )
}
