import { observer } from 'mobx-react-lite'
import { CloseButton, Dialog } from './base'
import { withStore } from '../../store'
import styled from 'styled-components'
import { openModal } from './overlays'
import { Box, H1, H2 } from '../ui/primitives'
import { Button } from '../ui/button'

const StyledH2 = styled(H2)`
  font-weight: 400;
  margin-bottom: 5rem;
  margin-top: 1.5rem;
`

const AlreadyLoggedInDialog = observer(({ close, onConfirm }) => {

  return (
    <Dialog
      flex
      flexDirection="column"
      px="5.7rem"
      py="4.3rem"
      bgColor="var(--ELK-White)"
      textAlign="center"
    >
      <CloseButton onClick={() => close()} color="var(--ELK-Black)" />
      <H1 color="var(--ELK-Black)">Already logged in!</H1>
      <Box maxWidth="44rem">
        <StyledH2 color="var(--ELK-Black)">
          Your ELK LIVE account can only be used in ONE place at the same time.
          <br />
          <br />
          You are already logged in another tab/browser window.
          <br />
          <br />
          Do you want to log in here instead? (other windows will be logged out)
        </StyledH2>
      </Box>
      <Box>
        <Button primary onClick={() => close()} mr="0.8rem">no, cancel this</Button>
        <Button danger onClick={() => {
          onConfirm()
          close()
        }} ml="0.8rem">yes, login here</Button>
      </Box>
    </Dialog>
  )
})

export function showAlreadyLoggedInDialog({ store, onConfirm }) {
  return openModal(
    ({ close }) => withStore(store, <AlreadyLoggedInDialog close={close} onConfirm={onConfirm} />)
  )
}
