import { Box } from '../../../ui/primitives'
import BridgeRowIcon from './default'
import DesktopRowIcon from './desktop'

function DeviceRowIcon(props) {
  const { device } = props;
  let icon = <BridgeRowIcon {...props} />;
  if (device.isDesktopDevice) {
    icon = <DesktopRowIcon {...props} />
  }

  return (
    <Box width="50px" height="50px">
      {icon}
    </Box>
  )
}

export default DeviceRowIcon
