import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from './store'
import { AppLayout } from './components/ui/layouts'
import { UserConfiguration } from './components/pages/user-configuration'
import { PageContainer } from './components/pages'
import { Auth } from 'aws-amplify'

import Amplify from 'aws-amplify'
import {
  getCognitoAppClientID,
  getCognitoDomain,
  getCognitoRegion,
  getCognitoUserPoolID,
  getCurrentUrlOrigin,
  getSubscriptionsEnabled,
  getGoogleAuthClientId,
  getWebsiteUrl,
  getRedirectSignIn
} from './utils/utils'

import { Snackbars } from './components/features/snackbars'
import Toasts from './components/features/toasts'
import { DebugOverlay } from './components/features/debug-overlay'
import { showVersionInfoDialog } from './components/dialogs/VersionInfoDialog'

const cognitoRegion = getCognitoRegion()
const cognitoUserPoolID = getCognitoUserPoolID()
const cognitoAppClientID = getCognitoAppClientID()
const subscriptionsEnabled = getSubscriptionsEnabled()
const websiteUrl = getWebsiteUrl()

Amplify.configure({
  Auth: {
    region: cognitoRegion,
    userPoolId: cognitoUserPoolID,
    userPoolWebClientId: cognitoAppClientID,
  },
  oauth: {
    clientId: getGoogleAuthClientId(),
    redirectSignIn: getRedirectSignIn(),
    redirectSignOut: getCurrentUrlOrigin(),
    domain: getCognitoDomain(),
    scope: [
      'openid',
      'email',
      'profile',
    ],
  }
})

export const App = observer(() => {
  const store = useStore()

  /**
    *
    * Only set this value on "startup".
    * Exposes selected environment variables to the rest of the application
    */
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const { REACT_APP_PRODUCT_MODE } = process.env
    store.setEnvironmentVariables({
      REACT_APP_PRODUCT_MODE,
    })

    if(window.electronAPI && window.electronAPI.showChangelog){
      window.electronAPI.showChangelog((event, desktopVersionInfo) => {
        showVersionInfoDialog(desktopVersionInfo)
      })
    }
  }, [])

  store.setElkLiveWebsiteURL(websiteUrl)
  store.setSubscriptionsEnabled(subscriptionsEnabled !== "false")
  const url = new URL(window.location.href)
  const shouldLogout = url.searchParams.get('logout')

  if (shouldLogout) {
    const performLogout = async () => {
      await Auth.signOut()
      url.searchParams.delete('logout')
      window.location.href = url.href
    }
    performLogout()
    return <div />
  }

  if (store.restoreAuthenticationPending) {
    return <div></div>
  }

  return (
    <AppLayout>
      {store.currentUserId
        ? <PageContainer />
        : <UserConfiguration/>
      }
      <Snackbars />
      <Toasts />
      {store.showDebugOverlay && <DebugOverlay />}
    </AppLayout>
  )
})
