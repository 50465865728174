import React from 'react'
import { observer } from 'mobx-react-lite'
import {
  BridgeLogo,
  FailedConnectionBridgeIconModal,
  FailedConnectionBridgeIconHeader,
  MultipleBridgesIcon,
} from './icons'
import { Spinner } from './spinner'

import {
  BOARD_CONNECTION_STATE_CONNECTING,
  BOARD_CONNECTION_STATE_FAILED,
  BOARD_CONNECTION_STATE_DISCONNECTED,
  BOARD_CONNECTION_STATE_DISCONNECTING,
  BOARD_CONNECTION_STATE_UNINITIALIZED,
  BOARD_CONNECTION_STATE_ALREADY_IN_USE,
  BOARD_CONNECTION_STATE_CONNECTED, BOARD_CONNECTION_STATE_RECONNECTING
} from '../../store/board-connection-states'
import DesktopRowIcon from '../dialogs/DeviceSelection/DeviceRowIcon/desktop'
import { DESKTOP_HW_TYPES } from '../../store/board'

export const BridgeStatusText = observer(({ connectionState, devices = [], shorten= true }) => {
  if (connectionState === BOARD_CONNECTION_STATE_CONNECTING || connectionState === BOARD_CONNECTION_STATE_RECONNECTING) {
    return (
      <>
        <Spinner style={{display: 'inline', verticalAlign: 'top', marginRight: '3px'}} size={22} color="var(--ELK-Sea-Foam-Green)" />
        Connecting...
      </>
    )
  }
  if (connectionState === BOARD_CONNECTION_STATE_DISCONNECTING) {
    return (
      <>
        <Spinner style={{display: 'inline', verticalAlign: 'top', marginRight: '3px'}} size={22} color="var(--ELK-Sea-Foam-Green)" />
        Disconnecting...
      </>
    )
  }
  if (connectionState === BOARD_CONNECTION_STATE_DISCONNECTED) {
    return (
      <>
        Disconnected
      </>
    )
  }
  if (connectionState === BOARD_CONNECTION_STATE_UNINITIALIZED ) {
    if(devices.length === 0) return 'Bridge not found'
    return (
      <>
        {devices.length > 1 ? 'Multiple Bridges Found' : 'Disconnected'}
      </>
    )
  }
  if (connectionState === BOARD_CONNECTION_STATE_CONNECTED) {
    return (
      <>
        {shorten ? 'All good to go!' : 'Bridge found, connected and all good to go!'}
      </>
    )
  }
  if (connectionState === BOARD_CONNECTION_STATE_FAILED) {
    return (
      <>
        Connection failed
      </>
    )
  }
  if (connectionState === BOARD_CONNECTION_STATE_ALREADY_IN_USE) {
    return (
      <>
        Bridge found but already in use
      </>
    )
  }
  return (
    <>
      Bridge not found
    </>
  )
})

export const BridgeStatusIcon = observer(({ connectionState, size, devices = [], device, darkBackground = false}) => {

  const getColor = () => {
    if (connectionState === BOARD_CONNECTION_STATE_CONNECTED) {
      return 'var(--ELK-High-Prio-Yellow-Enabled)'
    }
    if (connectionState === BOARD_CONNECTION_STATE_ALREADY_IN_USE) {
      return 'var(--ELK-High-Prio-Red-Enabled)'
    }
    return 'rgba(226, 222, 205, 0.57)'
  }
  const getBandColor = () => {
    if (connectionState === BOARD_CONNECTION_STATE_CONNECTED) {
      return 'var(--ELK-Black)'
    }
    if (connectionState === BOARD_CONNECTION_STATE_ALREADY_IN_USE) {
      return 'var(--ELK-Black)'
    }
    return 'rgba(0,0,0,0.3)'
  }

  const bandColor = getBandColor()
  const color = getColor()

  if(connectionState === BOARD_CONNECTION_STATE_UNINITIALIZED) {
    if(devices.length > 1){
      return(
        <MultipleBridgesIcon size={size}/>
      )
    }

    if(devices.length === 0) {
      return darkBackground ?
        <FailedConnectionBridgeIconHeader size={size} /> :
        <FailedConnectionBridgeIconModal size={size} />
    }
  }

  if(connectionState === BOARD_CONNECTION_STATE_FAILED && !darkBackground)
  return (
    <FailedConnectionBridgeIconModal size={size} />
  )


  if(DESKTOP_HW_TYPES.includes(device?.type)){
    return (
      <DesktopRowIcon connectionState={connectionState} />
    )
  }

    return <BridgeLogo size={size}  color={color} bandColor={bandColor}/>

})
