import styled from 'styled-components'

import { BOARD_CONNECTION_STATE_CONNECTED } from '../../../../store/board-connection-states'
import { Box } from '../../../ui/primitives'
import { MusicNoteIcon } from '../../../ui/icons'

const DesktopLogoBackground = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ $connected }) => $connected ? 'var(--ELK-Black)' : 'var(--ELK-UI-Disabled)' };
  color: var(--ELK-White);
  border-radius: 8px;
  padding: 1rem;
  width: 100%;
  height: 100%;
`

function DesktopRowIcon({ connectionState }) {
  return (
    <DesktopLogoBackground
      $connected={connectionState === BOARD_CONNECTION_STATE_CONNECTED}
    >
      <MusicNoteIcon />
    </DesktopLogoBackground>
  )
}

export default DesktopRowIcon
