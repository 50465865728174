/* eslint-disable no-useless-computed-key */

import { createAtom, makeAutoObservable, observable } from 'mobx'
import axios from 'axios'
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'
import { APIClient, board_ip_to_url, DEFAULT_BOARD_BACKEND_PORT } from '../api/client'
import { RPCClient } from '../api/rpc-client'
import {
  Board,
  BoardInfo,
  DESKTOP_HW_TYPES,
  DESKTOP_IP,
} from './board'
import { Session, SESSION_EVENT_TRIGGER_TYPE_AUTOMATIC, SESSION_EVENT_TRIGGER_TYPE_MANUAL } from './session'
import { Settings } from './settings'
import { Mixer } from './mixer'
import { showNatErrorDialog } from '../components/legacy/dialogs/nat-error'
import { showUpdatesAvailableDialog } from '../components/dialogs/updates-available'
import { showAlreadyLoggedInDialog } from '../components/dialogs/already-logged-in'
import { showDeviceInfoDialog } from '../components/dialogs/device-info'
import { showCoreWasResetDialog } from '../components/dialogs/core-was-reset'
import { AUTOMATIC_LABEL } from '../components/dialogs/AddPartnerToSession/settings'
import {
  getCloudServerPort,
  getCloudServerSecure,
  getCloudServerUrl,
  getDevicesApiUrl,
  isDesktop,
  getCognitoUserPoolID,
  getCognitoAppClientID,
  isBetaRegistrationRoutes,
  downloadFileFromBlob,
  getPlatformData,
  NDLToJson,
  getCurrentVersion,
  logsToJson,
} from '../utils/utils'
import { ElkLogger, logger } from '../utils/logging'
import { Auth } from 'aws-amplify'
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { ClientConnectedError } from '../api/error-codes'
import sortBy from 'lodash/sortBy'

import {
  USB_OUTPUT_FULL_MIX,
  USB_OUTPUT_LOCAL_ONLY,
  USB_OUTPUT_REMOTE_ONLY,
  USB_OUTPUT_DISABLED
} from './usb-output'

import {
  SESSION_PARTNER_STATE_JOINED,
  SESSION_PARTNER_STATE_LEFT,
} from './session-partner-states'

import {
  UPDATE_STATE_AVAILABLE,
  UPDATE_STATE_SUCCESS,
  UPDATE_STATE_ANOMALY,
  UPDATE_STATE_IN_PROGRESS,
} from './update-states'

import {
  BOARD_CONNECTION_STATE_CONNECTED,
  BOARD_CONNECTION_STATE_DISCONNECTED,
  BOARD_CONNECTION_STATE_DISCONNECTING,
  BOARD_CONNECTION_STATE_FAILED,
  BOARD_CONNECTION_STATE_ALREADY_IN_USE,
  BOARD_CONNECTION_STATE_UNINITIALIZED,
  BOARD_CONNECTION_STATE_CONNECTING,
  BOARD_CONNECTION_STATE_RECONNECTING,
} from './board-connection-states'

import {
  ACTIVE_VIEW_STATE_LOBBY,
  ACTIVE_VIEW_STATE_VIDEO,
  ACTIVE_VIEW_STATE_MIXER,
} from './active-view-states'
import showMaintenanceModeDialog from '../components/dialogs/MaintenanceMode'
import {
  getDesktopVersion, getElectronLogs,
  getOsHostname,
  isElectronAPIAvailable,
  isWirelessConnection,
} from './electronAPI'
import compareVersions from 'compare-versions'
import { showOfflineError } from '../components/dialogs/OfflineError'
import { NotificationsHandler, TypesHandlers } from './board-notification-handler'
import { showWifiWarningDialog } from '../components/dialogs/wifi'
import { Analytics } from '../api/analytics/analytics'
import { showLoadingDesktopDialog } from '../components/features/Settings/AudioSection/loading'
import UAParser from 'ua-parser-js'
import { NIKKEI_DISABLED, NIKKEI_ENABLED, STANDALONE_AUDIO, STANDALONE_NO_AUDIO } from './system-states'
import { RoomManager } from './room-manager'
import { initPaddle, setProductDetails, setSelectedPlan } from '../api/payment/paddle'

/* Proportion of the validity time of the refresh token at which the user will be logged out */
const LOGOUT_USER_AFTER_REFRESH_TOKEN_HAS_REACHED = 0.75

/**
 * Counter used to construct unique ids for snack bars.
 */
let _snackBarId = 0

/**
 * Timestamp saved to avoid race conditions in device change event.
 */
let previousDeviceChangeTimetamp = 0

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export class Store {

  mixer = null

  /**
   * Whether to prevent users without an active subscription from using the environment
   * @type {boolean}
   */
  subscriptionsEnabled = true
  /**
   * Indicates whether the user has an active subscription. Users
   * without active subscriptions will be shown a warning that their
   * experience is limited.
   */
  userHasActiveSubscription = true

  previewVideoEnabled = true

  /**
   * Set to true to display an overlay which lets developers
   * inspect the state of the application.
   */
  showDebugOverlay = false

  /**
   * Timestamp at which the refresh token expires.
   */
  refreshTokenExpiresAt = null

  /**
   * Timestamp for last time the board sent a heartbeat.
   */
  boardLastSeen = null

  /**
   * True if the user has opened the device selection and we shouldn't open the
   * dialog automatically after autoconnection has detected several devices.
   */
  userHasOpenedDeviceSelection = false

  /**
   * Boolean used to indicate whether developer mode is activated. In developer
   * mode the user has access to advanced device settings.
   */
  isDeveloperMode = false

  /**
   * The current board connection state.
   */
  boardConnectionState = BOARD_CONNECTION_STATE_UNINITIALIZED

  /**
   * State variable indicating which view is currently visible in the web app UI.
   */
  activeViewState = ACTIVE_VIEW_STATE_LOBBY

  /**
   * Boolean indicating whether the chat view is currently visisble in the web app UI.
   */
  showChatView = false

  /**
   * Boolean indicating whether the Bridge/System settings pane is visible.
   */
  showSettingsView = false

  /**
   * Boolean indicating whether the Bridge settings pane is visible.
   */
  showBridgeSettingsView = false

  /**
   * Boolean indicating whether manual compensation is enabled.
   */
  enableManualCompensation = false

  /**
   * Object containing information of the ELK Bridge the current user is connected to.
   */
  board = null

  /**
   * Object containing information on available software versions.
   */
  serverStartupData = {}

  /**
   * Settings object containing information about the current settings (see settings.js).
   */
  settings = null

  /**
   * A counter for the number of unread chat messages in the chat view.
   */
  chatMessageCounter = 0

  /**
   * An array containing all chat messages that should be available in the chat view.
   */
  chatMessages = []

  /**
   * The current user object and all relevant data
   */
  currentUser = {
    fastspringData: {
      account: {},
      subscription: {},
    },
  }

  /**
   * The current user id.
   */
  currentUserId = null

  /**
   * The current user email. Required by API call 'register_active_user'.
   */
  currentUserEmail = null

  /**
   * Object containing information about the current user.
   */
  user = null

  /**
   * Array containing objects with information of the friends of the current
   * user.
   */
  friendsList = []

  /**
   * Map from user id (of type number) to boolean indicating whether
   * the user is available or not.
   */
  userAvailable = new Map()

  /**
   * Map from user id (of type number) to boolean indicating whether
   * the user is active or not.
   */
  userActive = new Map()

  /**
   * Flag that indicates if code/email verification was validated
   */
  userConfirmed = null
  /**
   * Session object containing information about the current session (see
   * session.js).
   */
  session = null

  /**
   * Map from user id to user data.
   */
  users = new Map()

  /**
   * Set of user ids for which user data has been fetched.
   */
  fetchedUsers = new Set()

  /**
   * Map from session id to session object reference.
   */
  sessions = new Map()

  /**
   * Object containing information about an ongoing software update for the ELK
   * Bridge.
   */
  swUpdate = {
    state: UPDATE_STATE_AVAILABLE,
    info: '',
    update_percent: 0,
  }

  /**
   * Array containing information about the ELK Bridges on the network.
   */
  devices = []

  /**
   * Array containing TURN servers.
   */
  turnServers = []
  selectedTurnServerTitle = AUTOMATIC_LABEL
  selectedTurnServerIp = ''

  /**
   * Array containing all toasts.
   */
  toasts = []

  /**
   * Array containing all snack bars that are currently displayed.
   */
  snackbars = []

  /**
   * Boolean indicating whether the initial attempt to restore authentication is pending.
   */
  restoreAuthenticationPending = true

  /**
    * Boolean indicating if the user have dismissed the privatestudio banner.
    */
  privatestudioBannerDismissed = false

  /**
    * Environment variables to be accessible from the application
    */
  websiteUrl = "https://elk.live"
  env = {}

  /**
   * Video sync
   * Enabled - is video sync enabled through settings?
   */
  videoSync = {
    enabled: false,
  }

  /**
   * Data related to subscriptions that are used within the app, e.g. plan prices
   */
  fastSpringSubscriptionData = {}

  /**
   * Boolean indicating if there is an active wireless connection (desktop only), returns true if a WiFI connection is detected
   */
  isWirelessConnection = false

  /**
   * Boolean indicating if there is an active internet connection (desktop/browser)
   */
  isOnline = true

  /**
   * Object with the results of the speed test
   */
  speedTestResults = {}

  /**
   * Boolean indicating if there is an speedtest currently running
   */
  isSpeedTestRunning = false

  analytics = null

  isDesktopAudioInitializing = false

  nikkeiConfig = {}

  systemState = STANDALONE_NO_AUDIO

  /**
   * Array of user's rooms
   */
  rooms = []

  //Instance of room API Client
  roomManager = null

  mixerVisible = true

  alohaSuspendedState = {}

  isLoadedFromNikkei = false

  loadingStatus = null

  constructor() {

    this._subscribeToAPIClientEvents()
    this.analytics = new Analytics(['Rudderstack'])

    this.paddle = initPaddle(this)

    makeAutoObservable(this)

    this.settings = new Settings()
    this.roomManager = new RoomManager({ store: this })

    this._settingsAtom = createAtom('settings')
    this.settings.onChanged = () => {
      this._settingsAtom.reportChanged()
    }

    this.restoreAuthentication()

    /* Remove this as soon as the board is able to handle reconnecting users */
    window.addEventListener(
      'beforeunload',
      async (e) => {
        e.preventDefault()
        if(this.boardConnectionState === BOARD_CONNECTION_STATE_CONNECTED){
          await this.closeConnections()
        }

      },
      false,
    )

    if(window.electronAPI && window.electronAPI.onCloseFromElectron){
      window.electronAPI.onCloseFromElectron(async () => {
        await this.closeConnections()
      })
    }

    if(window.electronAPI && window.electronAPI.onNikkeiConfigSet){
      window.electronAPI.onNikkeiConfigSet((event, config) => {
        this.nikkeiConfig = config
      })
    }

    if(window.electronAPI && window.electronAPI.onLoadedFromNikkei){
      window.electronAPI.onLoadedFromNikkei((event, isLoadedFromNikkei) => {
        logger.info(`--force-plugin-mode is ${isLoadedFromNikkei}`)
        this.isLoadedFromNikkei = isLoadedFromNikkei
      })
    }

    this.verifyAccount()

    this.initDesktopAudio()

    /* Auto-connect to board or display device selection dialog */
    this.autoConnect()

    this.fetchTurnServers()

    this.initEthernetConnectionCheck()
  }

  async closeConnections(){
    if(this.boardConnectionState === BOARD_CONNECTION_STATE_CONNECTED){
      await this.uninitializeAPIClientConnections()
      await this.disconnectFromBoard()
    }
  }

  async checkDesktopEthernetConnection(){
    if(isDesktop()){
      this.isWirelessConnection = await isWirelessConnection()
    }
  }

  async initEthernetConnectionCheck(){
      await this.checkDesktopEthernetConnection()

      window.addEventListener('offline', async (e) => {
        this.isOnline = false
        showOfflineError({ store: this })
        await this.checkDesktopEthernetConnection()
      });

      window.addEventListener('online', async (e) => {
        await this.checkDesktopEthernetConnection()
        this.isOnline = true
      });

      setInterval(async () => { await this.checkDesktopEthernetConnection()}, 5000)
  }

  async autoConnect() {

    while (!this.currentUserId) {
      await sleep(250)
    }

    if(this.isMaintenanceModeOn()) return

    let devices = await this.queryDevicesOnNetwork()

    while (devices.length === 0) {
      await sleep(3000)
      devices = await this.queryDevicesOnNetwork()
    }

    const hardwareBridges = devices.filter(({ hwType }) => !DESKTOP_HW_TYPES.includes(hwType))

    const desktopUpdateAvailable = await this.checkDesktopUpdates()

    if(desktopUpdateAvailable) {
      showUpdatesAvailableDialog({ store: this, device: { hwType: 'desktop-mac'} })
      return
    }

    if(hardwareBridges.length === 0) return // disabling hardware auto-connect if there are no hardware bridges in the network

    if(isDesktop()) return

    let wasUnavailable = false

    while (hardwareBridges[0].status !== 'available' && this.boardConnectionState !== BOARD_CONNECTION_STATE_CONNECTED) {
      wasUnavailable = true
      logger.info("Found one device to auto-connect to but it was unavailable. Re-checking availability.")
      await sleep(2000)
      try {
        await hardwareBridges[0].checkAvailability()
      } catch (err) {
      }
    }

    if (wasUnavailable) {
      await sleep(10000)
    }

    /* This check makes sure that the user has not started connecting to the board manually */
    if (this.boardConnectionState !== BOARD_CONNECTION_STATE_UNINITIALIZED) {
      return
    }

    try {
      await this.connectToBoard(hardwareBridges[0].ipAddress)
    } catch (err) {
      logger.error('Auto-connection procedure failed, error: ', err)
      showDeviceInfoDialog({ store: this, device: devices[0] })
    }

  }

  async waitUntilDevicesAreReady() {
    while (!this.mixer || this.devices.length === 0 || this.serverStartupData === {}) {
      await sleep(250)
    }
  }

  /* Initializing desktop rpc client, setting audio devices (starting sushi) if there is already selected devices in localStorage */
  async initDesktopAudio() {
    if(!isDesktop()) return
    await this.waitUntilDevicesAreReady()

    logger.info('Initializing desktop audio')

    const desktopUpdateAvailable = await this.checkDesktopUpdates()

    if(desktopUpdateAvailable){ return } //if there is an update available stop init process

    //listening to media device changes
    navigator.mediaDevices.ondevicechange = async(event) => {
      if(event.timeStamp - previousDeviceChangeTimetamp > 100){
        previousDeviceChangeTimetamp = event.timeStamp
        await this.mixer.updateDevicesList()
      }
    };

    APIClient.init_desktop_rpc_client(
      DESKTOP_IP,
      this.nikkeiConfig?.jrpc_port || DEFAULT_BOARD_BACKEND_PORT
    )

    await this.mixer.getAudioDevices()
    await this.connectToDesktopBridge()
    const audioSettings = this.mixer.getStoredDesktopOptions()
    if(audioSettings?.input && audioSettings?.output){
      const input = this.mixer.desktop.availableDevices.find(({ uid, inputs }) => uid === audioSettings.input.uid && inputs > 0 )
      const output = this.mixer.desktop.availableDevices.find(({ uid, outputs }) => uid === audioSettings.output.uid && outputs > 0)

      if(this.isLoadedFromNikkei) return

      if(input && output && !this.board.recoveringConnection){
        logger.info('Loading previously stored audio preferences')
        this.mixer.desktop.selectedInput = input.uid
        this.mixer.desktop.selectedOutput = output.uid
        await this.mixer.setAudioDevices()

      }
    }
  }

  async checkDesktopUpdates(){
    if(isElectronAPIAvailable()){
      const desktopVersion = await getDesktopVersion()

      if(desktopVersion){
        return this.isDesktopUpdateAvailable(desktopVersion)
      }
    }
  }

  async downloadDesktopUpdate() {
    try {
      logger.info('Downloading desktop update')
      this.swUpdate.state = UPDATE_STATE_IN_PROGRESS
      const response = await axios({
        url: this.serverStartupData?.elkDesktopDownloadLink,
        method: "GET",
        responseType: "blob",
        onDownloadProgress: (progressEvent) => {
          this.swUpdate.update_percent = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        },
      })
      downloadFileFromBlob(response.data)
      this.swUpdate.state = UPDATE_STATE_SUCCESS
      logger.info('Desktop update download successfully')
    } catch(error) {
      logger.error('Error trying to download the desktop update', { error })
      this.swUpdate.state = UPDATE_STATE_ANOMALY
    }
  }

  async restoreAuthentication() {
    try {
      const cognitoSession = await Auth.currentSession()
      await this.AWSLogin(cognitoSession)
      logger.info('Successfully restored authentication')
    } catch (error) {
      logger.info('Failed to restore authentication', { error })
    }
    this.restoreAuthenticationPending = false
  }

  _subscribeToAPIClientEvents() {
    APIClient.on_mixer_message = (params) => this.mixer.onMixerMessage(params)

    APIClient.on_board_notification = (msg) => {
      logger.info(`[BOARD NOTIFICATION]: ${JSON.stringify(msg)}`)

      if(Object.keys(TypesHandlers).includes(msg.type)){
        TypesHandlers[msg.type](this, msg)
      }

      if(Object.keys(NotificationsHandler).includes(msg.notification)){
        NotificationsHandler[msg.notification](this, msg)
      }
    }

    APIClient.on_statistics_message = (params) => {
      logger.info(
        `[STATS]: ${params.type} ${
          params.track ? ': Track' + params.track : ''
        }:`,
        params.value,
      )
    }
    APIClient.on_cloud_event = async (event) => {
      // TODO: Clean up and sort out the different eventlistners from store and API Client
      try {
        await APIClient._process_cloud_event(event)
      } catch (err) {
        this.showSnackBar({
          heading: "Sorry... Something went wrong!",
          content: "We are experiencing server issues. Please contact support",
          level: 'error',
          duration: 12000,
        })
      }
      this._handleCloudEvent(event)
    }
    APIClient.on_friends_list_change = ({ friends_list }) => {
      const list = []
      for (let obj of friends_list) {
        const userId = parseInt(Object.keys(obj)[0])
        const value = Object.values(obj)[0]
        let dataToReturn
        if(typeof value === 'string'){
          dataToReturn = { status: value }
        } else {
          const { status, initial_message, profile_image_url, display_name } = Object.values(obj)[0]
          dataToReturn = {  status, initialMessage: initial_message, profileImageUrl: profile_image_url, displayName: display_name }
        }
        this.requestFetchUserInfo(userId)
        list.push({ id: userId, ...dataToReturn })
      }
      this.friendsList = list
    }

    APIClient.on_users_available_list_change = ({ users_available_list }) => {
      const map = new Map()
      users_available_list.forEach((id) => {
        map.set(id, true)
      })
      this.userAvailable = map
    }

    APIClient.on_users_active_list_change = ({ users_active_list }) => {
      const map = new Map()
      users_active_list.forEach((id) => {
        map.set(id, true)
      })
      this.userActive = map
    }

    // TODO: remove on_ice_message
    /* this is the callback for ICE messages coming from the connected Aloha board.
      It handles the messages of type "status", which are used to display a track connection status,
      and forwards the other types to their target users.

      Other types are: "offer", "response" and "failure".
     */
    APIClient.on_ice_message = (path, value) => {
      let msg_data = path.split('/')
      let ice_request = {
        type: msg_data[2],
        fromId: msg_data[3],
        toId: msg_data[4],
        sessId: msg_data[5],
      }
      if (ice_request.type === 'status') {
        logger.info(`ICE status with user ${ice_request.toId}: ${value}.`)
        this.session.handleICEStatusMessage(ice_request, value)
      } else {
        logger.info(
          `Sending ICE ${ice_request.type} to user ${ice_request.toId}`,
        )
        let ice_message = { ...ice_request, value }
        let dest_ids = []
        dest_ids.push(ice_message.toId)
        APIClient.post_message_to_users(dest_ids, ice_message)
      }
    }
  }

  initializeAPIClientConnections() {
    logger.info(
      'Connecting to server at : ' +
        getCloudServerUrl() +
        ':' +
        getCloudServerPort() +
        ', secure = ' +
        getCloudServerSecure(),
    )
    APIClient.init_cloud_socket_client(
      getCloudServerUrl(),
      getCloudServerPort(),
      getCloudServerSecure(),
    )
    APIClient.init_devices_rpc_client(getDevicesApiUrl(), 443, true)
    APIClient.init_cloud_rpc_client(
      getCloudServerUrl(),
      getCloudServerPort(),
      getCloudServerSecure(),
      APIClient._cloud_socket_client,
    )
  }
  uninitializeAPIClientConnections() {
    APIClient._cloud_socket_client?._socket?.disconnect()
    APIClient._cloud_socket_client = null
    APIClient._cloud_rpc_client = null
    APIClient._devices_rpc_client = null
  }

  async fetchTurnServers() {
    const response = await fetch('https://turn.elk.live/api/jsonrpc/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: '{"method": "get_turn_servers", "params": {}, "jsonrpc": "2.0", "id": 0}'
    })
    const json = await response.json()
    this.turnServers = json.result.servers
  }

  async startSoftwareUpdate() {
    if (this.boardConnectionState !== BOARD_CONNECTION_STATE_CONNECTED) {
      throw new Error("Can't perform update if board is not connected")
    }
    this.swUpdate.state = UPDATE_STATE_IN_PROGRESS
    const config = await APIClient.get_board_configuration()
    const boardType = config.board_audio_hat
    if (!boardType) {
      throw new Error('Unable to determine board type')
    }
    const swuLink = this.serverStartupData?.elkSWUDownloadLinks?.[boardType]
    if (!swuLink) {
      throw new Error("Can't find SWU for board type")
    }
    await APIClient.start_software_update(swuLink)
  }

  async requestFetchUserInfo(userId, force = false) {
    if (!this.fetchedUsers.has(userId) || force) {
      this.fetchedUsers.add(userId)
      const userData = await APIClient.get_userdata_by_id(userId)
      if (userData) {
        this.userAvailable.set(userId, userData.available)
        this.users.set(userId, {
          id: userId,
          ...userData,
        })
      }
    }
  }
    get soundcheckMode() {
    return this.activeViewState === ACTIVE_VIEW_STATE_MIXER && this.showBridgeSettingsView
  }

  /**
   * Boolean indicating whether the current user is currently in a session with
   * other users.
   */
  get sessionMode() {
    return this.boardConnectionState === BOARD_CONNECTION_STATE_CONNECTED && typeof this.session?.id === 'number'
  }

  get settingsSnapshot() {
    this._settingsAtom.reportObserved()
    return this.settings.snapshot
  }

  get manualBoard() {
    this._settingsAtom.reportObserved()
    if (this.settings.connection.boardIpAddress) {
      return new BoardInfo(this, {
        ipAddress: this.settings.connection.boardIpAddress,
        uuid: 'manual',
        name: 'manual-box',
      })
    }
    return null
  }

  get sessionChatMessages() {
    return this.chatMessages.filter((message) => message.sessionPrivate)
  }

  async verifyAccount() {
    const url = new URL(window.location.href)
    const code = url.searchParams.get('code')
    const state = url.searchParams.get('state')
    const email = url.searchParams.get('email')

    if(code && state) { //oauth flow
      await this.OAuthSignIn(window.location.href)
    }

    if (code && email) { // verification flow
      try {
        await Auth.confirmSignUp(email, code)
        this.userConfirmed = true
        this.currentUserEmail = email
        this.analytics.track('User Email Verified', { $email: email })
      } catch (error) {
        this.showSnackBar({
          heading: "Verification failed.",
          content: "Please contact support",
          level: 'error',
          duration: 12000,
        })
      }
    }
  }

  isMaintenanceModeOn() {
    return process.env.REACT_APP_MAINTENANCE_MODE === 'true'
  }

  isMaintenanceScheduled() {
    return process.env.REACT_APP_MAINTENANCE_SCHEDULED !== 'false'
  }

  isConnectedToDesktop(){
    return this.board?.ipAddress === DESKTOP_IP
  }

  async AWSLogin(cognitoSession, skipAlreadyLogin = false) {
    // verify user against backend - which has jwks token for verification of user claims
    // this could fail - ie if blocked on the backend or subscription is out of date
    this.initializeAPIClientConnections()
    this.checkServerStartupData()
    if(isBetaRegistrationRoutes()) return
    try {
      const data = await APIClient.verify_cognito_tokens(
        cognitoSession,
      )
      const { id, email, access_token, refresh_token_validity_in_seconds } = data
      const userData = await Auth.currentUserInfo()
      this.userHasActiveSubscription = userData?.attributes?.['custom:active_subscription'] === 'true'

      this.currentAnalyticsId = userData?.attributes?.['custom:analytics_id'] || id

      if(this.isMaintenanceModeOn()){
        showMaintenanceModeDialog({ store: this })
      }

      /**
       * Here we check the expiry date of the refresh token and if it expires in the
       * near future we destroy this session and send the user to the login prompt
       */
      if (refresh_token_validity_in_seconds) {
        try {
          const userData = localStorage.getItem('user')
          const parsed = JSON.parse(userData)
          const milliSecondsPerSecond = 1000
          this.refreshTokenExpiresAt = parsed.timestamp + refresh_token_validity_in_seconds * milliSecondsPerSecond
          const logoutUserAutomaticallyAt = Math.round(parsed.timestamp + refresh_token_validity_in_seconds * LOGOUT_USER_AFTER_REFRESH_TOKEN_HAS_REACHED * milliSecondsPerSecond)
          if (Date.now() >= logoutUserAutomaticallyAt) {
            await this.AWSLogout()
            return
          }
        } catch (err) {
          // ignore error
        }
      }

      APIClient._cloud_rpc_client.set_session_access_token(access_token)
      const isLoggedIn = await APIClient.get_is_logged_in(id)
      const proceedWithLogin = async () => {
        this.currentUserEmail = email
        this.currentUserId = id
        await APIClient.logout_everywhere(id)
        APIClient._cloud_socket_client.set_session_access_token(
          access_token,
        )
        APIClient._user_id = id
        await this.requestFetchUserInfo(id)
        const userData  = this.users.get(id)
        this.analytics.identify({ ...userData, email: this.currentUserEmail }, this.currentAnalyticsId)
        await this.settings.loadFromCloud()
        /**
         * This function is called to make sure that the user has no old
         * phantom power setting saved in the cloud from a time when the
         * phantom power setting used to persist there between logins
         */
        await this.settings.resetPhantomPowerIfEnabled(id)
        this.mixer = new Mixer(this)
        await this.roomManager.getUserRooms()
        await this.roomManager.cleanRoomStatus();
        this.startPrivateSession()
        await APIClient.get_product_data(id)
        if(this.userHasActiveSubscription){
          await APIClient.get_subscription_data(this.currentUserId)
        }
      }
      if (isLoggedIn && !skipAlreadyLogin) {
        showAlreadyLoggedInDialog({ store: this, onConfirm: proceedWithLogin })
      } else {
        this.currentUserEmail = email
        this.currentUserId = id
        proceedWithLogin()
      }
      return true
    } catch (err) {
      logger.error(err)
      this.AWSLogout()
      return false
    }
  }

  async AWSGoogleSignIn(){
    Auth.federatedSignIn({ provider : CognitoHostedUIIdentityProvider.Google })
  }

  async OAuthSignIn(url) {
    try {
      const response = await Auth._oAuthHandler._handleCodeFlow(url)

      const AccessToken = new AmazonCognitoIdentity.CognitoAccessToken({
        AccessToken: response.accessToken,
      })

      const IdToken = new AmazonCognitoIdentity.CognitoIdToken({
        IdToken: response.idToken,
      })

      const RefreshToken = new  AmazonCognitoIdentity.CognitoRefreshToken({
        RefreshToken: response.refreshToken,
      })

      const session = new AmazonCognitoIdentity.CognitoUserSession(
        { IdToken, AccessToken, RefreshToken }
      );

      const userPool = new AmazonCognitoIdentity.CognitoUserPool({
          UserPoolId: getCognitoUserPoolID(),
          ClientId: getCognitoAppClientID(),
        })

      const cognitoUser = new AmazonCognitoIdentity.CognitoUser(
        {
          Username: AccessToken.payload.username,
          Pool: userPool,
        }
      )

      cognitoUser.setSignInUserSession(session)
      if(!isBetaRegistrationRoutes()){
        const cognitoSession = await Auth.currentSession()
        await this.AWSLogin(cognitoSession, true)
      }
    } catch (error){
      logger.error(`Error while OAuth Sign in for URL: ${url}`, error)
    }
  }

  async AWSLogout() {
    logger.info('Logging out and performing page refresh')
    if (this.currentUserId) {
      await APIClient.logout(this.currentUserId)
    }
    await Auth.signOut()
    await this.disconnectFromBoard(true)
    await this.uninitializeAPIClientConnections()
    window.location.reload()

  }

  async registerUser({ name, email, password }) {
    return APIClient.register_user_with_backend({
      nickname: name,
      email,
      password,
    })
  }

  /*** Board Connection ****/

  async queryDevicesOnNetwork(skipDesktop = false) {
    try {
      const devices = await APIClient.query_devices_on_network()
      const devicesToShow = isDesktop() && !skipDesktop ? await this.addDesktopBridgeToDeviceList(devices) : devices

      this.devices = devicesToShow.map(
        (device) => new BoardInfo(this, { // TODO: BoardInfo -> DeviceInfo?
            ipAddress: device.ip_address,
            uuid: device.device_uuid,
            name: device.device_name,
            lastSeen: device.updated_at,
            hwType: device.hw_type,
            version: device.version,
          })
      )
      if (this.manualBoard) {
        this.devices.push(this.manualBoard)
      }
      await Promise.all(
        this.devices.map(async (device) => await device.checkAvailability()),
      )
    } catch (err) {
      // Ignore errors
    }
    return this.devices
  }

  /**
   * Returns the device list including the desktop bridge on top.
   */
  async addDesktopBridgeToDeviceList(devices) {

    logger.info('Adding desktop bridge to devices list')
    const hostname = await getOsHostname()
    devices = devices.filter(({ hw_type }) => !DESKTOP_HW_TYPES.includes(hw_type))

    devices.unshift({
      ip_address: DESKTOP_IP,
      device_uuid: DESKTOP_HW_TYPES[0],
      device_name: hostname.replace('.local', ''),
      updated_at: new Date().toISOString(),
      hw_type: DESKTOP_HW_TYPES[0]
    })

    return devices
  }

  async connectToBoard(ipAddress, reconnectToSession = false, reconnecting = false) {
    this.mixer.master.volume = 0
    this.mixer.panicMode = false
    if (this.boardConnectionState === BOARD_CONNECTION_STATE_CONNECTED) {
      throw new Error(
        "Can't call connectToBoard when board is already connected",
      )
    }
    if (!this.currentUserId) {
      throw new Error(
        `Can't call connectToBoard when the current user id is ${this.currentUserId}`,
      )
    }
    this.boardConnectionState = reconnecting ? BOARD_CONNECTION_STATE_RECONNECTING : BOARD_CONNECTION_STATE_CONNECTING
    const board = new Board(this, ipAddress)
    this.board = board
    const portOverride = this.settings.connection.udpPortOverride ?? null
    const bypassPortForwardingTest =
      this.settings.connection.bypassPortForwardingTest
    const advertisedIpOverride = this.settings.connection.advertisedIpOverride
    const hwInit = this.settings.hw_settings
    try {
      await board.connect({
        userName: this.currentUser.displayName,
        email: this.currentUserEmail,
        hwInit: hwInit,
        portOverride,
        bypassPortForwardingTest,
        advertisedIpOverride,
      })

      this.analytics.track('Connected to board', {
        ['Connection Type']: this.isConnectedToDesktop() ? 'Desktop' : 'Bridge',
        ['IP Address']: ipAddress,
      })

      this.boardConnectionState = BOARD_CONNECTION_STATE_CONNECTED
      const device = this.devices.find(
        (device) => device.ipAddress === this.board.ipAddress,
      )

      device.unableToIdentify = false

      if(board.isDesktopDevice) {
        APIClient.init_desktop_rpc_client(ipAddress)
      }

      if (
        device?.hasCustomCoreOverride &&
        !this.settings.connection.allowCustomCore
      ) {
        await device.resetAlohaCoreOverrideUrl()
        showCoreWasResetDialog({ store: this })
        this.disconnectFromBoard()
        return
      }
      const isUpdateAvailable = await device?.isUpdateAvailable()
      if (isUpdateAvailable) {
        showUpdatesAvailableDialog({ store: this, device: device })
      }
      if (!reconnectToSession) {
        logger.info(
          'Calling leave session as a security measure against getting stuck in a session',
        )
        await APIClient.leave_session(this.currentUserId, SESSION_EVENT_TRIGGER_TYPE_AUTOMATIC)
      }
    } catch (err) {
      logger.error(err)
      this.boardConnectionState = BOARD_CONNECTION_STATE_FAILED

      this.analytics.track('Connection to board failed', {
        ['Connection Type']: this.isConnectedToDesktop() ? 'Desktop' : 'Bridge',
        ['IP Address']: ipAddress,
      })

      /**
       * This error code means that someone is already connected to the board.
       */
      if (err.code === ClientConnectedError) {
        this.boardConnectionState = BOARD_CONNECTION_STATE_ALREADY_IN_USE
        /**
         * Just rethrow error without showing snack bar
         */
        throw err
      } else if (err.code === 'NAT_TEST_ERROR') {
        showNatErrorDialog({
          store: this,
          boardInfo: new BoardInfo(this, { ipAddress: ipAddress }),
          error: err,
        })
      } else if (err.code === 'GEOLOCATION_FETCH_ERROR') {
        this.showSnackBar({
          heading: "Sorry... Something went wrong!",
          content: "We are experiencing server issues. Please contact support",
          level: 'error',
          duration: 8000,
          moreInfo: {
            link: 'https://elkaudio.zendesk.com/hc/en-us/articles/360016460298',
          },
        })
      }
      /**
       * Error is rethrown to let the caller know if connection
       * was successful or if it failed.
       */
      throw err
    }
  }

  async connectToDesktopBridge() {
    if (this.boardConnectionState !== BOARD_CONNECTION_STATE_CONNECTED) {
      try {
        await this.connectToBoard(DESKTOP_IP)
      } catch (error) {
        // if the bridge is 'already in use' we do a takeover
        if (this.boardConnectionState === BOARD_CONNECTION_STATE_ALREADY_IN_USE) {
          await this.connectToBoard(DESKTOP_IP)
        }
      }
    }
  }

  showDesktopLoading(){
    if(this.boardConnectionState !== BOARD_CONNECTION_STATE_UNINITIALIZED) {
      this.activeViewState = ACTIVE_VIEW_STATE_MIXER
      this.showBridgeSettingsView = true
    }
    this.isDesktopAudioInitializing = true
    showLoadingDesktopDialog({ store: this })

  }

  isDesktopUpdateAvailable(desktopVersion) {
    // This is to fix the hardcoded 2.0.0 that was released.
    const overwrittenDesktopVersion = desktopVersion === '2.0.0' ? '1.0.0' : desktopVersion

    if(desktopVersion !== this.serverStartupData.requiredDesktopVersion ){
      return compareVersions.compare(
        this.serverStartupData.requiredDesktopVersion,
        overwrittenDesktopVersion,
        '>',
      )
    }
    return false
  }

  async performTakeover(ipAddress) {
    const board_client = RPCClient(
      board_ip_to_url(ipAddress),
      APIClient._default_board_backend_port,
      ipAddress !== DESKTOP_IP)
    await board_client.invoke('reset_backend', {
      master_password: 'a6jHTd5rRcy9KhPn'
    })
    /* We need to sleep here since the board is not ready even though it responded with OK */
    await sleep(700)
    try {
      await this.connectToBoard(ipAddress)
    } catch (err) {
      await sleep(700)
      await this.connectToBoard(ipAddress)
    }
  }

  async disconnectFromBoard() {
    if (this.boardConnectionState !== BOARD_CONNECTION_STATE_CONNECTED) {
      return
    }

    this.boardConnectionState = BOARD_CONNECTION_STATE_DISCONNECTING

    if (this.session.partnerIds.length > 0) {
      try {
        await this.leaveSession()
      } catch (err) {
        logger.info('Tried to leave session in store.disconnectFromBoard() but got error')
        logger.error(err)
      }
    }

    APIClient.uninitialize_board_clients()

    /**
     * Since the board might not reply we start with checking availability
     * with a timeout. If this succeeds we do a "clean" logout, otherwise
     * we just mark ourselves as disconnected.
     */
    try {
      if(!this.isConnectedToDesktop())
        await APIClient.check_board_availability(this.board.ipAddress, !this.isConnectedToDesktop())

      await APIClient.logout_board()
      logger.info('Disconnected from board')
    } catch (err) {
      logger.error('Tried to disconnect from board but failed, ignoring error', err)
    }

    // let the cloud server know the user is no longer active (connected to the board)
    try {
      await APIClient.unregister_active_user(this.currentUserId)
    } catch (err) {
      logger.info('Tried to unregister active user in disconnectFromBoard() but got error')
      logger.error(err)
    }

    this.board = null

    /* TODO: The bridge currently responds to the disconnect request before it is
     * ready to recieve a new connection, to make sure that the board is properly disconnected
     * when this function returns we add a sleep here */
    await sleep(700)
    this.boardConnectionState = BOARD_CONNECTION_STATE_DISCONNECTED

  }

  /*** Event Handling ***/

  async handleBridgeConnectionLost() {

    const device = this.devices.find(({ ipAddress }) => ipAddress === this.board.ipAddress)

    if(this.isConnectedToDesktop()) {
      this.mixer.showDesktopGenericError({ reload: true })
    } else {
      showDeviceInfoDialog({ store: this, device })
    }
    const ip = this.board.ipAddress
    logger.info(`Disconnecting...`)
    await this.disconnectFromBoard()
    logger.info(`Disconneced!`)
    if(ip === DESKTOP_IP) return
    let attempt = 1
    let timeout = 1000
    await sleep(timeout)
    const run = async () => {
      logger.info(`Trying to restore bridge connection, attempt ${attempt}, timeout ${timeout} ms`)
      if (this.boardConnectionState === BOARD_CONNECTION_STATE_CONNECTED) {
        return
      }
      try {
        await this.connectToBoard(ip, false, true)
      } catch (err) {

      }
      if (this.boardConnectionState === BOARD_CONNECTION_STATE_ALREADY_IN_USE) {
        const boardClient = RPCClient(board_ip_to_url(ip), APIClient._default_board_backend_port, ip !== DESKTOP_IP)
        await boardClient.invoke('reset_backend', {
          master_password: 'a6jHTd5rRcy9KhPn'
        })
        /* We need to sleep here since the board is not ready even though it responded with OK */
        await sleep(1000)
        await this.connectToBoard(ip, false, true)
      }
      if (this.boardConnectionState === BOARD_CONNECTION_STATE_CONNECTED) {
        return
      }
      timeout = timeout * 2
      attempt = attempt + 1
      setTimeout(run, timeout)
    }
    run()
  }

  /**
   * Runs the periodic backend polling loop and dispatches events
   * that can be used by other parts of the app.
   */
  startEventLoop() {
    logger.info('Starting board and cloud polling loops')
    let failureCount = 0
    let showError = false

    this._boardPollTimer = setInterval(async () => {
      if (APIClient._cloud_debug_mode) return
      if (this.boardConnectionState === BOARD_CONNECTION_STATE_CONNECTED) {
        if (this.boardLastSeen) {
          const msSinceLastSeen = Date.now() - this.boardLastSeen
          if (msSinceLastSeen >= 12000) {
            this.handleBridgeConnectionLost()
          }
        }
        if (this.isConnectedToDesktop()) return
        try {
          const boardEvent = await APIClient.poll_board_backend()
          if (boardEvent && Object.keys(boardEvent).length > 0) {
            logger.info('[STORE] event loop poll board event: ', {
              boardEvent: boardEvent,
            })
            this._handleBoardEvent(boardEvent)
          }
        } catch (error) {
          if (failureCount < 5) {
            failureCount++
            logger.warn(`Failed to poll bridge ${failureCount} times`)
          } else {
            if(isDesktop() && !showError){
              this.mixer.showDesktopGenericError({ reload: true })
              showError = true
            }
          }
        }
      }
    }, 1000)

    this._cloudPollTimer = setInterval(async () => {
      if (this.currentUserId) {
        if (this.refreshTokenExpiresAt && this.refreshTokenExpiresAt <= Date.now()) {
          /*
           * This should never happen in practice if the refresh token expiry
           * time is long enough since the user would get logged out when
           * loading the page instead of getting this message, see
           * store.AWSLogin
           */
          if (this.sessionMode) {
            this.showSnackBar({
              heading: 'Your login session has expired',
              content: "Please log out and then in again to continue to use Elk LIVE",
              level: 'error',
              duration: 120000,
            })
          } else {
            this.showSnackBar({
              heading: 'Your login session has expired',
              content: "Please log out and then in again to continue to use Elk LIVE",
              level: 'error',
              duration: 120000,
            })
            setTimeout(() => {
              this.AWSLogout()
            }, 10000)
          }
        }
        try {
          await APIClient.poll_cloud_backend(this.currentUserId, this.boardConnectionState === BOARD_CONNECTION_STATE_CONNECTED)
        } catch (err) {
          logger.error('Got error in cloud poll timer', err)
          logger.error(JSON.stringify(err))
          if (err.code === 'NotAuthorizedException') {
            this.showSnackBar({
              heading: 'This login session has expired',
              content: "Please log out and then in again to continue to use Elk LIVE",
              level: 'error',
              duration: 120000,
            })
          }
        }
      }
    }, 2000)
  }

  endEventLoop() {
    clearInterval(this._boardPollTimer)
    clearInterval(this._cloudPollTimer)
  }

  async _handleCloudEvent(event) {
    if(event.add_user_to_session_notification){
      this._handleAddUserToNotificationEvent(event.add_user_to_session_notification)
    }

    if (event.request_logout_notification) {
      APIClient?._cloud_socket_client?._socket?.close?.()
      window.location.replace('/logged-out.html')
      return
    }

    if (event.user_update_notification) {
      const userId = event.user_update_notification.user_id

      if (event.user_update_notification['custom:active_subscription'] && userId === this.currentUserId) {
        const value = event.user_update_notification['custom:active_subscription']
        this.userHasActiveSubscription = JSON.parse(value)
      }
      if (event.user_update_notification.hw_settings) {
        const { stereo_link, input_options } = event.user_update_notification.hw_settings
        this.mixer.pendingPartnerTracks[userId] = {
          stereoLinked: stereo_link,
          inputs: input_options
        }
      }
      if (event.user_update_notification.display_name) {
        const friend = this.users.get(userId)
        if (friend) {
          friend.display_name = event.user_update_notification.display_name
          this.users.set(userId, friend)
        }
      }
      if (event.user_update_notification.profile_image_url) {
        const friend = this.users.get(userId)
        if (friend) {
          friend.profile_image_url =
            event.user_update_notification.profile_image_url
          this.users.set(userId, friend)
        }
      }
    }

    if (event.session_closed_notification) {
      const sessionId = event.session_closed_notification.session_id
      /**
       * Leave current session automatically
       */
      if (this.session?.id === sessionId) {
        this.leaveSession()
      }
      /* Close associated session dialog */
      this.sessions.forEach((session) => {
        if (session.id === sessionId) {
          session.dialog?.close()
        }
      })
    }

    if (event.leave_session_notification) {

      const userId = event.leave_session_notification.user_id
      const sessionId = event.leave_session_notification.session_id

      if(userId === this.currentUserId) {
        return
      }

      const session = this.sessions.get(sessionId)
      if (!session) {
        logger.error(
          `Got leave_session_notification for unknown session with id ${sessionId}`,
          )
      } else {
        session.partnerState.set(userId, SESSION_PARTNER_STATE_LEFT)
      }

      if (this.session && this.session.partnerIds.length && this.session.userWillBeAloneForever()) {
        await this.leaveSession()
      }

      APIClient.board_leave_session(userId, sessionId)

      this.session.removePartnerFromSession(userId)

    }

    if (event.new_group_chat_message) {
      const userId = event.new_group_chat_message.user_id
      const message = event.new_group_chat_message.message
      const sessionPrivate = event.new_group_chat_message.session_private || false
      this.chatMessages.push({
        message,
        userId,
        sessionPrivate,
        time: new Date(),
      })

      if (!this.showChatView) {
        this.chatMessageCounter += 1
      } else {
        this.chatMessageCounter = 0
      }
    }

    if (event.video_chat_data) {
      this.session.setJoinData(event.video_chat_data)
    }

    if (event.new_message) {
      try {
        const eventData = JSON.parse(event.new_message);
        if (eventData.message?.type === 'video-sync-update') {
          const { active, delay } = eventData.message.data;
          this.session.videoSync.active = active;
          this.session.videoSync.delay = delay;
          APIClient.video_sync(active, delay);
          logger.debug('[Handle cloud event: "video-sync-update"] video_sync invoked on board')
        }
      } catch (error) {
        logger.error('Unable to handle inbound "new_message" event', error);
      }
    }

    if (event.user_subscription_cancelled_notification) {
      logger.debug('User Subscription Cancelled received')
      this.settings.subscription.canceledAt = null
      this.settings.save()
    }

    if (event.user_subscription_changed_notification) {
      logger.debug('User Subscription Changed received');
    }

    if (event.user_subscription_management_url_notification) {
      logger.debug('User Subscription Management URL received')
    }

    /**
     * Room events
     */

    if(event.room_update_notification){
      await this.roomManager.handleRoomUpdateNotification(event.room_update_notification)
    }

    if(event.request_room_access){
      await this.roomManager.handleRequestRoomAccess(event.request_room_access)
    }

    if(event.room_access_request_denied){
      this.roomManager.handleRoomAccessDeclined()
    }

    if(event.removed_from_room_notification) {
      const removedRoom = event.removed_from_room_notification
      this.rooms = this.rooms.filter((room) => room.room_id !== removedRoom.room_id)
    }

    if(event.freemium_session_expired){
      await this.roomManager.handleFreemiumSessionExpired(event.freemium_session_expired)
    }

    //paddle prodcut details
    if (event.user_product_details_notification) {
      setProductDetails(event.user_product_details_notification, this)
    }

    if(event.user_subscription_details_notification){
      setSelectedPlan(event.user_subscription_details_notification, this)
    }

    if(event.update_billing_frequency_details_notification){
      await APIClient.get_subscription_data(this.currentUserId)
      this.loadingStatus = 'SUCCESS'
      setTimeout(() => {
        this.loadingStatus = 'COMPLETED'
      }, 1500)

    }

    //TODO: Uncomment this when acknoledge method is confirmed
    // await this.acknowledgeMessageIfNeeded(event)
  } // End of handleCloudEvents

  async _handleAddUserToNotificationEvent(data){
    this.session.addPartnerToSession(data.add_user_id)
  }

  async acknowledgeMessageIfNeeded(event) {
    if(event.ack_uuid) {
      APIClient.acknowledge_notification(event.ack_uuid)
    }
  }

  _handleBoardEvent(event) {
    if (Object.keys(event)[0] === 'update_status') {
      const message = event.update_status.info
      logger.info('[SWU] message: ' + message)
      this.swUpdate.info = message
      const progress = event.update_status.update_percent
      this.swUpdate.update_percent = progress
      if (message === 'SWUPDATE successful !') {
        this.swUpdate.state = UPDATE_STATE_SUCCESS
      }
      if (progress < 0) {
        this.swUpdate.state = UPDATE_STATE_ANOMALY
        /* Here we make sure to not go from UPDATE_STATE_ANOMALY to another state */
      } else if (
        progress < 100 &&
        this.swUpdate.state !== UPDATE_STATE_ANOMALY
      ) {
        this.swUpdate.state = UPDATE_STATE_IN_PROGRESS
      }
    }
  }

  /*** Session Handling ***/

  /**
   * Start a new private session. I.e. a session without other users.
   *
   * This function is used to create a session object to hold sound information
   * whenever the user is not in a session with other users.
   */
  async startPrivateSession() {
    if (!this.currentUserId) {
      logger.error('Trying to start private session no currentUserId is set')
      this.session = null
      return
    }
    this.session = new Session({
      store: this,
      id: null,
      partnerIds: [],
    })
  }

  /**
   * Create a session from a list of partner ids.
   *
   * This will send out invites to all the partner ids in the array and join
   * the session.
   */
  async createSession(partnerIds, sessionPayload = {}) {

    let sessionId

    try {
      sessionId = await APIClient.create_session(this.currentUserId, partnerIds, {
        ...sessionPayload,
        ...getPlatformData(),
        ...{
          connection_type: sessionPayload.turn_server ? 'Turn' : 'P2P',
          turn_server_title: this.selectedTurnServerTitle,
          is_bridge_used: !this.isConnectedToDesktop(),
          is_plugin_used: this.systemState.includes('P')
        }
      })
    } catch (err) {
      logger.error(err)
      this.showSnackBar({
        heading: "Sorry... Something went wrong!",
        content: 'We are experiencing server issues. Please contact support',
        level: 'error',
        duration: 8000,
      })
      this.startPrivateSession()
      return
    }

    const session = new Session({
      store: this,
      id: sessionId,
      initiatorId: this.currentUserId,
      partnerIds,
      payload: sessionPayload,
    })

    this.session = session
    this.sessions.set(sessionId, session)

    if(this.isConnectedToDesktop()) {
      if(await isWirelessConnection()) showWifiWarningDialog({ store: this})
    }

    this.startSession(session)
  }

  /**
   * Start a cloud created session from a list of partner ids .
   *
   */
  async startExistingSession(room, partnerIds) {

    const currentTurnServerIp = this.turnServers.find((server) => server.city === room.turn_server_title)

    const sessionObject = {
      store: this,
      id: room.session_id,
      initiatorId: this.currentUserId,
      partnerIds
    }

    if(currentTurnServerIp){
      logger.info(`Starting session with current turn server ${currentTurnServerIp.ip} ${currentTurnServerIp.city}`)
      sessionObject.payload = { turn_server: currentTurnServerIp.ip }
    }

    const session = new Session(sessionObject)
    this.session = session
    this.sessions.set(room.session_id, session)

    this.startSession(session)
  }

  /**
   * Add users to the current session (if any) from a list of partner ids.
   *
   * This will send out invites to all the partner ids in the array and join
   * the session.
   */
  async addPartnersToOngoingSession(partnerId) {
    if(!this.session.id) throw new Error("Can't add partners if there is not an on-going session")
    try {
      await APIClient.add_user_to_session(this.currentUserId, partnerId, this.session.id)
    } catch (err) {
      logger.error(err)
      this.showSnackBar({
        heading: "Sorry... Something went wrong!",
        content: 'We are experiencing server issues. Please contact support',
        level: 'error',
        duration: 8000,
      })
    }
  }

  /**
   * Accept a session that we have got an invite to join.
   */
  async acceptSession(sessionId) {
    await APIClient.reply_to_session_request(this.currentUserId, sessionId, true)
  }

  async triggerBoardSessionAccepted(partnerIds, sessionId) {
    APIClient.board_session_accepted(
      partnerIds, sessionId
    )
    const session = this.sessions.get(sessionId)
    partnerIds.forEach(userId => {
      session.partnerState.set(userId, SESSION_PARTNER_STATE_JOINED)
    })
  }

  /**
   * Start (i.e. join) a session.
   */
  async startSession(session) {
    const payload = {
      initiator_id: session.initiatorId,
      session_id: session.id,
      own_user_id: this.currentUserId,
      partner_ids: this.session.partnerIds.slice(),
      jitter_delays: this.session.partnerIds.map((partnerId) => this.session.jitterBufferMultiplier.get(partnerId)),
      sender_multiples: this.session.partnerIds.map((partnerId) => +this.settings.connection.senderBufferSize),
      test_signal_mode: this.settings.connection.testSignalMode,
      enable_soft_resync: this.settings.sync.enableSoftResync,
      auto_resync: this.settings.sync.enableAutoResync,
      auto_resync_threshold: this.settings.sync.autoResyncThreshold,
      auto_jitter: this.settings.sync.enableAutoJitter,
      auto_jitter_threshold: this.settings.sync.autoJitterThreshold,
      ecc_params: [
        this.settings.ecc.midFilterLength,
        this.settings.ecc.sideFilterLength,
        this.settings.ecc.crossFadeLength,
      ],
      session_init: {
        enable_usb: (() => {
          switch (this.mixer.usbOutput) {
            case USB_OUTPUT_FULL_MIX:
              return [true, true]
            case USB_OUTPUT_LOCAL_ONLY:
              return [true, false]
            case USB_OUTPUT_REMOTE_ONLY:
              return [false, true]
            case USB_OUTPUT_DISABLED:
              return [false, true]
            default:
              return [false, false]
          }
        })(),
        master_gain: [
          this.mixer.master.volume,
          this.mixer.master.volume,
        ],
        input_gain: this.mixer.inputGains,
        own_gain: this.mixer.tracks.own.volume,
        own_pan: this.mixer.tracks.own.pan,
        stereo_link: this.mixer.tracks.own.stereoLinked,
      },
      turn_settings: {
        force_turn: !!this.session.payload.turn_server,
        turn_server: [this.session.payload.turn_server, 'elk', 'PymIqyQJ5hD'],
      },
      ice_failure_timeout_ms: null,
    }

    if(DESKTOP_HW_TYPES.includes(this.board.type)) {
      payload.restrictive_hysteresis_threshold = 12
      payload.minimum_delay_in_buffers = 4
    }

    await APIClient.start_internal_session(payload)
    this.activeViewState = ACTIVE_VIEW_STATE_VIDEO
    await sleep(100)
    try {
      await APIClient.send(0, this.mixer.send[0])
      await APIClient.send(1, this.mixer.send[1])
    } catch (err) {
      logger.error('Failed to set "send" after session started')
    }
  }

  /**
   * Decline a session that we have got an invite to join.
   */
  async declineSession(session) {
    await APIClient.reply_to_session_request(this.currentUserId, session.id, false)
  }

  /**
   * Leave a session and send out a notification to
   * the other users that the user has left the session.
   */
  async leaveSession() {

    if (this.session.hasLeft) {
      return
    }

    const sessionId = this.sessionMode ? this.session.id : undefined

    await APIClient.leave_session(this.currentUserId, SESSION_EVENT_TRIGGER_TYPE_MANUAL, sessionId)

    // getting pre-signed
    await APIClient.request_logfile_upload_presign(sessionId)

    this.session.hasLeft = true
    this.chatMessages = []
    this.chatMessageCounter = 0
    this.session.talkback.close()
    this.roomManager.currentActiveTime = null
    this.alohaSuspendedState = {}
    this.startPrivateSession()
    this.resetVideoPreview()
  }

  /*** Chat ***/

  postChatMessage(message, sessionPrivate = true) {
    APIClient.post_message_to_chat_group(
      this.currentUserId,
      message,
      sessionPrivate,
    )
    this.chatMessages.push({
      userId: this.currentUserId,
      message,
      sessionPrivate,
      time: new Date(),
    })
  }

  /*** General ***/

  async checkServerStartupData() {
    try {
      const data = await APIClient.get_server_startup_data()

      this.serverStartupData = {
        elkImageDownloadLink: data.elk_image_download_link,
        elkSWUDownloadLink: data.elk_swu_download_link,
        elkSWUDownloadLinks: data.elk_swu_download_links,
        requiredBridgeVersion: data.required_elk_bridge_version,
        requiredDesktopVersion: data.required_elk_desktop_version,
        elkDesktopDownloadLink: data.desktop_download_url
      }
    } catch (err) {
      logger.error(err)
    }
  }

  getSortedFriends() {
    const list = this.friendsList.slice().map((friend) => {
      return {
        ...friend,
        active: this.userActive.get(friend.id) ? 0 : 1,
        name: this.users.get(friend.id)?.name,
      }
    })
    return sortBy(list, ['active', 'status', 'name', 'id'])
  }
    /*** Snackbar ***/

  showSnackBar({
    heading,
    content,
    level = 'info',
    duration = 5000,
    moreInfo,
    variant,
  }) {
    const exists = this.snackbars.find((s) => {
      return s.heading === heading
    })
    if (exists) {
      return
    }
    logger.info(`[SNACKBAR] heading: '${heading}' - content: '${content}'`)
    const snackbar = observable({
      id: _snackBarId++,
      heading,
      content,
      level,
      exiting: false,
      moreInfo,
      variant,
    })
    snackbar.close = () => {
      const snack = this.snackbars.find(
        (_snackbar) => _snackbar.id === snackbar.id,
      )
      if (snack) {
        snack.exiting = true
      }
      setTimeout(() => {
        this.snackbars = this.snackbars.filter(
          (_snackbar) => _snackbar.id !== snackbar.id,
        )
      }, 250)
    }
    this.snackbars.push(snackbar)
    setTimeout(() => {
      snackbar.close()
    }, duration)
  }

  /*** Others */

  extractFromBackendLogs = (backendLogs) => {
    const parsedBackendLogs = this.isConnectedToDesktop() ? NDLToJson(backendLogs) : logsToJson(backendLogs)
    const lastSpeedtestLog = parsedBackendLogs.filter(log => log.event === 'Speedtest done')?.pop()
    const errorLogs = parsedBackendLogs.filter(log => log.level === 'error')
    const warningLogs = parsedBackendLogs.filter(log => log.level === 'warning')
    return [
        '',
        '---- Backend ----',
        lastSpeedtestLog && lastSpeedtestLog.result ? `Speedtest results: Upload: ${lastSpeedtestLog.result.upload.toFixed(2)} Ping: ${lastSpeedtestLog.result.ping}ms` : '',
        `Error logs: ${JSON.stringify(errorLogs)}`,
        `Warning logs: ${JSON.stringify(warningLogs)}`
      ]
  }

  extractFromWebappLogs = (webapplogs) => {
    const parser = new UAParser(navigator.userAgent)
    const { browser, device, os } = parser.getResult();
    const errorLogs = webapplogs.filter(log => log.level === 'error')
    const warningLogs = webapplogs.filter(log => log.level === 'warning')
    const audioInterface = webapplogs.find(log => log.message.includes('Audio devices selected'))
    return [
      '',
      '---- Webapp ----',
      `Version: ${getCurrentVersion().version}`,
      `Env: ${getCurrentVersion().env}`,
      `Desktop: ${isDesktop()}`,
      audioInterface ? audioInterface.message : '',
      `User Agent: ${browser.name} ${device.model} ${os.name} ${navigator.userAgent}`,
      `Error logs: ${JSON.stringify(errorLogs)}`,
      `Warning logs: ${JSON.stringify(warningLogs)}`
    ]
  }

  extractFromElectronLogs = (electronLogs) => {
    return [
      '',
      '---- Electron Info ----',
      `Build number: ${electronLogs[0].buildNumber}`,
      `Build version:  ${electronLogs[0].version}`,
      `OS Version: ${electronLogs[0].osVersion}`,
    ]
  }

  resetVideoPreview() {
    this.previewVideoEnabled = !this.previewVideoEnabled
    this.previewVideoEnabled = !this.previewVideoEnabled
  }


  async reportAnIssue({ subject, description, images, includeLogs = true }) {
    let comment = [description]
    let logs = {}
    if (includeLogs) {
      let boardLogs = 'Not connected'
      if(this.boardConnectionState === BOARD_CONNECTION_STATE_CONNECTED){
        boardLogs = await (this.isConnectedToDesktop() ? APIClient.get_discrete_logs(DESKTOP_IP) :  APIClient.get_board_logs(this.board?.ipAddress))
      }

      const electronLogs = isDesktop() ? await getElectronLogs() : undefined

      logs = {
        backend: boardLogs,
        webapp: ElkLogger.getLogs(),
        electron: electronLogs
      }


      if(boardLogs && this.isConnectedToDesktop()){
        comment.push(...this.extractFromBackendLogs(boardLogs.backend))
      }

      if(boardLogs && !this.isConnectedToDesktop()){
        comment.push(...this.extractFromBackendLogs(boardLogs))
      }

      comment.push(...this.extractFromWebappLogs(logs.webapp))
      if(electronLogs){
        comment.push(...this.extractFromElectronLogs(logs.electron))
      }
    }

    const message = comment.join('\n').trim()

    const reportData = {
      subject: subject || 'Aloha webapp support',
      description: message,
      server: getCloudServerUrl(),
      commit: process.env.REACT_APP_GIT_COMMIT || 'dev',
      images,
      logs: JSON.stringify(logs)
    }
    return await APIClient.report_an_issue(reportData)
  }

  notifyPurchaseCompleted(user_id, fastspring_order) {
    APIClient.fastspring_subscription_purchased(user_id, fastspring_order);
  }


  setElkLiveWebsiteURL(_url) {
    this.websiteUrl = _url
  }

  setSubscriptionsEnabled(_enabled) {
    this.subscriptionsEnabled = _enabled
  }

  setEnvironmentVariables(env) {
    this.env = { ...this.env, ...env }
  }

  /**
   * Save speedtest results into the store, meant to be called from the socket message event
   */
  setSpeedTestData(data) {
    this.speedTestResults = data
    this.isSpeedTestRunning = false
  }
  /**
   * Trigger the speedtest manually
   */
  async triggerSpeedTestManually() {
    this.isSpeedTestRunning = true
    await APIClient.do_speedtest()
  }

  async queryUserByEmail(email){
    const users = await APIClient.find_friends(email)

    if(Object.keys(users).length > 0){
      return {
        id: Number(Object.keys(users)[0]),
        email,
        name: Object.values(users)[0]
      }
    }
    throw new Error('User not found')
  }

  async updateProfileFields(profileData, displayName, image) {
    try {
      if(!this.currentUserId) return
      const currentUser = this.users.get(this.currentUserId)

      if(image){
        await APIClient.set_profile_fields(
          this.currentUserId,
          profileData.privacyStatus ? 'PRIVATE' : 'PUBLIC',
          profileData.personalSummary,
          image,
        )
      }
      await APIClient.set_display_name(this.currentUserId, displayName)

      this.users.set(this.currentUserId, image ? {
        ...currentUser,
        display_name: displayName,
        profile_image_url: image
      } : {
        ...currentUser,
        display_name: displayName,
      })

      this.analytics.track('User_Updated Profile', { ['Display Name']: displayName })
    } catch (e) {
      this.showSnackBar({
        heading: "Something went wrong when trying to update your profile",
        content: "We are experiencing server issues. Please contact support",
        level: "error"
      })

    }
  }

  isRoomOrSessionActive(){
    return this.sessionMode || this.roomManager.isRoomActive()
  }

  async toggleSystemState() {

    if(this.systemState === STANDALONE_NO_AUDIO){
      this.systemState = NIKKEI_DISABLED
      return
    }

    if(this.systemState === STANDALONE_AUDIO){
      await this.mixer.stopSushi()
      this.systemState = NIKKEI_DISABLED
      return
    }

    if(this.systemState.includes('P')){
      this.systemState = STANDALONE_NO_AUDIO
    }
  }

  isOnPluginMode(){
    return [NIKKEI_DISABLED,NIKKEI_ENABLED].includes(this.systemState)
  }

  async audioEngineStateChange(data) {
    const { old_state: oldState, new_state: newState } = data

    logger.info(`Received an audio engine state switch from backend, ${oldState} to ${newState}`)

    if(newState === 'PLUGIN') {
      if(oldState === 'STANDALONE' && this.mixer.desktop.isSushiReady){
        await this.mixer.stopSushi()
      }
      this.systemState = NIKKEI_ENABLED
      this.mixer.master.volume = 1
      this.mixer.tracks.own.stereoLinked = true
      this.settings.save()

      this.analytics.track('Audio Engine Switched', {
        ['Previous State']: ['STANDALONE','NO_SUSHI'].includes(oldState) ? 'STANDALONE' : oldState,
        ['New State']: newState,
        ['Is Connected']: this.isNikkeiProcessing
      })
    }

    if(newState === 'NO_SUSHI' && oldState === 'PLUGIN'){
      this.systemState = STANDALONE_NO_AUDIO
      this.mixer.desktop.isSushiReady = false

        this.roomManager.showGenericErrorDialog('Connection to Elk WIRE plugin was lost')
        if(this.sessionMode){
          await this.leaveSession()
        }
        await this.roomManager.closeRoom()
        this.mixer.resetSelectedDevices()
        this.analytics.track('Audio Engine Switched', {
          ['Previous State']: oldState,
          ['New State']: newState,
          ['Is Connected']: false
        })
    }
  }

  setNikkeiProcessing(value){
    if(this.systemState.includes('P')){
      const getState = (processing) => processing ? 'PROCESSING' : 'NO_PROCESSING'
      this.analytics.track('Audio Processing Changed', {
        ['Previous State']: getState(this.isNikkeiProcessing),
        ['New State']: getState(value)
      })
    }

    this.isNikkeiProcessing = value
    this.systemState = this.systemState.includes('P') ?
      value ? NIKKEI_ENABLED : NIKKEI_DISABLED : this.systemState
  }

  setAlohaSuspendedState(userId, value) {
    this.alohaSuspendedState[userId] = value
  }
}
