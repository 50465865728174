import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../store'
import { StyledH2, StyledH3 } from '../styles'
import Dropdown from '../../../ui/form/dropdown'
import Button from '../../../ui/button'
import {
  BOARD_CONNECTION_STATE_SUSHI_ERROR
} from '../../../../store/board-connection-states'
import { Bold, Box, I } from '../../../ui/primitives'

export const SettingsMenuButton = styled(Button)`
  background-color: var(--ELK-Sea-Foam-Green);
  color: var(--ELK-Black);
  width: 100%;
  height: 3.5rem;
  margin-top: 2.5rem;
  font-size: 16px;

  &:hover:not(:disabled) {
    background-color: var(--ELK-High-Prio-Green-Hover);
  }

  &:focus:not(:disabled) {
    background-color: var(--ELK-High-Prio-Green-Pressed);
  }
`

function DesktopAudioSection({ close }) {
  const [audioDevices,setAudioDevices] = useState([])
  const store = useStore()
  const [audioInput, setAudioInput] = useState(null)
  const [audioOutput, setAudioOutput] = useState(null)
  const renderFromDialog = close !== undefined

  useEffect(() => {
    const devices = store.mixer.desktop.availableDevices
    const devicesToShow =  devices.map(({  uid, name, inputs, outputs }) =>
      ({ title: name, value: uid, inputs, outputs }))
    setAudioDevices(devicesToShow)

    if(store.mixer.desktop.selectedInput){
      const inputIndex = devicesToShow.filter(({ inputs }) => inputs > 0)
        .findIndex(( { value }) => value === store.mixer.desktop.selectedInput )
      setAudioInput(inputIndex)
    }

    if(store.mixer.desktop.selectedOutput){
      const outputIndex = devicesToShow.filter(({ outputs }) => outputs > 0)
        .findIndex(( { value }) => value === store.mixer.desktop.selectedOutput )
      setAudioOutput(outputIndex)
    }
    // eslint-disable-next-line
 }, [store.mixer.desktop.availableDevices])

  useEffect(() => {
    if(store.isDesktopAudioInitializing === true){
      if(close) close()
    }
    // eslint-disable-next-line
  }, [store.isDesktopAudioInitializing])

  const selectAudioDevices = async () => {
    await store.mixer.setAudioDevices()
  }

  const areDevicesChanged = (inputs,outputs) => {
    if(audioInput === null || audioOutput === null){
      return false
    }

    return inputs[audioInput].value !== store.mixer.desktop.selectedInput
      || outputs[audioOutput].value !== store.mixer.desktop.selectedOutput
  }

  const inputs = audioDevices.filter(({ inputs }) => inputs > 0)
  const outputs = audioDevices.filter(({ outputs }) => outputs > 0)
  const getCurrentInputDevice = () => { return store.mixer.desktop.availableDevices.find(({ uid }) => uid === inputs[audioInput]?.value) }
  const getCurrentOutputDevice = () =>{ return store.mixer.desktop.availableDevices.find(({ uid }) => uid === outputs[audioOutput]?.value) }

  const applyButtonDisabled = store.sessionMode ||
    store.isDesktopAudioInitializing ||
    (!areDevicesChanged(inputs,outputs) && store.mixer.desktop.isSushiReady)

  const showOutputSelection =  audioInput !== null

  return (
    <Box width={renderFromDialog ? '53rem' : 'auto'}>
      {renderFromDialog && store.boardConnectionState === BOARD_CONNECTION_STATE_SUSHI_ERROR && (
        <StyledH3 mt="0" mb="15px" fontWeight="300"  lineHeight="27px">
          We had an unexpected error with the audio engine.
          <br />Make sure that your Sample Rate is set to <Bold style={{ display: 'contents'}}>48khz</Bold>.
          <br />Select your devices to restart.
        </StyledH3>
      )}
      <StyledH2>Select Audio {showOutputSelection ? 'Input' : 'Interface'}</StyledH2>
      {inputs.length === 0 && (
        <StyledH3 mt="10px" mb="15px" fontWeight="300">
          There are no compatible audio devices available. Make sure you have an audio interface connected and try again.
        </StyledH3>
      )}
      <Dropdown
        width="100%"
        height="305px"
        overflow="auto"
        title={
          inputs.length === 0
            ? 'No devices found'
            : getCurrentInputDevice()?.name || 'Select device'
        }
        menuItems={inputs}
        enabled={!store.isOnPluginMode() || !store.sessionMode}
        onChange={(selectedIndex) => {
          setAudioInput(selectedIndex)
        }}
      />
      {showOutputSelection && (
        <>
          <StyledH2 mt="15px">Select Audio Output</StyledH2>
          <Dropdown
            width="100%"
            title={
              audioDevices.length === 0
                ? 'No devices found'
                : getCurrentOutputDevice()?.name || 'Select device'
            }
            menuItems={outputs}
            enabled={!store.isOnPluginMode() || !store.sessionMode}
            onChange={(selectedIndex) => {
              setAudioOutput(selectedIndex)
            }}
          />
          <Box mt="10px">
            <I  mb="15px" fontWeight="300">
              For a better experience we recommend using the same device for input and output.
            </I>
          </Box>
        </>
      )}
      <SettingsMenuButton
        primary
        disabled={applyButtonDisabled || store.isOnPluginMode()}
        onClick={async () => {
          const selectedInputUid = inputs[audioInput].value
          const selectedOutputUid = outputs[audioOutput].value
          if(!store.mixer.checkAppleBuiltInDevices(selectedInputUid, selectedOutputUid)) {
            store.mixer.desktop.selectedInput = selectedInputUid
            store.mixer.desktop.selectedOutput = selectedOutputUid
            await selectAudioDevices()
          }
        }}
      >
        {store.isDesktopAudioInitializing ? 'Applying settings.. ' : applyButtonDisabled && store.sessionMode ? 'Not available during a session': 'Apply settings'}
      </SettingsMenuButton>
    </Box>
  )
}

export default observer(DesktopAudioSection)
