import { getCurrentVersion, isDesktop } from '../../utils/utils'
import { logger } from '../../utils/logging'
import { Mixpanel } from './providers/mixpanel'
import { Rudderstack } from './providers/rudderstack'
import UAParser from 'ua-parser-js'

export const Providers = {
  Mixpanel: Mixpanel,
  Rudderstack: Rudderstack
}

export class Analytics {

  providers = []

  constructor (providers) {

    if(!providers) throw new Error('You must specify a valid provider')

    const validProviders = providers.filter((provider) => Object.keys(Providers).includes(provider))

    if(validProviders){
      validProviders.forEach(provider => {
        this.providers.push(new Providers[provider]())
      })
    }
  }

  identify(user, analyticsId = null) {
    this.providers.forEach((provider) => {
      logger.info(`Identifying user ${user.id} for provider ${provider.constructor.name}`)
      provider.identify(user, analyticsId)
    })
  }

  alias(primaryKey, alias) {
    this.providers.forEach((provider) => {
      logger.info(`Addling alias ${alias} to user ${primaryKey} for provider ${provider.constructor.name}`)
      provider.alias(primaryKey, alias)
    })
  }

  track(name, eventData = {}) {
    this.providers.forEach((provider) => {
      logger.info(`Tracking ${name} event for provider ${provider.constructor.name}`)
      provider.track(name, {...eventData, ...this.commonFields()})
    })
  }

  getAnonymousId(){
    const identifiers = this.providers.map((provider) => {
      logger.info(`Getting Id for provider ${provider.constructor.name}`)
      return {[provider.constructor.name]: provider.getAnonymousId() }
    })
    const identifiersObject = {};

    for (const obj of identifiers) {
      Object.assign(identifiersObject, obj);
    }
    return identifiersObject
  }

  commonFields(){
    const parser = new UAParser(navigator.userAgent)
    const { os } = parser.getResult();

    return {
      Version: getCurrentVersion().version,
      Env: getCurrentVersion().env,
      Desktop: isDesktop(),
      OS: os,
    }
  }
}