import React, { useEffect, useRef } from 'react'
import { ELKBlack, ELKHighPrioRedEnabled, ELKHighPrioGreenEnabled, ELKDarkGrey } from '../../../../variables'

export const SignalMeter = ({ inputClipIndicatorUpdatedAt, signalIndicatorUpdatedAt, height, width, disabled = true }) => {

  const canvasRef = useRef(null)

  useEffect(() => {
    let requestId
    const canvas = canvasRef.current
    if (!canvas) return
    const context = canvas.getContext('2d')
    canvas.height = height ?? canvas.parentElement.clientHeight
    canvas.width = width ?? canvas.parentElement.clientWidth
    const canvasHeight = canvas.height
    const canvasWidth = canvas.width
    const drawFrame = () => {
      let nextValue = ELKDarkGrey
      if (!disabled) {
        if (Date.now() - inputClipIndicatorUpdatedAt() < 500) {
          nextValue = ELKHighPrioRedEnabled
        } else {
          if (Date.now() - signalIndicatorUpdatedAt() < 500) {
            nextValue = ELKHighPrioGreenEnabled
          }
        }
      }
      context.fillStyle = ELKBlack
      context.fillRect(0, 0, canvasWidth, canvasHeight)
      context.fillStyle = nextValue
      context.beginPath()
      const radius = width / 2
      context.arc(radius, radius, radius, 0, 2 * Math.PI)
      context.fill()
    }
    const render = () => {
      drawFrame()
      requestId = requestAnimationFrame(render)
    }
    render()
    return () => {
      cancelAnimationFrame(requestId)
    }
  }, [inputClipIndicatorUpdatedAt, signalIndicatorUpdatedAt, height, width, disabled])

  return (
    <canvas ref={canvasRef} />
  )

}
