import { BlinkingBridgeLogo } from '../../../ui/icons'
import { BridgeStatusIcon } from '../../../ui/bridge-status-icon'

function BridgeRowIcon({
  connectionState,
  device,
  identificationInProgressIp,
  size = "100%"
}) {

  return (
    <>
      {device.ipAddress === identificationInProgressIp ? (
        <BlinkingBridgeLogo size={size} />
      ) :  (
        <BridgeStatusIcon size={size} connectionState={connectionState} device={device} />
      )}
    </>
  )
}

export default BridgeRowIcon
