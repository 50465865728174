import styled from "styled-components";
import { Box } from '../../ui/primitives'
import { ELKDarkGrey, ELKHighPrioGreenEnabled } from '../../../variables'

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 15px;
  cursor: pointer;
`

const Container = styled.div`
  background: var(--Elk-Background-Grey);
  display: flex;
  width: 100%;
  min-height: 100%;
  align-items: stretch;
  justify-content: space-between;
`

const MixerWrapper = styled.div`
  display: flex;
  transition: transform 0.225s;
  &.expanded {
    transform: translateX(0px);
  }
`

const PluginModePeakMeterContainer = styled(Box)`
  width: 10px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between
`

const TrackWrapper = styled.div`
  display: flex;
  opacity: ${(props) => props?.visible ? '1' : '0'};
  position: ${(props) => props?.minimized ? 'relative' : props?.sessionMode ? 'absolute' : 'unset'};
  bottom: ${(props) => props?.sessionMode ? '5px' : '48px'};
  right: 0px;
  transition: opacity 0.3s ease;  
  height:  ${(props) => props?.minimized ? '392px' : props?.sessionMode ? '390px' : 'unset'};;
  top: ${(props) => props?.minimized ? '0' : 'unset'};
  left: ${(props) => props?.minimized ? '0' : 'unset'};
  z-index: 100;
  margin: ${(props) => props?.sessionMode && !props?.minimized ? '10px' : 'unset'};
`

const KnobContainer = styled(Box)`
  width: 55px;
  margin: 0px auto;
  align-items: center;
`

const ActivityLedIndicator = styled.div`
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background-color: ${(props) => {
      console.log('props.activityIndicator * 100',props.activityIndicator * 100)
      return (props.activityIndicator * 100) > 0 ? ELKHighPrioGreenEnabled : ELKDarkGrey
    } }; 
  `


const SuspendedStateContainer = styled(Box)`
    height: 70px;
    position: absolute;
    bottom: 0;
    width: 100%;
    bottom: 10%;
`

const SuspendedStateContent = styled(Box)`
   text-align: center;
   background: var(--ELK-Black);
   width: 180px;
   padding: 10px;
   margin: 0 auto;
   border-radius: 6px;
`

const SuspendedStateHeader = styled(Box)`
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    h2 {
      margin-right: 6px;
    }
`

export {
  CloseButton, Container, SuspendedStateHeader,  MixerWrapper, TrackWrapper, KnobContainer, ActivityLedIndicator, PluginModePeakMeterContainer, SuspendedStateContainer, SuspendedStateContent
}