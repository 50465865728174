import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../store'
import { logger } from '../../../../utils/logging'
import loadScript from '../../../../utils/load-script'
import Active from './active'
import Canceled from './canceled'
import Inactive from './inactive'

const ELK_MONTHLY_SUBSCRIPTION_PID = 'elk-live-monthly-plan'

const Subscriptions = observer(() => {
  const store = useStore()

  useEffect(() => {
    const fastSpringDataCallback = (data) => {
      const subscriptionPlanPrices = {}
      data?.groups?.forEach((groups) => {
        groups.items.forEach((item) => {
          if (item.pid === ELK_MONTHLY_SUBSCRIPTION_PID) {
            subscriptionPlanPrices.monthly = item.unitPrice
          }
        })
      })
      store.fastSpringSubscriptionData = subscriptionPlanPrices;
    }

    const fastSpringPopupWebhookReceived = (data) => {
      store.notifyPurchaseCompleted(store.currentUserId, data)
      logger.info(`Purchase completed for user ${store.currentUserId}`, { data });
    }

    // Function to available on window for FastSpring JS to pick it up
    window.onFastSpringData = fastSpringDataCallback
    window.onFastSpringPurchaseCompleted = fastSpringPopupWebhookReceived
    loadScript(
      'fsc-api',
      'https://d1f8f9xcsvx3ha.cloudfront.net/sbl/0.8.7/fastspring-builder.min.js',
      () => {},
      {
        'data-storefront': process.env.REACT_APP_FASTSPRING_STORE_FRONT,
        'data-debug': 'false',
        'data-data-callback': 'onFastSpringData',
        'data-popup-webhook-received': 'onFastSpringPurchaseCompleted',
      },
    )
  }, [store])

  const currentSubscription = store.currentUser.fastspringData?.subscription || {}
  const { state } = currentSubscription
  const isCanceled = state === 'canceled'

  const hasSubscription = store.userHasActiveSubscription
  if (hasSubscription && isCanceled) {
    return <Canceled />
  }
  if (hasSubscription) {
    return <Active />
  }
  return <Inactive />
})

export default Subscriptions
