import React, { useEffect } from 'react'
import { BetaLayout } from './layout'
import {
  AppDownloadContainer,
  DownloadBoxLink,
  Paragraph,
  Subtitle,
  Title,
  InlineStyledLink,
  DownloadIconsContainer
} from './style'
import { Box } from '../../../../ui/primitives'
import { AppleIcon } from '../../../../ui/icons'
import { useStore } from '../../../../../store'
import { observer } from 'mobx-react-lite'

export const Download = observer(({ setPage }) => {
  const store = useStore()
  const pendingToConfirm = store.userConfirmed === false

  const getTitle = () => {
    if(store.userConfirmed)
      return 'Thanks for verifying.'
    if(pendingToConfirm)
      return (<>Confirmation email sent! <br /> Verify your account before first login.</>)

    return 'Time to download.'
  }

  const getSubtitle = () => {
    if((store.userConfirmed) || pendingToConfirm)
      return 'Time to download.'
    if(pendingToConfirm)
      return 'Confirmation email sent!\n' +
        'Verify your account before first login.'
  }

  useEffect(() => {
    async function fetchData() {
      await store.initializeAPIClientConnections()
      await store.checkServerStartupData()
    }
    store.analytics.track('Page Viewed', { $email: store.currentUserEmail })
    fetchData()
  // eslint-disable-next-line
  }, [])

  return (
    <BetaLayout setPage={setPage}>
      <Title>{getTitle()}</Title>
      <Subtitle>{getSubtitle()}</Subtitle>
      {store.userConfirmed && (
        <Box fontSize='1.6rem'  mt="3rem" textAlign="center">
          <Paragraph>
            Rather download at a later time? <br />
            No worries, there is a link in your confirmation email.
          </Paragraph>
        </Box>
      )}

      <DownloadIconsContainer>
        <AppDownloadContainer>
          <DownloadBoxLink
            onClick={() => {
              store.analytics.track('Download button clicked', { $email: store.currentUserEmail })
            }}
            href={store.serverStartupData?.elkDesktopDownloadLink}
            target="_blank" rel="noreferrer">
            <AppleIcon size={33} color="var(--ELK-Black)" />
            <span>Download for Mac</span>
          </DownloadBoxLink>
        </AppDownloadContainer>
      </DownloadIconsContainer>
      <Box mt="2.5rem" mb="35vh" textAlign="center" fontSize="1.4rem">
        <Box>
          To use Elk you’ll need<strong> MacOS 11 or higher.</strong><br />
        </Box>
        <Box mt="2.5rem">
          Windows version coming later. <br /> <InlineStyledLink
          href="https://pages.elk.live/windows-waiting-list" target="_blank" rel="noreferrer">Stay updated!</InlineStyledLink>
        </Box>
      </Box>
    </BetaLayout>
  )
})


