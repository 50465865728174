import React from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from '../ui/button'
import { showReportIssueDialog } from './report-issue'
import { CloseButton, Dialog } from './base'
import { openModal } from './overlays'
import { Box, Text } from '../ui/primitives'
import { useStore, withStore } from '../../store'

const HelpDialog = observer(({ close }) => {
  const store = useStore()

  return (
    <Dialog
      width="35rem"
      py="2rem"
      px="1rem"
    >
      <CloseButton onClick={() => close()} color="var(--ELK-White)"/>

      <Text fontSize="2rem" color="var(--ELK-White)" textAlign="center" mb="2.5rem">Need some help?</Text>
      <Text textAlign="center" mb="3rem" color="var(--ELK-White)" fontSize="1.8rem">Don't worry. We got you!</Text>

      <Box mt="2rem" maxWidth="28rem" mx="auto" flex column rowGap="2rem">
        <a href="https://help.elk.live/en/articles/8811364-elk-requirements" target="_blank" rel="noreferrer">
          <Button primary height="3.8rem" width="100%" fontSize="1.6rem">Requirements</Button>
        </a>
        <a href="https://help.elk.live/" target="_blank" rel="noreferrer">
          <Button primary height="3.8rem" width="100%" fontSize="1.6rem">Go to support</Button>
        </a>
        <Button primary height="3.8rem" width="100%" fontSize="1.6rem" onClick={() => {showReportIssueDialog({ store }); close()}}>Report an Issue</Button>
      </Box>
    </Dialog>
  )
})

export function showHelpDialog({ store }) {
  return openModal(
    ({ close }) => withStore(store, <HelpDialog close={close}/>), {
      autoDismissable: true,
    }
  )
}
