import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { useStore, withStore } from '../../../store'
import { defaultSettings } from '../../../store/settings'
import { openModal } from '../../dialogs/overlays'
import { Dialog, UnderlineButton } from '../../dialogs/base'
import { Checkbox, FormRow, TextField as BaseTextField } from '../form'
import { LegacyButton } from '../legacy-button'
import { Tabs, TabPane } from './tabs'
import { Box, Text, Footer, H2 } from '../../ui/primitives'
import { APIClient } from '../../../api/client'

const Label = styled.label`
  display: block;
  margin-bottom: .25rem;
`

const LabelStrong = styled(Label)`
  font-weight: 700;
`

const TextField = styled(BaseTextField)`
  background-color: var(--color-grey-1);
  &:disabled {
    background-color: #393939;
  }
`

const CheckboxField = styled(({ label, className, ...props }) => {
  return (
    <Label className={className}>
      <Checkbox {...props}/>
      <Text ml=".6rem">{label}</Text>
    </Label>
  )
})`
  display: flex;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
`

function getConfig(s) {
  return {
    allowCustomCore: s.connection.allowCustomCore,
    setAlohaIpAddress: !!s.connection.boardIpAddress,
    alohaIpAddress: s.connection.boardIpAddress || '',
    overrideUdpPort: !!s.connection.udpPortOverride,
    manualUdpPort: s.connection.udpPortOverride || 35000,
    overrideAdverisedIpAddress: !!s.connection.advertisedIpOverride,
    advertisedIpAddress: s.connection.advertisedIpOverride || '',
    useTurn: s.connection.useTurn,
    turnServerIpAddress: s.connection.turnServerIpAddress || '',
    turnUser: s.connection.turnUser || '',
    turnPassword: s.connection.turnPassword || '',
    bypassPortForwardingTest: s.connection.bypassPortForwardingTest,
    senderBufferSize: s.connection.senderBufferSize,
    testSignalMode: s.connection.testSignalMode,
    mockUpBoardConnection: APIClient._cloud_debug_mode,

    videoUplinkMaxBandwidth: s.videoChat.uplinkMaxBandwidth,
    videoUplinkFramerate: s.videoChat.uplinkFramerate,
    videoWidth: s.videoChat.width,
    videoHeight: s.videoChat.height,
    enableBandwidthOptimizer: s.videoChat.enableBandwidthOptimizer,

    eccMidFilterLength: s.ecc.midFilterLength,
    eccSideFilterLength: s.ecc.sideFilterLength,
    eccCrossFadeLength: s.ecc.crossFadeLength * 1000,  // ms

    compensationMode1: s.compensation.mode1Preset || 2,
    compensationMode2: s.compensation.mode2Preset || 4,
    compensationMode3: s.compensation.mode3Preset || 8,

    enableAutoResync: s.sync.enableAutoResync,
    autoResyncThreshold: s.sync.autoResyncThreshold,
    enableAutoJitter: s.sync.enableAutoJitter,
    autoJitterThreshold: s.sync.autoJitterThreshold,
    enableSoftResync: s.sync.enableSoftResync,
    restrictiveHysteresisThreshold: s.sync.restrictiveHysteresisThreshold,
    minimumDelayInBuffers: s.sync.minimumDelayInBuffers
  }
}

function setConfig(s, state) {
  s.connection.allowCustomCore = state.allowCustomCore
  s.connection.boardIpAddress = state.setAlohaIpAddress ? (state.alohaIpAddress || null) : null
  s.connection.udpPortOverride = state.overrideUdpPort ? (state.manualUdpPort || null) : null
  s.connection.advertisedIpOverride = state.overrideAdverisedIpAddress ? (state.advertisedIpAddress || null) : null
  s.connection.useTurn = state.useTurn
  s.connection.turnServerIpAddress = state.turnServerIpAddress
  s.connection.turnUser = state.turnUser
  s.connection.turnPassword = state.turnPassword
  s.connection.bypassPortForwardingTest = state.bypassPortForwardingTest
  s.connection.senderBufferSize = _num(state.senderBufferSize, 2)
  s.connection.testSignalMode = state.testSignalMode
  APIClient._cloud_debug_mode = state.mockUpBoardConnection

  s.videoChat.uplinkMaxBandwidth = _num(state.videoUplinkMaxBandwidth, 2500)
  s.videoChat.uplinkFramerate = _num(state.videoUplinkFramerate, 15)
  s.videoChat.width = _num(state.videoWidth, 960)
  s.videoChat.height = _num(state.videoHeight, 540)
  s.videoChat.enableBandwidthOptimizer = state.enableBandwidthOptimizer

  s.ecc.midFilterLength = _num(state.eccMidFilterLength, 256)
  s.ecc.sideFilterLength = _num(state.eccSideFilterLength, 32)
  s.ecc.crossFadeLength = _num(state.eccCrossFadeLength, 25) / 1000

  s.compensation.mode1Preset = _num(state.compensationMode1, 2)
  s.compensation.mode2Preset = _num(state.compensationMode2, 4)
  s.compensation.mode3Preset = _num(state.compensationMode3, 8)

  s.sync.enableAutoResync = state.enableAutoResync
  s.sync.autoResyncThreshold = _float(state.autoResyncThreshold)
  s.sync.enableAutoJitter = state.enableAutoJitter
  s.sync.autoJitterThreshold = _float(state.autoJitterThreshold)
  s.sync.enableSoftResync = state.enableSoftResync
  s.sync.restrictiveHysteresisThreshold = _num(state.restrictiveHysteresisThreshold, 4)
  s.sync.minimumDelayInBuffers = _num(state.minimumDelayInBuffers, 2)

}

function _num(value, defaultValue = 0) {
  const num = parseInt(value, 10)
  return !isNaN(num) ? num : defaultValue
}

function _float(value, defaultValue = 0) {
  const float = parseFloat(value)
  return !isNaN(float) ? float : defaultValue
}

const AdvancedConfigurationSettingsDialog = observer(({ close }) => {
  const store = useStore()
  const boardInfo = store.devices.find(
    (device) => device.ipAddress === store.board?.ipAddress,
  )
  const state = useLocalObservable(() => {
    return {
      ...getConfig(store.settings),
      alohaCoreOverrideUrl: null,
      initialAlohaCoreOverrideUrl: null,
    }
  })
  const fileInputRef = useRef()

  useEffect(() => {
    async function fetchBoardConfig() {
      try {
        state.alohaCoreOverrideUrl = await boardInfo.getAlohaCoreOverrideUrl()
        state.initialAlohaCoreOverrideUrl = state.alohaCoreOverrideUrl
      } catch (err) {}
    }

    if (boardInfo) {
      fetchBoardConfig()
    }
  }, [boardInfo, state])

  async function getBoardAudioHat() {
    try {
        state.board_audio_hat = await APIClient.get_board_configuration().then((data) => {
            return data.board_audio_hat
        })
    } catch (err) {}
  }
  getBoardAudioHat()

  async function save() {
    // try to save AlohaCoreOverrideUrl
    if (boardInfo && state.alohaCoreOverrideUrl !== state.initialAlohaCoreOverrideUrl) {
      try {
        if (state.alohaCoreOverrideUrl) {
          await boardInfo.setAlohaCoreOverrideUrl(state.alohaCoreOverrideUrl)
          boardInfo.coreOverrideUrl = state.alohaCoreOverrideUrl
        }
      } catch (err) {
        store.showSnackBar({
          heading: "Couldn't save core override url",
          content: "",
          level: "error",
          duration: 2500
        })
        return
      }
    }

    setConfig(store.settings, state)
    store.settings.save()
    store.queryDevicesOnNetwork()

    if(state.simulatorActive){
      APIClient.set_ns_active(state.simulatorActive)
      APIClient.set_ns_packet_loss(state.packetErrorRatio, state.burstiness)
      APIClient.set_ns_latency(state.simulatorDelay, state.simulatorBuffer)
    }
    close()
  }

  function reset() {
    Object.assign(state, getConfig(defaultSettings))
    store.showSnackBar({
      heading: "Restored to default values",
      content: "",
      level: "info",
      duration: 2500
    })
  }

  function importSettings() {
    const file = fileInputRef.current.files[0]
    if (!file)
      return
    if (file.name.match(/\.json$/)) {
      const reader = new FileReader()
      reader.onload = function() {
        const data = JSON.parse(reader.result)
        store.settings.updateFromJson(data)
        Object.assign(state, getConfig(store.settings))
        store.settings.save()

        store.showSnackBar({
          heading: "Settings imported",
          content: "",
          level: "success",
          duration: 2500
        })
      }
      reader.readAsText(file)
    } else {
      store.showSnackBar({
        heading: "Unsupported file format",
        content: "",
        level: "error",
        duration: 2500
      })
    }
    fileInputRef.current.value = null
  }

  function exportSettings() {
    const data = store.settings.snapshot
    setConfig(data, state)

    const dataStr = JSON.stringify(data, null, 2)
    const dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr)

    const exportFileDefaultName = 'aloha_web.json'

    const linkElement = document.createElement('a')
    linkElement.setAttribute('href', dataUri)
    linkElement.setAttribute('download', exportFileDefaultName)
    linkElement.click()
  }

  function field(name, type, params = {}) {
    if (type === 'bool') {
      return {
        checked: state[name],
        onChange: e => {
          state[name] = e.target.checked

          if (e.target.checked && params.overrideFor) {
            const node = document.querySelector(`input[name=${params.overrideFor}]`)
            if (node) {
              setTimeout(() => node.focus(), 10)
            }
          }
          if (!e.target.checked && params.overrideFor) {
            state[params.overrideFor] = params.overrideDefaultValue || null
          }
        },
      }
    }

    if (state[name] === null) {
      return {
        disabled: true,
        value: ''
      }
    }

    return {
      name: name,
      value: state[name],
      onChange: e => {
        state[name] = e.target.value
      },
    }
  }

  return (
    <Dialog width="65rem" py="2.4rem" px="3.4rem">
      <H2 mt="1rem" mb="2.5rem">Advanced Configuration Settings</H2>

      <Tabs minHeight="36rem">
        <TabPane title="Aloha">

          <FormRow>
            <CheckboxField label="Enable automatic re-sync" {...field('enableAutoResync', 'bool')}/>
          </FormRow>


          <FormRow>
            <CheckboxField label="Mock up connection to the board" {...field('mockUpBoardConnection', 'bool')}/>
          </FormRow>
        </TabPane>

        <TabPane title="Network">
          <Text color="var(--color-yellow)" fontWeight="bold" mb="20px">Do not alter these settings unless directly instructed to do so!<br />If you experience connectivity issues, contact us, or refer to our online documentation</Text>

          <FormRow>
            <CheckboxField label="Aloha device IP address" {...field('setAlohaIpAddress', 'bool', {overrideFor: 'alohaIpAddress'})}/>
            <TextField {...field('alohaIpAddress', 'ip')} disabled={!state.setAlohaIpAddress}/>
          </FormRow>

          <FormRow>
            <CheckboxField label="Manual UDP port" {...field('overrideUdpPort', 'bool', {overrideFor: 'manualUdpPort', overrideDefaultValue: 35000})}/>
            <TextField {...field('manualUdpPort', 'port')} disabled={!state.overrideUdpPort}/>
          </FormRow>

          <FormRow>
            <CheckboxField label="Advertised IP address" {...field('overrideAdverisedIpAddress', 'bool', {overrideFor: 'advertisedIpAddress'})}/>
            <TextField {...field('advertisedIpAddress', 'ip')} disabled={!state.overrideAdverisedIpAddress}/>
          </FormRow>

          <FormRow className={"boxed"}>
            <CheckboxField label="Network Simulator" {...field('simulatorActive', 'bool')}/>
            <Grid style={{marginTop: '10px'}}>
              <Label>Packet error ratio:</Label>
              <Label>Burstiness:</Label>
              <Label>Delay:</Label>
              <TextField disabled={!state.simulatorActive} type="number" {...field('packetErrorRatio', 'num')} style={{width: 100}}/>
              <TextField disabled={!state.simulatorActive} type="number" {...field('burstiness', 'num')} style={{width: 100}}/>
              <TextField disabled={!state.simulatorActive} type="number" {...field('simulatorDelay', 'num')} style={{width: 100}}/>
              </Grid>
              <FormRow>
                <Label style={{width: 100}}>Jitter:</Label>
                <TextField disabled={!state.simulatorActive} type="number" {...field('simulatorJitter', 'num')} style={{width: 100}}/>
              </FormRow>
          </FormRow>
          </TabPane>

        <TabPane title="Video Chat">
          <FormRow>
            <Label>Max bandwidth (up to 2500 Kbps):</Label>
            <TextField type="number" {...field('videoUplinkMaxBandwidth', 'num')} style={{width: 100}}/>
          </FormRow>

          <FormRow>
            <Label>Video width (up to 1280 px):</Label>
            <TextField type="number" {...field('videoWidth', 'num')} style={{width: 100}}/>
          </FormRow>

          <FormRow>
            <Label>Video height (up to 720 px):</Label>
            <TextField type="number" {...field('videoHeight', 'num')} style={{width: 100}}/>
          </FormRow>

          <FormRow>
            <Label>Framerate (up to 15 fps):</Label>
            <TextField type="number" {...field('videoUplinkFramerate', 'num')} style={{width: 100}}/>
          </FormRow>

          <FormRow>
            <CheckboxField
              label="Automatically pause remote videos if downlink bandwidth is too low"
              {...field('enableBandwidthOptimizer', 'bool')}
            />
          </FormRow>
        </TabPane>

        {!store.isDeveloperMode ? null :
          <TabPane title="DEVS">
            <Text color="var(--color-yellow)" fontWeight="bold" mb="20px">These settings are for Elk developers only.
              Do not use them unless you are certain that you know what you're doing!</Text>

            <FormRow>
              <CheckboxField label="Allow custom core" {...field('allowCustomCore', 'bool')}/>
            </FormRow>

            <FormRow>
              <Label>Aloha core URL override:</Label>
              <Box flex flexDirection={"column"} spacing={"5px"}>
                <TextField {...field('alohaCoreOverrideUrl')}/>
                <Text color="var(--color-yellow)" fontWeight="bold" mb="20px" mt="20px">Please restart your Aloha after every change to the Core override URL!</Text>
                <LegacyButton secondary px=".5rem" onClick={() => {boardInfo.resetAlohaCoreOverrideUrl(); state.alohaCoreOverrideUrl = null}}>Reset to default core</LegacyButton>
              </Box>
            </FormRow>

            <FormRow>
              <LegacyButton textDecoration="hover:underline" onClick={()=> fileInputRef.current.click()}>Import Settings</LegacyButton>
            </FormRow>

            <FormRow>
              <LegacyButton textDecoration="hover:underline" onClick={exportSettings}>Export Settings</LegacyButton>
            </FormRow>

            <input ref={fileInputRef} type="file" style={{display:'none'}} onChange={importSettings}/>

            <FormRow className={"boxed"}>
              <Label>ECC PARAMETERS:</Label>
              <Grid>
                <Label>Mid filter length:</Label>
                <Label>Side filter length:</Label>
                <Label>Crossfade length (ms):</Label>
                <TextField type="number" {...field('eccMidFilterLength', 'num')} style={{width: 100}}/>
                <TextField type="number" {...field('eccSideFilterLength', 'num')} style={{width: 100}}/>
                <TextField type="number" {...field('eccCrossFadeLength', 'num')} style={{width: 100}}/>
              </Grid>
            </FormRow>

            <FormRow>
              <CheckboxField label="Bypass port forwarding test" {...field('bypassPortForwardingTest', 'bool')}/>
            </FormRow>

            <FormRow>
              <Label>Sender Buffer Size:</Label>
              <TextField type="number" min={1} max={20} {...field('senderBufferSize', 'num')} style={{width: 80}}/>
            </FormRow>

            <FormRow>
              <CheckboxField label="Test signal mode" {...field('testSignalMode', 'bool')}/>
            </FormRow>
          </TabPane>
        }

        {!store.isDeveloperMode ? null :
          <TabPane title="TURN">
            <FormRow>
              <CheckboxField label={"Use TURN?"} {...field('useTurn', 'bool')}/>
            </FormRow>
            <FormRow className={'boxed'}>
              <Label>TURN server IP address:</Label>
              <TextField {...field('turnServerIpAddress', 'ip')}/>
              <Label>TURN User:</Label>
              <TextField {...field('turnUser')} disabled={!state.turnServerIpAddress}/>
              <Label>TURN Password:</Label>
              <TextField {...field('turnPassword')} disabled={!state.turnServerIpAddress}/>
            </FormRow>
          </TabPane>
        }

        {!store.isDeveloperMode ? null :
          <TabPane title="SYNC">
            <FormRow>
              <CheckboxField label="Enable automatic re-sync" {...field('enableAutoResync', 'bool')}/>
            </FormRow>

            <FormRow>
              <Label>Auto resync threshold:</Label>
              <TextField type="number" min={0.00} max={1.00} step={0.01} {...field('autoResyncThreshold', 'num')} style={{width: 80}}/>
            </FormRow>

            <FormRow>
              <CheckboxField label="Enable soft re-sync" {...field('enableSoftResync', 'bool')}/>
            </FormRow>

            <FormRow>
              <CheckboxField label="Enable automatic jitter delay" {...field('enableAutoJitter', 'bool')}/>
            </FormRow>

            <FormRow>
              <Label>Auto jitter delay threshold:</Label>
              <TextField type="number" min={0.00} max={1.00} step={0.01} {...field('autoJitterThreshold', 'num')} style={{width: 80}}/>
            </FormRow>

            <FormRow>
              <Label>Minimum delay in buffers:</Label>
              <TextField type="number" step={1} {...field('minimumDelayInBuffers', 'num')} style={{width: 80}}/>
            </FormRow>

            <FormRow>
              <Label>Restrictive hysteresis threshold:</Label>
              <TextField type="number" step={1} {...field('restrictiveHysteresisThreshold', 'num')} style={{width: 80}}/>
            </FormRow>

            <FormRow className={"boxed"}>
              <Text mb={"1rem"}>Compensation presets:</Text>
              <Grid>
                <Label>Mode 1:</Label>
                <Label>Mode 2:</Label>
                <Label>Mode 3:</Label>
                <TextField type="number" {...field('compensationMode1', 'num')} style={{width: 100}}/>
                <TextField type="number" {...field('compensationMode2', 'num')} style={{width: 100}}/>
                <TextField type="number" {...field('compensationMode3', 'num')} style={{width: 100}}/>
              </Grid>
            </FormRow>
          </TabPane>
        }

        <TabPane title="Nikkei">
          <LabelStrong>Current State: <span style={{ color: '#F5BB0E'}}>{store.systemState}</span></LabelStrong>
          <br />
          <FormRow>
            <CheckboxField label="Plugin Mode" checked={store.systemState.includes('P')} onChange={async () => {
              await store.toggleSystemState()
            }}/>
          </FormRow>
          <FormRow className={"boxed"}>
              <FormRow>
                <Label>JRPC Port:</Label>
                <TextField disabled type="number" value={store.nikkeiConfig?.jrpc_port} style={{width: 100}}/>
              </FormRow>
              <FormRow>
                <Label>WS Port:</Label>
                <TextField disabled type="number" value={store.nikkeiConfig?.ws_port} style={{width: 100}}/>
              </FormRow>
              <FormRow>
                <Label>Aloha Port:</Label>
                <TextField disabled type="number" value={store.nikkeiConfig?.aloha_port} style={{width: 100}}/>
              </FormRow>
              <FormRow>
                <Label>Nikkei Port:</Label>
                <TextField disabled type="number" value={store.nikkeiConfig?.nikkei_port} style={{width: 100}}/>
              </FormRow>
          </FormRow>

        </TabPane>

      </Tabs>

      <Footer flex alignItems="center" justifyContent="space-between" mt="2rem">
        <Box>
          <UnderlineButton data-cy="advanced-settings-reset-to-defaults" onClick={()=> reset()}>Reset to defaults</UnderlineButton>
        </Box>

        <Box flex justifyContent="flex-end">
          <LegacyButton secondary onClick={()=> close()} width="10.6rem">Cancel</LegacyButton>
          <Box width="1rem"/>
          <LegacyButton data-cy="advanced-settings-save-button" primary onClick={()=> save()} width="10.6rem">Save</LegacyButton>
        </Box>
      </Footer>
    </Dialog>
  )
})


export function showAdvancedConfigurationSettingsDialog({ store }) {
  return openModal(
    ({ close }) => withStore(store,
      <AdvancedConfigurationSettingsDialog close={close}/>
    )
  )
}
