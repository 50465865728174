import styled from 'styled-components'

export const Input = styled.input`
  width: 100%;
  height: 52px;
  padding: 10px;
  background: none;
  border: 2px solid white;
  font-size: 2.4rem;
  &[disabled] {
    border-color: var(--ELK-Dark-Grey);
    color: var(--ELK-Text-Inactive);
  }
`

export default Input
