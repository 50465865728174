import React from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from '../ui/button'
import { CloseButton, Dialog } from './base'
import { openModal } from './overlays'
import { Box, Text, H1 } from '../ui/primitives'

const ThanksDialog = observer(({ close, text}) => {
  return (
    <Dialog width="60rem" py="3rem" px="3rem"
      bgColor="var(--ELK-White)">
      <CloseButton color="var(--ELK-Black)" onClick={() => close()}/>

      <H1 textAlign="center" mb="1.6rem" color="var(--ELK-Black)">Thank you!</H1>
      <Text textAlign="center" mb="1.4rem" mt="2.5rem" color="var(--ELK-Black)" fontSize="2.4rem">
       {text}
      </Text>

      <Box mt="2rem" maxWidth="26rem" mx="auto">
        <Button primary width="100%" onClick={() => close()}>
          Move along
        </Button>
      </Box>
    </Dialog>
  )
})

export function showThanksDialog({ text }) {
  return openModal(
    ({ close }) => <ThanksDialog close={close} text={text} />, {
      autoDismissable: true,
    }
  )
}
