import React from 'react'
import styled from 'styled-components'
import { Box, Center } from '../../ui/primitives'
import { ProfilePicture } from '../../ui/profile-picture'

const Container = styled.div`
  position: relative;
  background: ${(props) => props.sessionMode ? '#414141' : 'var(--ELK-Black)'};
  height: 100%;
  width: 100%;
  overflow: hidden;
`

const VideoPlaceholder = ({ user, sessionMode }) => (
  <Container sessionMode={sessionMode}>
    <Center absolute inset="0rem">
      <Box width="20rem" height="20rem">
        <ProfilePicture user={user}/>
      </Box>
    </Center>
  </Container>
)

export default VideoPlaceholder
