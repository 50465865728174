import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useAudioVideo } from 'amazon-chime-sdk-component-library-react'

import { useStore } from '../../store'
import { Box, RangeSlider } from '../ui/primitives'
import { APIClient } from '../../api/client'
import { showHelpDialog } from '../dialogs/help'
import { BOARD_CONNECTION_STATE_CONNECTED } from '../../store/board-connection-states'
import { PeakMeter } from '../pages/mixer/components/peak-meter'
import {
  StopIcon,
  VideoOffIcon,
  QuestionIcon,
  VideoOnIcon,
  CopyClipboardIcon
} from '../ui/icons'

import {
    CenterSection,
    RightSection,
    FooterElement,
    LeftSection,
    QuestionMarkButton,
    RoundedButton,
    FooterButton,
    DurationInfo,
    Timer,
    MuteAllButton,
    PeakMeterWrapper,
    HelpContainer,
    FooterTooltip,
} from './styles'
import { showTalkbackDialog } from '../dialogs/TalkbackDialog'
import { showDialog } from '../dialogs/ConfirmationDialog'
import { sleep } from '../../store/board'
import { MAX_DURATION_MINUTES } from '../../store/room-manager'
import { TooltipBoundary, TooltipDescription } from '../ui/tooltip'

const toTimer = (duration, initialTime = null) => {
  const seconds = Math.round(duration)
  const secondsPlusInitialTime = Math.floor(seconds + (initialTime || 0))
  const pad = (num) => (num < 10 ? `0${num}` : `${num}`)
  const ss = pad(secondsPlusInitialTime % 60)
  const mm = pad(Math.floor(secondsPlusInitialTime / 60) % 60)
  const hh = pad(Math.floor(secondsPlusInitialTime / 60 / 60))
  return { hh, mm, ss, seconds, secondsPlusInitialTime }
}

let timerId

export const Footer = observer(function Footer ({ videoEnabled, onToggleVideo }) {
  const audioVideo = useAudioVideo()
  const store = useStore()
  const boardIsConnected = store.boardConnectionState === BOARD_CONNECTION_STATE_CONNECTED
  const [muted, setMuted] = useState(audioVideo?.realtimeIsLocalAudioMuted() ?? true)
  const [duration, setDuration] = useState(0)
  const [talkbackInputRefresh, setTalkbackInputRefresh] = useState(false)
  const isRoomOrSessionActive = store.isRoomOrSessionActive()
  const [isCopied, setIsCopied] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  useEffect(() => {
    if(!store.sessionMode){
      setTalkbackInputRefresh(false)
    }
  }, [store.sessionMode, store.roomManager.currentActiveRoom])

  useEffect(() => {
    if(!store.sessionMode) return
    timerId = setInterval(() => {
      setDuration(Math.floor((Date.now() - store.session?.startTime) / 1000))
    }, 1000)
    return () => {
      clearInterval(timerId)
    }
  })

  useEffect(() => {
    if (!audioVideo) return
    async function init() {
      store.session.talkback.deviceList = await audioVideo.listAudioInputDevices()
    }

    const callback = (mutedValue) => {
     setMuted(mutedValue)
    }
    audioVideo.realtimeSubscribeToMuteAndUnmuteLocalAudio(callback)
    init()
    return () => {
      audioVideo.realtimeUnsubscribeToMuteAndUnmuteLocalAudio(callback)
    }
  // eslint-disable-next-line
  }, [audioVideo])

  useEffect(() => {
    if (store.mixer?.panicModeShouldFlash) {
      setTimeout(() => {
        store.mixer.panicModeShouldFlash = false
      }, 2200)
    }
  }, [store.mixer, store.mixer?.panicModeShouldFlash])

  const recoverTalkbackSourceIfAvailable = async () => {
    if(talkbackInputRefresh) return

    if (store.session.talkback.deviceList.length < 1) { return }

    let selectedAudioDevice = store.session.talkback.deviceList.find((device) =>
      device.deviceId === store.settings.videoChat.selectedAudioInputDeviceId)

    if (!selectedAudioDevice) {
      showTalkbackDialog({ store });
      return
    }

    await audioVideo.startAudioInput(selectedAudioDevice.deviceId)
    setTalkbackInputRefresh(true)
  }

  const timer = toTimer(duration, store.roomManager.currentActiveTime)

  return (
    <FooterElement>
      <LeftSection>
        <HelpContainer id="step-4-support">
            <QuestionMarkButton onClick={() => showHelpDialog({ store })}>
              <QuestionIcon size={14} />
            </QuestionMarkButton>
        </HelpContainer>
      </LeftSection>
      <CenterSection>
        {store.roomManager.currentActiveRoom && (
          <FooterButton
            width='10rem'
            style={{ justifyContent: 'space-evenly' }}
            onClick={async () => {
              setIsCopied(true)
              await store.roomManager.copyRoomCode(store.roomManager.currentActiveRoom.room_code, store.roomManager.currentActiveRoom.room_id)
              await sleep(2000)
              setIsCopied(false)
            }}>
            {isCopied ? 'copied!' : 'Room ID'}
            <CopyClipboardIcon size={18} />
          </FooterButton>
        )}
          <FooterButton
            width='8rem'
            className={`${muted ? 'off' : 'on'} ${store.sessionMode ? '' : 'disabled'}`}
            onMouseDown={async (e) => {
              if(store.settings.videoChat.selectedAudioInputDeviceId === null && store.sessionMode) {
                showTalkbackDialog({ store });
                return
              }
              if (audioVideo) {
                await recoverTalkbackSourceIfAvailable()
                audioVideo.realtimeUnmuteLocalAudio()
              }
              const onMouseUp = () => {
                if (audioVideo) {
                  audioVideo.realtimeMuteLocalAudio()
                }
                window.removeEventListener('mouseup', onMouseUp)
                window.removeEventListener('pointerup', onMouseUp)
                window.removeEventListener('touchend', onMouseUp)
              }
              if (!e.shiftKey) {
                window.addEventListener('mouseup', onMouseUp)
                window.addEventListener('pointerup', onMouseUp)
                window.addEventListener('touchend', onMouseUp)
              }
            }}
          >
            talkback
          </FooterButton>
          <RoundedButton
            className={videoEnabled ? 'on' : 'off'}
            onClick={() => {
              onToggleVideo()
            }}
          >
            {videoEnabled ? <VideoOnIcon size={20} /> : <VideoOffIcon size={25} />}
          </RoundedButton>
        <Box width='18.5rem'>
          <Box display='flex' columnGap='1rem'>

            <TooltipBoundary
              onClickOutside={() => {
              if(!tooltipOpen) return
                setTooltipOpen(false)
            }}
              tooltip={
                        <FooterTooltip
                          bgColor='var(--ELK-Dark-Grey)'
                          color='var(--ELK-White)'
                          isOpen={tooltipOpen}
                          style={{ top: '-105px', left: '-100px'}}
                          headerToolTip
                          >
                          <h2>{MAX_DURATION_MINUTES} minutes limited session</h2>
                          <TooltipDescription>
                            <p>When the yellow bar reaches the end your time limited session is over.</p>
                          </TooltipDescription>
                        </FooterTooltip>
                      }
              >
              <DurationInfo className={!store.sessionMode ? 'disabled' : ''} onClick={() => {
                if(store.roomManager.currentActiveTime !== null){
                  setTooltipOpen(true)
                }
              }}>
                <Timer sessionMode={store.sessionMode}>
                  {store.sessionMode ? `${timer.hh}:${timer.mm}:${timer.ss}` : '00:00:00'}
                </Timer>
                {/* {store.roomManager.currentActiveTime !== null && store.sessionMode && TIMERS_ENABLED && (
                  <ProgressBarContainer width="100%" height={0}>
                    <progress className='timerProgress' max={MAX_DURATION_SESSIONS} value={timer.secondsPlusInitialTime} />
                  </ProgressBarContainer>
                  )} */}
              </DurationInfo>
            </TooltipBoundary>
            <RoundedButton
              className={`stop ${!isRoomOrSessionActive ? 'disabled' : ''}`}
              tooltip='leave session'
              disabled={!isRoomOrSessionActive}
              onClick={async () => {
                showDialog(
                  {
                    store,
                    title: `Leave room?`,
                    okText: 'Yes',
                    cancelText: 'No',
                    reverse: true,
                    okAction: async () => {
                      try {
                        clearInterval(timerId)
                        setDuration(0)
                        const analyticEventPayload = {}
                        if(store.roomManager.currentActiveRoom){
                          analyticEventPayload['Room ID'] = store.roomManager.currentActiveRoom.room_id
                          await store.roomManager.closeRoom()

                        }

                        if(store.sessionMode){
                          analyticEventPayload['Session ID'] = store.session.id
                          await store.leaveSession()
                        }
                        store.mixerVisible = true
                        store.analytics.track('Room_Leave', analyticEventPayload)

                      } catch (err) {
                        store.showSnackBar({
                          heading: 'Could not leave the session',
                          content: 'We are experiencing server issues. Please contact support',
                          level: 'error',
                          duration: 5000
                        })
                      }
                    }
                  })
              }}
            >
              <StopIcon size={16} />
            </RoundedButton>
          </Box>
        </Box>
      </CenterSection>
      <RightSection>
          <MuteAllButton
            className={`${store.mixer?.panicMode ? 'on' : 'off'} ${store.mixer?.panicModeShouldFlash ? 'flashing' : ''} ${boardIsConnected ? '' : 'disabled'}`}
            onClick={() => {
              if (!boardIsConnected) {
                return
              }
              APIClient.toggle_panic_mode()
            }}
          >
            <span />
          </MuteAllButton>
        {store.mixer && (

          <Box position="relative">
            <RangeSlider
              type="range"
              width={"160px"}
              style={{ opacity: store.mixer?.isPluginMode() ? '0' : '1'}}
              disabled={!boardIsConnected}
              onChange={(e) => {
                store.mixer.master.volume = Number(e.target.value)
              }}
              min={0} max={1} step={0.1} value={store.mixer.master.volume}
            />
            <PeakMeterWrapper>
              {[0, 1].map((idx) => (
                <Box key={idx} width='100%' height='4px'>
                  <PeakMeter
                    getValue={() => store.mixer?.peakMeters.get(`master/level_${idx}`)  ?? 0}
                    markerHeight={3}
                    markerSpacing={0}
                    horizontal
                    getUpdatedAt={() =>
                      store.mixer?.peakMeterUpdatedAt.get(`master/level_${idx}`) ?? 0}
                    errorLimit={Infinity}
                    disabled={store.mixer?.peakMeters.get(`master/level_0`) === undefined}
                  />
                </Box>
              ))}
            </PeakMeterWrapper>
          </Box>
          )}
      </RightSection>
    </FooterElement>
  )
}
)
