import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { Dialog, CloseButton, ModalButton, ConfirmationDialogTitle } from '../base'
import { openModal } from '../overlays'
import { Box, Text } from '../../ui/primitives'
import { withStore } from '../../../store'
import { Spinner } from '../../ui/spinner'

const DialogFooter = styled(Box)`
    display: flex;
    height: ${(props) => props?.autoHeight ? 'auto': '80px'};
    justify-content: space-between;
    margin-top: 20px;
`

export const BodyText = styled(Text)`
  font-size : 14px;
  word-break: break-word;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  svg {
    margin-bottom: 20px;
  }
`

const ConfirmationDialog = observer(({ close, title, body, okText, cancelText, okAction, cancelAction, reverse, showSpinner, hideClose = false }) => {

  return (
    <Dialog
      width="30rem"
      py="2rem"
      px="2rem"
      bgColor="var(--ELK-Dark-Grey)"
    >
      {!hideClose && <CloseButton onClick={() => close()} color="var(--ELK-White)" />}
      {title && <ConfirmationDialogTitle>{title}</ConfirmationDialogTitle>}


      {body && (
        <Box my="30px">
          <BodyText fontSize="14px">
            {showSpinner && (
              <Spinner />
            )}
            {body}
          </BodyText>
        </Box>
      )}

      <DialogFooter flexDirection={`column${reverse ? '-reverse' : ''}`} autoHeight={!(okText && cancelText)}>
        {cancelText && (
          <ModalButton
            danger
            onClick={async () => {
              if(cancelAction) cancelAction()
              close()
            }}
          >
            {cancelText}
          </ModalButton>
        )}
        {okText && (
          <ModalButton
            primary
            onClick={async () => {
              if(okAction) await okAction()
              close()
            }}
          >
            {okText}
          </ModalButton>
        )}
      </DialogFooter>
    </Dialog>
  )
})

export function showDialog({ store, title, body, okText, cancelText, okAction, cancelAction, reverse = false, showSpinner = false, closeAction, hideClose }) {
  return openModal(
    ({ close }) =>
      withStore(
        store,
        <ConfirmationDialog store={store}
                           close={() => {
                             if(closeAction) closeAction()
                             close()
                           }}
                           title={title}
                           body={body}
                           okText={okText}
                           cancelText={cancelText}
                           okAction={okAction}
                           cancelAction={cancelAction}
                           reverse={reverse}
                           showSpinner={showSpinner}
                           hideClose={hideClose}
        />
      ),
    {
      autoDismissable: false
    }
  )
}
