import React, { useEffect, useCallback, useReducer } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../store'
import { Box, H1, H2, H3, I, Text } from '../../../ui/primitives'
import styled from 'styled-components'
import { EditIcon } from '../../../ui/icons'
import { Checkbox } from '../../../ui/checkbox'
import { Button } from '../../../ui/button'
import { APIClient } from '../../../../api/client'

const Link = styled(H3)`
  white-space: nowrap;
  color: var(--ELK-White);
  cursor: pointer;
  margin-top: 0.5rem;
`

const StyledH2 = styled(H2)`
  font-weight: 700;
  color: var(--ELK-White);
  margin-bottom: 1rem;
`

const StyledTextarea = styled.textarea`
  resize: none;
  width: 100%;
  height: 10rem;
  padding: 1rem 1.2rem;
  border: 1px solid var(--ELK-Border);
  font-style: italic;
`

const DisplayNameInput = styled.input`
  padding: 10px;
  font-size: 3.6rem;
  color: var(--ELK-Black);
  font-weight: 700;
`

const SaveButton = styled(Button)`
  margin-top: 2.9rem;
  align-self: end;
`

const ProfileSection = styled(Box)`
  margin-top: 1.8rem;
`
const HiddenFileInput = styled.input`
  visibility: hidden;
  position: absolute;
`

function reducer(state, { action, payload }) {
  function newState(stateUpdate) {
    return {
      ...state,
      ...stateUpdate,
    }
  }
  switch (action) {
    case 'editingDisplayName':
      return newState({ editingDisplayName: true })
    case 'displayNameChanged':
      return newState({ profileChanged: true, displayName: payload })
    case 'imageChanged':
      return newState({ image: payload })
    case 'privacyStatusChanged':
      return newState({ profileChanged: true, privacyStatus: payload })
    case 'personalSummaryChanged':
      return newState({ profileChanged: true, personalSummary: payload })
    case 'syncState':
      return newState({ ...payload })
    case 'reset':
      return newState({ editingDisplayName: false, profileChanged: false })
    default:
      throw new Error('Unsupported action submitted')
  }
}

export const UserProfile = observer(() => {
  const store = useStore()
  const user = store.users.get(store.currentUserId)
  const userName = user.name

  const [{
    editingDisplayName,
    displayName,
    image,
    privacyStatus,
    personalSummary,
    profileChanged,
  }, dispatch] = useReducer(reducer, {
    editingDisplayName: false,
    displayName: userName,
    image: '',
    privacyStatus: false,
    personalSummary: '',
    profileChanged: false
  })

  const setProfileFields = useCallback((profileData) => {
    async function invokeBackend() {
      try {
        await APIClient.set_profile_fields(
          user.id,
          privacyStatus ? 'PRIVATE' : 'PUBLIC',
          personalSummary,
          profileData.image ?? image,
        )
      } catch (e) {
        store.showSnackBar({
          heading: "Something went wrong when trying to update your profile",
          content: "We are experiencing server issues. Please contact support",
          level: "error"
        })
        return
      }
    }
    invokeBackend()
  }, [privacyStatus, image, personalSummary, store, user.id])

  const onFileInputChange = useCallback((e) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.addEventListener('load', async (event) => {
      const readImage = event.target.result
      setProfileFields({
        image: readImage,
      })
      dispatch({ action: 'imageChanged', payload: readImage })
    })
    reader.readAsDataURL(file)
  }, [setProfileFields])

  useEffect(() => {
    // Override the value of the profile image url for the current user in the store
    // The real url will be refetched on page reload but to make sure that the user
    // gets the update immediately after saving we set it here too
    if (image) {
      user.profile_image_url = image
    }
  }, [image, user])

  const onSave = useCallback(() => {
    async function save() {
      if (displayName !== userName) {
        try {
          await APIClient.set_display_name(user.id, displayName)
        } catch (e) {
          store.showSnackBar({
            heading: "Something went wrong when trying to change your display name",
            content: "We are experiencing server issues. Please contact support",
            level: "error",
            duration: 100000
          })
          return
        }

        user.name = displayName
      }

      setProfileFields({
        image: '', // Ignore uploading file again (handled directly when changing image)
      })

      dispatch({ action: 'reset' })
    }

    save()
  }, [displayName, store, user, userName, setProfileFields])

  useEffect(() => {
    async function getProfileFields() {
      const result = await APIClient.get_profile_fields(user.id)
      dispatch({
        action: 'syncState',
        payload: {
          privacyStatus: result.privacy === 'PRIVATE',
          personalSummary: result.personal_summary,
        }
      })
    }
    getProfileFields()
  }, [user.id])

  return (
    <Box  display="flex" justifyContent={"center"}>
      <Box display="flex" direction="column" width="45rem">
        {editingDisplayName ? <DisplayNameInput autoFocus value={displayName} onChange={(e) => dispatch({ action: 'displayNameChanged', payload: e.target.value })} /> : <H1 color="var(--ELK-White)">{userName}</H1>}
        <Link onClick={() => dispatch({ action: 'editingDisplayName' })}>
          <Box flex columnGap="0.5rem" alignItems="center">
            <EditIcon />
            <Box>Change Name</Box>
          </Box>
        </Link>
        <Link onClick={() => store.AWSLogout()}>
          <Box flex columnGap="0.5rem" alignItems="center">
            <Box>Logout</Box>
          </Box>
        </Link>
        <ProfileSection>
          <Box display="flex" alignItems="center">
            <label htmlFor="avatar">
              <Link>
                <Box flex columnGap="0.5rem" alignItems="center">
                  <EditIcon />
                  <Box>Change Profile Picture</Box>
                </Box>
              </Link>
            </label>
            <HiddenFileInput
              type="file"
              id="avatar"
              name="avatar"
              onChange={onFileInputChange}
              accept="image/png, image/jpeg"
            />
          </Box>
        </ProfileSection>
        <ProfileSection>
          <StyledH2>Visibility</StyledH2>
          <Box flex>
            <Checkbox
              checked={privacyStatus}
              onChange={(e) => dispatch({ action: 'privacyStatusChanged', payload: e.target.checked })}
            />
            <Box ml="13px">
              <Box color="var(--ELK-White)">
                private
              </Box>
              <I color="var(--ELK-Text-White)">
                By default, your profile is set to public. It's the recommended setting to be easily found by other musicians looking for someone to play with. With your profile set to private, you can be found only by your email.
              </I>
            </Box>
          </Box>
        </ProfileSection>
        <ProfileSection>
          <StyledH2>About me</StyledH2>
          <StyledTextarea
            placeholder="Let people know who you are and what you're playing! \n Ready to meet new people? Let people know you're #OPENTOJAM?"
            value={personalSummary}
            maxLength="250"
            onChange={(e) => dispatch({ action: 'personalSummaryChanged', payload: e.target.value })}
          />
          <Box textAlign="end">
            <Text>{personalSummary.length}/250</Text>
          </Box>
        </ProfileSection>
        <SaveButton
          width="11.5rem"
          height="4rem"
          fontSize="1.8rem"
          primary
          disabled={!profileChanged}
          onClick={onSave}
        >
          Save
        </SaveButton>
      </Box>
    </Box>
  )
})
