import { useAudioVideo } from 'amazon-chime-sdk-component-library-react'
import { useEffect, useState } from 'react'

const useVideoPausedState = (tileId) => {
  const audioVideo = useAudioVideo()
  const [paused, setPaused] = useState(false)

  useEffect(() => {
    if (!audioVideo) return

    const tile = audioVideo.getVideoTile(tileId)
    if (tile) {
      setPaused(tile.state().paused)
    }

    const observer = {
      videoTileDidUpdate: (tileState) => {

        if (tileState.tileId === tileId) {
          setPaused(tileState.paused)
        }
      },
    }

    audioVideo.addObserver(observer)

    return () => audioVideo.removeObserver(observer)
  }, [tileId, audioVideo])

  return paused
}

export default useVideoPausedState