import styled from 'styled-components'
import { Box, property } from '../ui/primitives'

export const LegacyButton = styled(Box).attrs(props => ({as: props.as || 'button', type: props.type || 'button'}))`
  ${props => (props.primary || props.secondary) && `
    display: inline-block;
    color: white;
    padding: 1rem 2rem;
    text-align: center;
    background: ${props.secondary ? 'transparent' : '#F2C700'};
    border: 1px solid ${props.secondary ? '#c1c1c1' : 'transparent'};
    outline-offset: 2px;
    transition: background .2s ease, border-color .2s ease, color .2s ease;
    ${property(props, 'px', (px) => `padding-left: ${px}; padding-right: ${px};`)}
    ${property(props, 'width', 'width')}

    &:hover {
      background: ${props.secondary ? 'transparent' : '#F2C700'};
      ${props.secondary && `border-color: white;`}
    }

    &:disabled {
      cursor: default;
      background: ${props.secondary ? 'transparent' : '#F2C700'};
      border-color: ${props.secondary ? '#5d5d5d' : 'transparent'};
    }
  `}
  &:focus {
    outline: 1px dotted var(--color-light-grey);
  }
`
