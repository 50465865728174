import React, { useEffect } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { Box, Text } from '../../../../ui/primitives'
import { useStore } from '../../../../../store'
import { Auth } from 'aws-amplify'
import styled from 'styled-components'
import { ShowPasswordIcon } from '../../../../ui/icons'
import { Spinner } from '../../../../ui/spinner'
import {
  Title,
  StyledButton,
  BetaTextField,
  StyledH4,
  Paragraph,
  StyledLink
} from './style'
import { BetaLayout } from './layout'
import { validateEmail } from '../../../../../utils/utils'

const RegisterForm = styled.form`
  width: 40.8rem;
  padding: 2.6rem 3rem;
`

const ShowPasswordButton = styled.button`
  position: relative;
  z-index: 99;
  float: right;
  top: -4.3rem;
  right: 1rem;
  margin: ${props => props.m ? props.m : 0};
`

export const Register = observer(({ setPage }) => {
  const store = useStore()
  const state = useLocalObservable(() => ({
    email: '',
    password: '',
    displayName: '',
    verificationCode: '',
    busy: false,
    passwordShown: false,
    passwordError: '',
    hasRegistered: false
  }))

  useEffect(() => {
    store.analytics.track('Page Viewed')
  // eslint-disable-next-line
  }, [])

  const checkPasswordRequirements = () => {
    return state.password.length >= 8
  }

  const togglePasswordShown = () => {
    state.passwordShown = !state.passwordShown
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    state.busy = true
    if (checkPasswordRequirements()) {
      try {
        const analyticsId = Object.values(store.analytics.getAnonymousId())[0]
        await Auth.signUp({
          username: state.email,
          password: state.password,
          attributes: {
            'custom:displayname': state.displayName,
            'custom:analytics_id': analyticsId
          }
        })
        store.analytics.track('Account Created', { $email: state.email, name: state.displayName })
        store.analytics.identify({  email: state.email, display_name: state.displayName },analyticsId)
        store.analytics.alias(state.email, analyticsId)

        store.currentUserEmail = state.email
        store.userConfirmed = false
        const newURL = window.location.protocol + "//" + window.location.host + window.location.pathname + '?success=true';
        window.history.pushState({path:newURL},'',newURL)
        setPage('download')
      } catch (error) {
        console.error(error)
        store.showSnackBar({
          heading: 'Signup failed',
          content: error.message,
          level: 'error',
          duration: 5000
        })
      }
    }
    state.busy = false
  }

  const field = (name) => ({
    value: state[name],
    onChange: (e) => {
      (state[name] = e.target.value)
      if (name === 'password') {
        checkPasswordRequirements()
      }
      if (name === 'email') {
        validateEmail(state.email)
      }
      state.passwordError = ''
    }
  })

  return (
    <BetaLayout setPage={setPage}>
      <>
        <Title>Signup & get started</Title>
        <Text textAlign="center" mt="20px">
          Elk comes with a free plan to make it easy and risk-free to try it out.<br />
          Sign up right here, then download the app and start your online music collaborations!
        </Text>
        <RegisterForm onSubmit={handleSubmit}>
          <>
            {/*<div>*/}
            {/*  <SocialButton onClick={async () => {*/}
            {/*    await store.AWSGoogleSignIn()*/}
            {/*  }}>*/}
            {/*    <GoogleIcon size={20} />*/}
            {/*    Sign in with Google*/}
            {/*  </SocialButton>*/}
            {/*  /!*<FacebookButton>*!/*/}
            {/*  /!*  <FacebookIcon size={20} />*!/*/}
            {/*  /!*  Sign in with Facebook*!/*/}
            {/*  /!*</FacebookButton>*!/*/}
            {/*</div>*/}
            {/*<Box textAlign="center">*/}
            {/*  or*/}
            {/*</Box>*/}
            <BetaTextField
              placeholder='name'
              type='text'
              {...field('displayName')}
              m='1.5rem 0'
              required
            />
            <BetaTextField
              placeholder='email'
              type='email'
              {...field('email')}
              m='1.5rem 0'
              required
            />
            <BetaTextField
              placeholder='password (at least 8 characters)'
              type={state.passwordShown ? 'text' : 'password'}
              {...field('password')}
              required
              m='1.5rem 0'
              bg={state.passwordError}
            />
            <ShowPasswordButton
              type='button' onClick={togglePasswordShown}
              m='0 0 -1.5rem 0'
            >
              <ShowPasswordIcon size='18' />
            </ShowPasswordButton>
            <StyledButton
              type='submit'
              data-testid='submit'
              disabled={
              state.busy ||
                (!checkPasswordRequirements() || state.displayName === '' || !validateEmail(state.email))}
            >
              {state.busy ? <Spinner size='24' /> : <>Create Account</>}
            </StyledButton>
          </>
        </RegisterForm>
        <Box fontSize='1.4rem' textAlign="center"> By continuing, you agree to our <br />
          <StyledLink href="https://elk.audio/elk-audio-terms-conditions" target="_blank" rel="noreferrer">Terms of Use</StyledLink> and&nbsp;
          <StyledLink href="https://elk.audio/elk-audio-privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</StyledLink>
        </Box>

        <Box fontSize='1.4rem' mt="1.5rem"textAlign="center">
          <Paragraph>
            Already registered?&nbsp;
            <StyledLink onClick={() => setPage('download')}>
              Download here
            </StyledLink>
          </Paragraph>
        </Box>
        <Box fontSize='1.4rem' textAlign="center"  mb="5rem" >
          <StyledH4>Good to know:</StyledH4>
          <Paragraph>To use Elk you’ll need a <strong>Mac with MacOS 11 or higher.</strong></Paragraph>
          <Paragraph>Support for Windows and DAW plugin is coming - register above to be notified.</Paragraph>
          <br />
        </Box>
      </>
    </BetaLayout>
  )
})


