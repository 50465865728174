/* eslint-disable no-useless-computed-key */

import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  CardContent,
  IconWrapper,
} from './card-components'
import {
  EditIcon,
  SessionEqualizer,
} from '../ui/icons'
import { CardFooter, EditIconWrapper, RoomTitle, StyledMembers, StyledRoomCard } from './styles'
import { useStore } from '../../store'
import { showCreateRoomDialog } from '../dialogs/Room/room-form'
import { showDialog } from '../dialogs/ConfirmationDialog'
import { ADMITTED } from '../dialogs/Room/member-status'
import { NIKKEI_DISABLED } from '../../store/system-states'
import { MAX_BLOCKED_TIME } from '../../store/room-manager'

export const RoomCard = observer(({ room }) => {
  const store = useStore()
  const [freemiumBlocked, setFreemiumBlocked] = useState(false)

  useEffect(() => {
    room.users.forEach(async user => {
      if(!store.users.get(user?.user_id)){
        await store.requestFetchUserInfo(user?.user_id, true)
      }
    })

    const freemiumTimestamp = localStorage.getItem('freemiumWaitingDisabled')

    if(freemiumTimestamp){
      const remainingSeconds = Math.floor((Date.now() - Number(freemiumTimestamp)) / 1000)
      // init block timer only if last block timestamp is more than 5 minutes away
      if(remainingSeconds < MAX_BLOCKED_TIME){
        setFreemiumBlocked(true)
        const interval = setInterval(() => {
          const secondsToUnblock = Math.floor((Date.now() - Number(freemiumTimestamp)) / 1000)
          if(secondsToUnblock >= MAX_BLOCKED_TIME){
            setFreemiumBlocked(false)
            clearInterval(interval)
          }
        }, 1000)
      }
    }
    //eslint-disable-next-line
  }, [store.roomManager.currentActiveRoom, store.roomManager.currentActiveTime])
  return (
    <>
      <CardContent>
        <StyledRoomCard>
          <RoomTitle
            disabled={store.systemState === NIKKEI_DISABLED || freemiumBlocked}
            title={room.title}
            onClick={async () => {

            if(freemiumBlocked) {
              store.roomManager.showBlockedRoomDialog()
              return
            }

              if(!store.mixer.isAudioEngineReady())
              {
                store.roomManager.showGenericErrorDialog(
                  store.systemState === NIKKEI_DISABLED
                  ? 'You need to enable Elk WIRE plugin before entering the room'
                  : 'You need to select audio device before entering a room'
                )
                return
              }
              showDialog(
                {
                  store,
                  title: `Enter room ${room.title}`,
                  okText: 'Yes',
                  cancelText: 'Cancel',
                  reverse: true,
                  okAction: async () => {
                    store.analytics.track('Room_Clicked Enter', {['Room ID'] : room.room_id })
                    await store.roomManager.joinRoom(room.room_id)
                  }
              })
            }}
          >
            {room.title.length > 35 ? `${room.title.slice(0, 35)}...` : room.title}
          </RoomTitle>
          <EditIconWrapper
            onClick={() => {
              showCreateRoomDialog({ store, room })
              store.analytics.track('Room_Click Edit Icon', { ['Room ID']: room.room_id })
            }}
          >
            <EditIcon
              color={store.systemState === NIKKEI_DISABLED || freemiumBlocked ? '#8C8C8C' : '#fff'}
              width={18}
              height={18}
            />
          </EditIconWrapper>
          <CardFooter>
            <ul>
              {room.users.map(({user_id, is_active, status }) => {
                if(user_id === store.currentUserId) return null
                if(status === ADMITTED && !is_active) return null

                const currentUser = store.users.get(user_id)
                return currentUser && (
                  <StyledMembers active={is_active}>
                  {currentUser.display_name || currentUser.name}
                  </StyledMembers>
                )
              })}
            </ul>
            {
              room.session_id && (
                <IconWrapper>
                  <SessionEqualizer size={25} color="var(--ELK-Sea-Foam-Green)" />
                </IconWrapper>
              )
            }
          </CardFooter>

        </StyledRoomCard>
      </CardContent>
    </>
  )
})
