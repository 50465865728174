import styled from 'styled-components'
import { Box } from '../ui/primitives'
import { Button } from '../ui/button'

export const CardContainer = styled.div`
  position: relative;
  width: 20vh;
  min-width: 180px;
  max-width: 225px;
  height: 180px;
  color: white;
`

export const CardContent = styled.div`
  position: relative;
  height: 20vh;
  min-height: 180px;
  max-height: 180px;
`

export const ClickableCardContent = styled(CardContent)`
  cursor: pointer;
   &:hover {
    filter: brightness(0.58);
  }
`

export const CardMenu = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 3px;
`
export const CardMenuItem = styled.button`
  justify-content: space-between;
  background: var(--ELK-White);
  color: var(--ELK-Black);
  align-items: center;
  width: 100%;
  display: flex;
  padding: 0 0.8rem;
  height: 3.4rem;
  &:hover {
    background: var(--ELK-Standard-Enabled);
    color: var(--ELK-White);
  }
  &.disabled {
    background: var(--ELK-Standard-Inactive);
    color: var(--ELK-Text-Standard-Inactive);
    cursor: default;
  }
`

export const CardList = styled.div`
  width: 100%;
`

export const CardListItem = styled(Box)`
  position: relative;
  height: 3.4rem;
  bottom: 3.4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0.8rem;
  column-gap: 0.8rem;
  color: var(--ELK-Black);
  background-color: ${(props) => props.bgColor ?? 'var(--ELK-Black)'};
  & > h3 {
    line-height: 1.5;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

export const FriendStatusItem = styled(CardListItem)`
`

export const ActionsList = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 3px;
`

export const ActionItem = styled(Button)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
  padding: 0 0.8rem;
  height: 3.4rem;
  & > h3 {
    margin: auto;
    line-height: 1.5;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

export const LiveSessionItem = styled(Box)`
  position: relative;
  height: 3.4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;
  padding: 0 0.8rem;
  margin-top: auto;
  color: var(--ELK-Black);
  background-color: var(--ELK-High-Prio-Yellow-Enabled);
  & > h3 {
    margin: auto;
    line-height: 1.5;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

export const IconWrapper = styled.div``
