import React from 'react'
import { observer } from 'mobx-react-lite'
import { Dialog, ModalButton } from './base'
import { openModal } from './overlays'
import { Box, Text, H1 } from '../ui/primitives'
import { withStore } from '../../store'

const WifiWarningDialog = observer(({ close }) => {
  return (
    <Dialog
      width="60rem"
      py="4rem"
      px="2.5rem"
      bgColor="var(--ELK-White)"
    >
      <H1 fontSize="3.2rem" color="var(--ELK-Black)" textAlign="center" mb="2.5rem">Looks like you’re on wifi</H1>
      <Text textAlign="center" mb="5rem" mt="5rem" color="var(--ELK-Black)" fontSize="1.8rem">
        For the best performance possible, especially for live jams, try using Elk LIVE with a wired internet connection (ethernet cable).
      </Text>

      <Box mt="2rem" maxWidth="32rem" mx="auto" flex column rowGap="2rem">
        <ModalButton primary height="3.8rem" width="100%" fontSize="1.8rem" py="10px" onClick={() => {close()}}>Ok, got it!</ModalButton>
      </Box>
    </Dialog>
  )
})

export function showWifiWarningDialog({ store }) {
  return openModal(
    ({ close }) => withStore(store, <WifiWarningDialog close={close}/>), {
      autoDismissable: true,
      alwaysOnTop: true
    }
  )
}
