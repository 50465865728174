/* eslint-disable no-useless-computed-key */

import React, { useState, useEffect, useCallback, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { Dialog, CloseButton, ModalButton, DialogTitle } from '../base'
import { openModal } from '../overlays'
import { Box, Text } from '../../ui/primitives'
import { withStore } from '../../../store'
import { FormLabel } from '../Room/styles'
import { DeleteIcon, ImageUpload } from '../../ui/icons'
import { TextField } from '../../ui/text-field'
import { Picture, ProfileAvatar } from '../../ui/profile-avatar'
import {
  AvatarContainer,
  FloatingButtonContainer,
  FormContainer,
  ProfileEditButtons,
  HiddenFileInput
} from './styles'
import { APIClient } from '../../../api/client'
import { getCurrentPriceId, getScheduleCancel, getSubscriptionByPriceId } from '../../../api/payment/paddle'
import { showSubscriptionDialog } from '../Subscription'
import { getSubscriptionsEnabled } from '../../../utils/utils'

const ProfileDialog = observer(({ close, store }) => {

  const user = store.users.get(store.currentUserId)
  const fileInput = useRef()

  const [image, setImage] = useState('')
  const [displayName, setDisplayName] = useState(user.display_name || '')
  const [profileData, setProfileData] = useState({})
  const [subscriptionData, setSubscriptionData] = useState(null)
  useEffect(() => {
    async function getProfileFields() {
      const result = await APIClient.get_profile_fields(user.id)
      setProfileData({
        privacyStatus: result.privacy === 'PRIVATE',
        personalSummary: result.personal_summary,
      })
    }

    async function getSubscriptionPlans() {
      await APIClient.get_product_data(user.id)
    }


    getProfileFields()
    getSubscriptionPlans()
    setSubscriptionData(getCurrentPriceId(store))
  // eslint-disable-next-line
  }, [user.id])

  const triggerFileInput = () => {
    fileInput.current.click()
  }

  const onFileInputChange = useCallback((e) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.addEventListener('load', async (event) => {
      const readImage = event.target.result
      setImage(readImage)
    })
    reader.readAsDataURL(file)
  }, [setImage])

  const onSubmitProfile = async () => {
    await store.updateProfileFields(profileData, displayName, image)
    close()
  }

  const getSubscriptionToDisplay = () => {
    return `unlimited ${getSubscriptionByPriceId(store, subscriptionData.priceId).billing_cycle.interval}ly`
  }

  const saveEnabled = (user.display_name !== displayName) || image !== ''
  const scheduledCancel = getScheduleCancel(store)

  return (
    <Dialog
      width="30rem"
      py="2rem"
      px="2rem"
      bgColor="var(--ELK-Dark-Grey)"
    >
      <CloseButton onClick={() => close()} color="var(--ELK-White)" />
      <DialogTitle>Profile Settings</DialogTitle>

      <FormContainer>
        <FormLabel>
          Profile Image
        </FormLabel>

        <AvatarContainer onClick={() => {
          triggerFileInput()
        }}>
          {image ? (
            <Picture
              cover
              image={image}
            />
          ): (
            <ProfileAvatar
              user={store.users.get(store.currentUserId)}
            />
          )}
        </AvatarContainer>

        <FloatingButtonContainer>
          <ProfileEditButtons onClick={() => {
            triggerFileInput()
          }}>
            <ImageUpload size={20} />
            <HiddenFileInput
              type="file"
              id="avatar"
              name="avatar"
              onChange={onFileInputChange}
              accept="image/png, image/jpeg"
              ref={fileInput}
            />
          </ProfileEditButtons>
          <ProfileEditButtons style={{ opacity: 0}}>
            <DeleteIcon size={20} />
          </ProfileEditButtons>
        </FloatingButtonContainer>

        <FormLabel>
          Nickname
        </FormLabel>
        <TextField
          placeholder="Name"
          type="text"
          m="1rem 0"
          value={displayName}
          onChange={(e) =>{
            setDisplayName(e.target.value)
          }}
        />

      </FormContainer>

      {getSubscriptionsEnabled() === 'true' && (
        <Box mb="25px">
          <FormLabel mt="10px">
            Subscription
          </FormLabel>
          <ModalButton
            mt="1rem"
            secondary
            onClick={() => {
              close()
              store.analytics.track('Subs_clicked_button_profile', { ['Subscription Status']: subscriptionData ? getSubscriptionToDisplay() : 'Free'})
              showSubscriptionDialog({ store })
            }}
            >
            {subscriptionData ? getSubscriptionToDisplay() : 'Free'}
          </ModalButton>
          {scheduledCancel !== false && (
            <Text mt="10px" color="var(--ELK-High-Prio-Green-Enabled)" textAlign="center">
              Scheduled to cancel on <strong>{new Date(scheduledCancel).toDateString()}</strong>
            </Text>
          )}
        </Box>
      )}
      <Box flex flexDirection="column" mt="20px">
        <ModalButton
          secondary
          onClick={async () => { await store.AWSLogout() }}
        >
          Logout
        </ModalButton>
        <ModalButton
          primary
          mt="30px"
          disabled={!saveEnabled}
          onClick={async () => {
            await onSubmitProfile()
          }}
        >
      Save Changes
        </ModalButton>
      </Box>
    </Dialog>
  )
})

export function showProfileDialog({ store }) {
  return openModal(
    ({ close }) =>
      withStore(
        store,
        <ProfileDialog store={store} close={close} />
      ),
    {
      autoDismissable: false
    }
  )
}
