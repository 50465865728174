import React, { useEffect } from  'react'
import { observer } from 'mobx-react-lite'
import { openModal } from '../overlays'
import { Dialog } from '../base'
import { Box, Text } from '../../ui/primitives'
import { useStore, withStore } from '../../../store'
import { Spinner } from '../../ui/spinner'
import { FailIcon, SuccessIcon } from '../../ui/icons'

const AsyncLoading = ({text, store}) => {

    const content = () => {
        if(['SUCCESS', 'COMPLETED'].includes(store.loadingStatus))
            return (
                <Box flex flexDirection="column" alignItems="center" justifyContent="center">
                    <SuccessIcon />
                    <Text fontSize="18px" mt="10px" fontWeight="800">
                        Plan changed
                        succesfully!</Text>
                </Box>
                )
        if(store.loadingStatus === 'FAILURE'){
            return (<Box flex flexDirection="column" alignItems="center" justifyContent="center">
                <FailIcon />
                <Text fontSize="18px" mt="10px" fontWeight="800">
                    Failure changing plan.</Text>
                <Text>Try again. If it still doesn’t work,
                    contact support</Text>
            </Box>)
        }

        return text
    }

    return (
        <Box flex
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            textAlign="center"
            width="280px"
            height="252px"
            p="0 20px"
            margin="0 auto"
            >
            {store.loadingStatus === 'LOADING' && (
                <Spinner
                    style={{
                    verticalAlign: 'top',
                        marginRight: '3px',
                    }}
                    size={50}
                    color="var(--ELK-White)"
                />
            )}
            {content()} <br />
        </Box>
        )
}

const AsyncLoadingDialog = observer(({ close, text }) => {
    const store = useStore()

    useEffect(() => {
        if(['COMPLETED','FAILURE'].includes(store.loadingStatus)){
            close()
        }
        // eslint-disable-next-line
    }, [store.loadingStatus])

    return (
        <Dialog width="auto" bgColor="var(--ELK-Black)">
            <AsyncLoading text={text} status={store.loadingStatus} close={close} store={store}  />
        </Dialog>
        )
})


export function showAsyncLoading({ store, text }) {
    return openModal(
        ({ close }) => withStore(store,
                                 <AsyncLoadingDialog close={close} store={store} text={text} />
                                 )
                                 )
}
