import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import { useStore } from '../../store'
import { Box } from '../ui/primitives'
import SystemSettings from '../features/Settings'
import DeviceStatus from '../features/DeviceStatus'
import {
  SettingsGear,
  LowInternetIcon,
} from '../ui/icons'

import { ACTIVE_VIEW_STATE_MIXER } from '../../store/active-view-states'
import { isDesktop } from '../../utils/utils'
import { ProfileAvatar } from '../ui/profile-avatar'
import { showProfileDialog } from '../dialogs/ProfileDialog'
import {
  HeaderContainer,
  MenuOption,
  StyledHeader,
  SettingsWrapper,
  NetworkWarning,
  LowInternetWarning,
  AvatarContainer,
  DraggableHeader, FooterTooltip
} from './styles'
import { TooltipBoundary, TooltipDescription } from '../ui/tooltip'

export const Header = observer(() => {

  const store = useStore()

  const settingsRef = useRef(null)
  const buttonRef = useRef(null)
  const [tooltipOpen, setTooltipOpen ] = useState({
    wifi: false,
    slowInternet: false
  })

  useEffect(() => {
    const hideMenu = (e) => {
      if(store.activeViewState === ACTIVE_VIEW_STATE_MIXER) return
      if (settingsRef.current?.contains(e.target)) return
      if (buttonRef.current?.contains(e.target)) return
      if(e.target.getAttribute('data-hidemenu') === "false") return
      store.showSettingsView = false
    }
    document.addEventListener('click', hideMenu)
    return () => {
      document.removeEventListener('click', hideMenu)
    }
  }, [settingsRef, buttonRef, store])


  return (
    <HeaderContainer>
      <StyledHeader>
        <Box flex row alignItems="center" color="var(--ELK-White)" width="auto">
          {store.isDeveloperMode && (
            <DeviceStatus />
          )}
        </Box>
        <DraggableHeader />
        <Box flex row justifyContent="flex-end" alignItems="center" width="auto">
          {
            isDesktop() && (
              <>
                {store.speedTestResults?.upload < 10 &&
                  <TooltipBoundary
                    onClickOutside={() => {
                      if(!tooltipOpen.slowInternet) return
                      setTooltipOpen({
                        ...tooltipOpen,
                        slowInternet: false
                      })
                    }}
                    tooltip={
                      <FooterTooltip
                        bgColor='var(--ELK-Dark-Grey)'
                        color='var(--ELK-White)'
                        isOpen={tooltipOpen.slowInternet}
                        headerToolTip
                      >
                        <h2>Slow connection</h2>
                        <TooltipDescription>
                          <p>You have a slow internet connection and it might impact your experience.</p>
                          <p>Is there anything else stealing bandwidth on your network (streaming, downloads, etc)?</p>
                        </TooltipDescription>
                      </FooterTooltip>
                    }
                  >
                    <LowInternetWarning>

                     <div onClick={() => {
                       setTooltipOpen({
                         ...tooltipOpen,
                         wifi: false
                       })
                     }}>
                       <LowInternetIcon />
                     </div>
                    </LowInternetWarning>

                  </TooltipBoundary>
                }
                {store.isWirelessConnection &&
                  <>
                    <TooltipBoundary
                      onClickOutside={() => {
                        if(!tooltipOpen.wifi) return
                        setTooltipOpen({
                          ...tooltipOpen,
                          wifi: false
                        })
                      }}
                      tooltip={
                        <FooterTooltip
                          bgColor='var(--ELK-Dark-Grey)'
                          color='var(--ELK-White)'
                          isOpen={tooltipOpen.wifi}
                          headerToolTip
                        >
                          <h2>WiFI</h2>
                          <TooltipDescription>
                            <p>Looks like you’re on WIFI. Wired internet connections provide a faster and more stable connection than wireless.</p>
                          </TooltipDescription>
                        </FooterTooltip>
                      }
                    >
                      <NetworkWarning flex onClick={() => {
                        setTooltipOpen({
                          ...tooltipOpen,
                          wifi: !tooltipOpen.wifi
                        })
                      }}>WiFi</NetworkWarning>
                    </TooltipBoundary>
                  </>
                }
              </>
            )
          }
          {store.isDeveloperMode && (
            <MenuOption
              ref={buttonRef}
              onClick={() => {
                store.showSettingsView = !store.showSettingsView
              }}
            >
              <Box
                color={'var(--ELK-White)'}
                bgColor="var(--ELK-Black)"
              >
                <SettingsGear size={25} />
              </Box>
            </MenuOption>
          )}
          <Box>
            <AvatarContainer onClick={() => {
              store.analytics.track('Clicked Edit Profile icon')
              showProfileDialog({ store })
            }}>
              <ProfileAvatar
                user={store.users.get(store.currentUserId)}
              />
            </AvatarContainer>
          </Box>
        </Box>
      </StyledHeader>
      {store.showSettingsView && (
        <SettingsWrapper ref={settingsRef}>
          <SystemSettings />
        </SettingsWrapper>
      )}
    </HeaderContainer>
  )
})
