import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../store'
import { SimpeH2 } from '../styles'
import Dropdown from '../../../ui/form/dropdown'
import { Box } from '../../../ui/primitives'
import { BOARD_CONNECTION_STATE_CONNECTED } from '../../../../store/board-connection-states'

function MinimalDesktopAudioSelection() {
  const [audioDevices,setAudioDevices] = useState([])
  const store = useStore()
  const [audioInput, setAudioInput] = useState(null)
  const [audioOutput, setAudioOutput] = useState(null)

  useEffect(() => {
    const devices = store.mixer.desktop.availableDevices
    const devicesToShow =  devices.map(({  uid, name, inputs, outputs }) =>
      ({ title: name, value: uid, inputs, outputs }))
    setAudioDevices(devicesToShow)

    if(store.mixer.desktop.selectedInput){
      const inputIndex = devicesToShow.filter(({ inputs }) => inputs > 0)
        .findIndex(( { value }) => value === store.mixer.desktop.selectedInput )
      setAudioInput(inputIndex)
    }

    if(store.mixer.desktop.selectedOutput){
      const outputIndex = devicesToShow.filter(({ outputs }) => outputs > 0)
        .findIndex(( { value }) => value === store.mixer.desktop.selectedOutput )
      setAudioOutput(outputIndex)
    }

    if(store.mixer.desktop.selectedInput === null){ setAudioInput(null) }

    if(store.mixer.desktop.selectedOutput === null){ setAudioOutput(null) }

    // eslint-disable-next-line
 }, [store.mixer.desktop.availableDevices, store.mixer.desktop.selectedInput, store.mixer.desktop.selectedOutput])


  const selectAudioDevices = async () => {
    await store.mixer.setAudioDevices()
  }

  const inputs = audioDevices.filter(({ inputs }) => inputs > 0)
  const outputs = audioDevices.filter(({ outputs }) => outputs > 0)
  const getCurrentInputDevice = () => { return store.mixer.desktop.availableDevices.find(({ uid }) => uid === inputs[audioInput]?.value) }
  const getCurrentOutputDevice = () =>{ return store.mixer.desktop.availableDevices.find(({ uid }) => uid === outputs[audioOutput]?.value) }

  const applySettings = async (input,output) => {
    if(input >= 0 && output >= 0){
      const selectedInputUid = inputs[input].value
      const selectedOutputUid = outputs[output].value
      if(!store.mixer.checkAppleBuiltInDevices(selectedInputUid, selectedOutputUid)) {
        store.mixer.desktop.selectedInput = selectedInputUid
        store.mixer.desktop.selectedOutput = selectedOutputUid
        await selectAudioDevices()
      }
    }
  }

  const enableAudioSelection = !store.sessionMode && store.boardConnectionState === BOARD_CONNECTION_STATE_CONNECTED

  return (
    <Box width={'auto'} flex alignItems="center">
      <SimpeH2 mx="10px" my="0">Input</SimpeH2>
      <Dropdown
        width="222px"
        overflow="auto"
        dropUp
        title={
          inputs.length === 0
            ? 'No devices found'
            : getCurrentInputDevice()?.name || 'Select device'
        }
        menuItems={inputs}
        enabled={enableAudioSelection}
        onChange={async (selectedIndex) => {
          setAudioInput(selectedIndex)
          const outputIndexToSelect = inputs[selectedIndex].outputs > 0 ? outputs.findIndex(({ value }) => value === inputs[selectedIndex].value) : audioOutput
          setAudioOutput(outputIndexToSelect)
          if(outputIndexToSelect >= 0)
            await applySettings(selectedIndex, outputIndexToSelect)
        }}
      />
        <>
            <SimpeH2 mx="10px" my="0">Output</SimpeH2>
          <Dropdown
            width="222px"
            dropUp
            title={
              audioDevices.length === 0
                ? 'No devices found'
                : getCurrentOutputDevice()?.name || 'Select device'
            }
            menuItems={outputs}
            enabled={enableAudioSelection}
            onChange={async (selectedIndex) => {
              setAudioOutput(selectedIndex)
              await applySettings(audioInput, selectedIndex)
            }}
          />
        </>
    </Box>
  )
}

export default observer(MinimalDesktopAudioSelection)
