import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../store'
import { ConsoleLogger, DefaultDeviceController } from 'amazon-chime-sdk-js'
import { logger } from '../../../../utils/logging'
import Dropdown from '../../../ui/form/dropdown'
import { useEffect, useState } from 'react'
import { Box, I, RangeSlider } from '../../../ui/primitives'
import { StyledH2 } from '../styles'
import { TalkbackPeakMeter } from '../../../pages/mixer/components/talkback-peak-meter'
import { MuteButton } from '../../../pages/mixer/components/mute-button'
import { useAudioVideo } from 'amazon-chime-sdk-component-library-react'
import { ELKDarkGrey, ELKHighPrioGreenEnabled } from '../../../../variables'

const AudioSourceSettings = observer(({ close }) => {
  const store = useStore()
  const audioVideo = useAudioVideo()
  const [sourceSettings, setSourceSettings] = useState([])
  const [currentDesktopDeviceId, setCurrentDesktopDeviceId] = useState(-1)
  const [talkbackVolume, setTalkbackVolume] = useState(store.session.talkback.audioElement?.volume || 0.5)
  const renderFromDialog = close !== undefined

  let selectedIndex = sourceSettings.findIndex((setting) => {
    return setting.value === store.settings.videoChat.selectedAudioInputDeviceId
  })

  // Prevent using desktop selected audio input as talkback audio source.
  useEffect(() => {
    if(!store.isConnectedToDesktop()) return

    const currentDevice = store.mixer.getCurrentInputDevice()

    if(currentDevice && sourceSettings.length > 0){
      const desktopDevice = sourceSettings.find((device) => device.title.includes(currentDevice.name))
      const talkbackDevice = sourceSettings.find((device) => device.value === store.settings.videoChat.selectedAudioInputDeviceId)


      if(desktopDevice){
        setCurrentDesktopDeviceId(desktopDevice.value)
        if(talkbackDevice && desktopDevice.value === talkbackDevice.value){
          store.settings.videoChat.selectedAudioInputDeviceId = null
        }
      }
    }
  // eslint-disable-next-line
  }, [store.mixer.desktop, sourceSettings, store.mixer.desktop.selectedInput])

  useEffect(() => {
    const deviceLogger = new ConsoleLogger('VideoChatDeviceController')
    const deviceController = new DefaultDeviceController(deviceLogger)
    async function init () {
      try {
        const deviceList = (await deviceController.listAudioInputDevices()).filter(
          ({ deviceId } ) => deviceId !== 'default'
        )

        setSourceSettings(
          [
            {title: 'No device selected', value: null},
            ...deviceList.map((device, index) => {
              return {
                title: device.label || `Device ${index + 1}`,
                value: device.deviceId,
              }
          })]
        )
      } catch (e) {
        logger.error(e.message)
      }
    }
    init()
    return () => {
      try {
        deviceController.destroy()
      } catch (e) {
        logger.error(e.message)
      }
    }
  }, [])

  useEffect(() => {
    if(audioVideo?.audioMixController && store.session.talkback.audioTrack){
      store.session.talkback.setAudioElement(audioVideo.audioMixController.audioElement)
    }
// eslint-disable-next-line
  },[audioVideo, store.session.talkback.audioTrack])

  const options = sourceSettings.map(((item) =>
    item.value === currentDesktopDeviceId ? { ...item, disabled: true} : item))

  return (
    <>
      <Box flex justifyContent="space-between">
        <StyledH2>Audio Source</StyledH2>
        <div style={{
          width: '18px',
          height: '18px',
          borderRadius: '100%',
          backgroundColor: store.session.talkback.localVolumeIndicator > 0 ? ELKHighPrioGreenEnabled : ELKDarkGrey
        }} />
      </Box>
      <Dropdown
        title={
          sourceSettings.length === 0
            ? 'No devices found'
            : selectedIndex === -1 ? 'No device selected' : options[selectedIndex]?.title
        }
        width='100%'
        menuItems={options}
        enabled={store.sessionMode}
        selectedIndex={selectedIndex}
        onChange={(selected) => {
          store.settings.videoChat.selectedAudioInputDeviceId =
            options[selected].value
          store.settings.save()
          if(close){
            close()
          }
        }}
      />
      {!renderFromDialog && (
        <Box>
          <StyledH2 mt="15px">Incoming Talkback Volume</StyledH2>
          <Box position="relative" mb="10px" display="flex" justifyContent="space-between" >
            <MuteButton
              hideMenu={false}
              muted={talkbackVolume === 0}
              disabled={store.session.talkback.audioElement === null && !store.sessionMode}
              onChange={() => {
                if(talkbackVolume !== 0){
                  store.session.talkback.audioElement.volume = 0
                  setTalkbackVolume(0)
                } else {
                  store.session.talkback.audioElement.volume = 0.5
                  setTalkbackVolume(0.5)
                }
              }}
            />
            <RangeSlider
              type="range"
              onChange={(e) => {
                if(store.session.talkback.audioElement){
                  store.session.talkback.audioElement.volume = e.target.value
                  setTalkbackVolume(Number(e.target.value))
                }
              }}
              min={0} max={1} step={0.1} value={talkbackVolume}
              disabled={store.session.talkback.audioElement === null && !store.sessionMode}
            />
            <TalkbackPeakMeter
              barWidth={10}
              warningLimit={0.9}
              getValue={() => store.session.talkback.getAverageVolumeIndicator()}
              getUpdatedAt={() => Date.now()}
            />
          </Box>
          <I mt="10px">Please note, talkback is not low latency and only meant for basic communication and bantering.</I>
        </Box>
      ) }
    </>
  )
})

export default AudioSourceSettings