import React, { useEffect } from  'react'
import { observer } from 'mobx-react-lite'
import { useStore, withStore } from '../../../../store'
import { openModal } from '../../../dialogs/overlays'
import { Dialog } from '../../../dialogs/base'
import { Box } from '../../../ui/primitives'
import { Spinner } from '../../../ui/spinner'


const LoadingState = () => {
  return (
    <Box flex
         justifyContent="center"
         alignItems="center"
         flexDirection="column"
         textAlign="center"
         width="150px"
         height="150px"
         p="0 20px"
         margin="0 auto"
    >
      <Spinner
        style={{
          verticalAlign: 'top',
          marginRight: '3px',
        }}
        size={22}
        color="var(--ELK-Sea-Foam-Green)"
      />
      setting up... <br />
    </Box>
  )
}

const LoadingDesktopDialog = observer(({ close }) => {
  const store = useStore()

  useEffect(() => {
    if(store.isDesktopAudioInitializing === false){
      close()
    }
    // eslint-disable-next-line
  }, [store.isDesktopAudioInitializing])

  return (
    <Dialog width="auto" bgColor="var(--ELK-Black)">
      <LoadingState close={close}  />
    </Dialog>
  )
})


export function showLoadingDesktopDialog({ store }) {
  return openModal(
    ({ close }) => withStore(store,
      <LoadingDesktopDialog close={close}  />
    )
  )
}
