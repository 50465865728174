import React from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from '../ui/button'
import { CloseButton, Dialog } from './base'
import { openModal } from './overlays'
import { Box, Text, H1 } from '../ui/primitives'

const CoreWasResetDialog = observer(({ close }) => {

  return (
    <Dialog width="60rem" py="3rem" px="3rem"
      bgColor="var(--ELK-White)">
      <CloseButton color="var(--ELK-Black)" onClick={() => close()}/>

      <H1 textAlign="center" mb="1.6rem" color="var(--ELK-Black)">Core was reset</H1>
      <Text textAlign="center" mb="1.4rem" mt="2.5rem" color="var(--ELK-Black)" fontSize="2.4rem">
        You were using a custom core. It has now been reset to the default core. Please reboot your device now and then connect again.
      </Text>

      <Box mt="2rem" maxWidth="26rem" mx="auto">
        <Button primary width="100%" onClick={() => close()}>
          I have rebooted
        </Button>
      </Box>
    </Dialog>
  )
})

export function showCoreWasResetDialog() {
  return openModal(
    ({ close }) => <CoreWasResetDialog close={close} />, {
      autoDismissable: true,
    }
  )
}
