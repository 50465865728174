import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../store'
import { Box } from '../../../ui/primitives'
import { StyledH1 } from '../styles'
import { SettingsMenuButton } from '../AudioSection/desktop'
const Paragraph = styled.p`
  font-size: 14px;
  strong {
    font-weight: 500;
  }
`
const SpeedTestSection = observer(({ close }) => {
  const store = useStore()
  return (
    <>
    {store.speedTestResults.upload && (
      <>
        <StyledH1>Connection Speed</StyledH1>
        <Box>
          <Paragraph><strong>upload  speed:</strong> {store.speedTestResults.upload.toFixed(2)} Mbps</Paragraph>
          <Paragraph><strong>ping:</strong> {store.speedTestResults.ping}ms</Paragraph>
        </Box>
        <SettingsMenuButton
          primary
          disabled={store.isSpeedTestRunning}
          onClick={async () => {
            await store.triggerSpeedTestManually()
          }}
        >
          {store.isSpeedTestRunning ? 'Checking internet connection.. ' : 'Trigger Speed Test'}
        </SettingsMenuButton>
      </>
      )}
    </>
  )
})
export default SpeedTestSection