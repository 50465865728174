import React from 'react'
import { LegacyButton } from '../legacy-button'
import { CloseButton, Dialog } from '../../dialogs/base'
import { openModal } from '../../dialogs/overlays'
import { Box, H1, P, Link, Span } from '../../ui/primitives'
import { showAdvancedConfigurationSettingsDialog } from './advanced-settings'
import { useStore, withStore } from '../../../store'

const NatErrorDialog = ({ close, boardInfo, error }) => {
  const store = useStore()
  const { ipAddress, routerIp, publicIp, basePorts, networkTestMessage } = error.data
  return (
    <Dialog width="56rem" py="4rem">
      <CloseButton inset="1.5rem" onClick={()=> close()}/>

      <Box textAlign="center" mx="auto" width="34rem">
        <H1 textAlign="center" mb="2rem">Network Error</H1>

        <P fontSize="1.3rem" fontWeight="700">Timed out while waiting for port-forwarding test.</P>

        <P>
          uPnP might not be working as expected on your router, or you might have not configured
          properly the port-forwarding rules, or be under NAT from your ISP.
        </P>
        <P>
          External IP address reported by the router:<br />
          <Span fontWeight="700">{routerIp}</Span>
        </P>

        <P>
          External IP address detected:<br />
          <Span fontWeight="700">{publicIp}</Span>
        </P>

        <P>
          Extra error information:<br/>
          <Span fontWeight="700">{networkTestMessage}</Span>
        </P>

        <P>
          UDP ports in use:<br/>
          <Span fontWeight="700">{basePorts[0] ?? 'null'}</Span>, <Span fontWeight="700">{basePorts[1] ?? 'null'}</Span>
        </P>

        <P>
          Enter <em>Advanced Settings</em> and under network specify <em>Manual UDP Port</em> with a number N (eg. N=35000)
          and in your router, set up port forwarding rules for the device with IP {ipAddress} on UDP ports N, N+1 (e.g. 35000, 35001).
        </P>

        <P>
          For more help check <Link fontWeight="700" className="underline" href="https://elkaudio.zendesk.com/hc/en-us/articles/360016220837" target="_blank" rel="noreferrer">Port-Forwarding Guide</Link>.
        </P>

        <Box mt="2rem">
          <LegacyButton secondary width="26rem" mx="auto" onClick={()=> { close(); showAdvancedConfigurationSettingsDialog({ store, boardInfo }) }}>Advanced Settings</LegacyButton>
        </Box>
      </Box>

    </Dialog>
  )
}

export function showNatErrorDialog({store, boardInfo, error}) {
  return openModal(
    ({ close }) => withStore(store, <NatErrorDialog error={error} close={close} />), {
      autoDismissable: true,
    }
  )
}