import { BgContainer, BgOverlay, Container } from './styles'

export const LoginLayout = ({ children }) => {
  return (
    <Container>
      <BgContainer>
        <BgOverlay>
          {children}
        </BgOverlay>
      </BgContainer>
    </Container>
  )
}