import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { useState } from 'react'
import { CloseButton, Dialog } from './base'
import { useStore, withStore } from '../../store'
import { openModal } from './overlays'
import { Button } from '../ui/button'
import { Box, H1, H2, Link } from '../ui/primitives'
import { showDeviceInfoDialog } from './device-info'
import { showDeviceSelectionDialog } from './DeviceSelection'

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const DeviceNotFoundDialog = observer(({ close }) => {

  const store = useStore()
  const [scanning, setScanning] = useState(false)

  return (
    <Dialog
      flex
      flexDirection="column"
      px="5.7rem"
      py="4.3rem"
      bgColor="var(--ELK-White)"
      color="var(--ELK-Black)"
      width="70rem"
      alignItems="center"
      fontSize="1.6rem"
    >
      <CloseButton onClick={() => close()} color="var(--ELK-Black)" />
      <H1 mb="3rem" color="var(--ELK-Black)">My Bridge</H1>
      <H2>Can't find the Elk Bridge</H2>
      <Box width="40rem" mt="2rem" textAlign="center">
        Make sure your Bridge is plugged in, turned on and running on the same network as your computer.
        <br />
        <br />
        Please also make sure any VPN service is turned off while using Elk LIVE.
        <br />
        <br />
        <StyledLink href="https://help.elk.live/en/articles/5900661-bridge-not-found" target="_blank" rel="noreferrer">Click here for more info</StyledLink>
      </Box>
      <Box mt="4rem">
        <Button primary disabled={scanning} onClick={async () => {
          setScanning(true)
          await store.queryDevicesOnNetwork()
          await sleep(700)
          setScanning(false)
          if (store.devices.length === 1) {
            close()
            showDeviceInfoDialog({ store, device: store.devices[0] })
          } else if (store.devices.length > 1) {
            close()
            showDeviceSelectionDialog({ store })
          }
        }}>Re-scan</Button>
      </Box>
    </Dialog>
  )
})

export function showDeviceNotFoundDialog({ store }) {
  store.userHasOpenedDeviceSelection = true
  return openModal(
    ({ close }) => withStore(store, <DeviceNotFoundDialog close={close} />),
    {
      autoDismissable: true,
    },
  )
}
