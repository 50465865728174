import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../../store'
import { Box, Text } from '../../../../ui/primitives'
import { Button } from '../../../../ui/button'
import OpenChangeSubscriptionDialog from '../../../../dialogs/ChangeSubscription'
import OpenChangePaymentDialog from '../../../../dialogs/ChangePayment'
import OpenCancelSubscriptionDialog from '../../../../dialogs/CancelSubscription'
import Transactions from '../transactions'
import {
  Container,
  Header,
  SubscriptionH1,
  SubscriptionH2,
} from '../styles'

const ActiveSubscription = styled.span`
  color: var(--ELK-Sea-Foam-Green);
`;

const Active = observer(() => {
  const store = useStore()
  const subscriptionCanceledAt = store.settings.subscription.canceledAt
  const currentSubscription = store.currentUser.fastspringData?.subscription || {}
  const { intervalUnit, priceDisplay } = currentSubscription

  return (
    <Container>
      <Header>
        <SubscriptionH1>Subscription <ActiveSubscription>(active)</ActiveSubscription></SubscriptionH1>
        <SubscriptionH2>
          You’ve got an active subscription{priceDisplay ? ` of ${priceDisplay}` : ''}{intervalUnit && ` that is billed ${intervalUnit === 'month' ? 'monthly' : 'annually'}`}.
        </SubscriptionH2>
      </Header>

      <Box flex column mt="2rem">
        <Button primary my="0.5rem" fontSize="14px" height="auto" onClick={() => OpenChangeSubscriptionDialog({ store })}>Change subscription</Button>
        <Button primary my="0.5rem" fontSize="14px" height="auto" onClick={() => OpenChangePaymentDialog({ store })}>Change payment method</Button>
        <Box flex alignItems="center">
          <Button disabled={!!subscriptionCanceledAt} primary my="0.5rem" fontSize="14px" height="auto" onClick={() => OpenCancelSubscriptionDialog({ store })}>Cancel Subscription</Button>
          {subscriptionCanceledAt && <Text fontWeight="bold" ml="1rem" fontSize="1.4rem" color="var(--ELK-High-Contrast-Red)">Cancel request sent to Elk support {new Date(subscriptionCanceledAt).toISOString().substring(0, 10)}</Text>}
        </Box>
      </Box>
      <Transactions />
    </Container>
  )
})

export default Active